import { TextDefinition, Texts } from "./types";
import { exhaustiveCheck } from "ts-exhaustive-check";

const defaultDb = "TOP";

export function translate(
  texts: Texts,
  textDefinition: TextDefinition,
  showTextDb: boolean,
  showWithTextId: boolean
): string {
  const getStaticTextTranslation = (textKey: string, textDb?: string) =>
    getStaticText(texts, textKey, textDb, showTextDb, showWithTextId);

  switch (textDefinition.type) {
    case "LanguageIdText":
      return getStaticTextTranslation(textDefinition.languageId);
    case "StaticText":
      return getStaticTextTranslation(
        textDefinition.textId,
        textDefinition.systemTypeId
      );
    case "CurlyText":
      return getCurlyText(
        texts,
        textDefinition.text,
        textDefinition.systemTypeId,
        showTextDb,
        showWithTextId
      );
    case "StaticTextWithParameters": {
      return getStaticTextWithParameters(
        texts,
        textDefinition.textId,
        textDefinition.parameters
      );
    }
    case "SystemIdText":
      return getStaticTextTranslation(
        textDefinition.systemId,
        textDefinition.systemId.toUpperCase()
      );
    case "TabTitleText":
      return getStaticTextTranslation(textDefinition.key);

    case "ProductText":
      return getStaticTextTranslation(
        textDefinition.productId,
        textDefinition.productId.substring(0, 3).toUpperCase()
      );
    case "ProductPropertyText": {
      const systemTypeId = textDefinition.productId.substring(0, 3);
      const key = `p_${systemTypeId}_${textDefinition.propertyName}`;
      return getStaticTextTranslation(key, systemTypeId.toUpperCase());
    }
    case "ProductPropertyValueText": {
      const systemTypeId = textDefinition.productId.substring(0, 3);
      const key = `p_${systemTypeId}_${textDefinition.propertyName}_${textDefinition.propertyValue}`;
      return getStaticTextTranslation(key, systemTypeId.toUpperCase());
    }

    case "SystemTypePropertyText": {
      const key = `p_${textDefinition.systemTypeId.toLowerCase()}_${
        textDefinition.propertyName
      }`;
      return getStaticTextTranslation(
        key,
        textDefinition.systemTypeId.toUpperCase()
      );
    }

    case "SystemTypePropertyValueText": {
      const key = `p_${textDefinition.systemTypeId.toLowerCase()}_${
        textDefinition.propertyName
      }_${textDefinition.propertyValue}`;

      return getStaticTextTranslation(
        key,
        textDefinition.systemTypeId.toUpperCase()
      );
    }

    case "TranslateTodoText":
      return `{${textDefinition.textId}}`;

    case "ReportParamText": {
      const key = `rp_${textDefinition.paramName}`;
      return getStaticTextTranslation(key, textDefinition.systemTypeId);
    }

    case "ReportParamValueText": {
      const key = `rp_${textDefinition.paramName}_${textDefinition.paramValue}`;
      return getStaticTextTranslation(key, textDefinition.systemTypeId);
    }
    default:
      return exhaustiveCheck(textDefinition, true);
  }
}

function fallBackTextDb(texts: Texts, textId: string, showTextDb: boolean) {
  const db = texts[defaultDb] || {};
  const text = db[textId.toLowerCase()];

  if (!text) {
    return undefined;
  }
  return `${text} ${showTextDb ? "(FALLBACK)" : ""}`;
}

function fromSystemTypeDB(
  texts: Texts,
  textId: string,
  textDB: string,
  showTextDb: boolean
) {
  const db = texts[textDB] || {};
  const text = db[textId.toLowerCase()];

  if (!text) {
    return undefined;
  }

  if (textDB === defaultDb) {
    return `${text} ${showTextDb ? "(TOP)" : ""}`;
  }

  return `${text} ${showTextDb ? "(SYSTEMDB)" : ""}`;
  return text + "(SYSTEMDB)";
}

function getStaticText(
  texts: Texts,
  textId: string,
  textDb = defaultDb,
  showTextDb: boolean,
  showWithTextId: boolean
): string {
  const textIdStringified = `{${textId}}`;

  if (showWithTextId) {
    const translatedText =
      fromSystemTypeDB(texts, textId, textDb, showTextDb) ||
      fallBackTextDb(texts, textId, showTextDb);

    if (translatedText) {
      return translatedText + textIdStringified;
    }
    return textIdStringified;
  }
  return (
    fromSystemTypeDB(texts, textId, textDb, showTextDb) ||
    fallBackTextDb(texts, textId, showTextDb) ||
    textIdStringified
  );
}

function getStaticTextWithParameters(
  texts: Texts,
  textId: string,
  parameters: ReadonlyArray<string>
): string {
  const textDb = texts[defaultDb] || {};
  const text = textDb[textId.toLowerCase()];
  if (!text) {
    return `{${textId}}`;
  }

  return parameters.reduce(
    (soFar, current, ix) => soFar.replace(`{${ix}}`, current),
    text
  );
}

const curlyRegex = /\{(.+?)\}/g;
function getCurlyText(
  texts: Texts,
  textKey: string,
  textDb: string,
  showTextDb: boolean,
  showWithTextId: boolean
): string {
  const translated = textKey.replace(curlyRegex, (_, group1) =>
    getStaticText(texts, group1, textDb, showTextDb, showWithTextId)
  );

  return translated;
}
