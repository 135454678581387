import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Amount } from "@genesys/uom";
import { PropertyValue } from "@genesys/property";
import { getValue } from "@genesys/shared/lib/product-properties";
import { Physics } from "@munters/calculations";
import { BinDataLocation } from "../../location-selector";
import { BinListViewType, BinType } from "../types";
import { listFieldGroup } from "./list-field-group";

export function createBinList(
  selectedBinType: BinType,
  selectedBinListView: BinListViewType,
  binCases: ReadonlyArray<SharedEnergyTools.BinCase>,
  pressure: PropertyValue.PropertyValue | undefined,
  translate: LanguageTexts.Translate,
  getAmountFormat: ScreenAmounts.GetAmountFormat
): SharedEnergyTools.EnergyResultTable {
  const getList = () => {
    switch (selectedBinType) {
      case "Generated":
      case "Custom": {
        if (selectedBinListView === "standard") {
          return SharedEnergyTools.createStandardBinList(
            binCases,
            translate,
            getAmountFormat,
            listFieldGroup
          );
        }

        return SharedEnergyTools.createBinListWithMonths(
          binCases,
          translate,
          getAmountFormat,
          listFieldGroup
        );
      }
      case "MoistureLoad": {
        if (selectedBinListView === "standard") {
          return SharedEnergyTools.createMoistureLoadBinList(
            binCases,
            translate,
            getAmountFormat,
            listFieldGroup
          );
        }

        return SharedEnergyTools.createMoistureLoadBinListMonthly(
          binCases,
          translate,
          getAmountFormat,
          listFieldGroup
        );
      }
      default:
        return exhaustiveCheck(selectedBinType, true);
    }
  };

  const binListWithEnthalpyColumn = addEnthalpyColumn(
    getList(),
    binCases,
    pressure,
    getAmountFormat,
    translate
  );

  return binListWithEnthalpyColumn;
}

function addEnthalpyColumn(
  list: SharedEnergyTools.EnergyResultTable,
  binCases: ReadonlyArray<SharedEnergyTools.BinCase>,
  climatePressure: PropertyValue.PropertyValue | undefined,
  getAmountFormat: ScreenAmounts.GetAmountFormat,
  translate: LanguageTexts.Translate
): SharedEnergyTools.EnergyResultTable {
  const headers = Array.from(list.formattedHeader);
  const humidityIndex = headers.findIndex(x => x.internalName === "Humidity");
  const pressureIndex = headers.findIndex(
    x => x.internalName === "BinPressure"
  );
  const temperatureIndex = headers.findIndex(
    x => x.internalName === "AverageTemp"
  );

  const specificEnthalpyHeader: SharedEnergyTools.EnergyListColumn = {
    internalName: "SpecificEnthalpy",
    name: translate(LanguageTexts.averageSpecificEnthalpy()),
    format: getAmountFormat(
      listFieldGroup,
      "SpecificEnthalpy",
      "SpecificEnthalpy"
    ),
    createConversionParameters: undefined
  };

  headers.splice(humidityIndex + 1, 0, specificEnthalpyHeader);

  const rows = binCases.map(
    bc => SharedEnergyTools.getBaseColumns(bc, "Acceleration") // Just chose a random quantity
  );

  const newRows = Array.from(list.formattedRows).map((x, i) => {
    const pressure = climatePressure
      ? (PropertyValue.getAmount(climatePressure) as Amount.Amount<"Pressure">)
      : (rows[i][pressureIndex - 1] as Amount.Amount<"Pressure">);

    const temperature = rows[i][
      temperatureIndex - 1
    ] as Amount.Amount<"Temperature">;

    const hum = rows[i][humidityIndex - 1] as Amount.Amount<"HumidityRatio">;

    const specifcEnthalpy =
      Physics.RP1485.AmountConversions.humidityRatioToSpecificEnthalpy(
        pressure,
        temperature,
        hum
      );

    const updatedRow = Array.from(x);
    updatedRow.splice(
      humidityIndex + 1,
      0,
      getValue(
        PropertyValue.fromAmount(specifcEnthalpy!),
        specificEnthalpyHeader.format!
      )
    );

    return updatedRow;
  });

  return {
    ...list,
    formattedHeader: headers,
    formattedRows: newRows
  };
}

export function createBinsExportData(
  table: SharedEnergyTools.EnergyResultTable
): string[][] {
  return [
    table.formattedHeader.map(
      h => h.name + (h.format ? " (" + h.format.unit.name + ")" : "")
    )
  ].concat(table.formattedRows);
}

export function isMonthlyBinTablePossible(
  binCases: ReadonlyArray<SharedEnergyTools.BinCase>
): boolean {
  return binCases.every(
    br =>
      br.binTimeJanuary !== undefined &&
      br.binTimeFebruary !== undefined &&
      br.binTimeMarch !== undefined &&
      br.binTimeApril !== undefined &&
      br.binTimeMay !== undefined &&
      br.binTimeJune !== undefined &&
      br.binTimeJuly !== undefined &&
      br.binTimeAugust !== undefined &&
      br.binTimeSeptember !== undefined &&
      br.binTimeOctober !== undefined &&
      br.binTimeNovember !== undefined &&
      br.binTimeDecember !== undefined
  );
}

export function removePressureColumn(
  list: SharedEnergyTools.EnergyResultTable
) {
  const headers = Array.from(list.formattedHeader);
  const index = headers.findIndex(x => x.internalName === "BinPressure");

  headers.splice(index, 1);

  const newRows = Array.from(list.formattedRows).map(x =>
    x.filter((_y, i) => i !== index)
  );

  return {
    ...list,
    formattedHeader: headers,
    formattedRows: newRows
  };
}

export function getLocationExportData(
  binDataLocation: BinDataLocation | undefined,
  binPressure: PropertyValue.PropertyValue | undefined,
  translate: LanguageTexts.Translate
): ReadonlyArray<ReadonlyArray<string>> {
  if (binDataLocation === undefined) {
    return [[]];
  }
  const {
    countryName,
    regionName,
    locationName,
    latitude,
    longitude,
    binDataLocationId
  } = binDataLocation;

  return [
    [translate(LanguageTexts.country()), countryName],
    [translate(LanguageTexts.state()), regionName],
    [translate(LanguageTexts.location()), locationName],
    [translate(LanguageTexts.latitude()), latitude.toString()],
    [translate(LanguageTexts.longitude()), longitude.toString()],
    ["wmo", binDataLocationId],
    [
      translate(LanguageTexts.binPressure()),
      binPressure ? PropertyValue.toString(binPressure) : "Undefined"
    ]
  ];
}
