import * as React from "react";
import {
  HeaderBarContainer,
  FastNavContainer,
  StatusIndicatorContainer,
  HeaderBarLeftContainer,
  HeaderBarRightContainer,
  MeasurementsContainer,
  HeaderBarInnerRightContainer
} from "../elements";
import { IconButton, ToolTipWrapper, S2 } from "@genesys/ui-elements";
import {
  Files,
  Price,
  Printer,
  Measurements,
  LogsIcon
} from "@genesys/ui-elements/lib/icons";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import { SystemInfo, SystemInfoProps } from "./system-info";
import { CalculateButton } from "./calculate-button";
import * as SharedState from "../../shared-state";
import * as StatusIcon from "../../status-icon-manager";

export interface Props {
  readonly filesClick: () => void;
  readonly priceClick: () => void;
  readonly printClick: () => void;
  readonly systemInfoProps: SystemInfoProps;
  readonly systemStatus: number;
  readonly sharedState: SharedState.State;
  readonly showConfirmLockRevision: boolean;
  readonly showEnergyBinSelectionsValidationError: boolean;
  readonly translate: LanguageTexts.Translate;
  readonly oncalculateEnergy: () => void;
  readonly oncalculateMCompare: () => void;
  readonly calculate: () => void;
  readonly lockSystem: () => void;
  readonly cancelLock: () => void;
  readonly calculateEnergy: boolean;
  readonly calculateMCompare: boolean;
  readonly setActiveAmountField: (amountProfileId: string) => void;
  readonly toggleEnergyBinSelectionsValidationError: () => void;
  readonly traceId: string | undefined;
}

export function HeaderBar(props: Props): JSX.Element {
  const isDeveloper = AuthorizationTools.checkPermission(
    props.sharedState.user.applicationClaims,
    AuthorizationTools.genesysUserClaims.developer
  );
  return (
    <HeaderBarContainer>
      <HeaderBarLeftContainer>
        <SystemInfo {...props.systemInfoProps} />
        <FastNavContainer>
          <ToolTipWrapper
            title={props.translate(LanguageTexts.notes())}
            onClick={props.filesClick}
          >
            <IconButton icon={<Files height="1.6rem" />} />
          </ToolTipWrapper>

          {props.systemInfoProps.showAdjustPrice && (
            <ToolTipWrapper
              title={props.translate(LanguageTexts.adjustPrice())}
              onClick={props.priceClick}
            >
              <IconButton icon={<Price height="1.6rem" />} />
            </ToolTipWrapper>
          )}
          {props.systemInfoProps.showSystemPrintoutScreen && (
            <ToolTipWrapper
              title={props.translate(LanguageTexts.systemPrintout())}
              onClick={props.printClick}
            >
              <IconButton icon={<Printer height="1.6rem" />} />
            </ToolTipWrapper>
          )}
          {isDeveloper && props.traceId && (
            <ToolTipWrapper title="Calculation logs">
              <a href={`/log-viewer/${props.traceId}`} target="_blank">
                <IconButton icon={<LogsIcon height="1.6rem" />} />
              </a>
            </ToolTipWrapper>
          )}
        </FastNavContainer>
      </HeaderBarLeftContainer>
      <HeaderBarRightContainer>
        <MeasurementsContainer>
          <Measurements />
          {props.sharedState.user.settings.amountFieldProfiles
            .filter(a => a.name === "SI" || a.name === "IP")
            .map(a => (
              <div
                key={a.measureSystem}
                onClick={() => props.setActiveAmountField(a.id)}
              >
                <S2
                  weight="bold"
                  color={
                    props.sharedState.user.settings.selectedAmountProfile.id ===
                    a.id
                      ? "dark"
                      : "light"
                  }
                >
                  {props
                    .translate(
                      LanguageTexts.globalPropertyName(a.name.toLowerCase())
                    )
                    .toUpperCase()}
                </S2>
              </div>
            ))}
        </MeasurementsContainer>
        <HeaderBarInnerRightContainer>
          <StatusIndicatorContainer>
            <StatusIcon.StatusIconManager
              sharedState={props.sharedState}
              statusNumber={props.systemStatus}
              height="21.81px"
              width="21.81px"
            ></StatusIcon.StatusIconManager>
          </StatusIndicatorContainer>
          <CalculateButton
            calculateEnergyConparison={props.calculateMCompare}
            onCalculateEnergyComparisons={props.oncalculateMCompare}
            showConfirmLockRevision={props.showConfirmLockRevision}
            showEnergyBinSelectionsValidationError={
              props.showEnergyBinSelectionsValidationError
            }
            sharedState={props.sharedState}
            toggleConfirmBox={props.cancelLock}
            toggleEnergyBinSelectionsValidationError={
              props.toggleEnergyBinSelectionsValidationError
            }
            onLock={props.lockSystem}
            calculateEnergy={props.calculateEnergy}
            status={props.systemStatus}
            translate={props.translate}
            onCalculateEnergy={props.oncalculateEnergy}
            onCalculate={props.calculate}
          />
        </HeaderBarInnerRightContainer>
      </HeaderBarRightContainer>
    </HeaderBarContainer>
  );
}
