import gql from "graphql-tag";

export const energyResultEnergyTotalItem = gql`
  fragment EnergyResultEnergyTotalItem on EnergyTotalItemType {
    id
    name
    sortNo
  }
`;

export const energyResultEnergyListItem = gql`
  fragment EnergyResultEnergyListItem on EnergyListItemType {
    id
    name
    sortNo
  }
`;

export const energyResultChartPresets = gql`
  fragment EnergyResultChartPresets on EnergyChartPresetType {
    id
    name
    y1Label
    y1AxisType
    y1PerfParams
    y1UnitSi
    y1UnitIp
    y2Label
    y2AxisType
    y2PerfParams
    y2UnitSi
    y2UnitIp
  }
`;

export const energyResultOperatingCaseResult = gql`
  fragment EnergyResultOperatingCaseResult on OperatingCaseResultType {
    id
    settings
    componentId
  }
`;

export const energyResultOperatingCase = gql`
  fragment EnergyResultOperatingCase on OperatingCaseType {
    id
    caseType
    binData
    results {
      ...EnergyResultOperatingCaseResult
    }
  }
  ${energyResultOperatingCaseResult}
`;

export const energyResultComponent = gql`
  fragment EnergyResultComponent on ComponentType {
    id
    productId
    properties
  }
`;

export const energyResultSystem = gql`
  fragment EnergyResultSystem on SystemItemType {
    id
    binSelections
    revisionNo
    status
    systemFile {
      id
      name
      genesysNo
      systemTypeId
    }
    components {
      ...EnergyResultComponent
    }
    operatingCases {
      ...EnergyResultOperatingCase
    }
  }
  ${energyResultComponent}
  ${energyResultOperatingCase}
`;

export const completeEnergyParam = gql`
  fragment CompleteEnergyParam on EnergyParamType {
    paramName
    amountFieldValue {
      amount
      amountField {
        fieldGroup
        fieldName
      }
    }
  }
`;

export const completeEnergyCost = gql`
  fragment CompleteEnergyCost on EnergyCostGraphType {
    name
    cost
    currencySymbol
  }
`;

export const completeCoeffResult = gql`
  fragment CompleteCoeffResult on CoeffResultType {
    airVelocity
    airflow
    supplierPower
    coilProPower
    diffPower
    diffPowerPercentage
    supplierAirPressureDrop
    coilProAirPressureDrop
    diffAirPressureDrop
    diffAirPressureDropPercentage
  }
`;

export const completeCoilProHeatingCoeffResult = gql`
  fragment CompleteCoilProHeatingCoeffResult on HeatingCoefficientsResultsType {
    hasError
    errorText
    coeffs {
      aDry
      bDry
      cDry
      dDry
      aWet
      bWet
      cWet
      dWet
    }
    heatingResults {
      ...CompleteCoeffResult
    }
  }
  ${completeCoeffResult}
`;

export const completeCoilProCoolingCoeffResult = gql`
  fragment CompleteCoilProCoolingCoeffResult on CoolingCoefficientsResultsType {
    hasError
    errorText
    coeffs {
      aDry
      bDry
      cDry
      dDry
      aWet
      bWet
      cWet
      dWet
    }
    coolingResults {
      ...CompleteCoeffResult
    }
    heatingResults {
      ...CompleteCoeffResult
    }
  }
  ${completeCoeffResult}
`;

export const completeCoilProFreonResults = gql`
  fragment CompleteCoilProFreonResults on FreonResultsType {
    hasError
    errorCode
    errorText
    airPressureDrop
    airPressureDropDry
    airTotalCapacity
    temperatureOut
    humidityOut
    pressureOut
    flow
    airVelocity
    condensedWater
    sensibleTotalCapacityRatio
    marginal
    freonPressureDrop
    freonMassFlow
    freonLiquidVelocity
    freonGasVelocity
    supplierCode
  }
`;

export const completeCoilProFreonTargetTemperatureResults = gql`
  fragment CompleteCoilProFreonTargetTemperatureResults on FreonTargetTemperatureResultsType {
    targetReached
    marginOnTotalPower
    results {
      ...CompleteCoilProFreonResults
    }
  }
  ${completeCoilProFreonResults}
`;

export const completeCoilProSteamResults = gql`
  fragment CompleteCoilProSteamResults on SteamResultsType {
    hasError
    errorCode
    errorText
    airPressureDropDry
    airTotalHeat
    steamTotalHeat
    totalHeat
    temperatureOut
    humidityOut
    pressureOut
    flow
    airVelocity
    sensibleTotalCapacityRatio
    marginal
    steamMassFlow
    steamVelocity
    supplierCode
  }
`;

export const completeCoilProSteamTargetTemperatureResults = gql`
  fragment CompleteCoilProSteamTargetTemperatureResults on SteamTargetTemperatureResultsType {
    targetReached
    marginOnTotalPower
    results {
      ...CompleteCoilProSteamResults
    }
  }
  ${completeCoilProSteamResults}
`;

export const completeFanProFanResults = gql`
  fragment CompleteFanProFanResults on FanResultsType {
    hasError
    errorCode
    errorText
    version
    airVelocity
    staticEfficiency
    staticPressure
    dynamicPressure
    totalPressure
    totalEfficiency
    totalPowerConsumption
    shaftPower
    soundPowerLevelsInlet
    soundPowerLevelsOutlet
    fanSpeed
    fanMaxSpeed
    airFlowPerFan
    specificFanPower
    temperatureOut
    humidityOut
    massFlow
    current
    cosPhi
  }
`;

export const completeCoilProFluidResults = gql`
  fragment CompleteCoilProFluidResults on FluidResultsType {
    hasError
    errorCode
    errorText
    airPressureDrop
    airPressureDropDry
    airTotalCapacity
    temperatureOut
    humidityOut
    pressureOut
    flow
    airVelocity
    condensedWater
    sensibleTotalCapacityRatio
    marginal
    fluidPressureDrop
    fluidTemperatureIn
    fluidTemperatureOut
    fluidVolumeFlow
    fluidDensity
    fluidVelocity
    reynoldsNumberFluid
    supplierCode
  }
`;

export const completeCoilProFluidTargetTemperatureResults = gql`
  fragment CompleteCoilProFluidTargetTemperatureResults on FluidTargetTemperatureResultsType {
    targetReached
    marginOnTotalPower
    results {
      ...CompleteCoilProFluidResults
    }
  }
  ${completeCoilProFluidResults}
`;

export const completeAmountFieldFormat = gql`
  fragment CompleteAmountFieldFormat on AmountFieldFormatType {
    id
    fieldGroup
    fieldName
    measureSystem
    unit
    decimalCount
  }
`;

export const completeAmountFieldProfile = gql`
  fragment CompleteAmountFieldProfile on AmountFieldProfileType {
    id
    name
    measureSystem
    isEditable
    amountFieldFormats {
      ...CompleteAmountFieldFormat
    }
  }
  ${completeAmountFieldFormat}
`;

export const completeLocale = gql`
  fragment CompleteLocale on LocaleType {
    id
    name
  }
`;

export const completeLanguage = gql`
  fragment CompleteLanguage on LanguageType {
    id
    texts
  }
`;

export const completeCurrency = gql`
  fragment CompleteCurrency on CurrencyType {
    id
  }
`;

export const completeUserCultureSettings = gql`
  fragment CompleteUserCultureSettings on UserSettingsCultureType {
    id
    locale {
      ...CompleteLocale
    }
    language {
      ...CompleteLanguage
    }
    currency {
      ...CompleteCurrency
    }
  }

  ${completeLocale}
  ${completeLanguage}
  ${completeCurrency}
`;

export const completeUserClimateSettings = gql`
  fragment CompleteUserClimateSettings on UserSettingsClimateType {
    id
    country {
      id
    }
    region {
      id
    }
    location {
      id
    }
    heatingDataType
    coolingDataType
    heatingOccurence
    coolingOccurence
    climateDataSource
    manualData
  }
`;

export const completeUserSettingsSales = gql`
  fragment CompleteUserSettingsSales on UserSettingsSalesType {
    id
    defaultSalesOrganisation
  }
`;

// We don't want to include a full system in this graph
export const completeUserSettings = gql`
  fragment CompleteUserSettings on UserSettingsType {
    id
    amountFieldProfiles {
      ...CompleteAmountFieldProfile
    }
    culture {
      ...CompleteUserCultureSettings
    }
    sales {
      ...CompleteUserSettingsSales
    }
    selectedAmountFieldProfile {
      ...CompleteAmountFieldProfile
    }
  }

  ${completeAmountFieldProfile}
  ${completeUserCultureSettings}
  ${completeUserSettingsSales}
`;

export const completeOwnerInfo = gql`
  fragment CompleteOwnerInfo on UserInfoType {
    id
    name
    userName
  }
`;

export const completeLabel = gql`
  fragment CompleteLabel on LabelType {
    id
    name
    userProfileId
  }
`;

export const completePricingRow = gql`
  fragment CompletePricingRow on PricingRowType {
    id
    articleNo
    description
    transferPricePerUnit
    masterPricePerUnit
    systemId
    rowNo
    type
    visualizerCode
    quantity
    unit
    costPerUnit
    pid
  }
`;

export const completePricing = gql`
  fragment CompletePricing on PricingType {
    id
    name
    comment
    status
    statusDate
    revisionNo
    masterMode
    currencyCode
    salesOrganisationId
    masterSalesOrganisationId
    exchangeRateTemplateId
    owner {
      ...CompleteOwnerInfo
    }
    pricingRows {
      ...CompletePricingRow
    }
    pricingFile {
      ...PricingFile
    }
  }

  ${completeOwnerInfo}
  ${completePricingRow}

  fragment PricingFile on PricingFileType {
    id
    name
    pricingNo
    labels {
      ...CompleteLabel
    }
  }

  ${completeLabel}
`;

export const completePricingFile = gql`
  fragment CompletePricingFile on PricingFileType {
    id
    name
    pricingNo
    owner {
      ...CompleteOwnerInfo
    }
    latestPricing {
      ...CompletePricing
    }
    pricings {
      ...CompletePricing
    }

    labels {
      ...CompleteLabel
    }
  }

  ${completeOwnerInfo}
  ${completePricing}
  ${completeLabel}
`;

export const completeSystemFile = gql`
  fragment CompleteSystemFile on SystemFileType {
    id
    name
    genesysNo
    systemTypeId
    isDeleted
    owner {
      ...CompleteOwnerInfo
    }
    labels {
      ...CompleteLabel
    }
    systems {
      id
      revisionNo
      status
      statusDate
      comment
      owner {
        ...CompleteOwnerInfo
      }
    }
  }

  ${completeOwnerInfo}
  ${completeLabel}
`;

export const completeSystemItem = gql`
  fragment CompleteSystemItem on SystemItemType {
    id
    revisionNo
    status
    statusDate
    comment
    owner {
      ...CompleteOwnerInfo
    }
    systemFile {
      ...CompleteSystemFile
    }
  }
  ${completeOwnerInfo}
  ${completeSystemFile}
`;

export const completePricingArticle = gql`
  fragment CompletePricingArticle on PricingArticleType {
    id
    salesOrganisationId
    articleNo
    description
    quantity
    unit
    costPerUnit
    transferPricePerUnit
    sortNo
    currencyCode
    templateGroup
  }
`;

export const completeSystemTemplateComponentSectionType = gql`
  fragment CompleteSystemTemplateComponentSectionType on SystemTemplateComponentSectionType {
    id
    airstream
    productSectionId
    sortNo
  }
`;

export const completeTemplateComponentComponentSectionType = gql`
  fragment CompleteTemplateComponentComponentSectionType on TemplateComponentComponentSectionType {
    id
    sections {
      ...CompleteSystemTemplateComponentSectionType
    }
  }
  ${completeSystemTemplateComponentSectionType}
`;

export const moistureLoadToolUserSettings = gql`
  fragment MoistureLoadToolUserSettings on MoistureLoadCalcInputSettingsType {
    id
    settings
    caseOverridesDesignSummer
    caseOverridesDesignWinter
    caseOverridesJanuary
    caseOverridesFebruary
    caseOverridesMarch
    caseOverridesApril
    caseOverridesMay
    caseOverridesJune
    caseOverridesJuly
    caseOverridesAugust
    caseOverridesSeptember
    caseOverridesOctober
    caseOverridesNovember
    caseOverridesDecember
    binSize
    binDataLocation
    coolingDataType
    climateSettings
    systemOperationTime
    diffusionThroughMaterialsOperationTime
    evaporationOfWaterOperationTime
    evaporationFromMaterialsOperationTime
    emissionFromCombustionOperationTime
    emissionFromPeopleOperationTime
    heatTransmissionOperationTime
    intentionalVentilationOperationTime
    staticMoistureLoadOperationTime
    staticHeatLoadOperationTime
    unintendedVentilationOperationTime
  }
`;

export const moistureLoadResultFragment = gql`
  fragment MoistureLoadResultFragment on MoistureLoadResultType {
    allBinResults
    diagramResults
    diagramResultsToShow
    summaryAndMonthlyResultsToShow
    resultWarnings {
      diagramWarnings {
        warning
        plainTextResultNames
      }
      summaryWarnings {
        warning
        localizedResultNames {
          name
          nameResourceString
        }
      }
    }

    monthlyResults {
      resultName {
        name
        nameResourceString
      }
      propertyValueSet
    }
    summaryResults {
      resultName {
        name
        nameResourceString
      }
      propertyValueSet
    }

    maxLoadResults {
      id
      selectedFlowId
      caseType
      needResourceString
      caseName
      usedRoomControlSettings
      operatingCaseResults
      roomWarnings
      flowSuggestions {
        id
        flowSourceId
        isValid
        result
      }
    }
  }
`;

// tslint:disable-next-line:max-file-line-count
