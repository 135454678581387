import styled from "styled-components";

export const Root = styled.div`
  padding: 25px 50px 25px 50px;
  overflow-y: auto;
`;

export const ExportContainer = styled.div`
  display: flex;
  button {
    margin-left: 8px;
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 731px;
`;

export const FlowDiagramContainer = styled.div`
  max-width: 85%;
  max-height: 85%;
  svg {
    width: 100%;
    height: 100%;
  }
`;
