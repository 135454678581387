import * as React from "react";
import styled from "styled-components";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  Label,
  Input,
  StandardButton,
  S2,
  zIndex,
  PrimaryColors
} from "@genesys/ui-elements";
import { AddComponent, MenuDots } from "@genesys/ui-elements/lib/icons";
import { LabelInputState, SystemLabel } from "./types";
import * as SharedState from "../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

interface SidebarLabelsComponentProps {
  readonly sharedState: SharedState.State;
  readonly labels: ReadonlyArray<SystemLabel>;
  readonly labelInputState: LabelInputState;
  readonly openLabelPopup: string | undefined;
  readonly searchKey: string;
  readonly onLabelSelected: (searchKey: string) => void;
  readonly toggleOpenLabelPopup: (openLabelPopup: string | undefined) => void;
  readonly setLabelInputState: (labelInputState: LabelInputState) => void;
  readonly onCreateLabel: (labelName: string) => void;
  readonly onUpdateLabel: (labelId: string, labelName: string) => void;
  readonly deleteLabel: (labelId: string, labelName: string) => void;
}

interface ListItemProps {
  readonly sharedState: SharedState.State;
  readonly label: SystemLabel;
  readonly openLabelPopup: string | undefined;
  readonly searchKey: string;
  readonly onLabelClick: () => void;
  readonly onToggleOpenLabelPopup: (openLabelPopup: string | undefined) => void;
  readonly onDeleteLabel?: (labelId: string, labelName: string) => void;
  readonly onEditLabel?: (labelInputState: LabelInputState) => void;
}

const viewAllLabelId = "viewAll";
const showUnassignedLabelId = "showUnassigned";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  align-content: flex-start;
  align-items: stretch;
  width: 18%;
  height: 100%;
  background-color: #f7f9fc;
  /* white-space: nowrap; */
`;

const TopContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  width: 100%;
  padding: 20px 0 18px 0;
  h5:first-child {
    line-height: 24px;
    position: relative;
    top: 3px;
  }
`;

const NewLabelButton = styled.a`
  display: flex;
  align-items: center;
  background-color: inherit;
  padding: 3px 5px;
  cursor: pointer;
  img {
    margin-right: 8px;
  }
  :hover {
    border-radius: 20px;
    background: ${PrimaryColors.blue25};
  }
  h5 {
    line-height: 24px;
  }
`;

const NewLabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 192px;
  height: 91px;
  margin: 0 0 27px 16px;
  background-color: inherit;

  input {
    height: 26px;
    width: 192px;
    padding-left: 9px;
    margin-bottom: 13px;
    font-size: 15px;
    line-height: 22px;

    &:focus {
      border: none;
    }
  }
`;

const LabelList = styled.ul`
  list-style-type: none;
  height: 100%;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    margin-right: 20px;
    opacity: 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledLi = styled.li`
  height: 36px;
  width: 225px;
  max-width: 225px;
  border-radius: 0 18px 18px 0;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  background-color: ${(props: { readonly isSelected: boolean }) =>
    props.isSelected ? "#dde4f0" : "inherit"};

  a {
    width: 100%;
  }

  * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    background-color: #dde4f0;
  }
  img {
    margin: 0 5px;
  }
`;

const LabelPopup = styled.div`
  width: 100px;
  height: 70px;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;

  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.15);

  z-index: ${zIndex.Dropdown};
  position: absolute;

  top: -15px;
  left: 125px;

  button {
    background-color: inherit;
    width: 100%;
    height: 25px;
    margin: 0;
    text-align: left;
    cursor: pointer;
    span {
      margin-left: 24px;
    }
    &:hover {
      background-color: #f1f5fa;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: stretch;

  button {
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }

  button:first-child {
    margin-right: 5px;
  }
`;

function ListItem({
  sharedState,
  label,
  openLabelPopup,
  searchKey,
  onLabelClick,
  onToggleOpenLabelPopup,
  onDeleteLabel,
  onEditLabel
}: ListItemProps) {
  const isSelected =
    searchKey.includes(`label:"${label.name}"`) ||
    (label.id === viewAllLabelId && !searchKey.includes("label")) ||
    (label.id === showUnassignedLabelId && searchKey.includes("labelcount:0"));

  return (
    <StyledLi isSelected={isSelected}>
      {label.id === viewAllLabelId || label.id === showUnassignedLabelId ? (
        <div>
          <MenuDots height="1.6rem" />
        </div>
      ) : (
        <div onClick={() => onToggleOpenLabelPopup(label.id)}>
          <MenuDots height="1.6rem" />
          {openLabelPopup === label.id && (
            <ClickAwayListener
              onClickAway={() => onToggleOpenLabelPopup(undefined)}
            >
              <LabelPopup>
                <button
                  onClick={() => {
                    onToggleOpenLabelPopup(undefined);
                    onEditLabel!({
                      input: label.name,
                      mode: "update-label",
                      labelId: label.id
                    });
                  }}
                >
                  <Label weight="bold" color="secondary">
                    {sharedState.translate(LanguageTexts.edit())}
                  </Label>
                </button>
                <button
                  onClick={() => {
                    onToggleOpenLabelPopup(undefined);
                    onDeleteLabel!(label.id, label.name);
                  }}
                >
                  <Label weight="bold" color="secondary">
                    {sharedState.translate(LanguageTexts.deleteText())}
                  </Label>
                </button>
              </LabelPopup>
            </ClickAwayListener>
          )}
        </div>
      )}

      <a onClick={onLabelClick}>
        <Label weight="normal" color="secondary">
          {label.name}
        </Label>
      </a>
    </StyledLi>
  );
}

export function SidebarLabelsComponent({
  sharedState,
  labels,
  labelInputState,
  openLabelPopup,
  searchKey,
  onLabelSelected,
  onCreateLabel,
  onUpdateLabel,
  deleteLabel,
  setLabelInputState,
  toggleOpenLabelPopup
}: SidebarLabelsComponentProps) {
  const isDisabled =
    !labelInputState?.input.replace(/\s/g, "").length ||
    !!labels.find(
      x => x.name.toLowerCase() === labelInputState?.input.toLowerCase()
    ); // checks if the label only contains whitespaces or if theres is a label with the same name already

  return (
    <Root>
      <TopContainer>
        <S2 weight="bold" color="dark">
          {sharedState.translate(LanguageTexts.labels())}
        </S2>

        <NewLabelButton
          onClick={() =>
            setLabelInputState({
              input: "",
              labelId: undefined,
              mode: "new-label"
            })
          }
        >
          <AddComponent height="1.6rem" />
          <S2 weight="bold" color="secondary">
            {sharedState.translate(LanguageTexts.addNew())}
          </S2>
        </NewLabelButton>
      </TopContainer>

      {labelInputState && (
        <ClickAwayListener onClickAway={() => setLabelInputState(undefined)}>
          <NewLabelContainer>
            <Label weight="normal" color="secondary">
              {sharedState.translate(LanguageTexts.labelName())}
            </Label>
            <Input
              placeholder={sharedState.translate(LanguageTexts.newLabel())}
              value={labelInputState.input}
              onChange={e =>
                setLabelInputState({
                  ...labelInputState,
                  input: e.target.value
                })
              }
            ></Input>
            <ButtonContainer>
              <StandardButton
                buttonType="SecondaryGreen"
                size="Small"
                onClick={() => setLabelInputState(undefined)}
              >
                {sharedState.translate(LanguageTexts.cancel())}
              </StandardButton>
              <StandardButton
                disabled={isDisabled}
                buttonType="PrimaryGreen"
                size="Small"
                onClick={() =>
                  labelInputState.mode === "update-label" &&
                  labelInputState.labelId
                    ? onUpdateLabel(
                        labelInputState.labelId,
                        labelInputState.input
                      )
                    : onCreateLabel(labelInputState.input)
                }
              >
                {labelInputState.mode === "update-label"
                  ? sharedState.translate(LanguageTexts.update())
                  : sharedState.translate(LanguageTexts.save())}
              </StandardButton>
            </ButtonContainer>
          </NewLabelContainer>
        </ClickAwayListener>
      )}

      <LabelList>
        <ListItem
          sharedState={sharedState}
          label={{
            id: viewAllLabelId,
            name: sharedState.translate(LanguageTexts.viewAll())
          }}
          searchKey={searchKey}
          onLabelClick={() => onLabelSelected("")}
          openLabelPopup={openLabelPopup}
          onToggleOpenLabelPopup={() => toggleOpenLabelPopup(undefined)}
        />
        <ListItem
          sharedState={sharedState}
          label={{
            id: showUnassignedLabelId,
            name: sharedState.translate(LanguageTexts.showUnassigned())
          }}
          searchKey={searchKey}
          onLabelClick={() => onLabelSelected("labelcount:0")}
          openLabelPopup={openLabelPopup}
          onToggleOpenLabelPopup={() => toggleOpenLabelPopup(undefined)}
        />
        {labels.map(label => (
          <ListItem
            sharedState={sharedState}
            key={label.id}
            label={label}
            searchKey={searchKey}
            onLabelClick={() => onLabelSelected(`label:"${label.name}"`)}
            onDeleteLabel={deleteLabel}
            onEditLabel={setLabelInputState}
            openLabelPopup={openLabelPopup}
            onToggleOpenLabelPopup={toggleOpenLabelPopup}
          />
        ))}
      </LabelList>
    </Root>
  );
}
