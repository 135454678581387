// import { preCalculatedResultForDevMode } from "../pre-calculated-result";
import { Cmd } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import {
  PropertyDefinitions,
  loadRuleToPropertyDefinition,
  SettingsCollection,
  getDefaultSystemOverrides,
  createSystemOverridesFromCalcInput,
  SystemOverrides,
  getCoordinateFromClimateDataProperties,
  BinDataType,
  getAllPropertyUpdates
} from "../tools";
import { State, BaseState, Action } from ".";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as SharedState from "../../shared-state";
import * as PropertiesSelector from "../../properties-selector";
import * as GraphQLTypes from "../../graphql-types";
import * as ClimateSettingsEditor from "../components/climate-settings-editor";
import * as IndoorSettingsEditor from "../components/indoor-settings-editor";
import * as Main from "../main-content";
import * as PrintParams from "../../print-params";
import * as ClimateSelector from "../../climate-selector";
import * as ClimateSelectorKnownProps from "../../climate-selector/known-properties";
import * as OperationTime from "@genesys/client-core/lib/operation-time-dialog";
import { getOperationTimesFromInputSettings } from "../main-content/load-types/utils";

const moistureLoadSupportedLanguaghges = ["en-US", "de-DE", "sv-SE"];

export function buildInitialState(
  productQuery: GraphQLTypes.MoistureLoadCalculationProductQuery,
  userQuery: GraphQLTypes.MoistureLoadCalculationUserQuery,
  sharedState: SharedState.State,
  state: State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  const moistureLoadItem = userQuery.user.moistureLoadByMoistureLoadNo;

  if (!moistureLoadItem) {
    return [
      { ...state, errorMessage: "Moisture load not found. Or Unauthorized" }
    ];
  }

  const moistureLoadRules = productQuery.product.moistureLoadRules;

  const propertyDefinitions: PropertyDefinitions =
    moistureLoadRules.propertyDefinitions.map((x, index) =>
      loadRuleToPropertyDefinition(x, index, sharedState.translate)
    );

  const propertiesToKeep = propertyDefinitions.map(p => p.name);

  const binLocations = productQuery.product.binDataLocations;

  const moistureLoadUserSettings =
    userQuery.user.userProfile.userSettings.moistureLoadToolUserSettings;

  const climateDataFromMlSettings = PropertyValueSet.fromString(
    moistureLoadUserSettings?.climateSettings || ""
  );

  const initialSelections = getInitialInputs(
    moistureLoadItem as any,
    propertiesToKeep,
    productQuery,
    sharedState,
    propertyDefinitions,
    climateDataFromMlSettings
  );

  const { binData, climateData, moistureLoadSettings, systemOverrides } =
    initialSelections;

  const coordinate = getCoordinateFromClimateDataProperties(climateData);

  const claimValues = Authorization.getClaimValues(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.moistureLoadCalculation
  ) || ["1"];

  const moistureLoadClaimValue = claimValues
    // tslint:disable-next-line:radix
    .map(cv => parseInt(cv))
    .sort((a, b) => a - b)
    .reverse()[0];

  const baseState: BaseState = {
    moistureLoadNo: moistureLoadItem.moistureloadFile.moistureLoadNo,
    revisionNo: moistureLoadItem.revisionNo,
    propertyDefinitions,
    moistureLoadClaimValue,
    moistureLoadUserSettings,
    initialSelections
  };

  const languages = productQuery.product.languages.filter(x =>
    moistureLoadSupportedLanguaghges.includes(x.id)
  );
  const calcResult = moistureLoadItem.moistureLoadResult
    ? {
        success: true,
        moistureLoadSettingsErrors: [],
        result: moistureLoadItem.moistureLoadResult
      }
    : undefined;

  const currentState: State = {
    ...state,
    moistureLoadId: moistureLoadItem.id,
    moistureLoadFileId: moistureLoadItem.moistureloadFile.id,
    languages
  };

  const moistureLoadNo = sharedState.genesysPrefix.moistureLoadNo(
    moistureLoadItem.moistureloadFile.moistureLoadNo,
    moistureLoadItem.revisionNo
  );

  const fileName = moistureLoadItem.moistureloadFile.name;

  const browserTitle = `${moistureLoadNo} ${fileName}`;

  const [climateSettingsEditorState, climatSettingsEditorCmd] =
    ClimateSettingsEditor.init(
      baseState,
      sharedState,
      climateData,
      binLocations,
      coordinate,
      binData
    );

  const [indoorSettingsEditorState] = IndoorSettingsEditor.init(baseState);

  const [mainstate] = Main.init(
    baseState,
    moistureLoadSettings,
    initialSelections.systemOperationTime,
    initialSelections.loadOperationTimes
  );

  const propertiesSelectorState = PropertiesSelector.init(moistureLoadSettings);

  return [
    {
      ...currentState,
      ...baseState,
      calcResult: calcResult,
      developerSettings: {
        ...currentState.developerSettings,
        showDevResults: !!calcResult?.result?.allBinResults?.length
      },
      printParamsState: PrintParams.init(languages, {
        selectedPaperSize: PrintParams.paperSizes[0].size,
        selectedHeaderLabel: PrintParams.headerLabels[0],
        selectedLanguage: "en-US"
      })[0],

      mainContentState: mainstate,
      currentSystemOverrides: systemOverrides,
      propertiesSelectorState,
      climateSettingsEditorState,
      indoorSettingsEditorState,
      productQueryMoistureLoad: productQuery,
      userQuery: userQuery
    },
    Cmd.map(Action.dispatchClimateSettingsEditor, climatSettingsEditorCmd),
    [SharedState.Action.setBrowserTitle(browserTitle)]
  ];
}

function getInitialInputs(
  moistureLoadItem: GraphQLTypes.MoistureLoadItemType,
  propertiesToKeep: Array<string>,
  productQuery: GraphQLTypes.MoistureLoadCalculationProductQuery,
  sharedState: SharedState.State,
  propertyDefinitions: PropertyDefinitions,
  climateDataFomMlSettings: PropertyValueSet.PropertyValueSet
): SettingsCollection {
  const moistureloadInput = moistureLoadItem.moistureloadInput;
  if (moistureloadInput) {
    return getInputsFromMoistureLoadItem(moistureLoadItem, propertiesToKeep);
  }

  const defaults = getDefaults(productQuery, sharedState, propertyDefinitions);
  return createInitialInputsFromDefaults(
    defaults,
    productQuery.product.binDataLocations,
    climateDataFomMlSettings
  );
}

function createInitialInputsFromDefaults(
  defaults: Defaults,
  binLocations: ReadonlyArray<GraphQLTypes.BinLocationType>,
  climateDataFomMlSettings: PropertyValueSet.PropertyValueSet
) {
  const coordinate = getCoordinateFromClimateDataProperties(
    defaults.defaultClimateSettings
  );
  const binData = ClimateSettingsEditor.defaultBinDataLocationProperties(
    binLocations,
    coordinate
  );

  return {
    climateData: PropertyValueSet.isEmpty(climateDataFomMlSettings)
      ? defaults.defaultClimateSettings
      : climateDataFomMlSettings,
    moistureLoadSettings: defaults.defaultMoistureLoadSettings,
    systemOverrides: defaults.defaultSystemOverrides,
    binData,
    loadOperationTimes: undefined,
    systemOperationTime: OperationTime.presets.allDay
  };
}

function getInputsFromMoistureLoadItem(
  moistureLoadItem: GraphQLTypes.MoistureLoadItemType,
  propertiesToKeep: Array<string>
): SettingsCollection {
  const moistureloadInput = moistureLoadItem.moistureloadInput!;

  const climateData = PropertyValueSet.fromString(
    moistureloadInput.climateSettings
  );

  const moistureLoadSettings = PropertyValueSet.fromString(
    moistureloadInput.settings
  );

  const binData = {
    binSize: parseInt(moistureloadInput!.binSize, 10) as 1 | 2 | 5,
    dataType: moistureloadInput!.coolingDataType as BinDataType,
    locationId: moistureloadInput!.binDataLocation
  };

  const systemOverrides = createSystemOverridesFromCalcInput(
    moistureloadInput,
    propertiesToKeep
  );

  const loadOperationTimes = getOperationTimesFromInputSettings(
    moistureLoadItem.moistureloadInput
  );

  const systemOperationTime = OperationTime.fromPropertyValueSet(
    PropertyValueSet.fromString(moistureloadInput.systemOperationTime || "")
  );

  return {
    climateData,
    moistureLoadSettings,
    binData,
    systemOverrides,
    systemOperationTime,
    loadOperationTimes: loadOperationTimes as any
  };
}

type Defaults = {
  readonly defaultMoistureLoadSettings: PropertyValueSet.PropertyValueSet;
  readonly defaultSystemOverrides: SystemOverrides;
  readonly defaultClimateSettings: PropertyValueSet.PropertyValueSet;
};

function getDefaults(
  productQuery: GraphQLTypes.MoistureLoadCalculationProductQuery,
  sharedState: SharedState.State,
  propertyDefinitions: PropertyDefinitions
): Defaults {
  const moistureLoadRules = productQuery.product.moistureLoadRules;

  const propertieUpdates = getAllPropertyUpdates(
    PropertyValueSet.fromString(moistureLoadRules.defaultSettings),
    PropertyValueSet.fromString(moistureLoadRules.defaultSettings),
    propertyDefinitions,
    true
  );

  const defaultMoistureLoadSettings = PropertyValueSet.merge(
    propertieUpdates,
    PropertyValueSet.fromString(moistureLoadRules.defaultSettings)
  );

  const defaultSystemOverrides = getDefaultSystemOverrides(
    defaultMoistureLoadSettings
  );

  const defaultClimateSettings = ClimateSelector.getDefaultClimateSettings(
    sharedState,
    getDefaultDataTypes(),
    productQuery.product.dataPointsForLocationId!,
    productQuery.product.countries
  );
  return {
    defaultSystemOverrides,
    defaultMoistureLoadSettings,
    defaultClimateSettings
  };
}

function getDefaultDataTypes() {
  const defaultDataTypes = PropertyValueSet.Empty;
  return PropertyValueSet.setText(
    ClimateSelectorKnownProps.manualData.coolingDataType,
    "DP/MCDB",
    defaultDataTypes
  );
}
