import * as React from "react";
import * as Elements from "@genesys/ui-elements";
import { All, Empty } from "./state";
export function FilterListComponent(
  header: string,
  set: Set<string>,
  enabledSet: Set<string>,
  toggle: (id: string) => void
): JSX.Element {
  return (
    <div key={header}>
      <h2>{header}</h2>
      <ul>
        <li>
          <Elements.CheckBox
            isChecked={enabledSet.has(All)}
            onClick={() => {
              toggle(All);
            }}
            children="All"
          />
        </li>
        <li>
          <Elements.CheckBox
            isChecked={enabledSet.has(Empty)}
            onClick={() => {
              toggle(Empty);
            }}
            children="Empty"
          />
        </li>
        {Array.from(set.values())
          .sort((a, b) => a.localeCompare(b))
          .filter(c => c !== All && c !== Empty)
          .map(c => (
            <li key={c}>
              <Elements.CheckBox
                isChecked={enabledSet.has(c)}
                onClick={() => {
                  toggle(c);
                }}
                children={c}
              />
            </li>
          ))}
      </ul>
    </div>
  );
}
