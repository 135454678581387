import * as React from "react";
import styled from "styled-components";
import { StandardButton, S2 } from "@genesys/ui-elements";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { NewHeaderState, NewPricingRow } from "../../types";
import * as Guid from "@genesys/shared/lib/guid";
import { InputContainer } from "../../elements";
import { NewRowInput } from "./new-row-selection";

interface HeaderSelectorProps {
  readonly sharedState: SharedState.State;
  readonly newHeaderState: NewHeaderState;
  readonly rowNo: number;
  readonly onChange: (newHeaderState: NewHeaderState) => void;
  readonly onAddRow: (row: NewPricingRow) => void;
}

const Root = styled.div`
  display: flex;
  align-items: flex-end;
`;

export function HeaderSelector({
  sharedState,
  newHeaderState,
  rowNo,
  onChange,
  onAddRow
}: HeaderSelectorProps) {
  const onDescriptionChange = (description: string) => {
    onChange({ ...newHeaderState, description: description });
  };

  return (
    <Root>
      <InputContainer>
        <S2 weight="normal" color="dark">
          {sharedState.translate(LanguageTexts.description())}
        </S2>
        <NewRowInput
          value={newHeaderState.description}
          onChange={e => onDescriptionChange(e.target.value)}
        />
      </InputContainer>

      <StandardButton
        buttonType="PrimaryBlue"
        size="Small"
        onClick={() => {
          onAddRow({
            row: {
              id: Guid.guidToString(Guid.createGuid()),
              rowNo: rowNo,
              articleNo: "HEADER",
              description: newHeaderState.description,
              pid: undefined,
              quantity: 1,
              unit: "One",
              costPerUnit: 0,
              transferPriceFactor: undefined,
              listPriceFactor: undefined,
              masterPriceFactor: undefined,
              transferPricePerUnit: undefined,
              listPricePerUnit: undefined,
              masterPricePerUnit: undefined,
              systemId: undefined,
              systemTypeId: undefined,
              type: "header",
              visualizerCode: ""
            },
            currencyCode: "SEK",
            masterCurrencyCode: "SEK",
            exchangeRateTemplateId: undefined,
            salesOrganisationId: undefined,
            masterSalesOrganisationId: undefined
          });
        }}
      >
        {sharedState.translate(LanguageTexts.add())}
      </StandardButton>
    </Root>
  );
}
