import styled from "styled-components";
import { Input, StandardButton } from "@genesys/ui-elements";

export const FilterInput = styled(Input)`
  height: 38px;
  margin: 8px 8px 8px 11px;
`;

export const RowSection = styled.ul`
  overflow-y: auto;
  max-height: 283px;

  ::-webkit-scrollbar {
    width: 4px;
    margin-right: 20px;
    opacity: 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const InputContainer = styled.div`
  padding-top: 5px;
  label {
    color: #8f9bb3;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    margin: 7px;
    display: block;
  }

  input {
    width: 97%;
    display: block;
    margin: auto;
    margin-bottom: 5px;
  }
`;

export const StyledLi = styled.li`
  padding-left: 12px;
  padding-top: 2px;

  ${(props: { readonly isChecked: boolean }) =>
    props.isChecked && "background-color: #F1F5FA;"};
`;

export const TrashContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  > label {
    cursor: pointer;
  }
`;

export const StyledLabel = styled.label`
  color: #16181e;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  height: 34px;
  display: inline-block;
  cursor: pointer;

  > span {
    margin-left: 15px;
    cursor: pointer;
  }
`;

///

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 350px;
  border: 1px solid #f1f5fa;
  background-color: #ffffff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
`;

export const UpperSubContainer = styled.div`
  button {
    margin-left: 9px;
    margin-bottom: 5px;
  }
`;

export const LowerSubContainer = styled.div`
  flex-grow: 1;

  background-color: #f1f5fa;
  padding: 0 10px 10px 10px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 10px;

  button:first-child {
    margin-right: 8px;
  }
  button {
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
`;

export const StyledButton = styled(StandardButton)`
  padding: 4px 12px;
`;
