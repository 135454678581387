import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as GraphQlTypes from "../../graphql-types";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../shared-state";
import { calculateOilon } from "./queries";
import * as PropertiesSelector from "../../properties-selector";
import {
  initialConfigPvs,
  initialCalculationCasePvs,
  newCalculationCasePvs
} from "./data";
import { getOilonInput, parseCalculationCasesResults } from "./functions";

export type State = {
  readonly propertiesSelectors: {
    readonly [id: string]: PropertiesSelector.State;
  };
  readonly results:
    | {
        readonly calculationCaseResults: ReadonlyArray<PropertiesSelector.State>;
        readonly errors: ReadonlyArray<string>;
      }
    | undefined;
  readonly isLoading: boolean;
};

export const init = (): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  const propertiesSelectors: {
    readonly [id: string]: PropertiesSelector.State;
  } = {
    ["config"]: PropertiesSelector.init(initialConfigPvs),
    ["case-0"]: PropertiesSelector.init(initialCalculationCasePvs)
  };

  return [
    {
      propertiesSelectors: propertiesSelectors,
      results: undefined,
      isLoading: false
    }
  ];
};

export const Action = ctorsUnion({
  dispatchPropertiesSelector: (
    componentId: string,
    action: PropertiesSelector.Action
  ) => ({
    componentId,
    action
  }),
  addCase: () => ({}),
  deleteCase: (id: string) => ({ id }),
  calculate: () => ({}),
  parseResult: (
    result: GraphQlTypes.CalculateOilon["user"]["calculateOilon"]
  ) => ({
    result
  })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "dispatchPropertiesSelector": {
      const propertiesSelectorState =
        state.propertiesSelectors[action.componentId];

      const [
        newPropertiesSelectorState,
        propertiesSelectorCmd,
        propertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        propertiesSelectorState,
        sharedState,
        "SkipCalculateProperties"
      );

      return [
        {
          ...state,
          propertiesSelectors: {
            ...state.propertiesSelectors,
            [action.componentId]: newPropertiesSelectorState
          }
        },
        Cmd.map(
          cmdAction =>
            Action.dispatchPropertiesSelector(action.componentId, cmdAction),
          propertiesSelectorCmd
        ),
        [propertiesSelectorSharedAction]
      ];
    }
    case "addCase": {
      const nextCaseIndex =
        Object.entries(state.propertiesSelectors)
          .filter(([id, _v]) => id.startsWith("case"))
          .map(([key, _state]) => +key.split("-")[1])
          .sort((a, b) => b - a)[0] + 1;

      const propertiesSelectors = {
        ...state.propertiesSelectors,
        [`case-${nextCaseIndex}`]: PropertiesSelector.init(
          newCalculationCasePvs
        )
      };

      return [
        {
          ...state,
          results: undefined,
          propertiesSelectors: propertiesSelectors
        }
      ];
    }
    case "deleteCase": {
      const propertiesSelectors = Object.keys(state.propertiesSelectors).reduce(
        (soFar, key) => {
          if (key !== action.id) {
            soFar[key] = state.propertiesSelectors[key];
          }
          return soFar;
        },
        {} as {
          // tslint:disable-next-line
          [id: string]: PropertiesSelector.State;
        }
      );

      return [
        {
          ...state,
          results: undefined,
          propertiesSelectors: propertiesSelectors
        }
      ];
    }
    case "calculate": {
      const oilonInput = getOilonInput(state);

      return [
        { ...state, results: undefined, isLoading: true },
        sharedState.graphQL.queryUserCmd<
          GraphQlTypes.CalculateOilon,
          GraphQlTypes.CalculateOilonVariables,
          Action
        >(
          calculateOilon,
          {
            oilonInput: oilonInput
          },
          data => Action.parseResult(data.user.calculateOilon)
        )
      ];
    }
    case "parseResult": {
      const calculationCaseResults = parseCalculationCasesResults(
        action.result.calculationCases
      ).map(pvs => PropertiesSelector.init(pvs));

      const errors = action.result.errors ?? [];

      return [
        {
          ...state,
          isLoading: false,
          results: {
            calculationCaseResults: calculationCaseResults,
            errors: errors
          }
        }
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
