import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { MenuButton } from "@genesys/ui-elements";
import { DropDownMenuRoot, DropDownMenuContainer } from "../elements";
import { State, Action, MenuType } from "../state";

export function DropDownMenu({
  state,
  options,
  menuType,
  menuTitle,
  dispatch
}: {
  readonly state: State;
  readonly menuTitle: string;
  readonly menuType: MenuType;
  readonly options: ReadonlyArray<{
    readonly text: string;
    readonly href: string;
  }>;
  readonly dispatch: Dispatch<Action>;
}) {
  const closeMenu = () => dispatch(Action.setOpenMenu(undefined));

  return (
    <DropDownMenuRoot
      onMouseEnter={() => dispatch(Action.setOpenMenu(menuType))}
      onMouseLeave={closeMenu}
    >
      <MenuButton
        children={menuTitle}
        hasMenu={true}
        isOpen={state.openMenu === menuType}
      />
      {state.openMenu === menuType && (
        <DropDownMenuContainer onClick={closeMenu}>
          {options.map(option => (
            <a href={option.href}>
              <MenuButton children={option.text} hasMenu={false} />
            </a>
          ))}
        </DropDownMenuContainer>
      )}
    </DropDownMenuRoot>
  );
}
