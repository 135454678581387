import * as React from "react";
import * as SharedState from "../shared-state";
import * as Oilon from "./oilon";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";

export function DevToolsView({
  state,
  sharedState,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  switch (state.type) {
    case "oilon": {
      return (
        <Oilon.OilonView
          state={state.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchOilon, dispatch)}
        />
      );
    }
    default:
      return (
        <div>
          <p>The Tools page you are looking for does not exist</p>
        </div>
      );
  }
}
