export const CO2EmissionElectric = "co2emissionelectric";
export const CO2EmissionGas = "co2emissiongas";
export const CO2EmissionSteam = "co2emissionsteam";
export const CO2EmissionCoolingWater = "co2emissioncoolingwater";
export const CO2EmissionHeatingWater = "co2emissionheatingwater";

export const binId = "binid";
export const sortNo = "sortno";
export const energyEmissionInput = "energyemissioninput";
export const energyPriceInput = "energypriceinput";
export const energyBinSize = "binsize";
export const energyOperationTime = "operationtime";
export const priceInc = "priceinc";
export const maintenanceCost = "maintenancecost";
export const maintenanceCostPriceInc = "maintenancecostpriceinc";
export const disposalCost = "disposalcost";
export const lccYears = "lccyears";
export const systemPrice = "systemprice";
export const interest = "interest";
export const atmosphericPressure = "atmosphericpressure";

export const steamCost = "SteamCost";
export const coolingWaterCost = "CoolingWaterCost";
export const heatingWaterCost = "HeatingWaterCost";

export const coolingWaterCostPerUnitEnergy = "CoolingWaterCostperunitenergy";
export const heatingWaterCostPerUnitEnergy = "HeatingWaterCostperunitenergy";
export const steamCostPerUnitEnergy = "SteamCostperunitenergy";

export const coolingWaterCop = "CoolingWatercop";
export const heatingWaterEfficiency = "HeatingWaterCostefficiency";
export const steamEfficiency = "SteamCostefficiency";
export const costPerUnitEnergy = "Costperunitenergy";

export const id = "id";
export const airMidPointTemperature = "airmidpointtemperature";
export const airMidPointWetTemperature = "airmidpointwettemperature";
export const airMidPointDewPointTemperature = "airmidpointdewpointtemperature";
export const airMidPointSpecificEnthalpy = "airmidpointspecificenthalpy";
export const airMidPointHumidityRatio = "airmidpointhumidityratio";
export const airTemperature = "airtemperature";
export const binPressure = "binpressure";
export const airHumidity = "airhumidity";
export const time = "time";
export const binTimeJanuary = "bintimejanuary";
export const binTimeFebruary = "bintimefebruary";
export const binTimeMarch = "bintimemarch";
export const binTimeApril = "bintimeapril";
export const binTimeMay = "bintimemay";
export const binTimeJune = "bintimejune";
export const binTimeJuly = "bintimejuly";
export const binTimeAugust = "bintimeaugust";
export const binTimeSeptember = "bintimeseptember";
export const binTimeOctober = "bintimeoctober";
export const binTimeNovember = "bintimenovember";
export const binTimeDecember = "bintimedecember";
export const wind = "wind";

export const binType = "bintype";
export const binSize = "binsize";
export const binUseEnglishUnits = "binuseenglishunits";
export const binDataLocationId = "bindatalocationid";
export const binLocation = "binlocation";
export const binOperationTime = "binoperationtime";
export const binDataHourlyVersion = "bindatahourlyversion";
export const binInputCustomPressure = "bininputcustompressure";
export const binMonths = "months";
export const binMonday = "monday";
export const binTuesday = "tuesday";
export const binWednesday = "wednesday";
export const binThursday = "thursday";
export const binFriday = "friday";
export const binSaturday = "saturday";
export const binSunday = "sunday";
export const climateCoolingDataType = "climatecoolingdatatype";
export const roofTempCompensation = "rooftempcompensation";
export const maxRoofTempCompensation = "maxrooftempcompensation";

export const outdoorTemperature = "outdoortemperature";
export const outdoorAirTemperature = "outdoorairtemperature";
export const outdoorsTemp = "outdoorstemp";
export const outdoorHumidity = "outdoorhumidity";
export const outdoorAirHumidity = "outdoorairhumidity";
export const outdoorsHum = "outdoorshum";
export const caseName = "casename";
export const outdoorData = "outdoordata";

export const supplyOutletFlow = "supplyoutletflow";
export const supplyOutletAirFlow = "supplyoutletairflow";
export const targetHum = "targethum";
export const supplyTargetHumidity = "supplytargethumidity";
export const targetTemp = "targettemp";
export const supplyTargetTemperature = "supplytargettemperature";
export const returnTemp = "returntemp";
export const returnAirTemperature = "returnairtemperature";
export const preferredReturnTemperature = "preferredreturntemp";
export const returnHum = "returnhum";
export const returnAirHumidity = "returnairhumidity";
export const totalMoistureLoad = "totalmoistureload";
export const totalHeatLoad = "totalheatload";
