import { exhaustiveCheck } from "ts-exhaustive-check";
import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import * as State from "./state";
import * as SharedState from "../../shared-state";
import styled from "styled-components";
import {
  H2,
  P1,
  CircularProgressLoader,
  PrimaryColors
} from "@genesys/ui-elements";
import { StandardButton } from "@genesys/ui-elements/lib/atoms/button";
import { View as LoadedView } from "../view";
import {
  Modal,
  ModalContent,
  ModalHeader
} from "@genesys/ui-elements/lib/organisms/modal";
import { clientConfig, getModalBorderColor } from "../../config";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

export interface Props {
  readonly dispatch: Dispatch<State.Action>;
  readonly state: State.State;
  readonly sharedState: SharedState.State;
}

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  word-wrap: break-word;
  margin-top: 200px;
  padding: 50px;
  background-color: ${PrimaryColors.red};

  p {
    max-width: 1000px;
  }
`;

const ControllerContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button + button {
    margin-left: 16px;
  }
`;

export function View(props: Props): JSX.Element {
  switch (props.state.mode) {
    case "loading":
      return <CircularProgressLoader />;
    case "expired":
      return (
        <SystemExpiredDialog
          translate={props.sharedState.translate}
          continue={() => props.dispatch(State.Action.lowerStatusAndLoad())}
          cancel={() => props.dispatch(State.Action.redirect("/"))}
        />
      );
    case "error":
      return (
        <div>
          <Error errorMessage={props.state.errorMessage} />
        </div>
      );
    case "loaded":
      return (
        <>
          <LoadedView
            dispatch={Dispatch.map(
              State.Action.dispatchLoadedSystem,
              props.dispatch
            )}
            sharedState={props.sharedState}
            state={props.state.loadedState}
            traceId={props.state.traceId}
          />
          <Loader translate={props.sharedState.translate} state={props.state} />
        </>
      );
    default:
      return <div></div>;
  }
}

function Loader({
  state,
  translate
}: {
  readonly state: State.State;
  readonly translate: (textDefinition: LanguageTexts.TextDefinition) => string;
}): JSX.Element | null {
  if (
    state.mode !== "loaded" ||
    state.loadedState.overlay.type === "OverlayNone"
  ) {
    return null;
  }

  if (state.loadedState.overlay.type === "OverlayBinCalculations") {
    return (
      <CircularProgressLoader
        progress={Math.round(
          ((state.loadedState.overlay.total -
            state.loadedState.overlay.casesLeft.length) /
            state.loadedState.overlay.total) *
            100
        )}
        label={`${translate(
          LanguageTexts.globalPropertyName("calculatingenergycase")
        )} ${
          state.loadedState.overlay.total -
          state.loadedState.overlay.casesLeft.length
        }/${state.loadedState.overlay.total}`}
      />
    );
  }

  if (
    state.loadedState.overlay.type === "OverlayWithText" ||
    state.loadedState.overlay.type === "OverlayQuery"
  ) {
    return (
      <CircularProgressLoader
        // progress={Math.round(
        //   ((state.loadedState.overlay.total -
        //     state.loadedState.overlay.casesLeft.length) /
        //     state.loadedState.overlay.total) *
        //     100
        // )}
        label={state.loadedState.overlay.text}
      />
    );
  }

  if (state.loadedState.overlay.type === "OverlayDefault") {
    return <CircularProgressLoader />;
  }

  exhaustiveCheck(state.loadedState.overlay);
  return null;
}

function Error({
  errorMessage
}: {
  readonly errorMessage: string;
}): JSX.Element {
  return (
    <ErrorContainer>
      <P1 color="white" weight="bold">
        {errorMessage}
      </P1>
    </ErrorContainer>
  );
}

function SystemExpiredDialog(props: {
  readonly translate: LanguageTexts.Translate;
  readonly continue: () => void;
  readonly cancel: () => void;
}): JSX.Element {
  return (
    <Modal maxWidth={500} onClose={() => ({})}>
      <ModalHeader borderBottom={getModalBorderColor(clientConfig.environment)}>
        <H2>{props.translate(LanguageTexts.systemValidityExpired())}</H2>
      </ModalHeader>
      <ModalContent>
        <P1>{props.translate(LanguageTexts.systemExpiredRecalculate())}</P1>
        <ControllerContainer>
          <StandardButton
            buttonType="PrimaryGreen"
            size="Large"
            onClick={props.continue}
          >
            {props.translate(LanguageTexts.edContinue())}
          </StandardButton>
          <StandardButton
            buttonType="SecondaryGreen"
            size="Large"
            onClick={props.cancel}
          >
            {props.translate(LanguageTexts.cancel())}
          </StandardButton>
        </ControllerContainer>
      </ModalContent>
    </Modal>
  );
}
