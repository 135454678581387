import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import styled from "../../styled-components";

const BootstrapInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    fontFamily: "Futura",
    fontSize: "13px",
    color: "#959695",
    height: "16px",
    borderBottom: "1px solid #00adf2",
    borderRadius: 0,
    "&:focus": {
      borderRadius: 0
    },
    "& > img": {
      height: "25px"
    }
  },
  "& .MuiSelect-select.MuiInputBase-input": {
    height: "16px",
    display: "flex",
    alignItems: "center"
  }
});

const DisabledInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    fontFamily: "Futura",
    fontSize: "13px",
    color: "#959695",
    height: "17px",
    borderRadius: 0,
    "&:focus": {
      borderRadius: 0
    },
    "& > img": {
      height: "25px"
    }
  },
  "& .MuiSelect-select.MuiInputBase-input": {
    height: "15px",
    display: "flex",
    alignItems: "center"
  }
});

export const GenesysTableOption = MenuItem;

export function GenesysTableSelect({
  onChange,
  children,
  value,
  disabled
}: {} & Pick<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  "onChange" | "children" | "value" | "disabled"
>): JSX.Element {
  return (
    <div>
      <Select
        style={{ width: "100%" }}
        value={value}
        onChange={(e, _c) =>
          onChange !== undefined ? onChange(e as any) : undefined
        }
        input={disabled ? <DisabledInput /> : <BootstrapInput />}
        disabled={disabled}
      >
        {children}
      </Select>
    </div>
  );
}
