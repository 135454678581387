import { State, LoadOperationTime } from "./types";
import { Cmd } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import { loadMapCreator } from "../loads-creator";
import { MoistureHeatLoadTypeEnum } from "../../../../graphql-types";
import { BaseState } from "../../../state";
import { Action } from "./action";
import { generateActiveLoadsFromMoistureLoadSettings } from "./tools/helpers";
import * as SharedState from "../../../../shared-state";
import * as SystemOverride from "../../../components/system-overrides";

export const init = (
  baseState: BaseState,
  initialSettings: PropertyValueSet.PropertyValueSet,
  initialOperationTimes: LoadOperationTime | undefined
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  const allLoads = new Map(
    (
      Object.keys(
        MoistureHeatLoadTypeEnum
      ) as ReadonlyArray<MoistureHeatLoadTypeEnum>
    ).map(key => {
      return [key, loadMapCreator[key](baseState.moistureLoadClaimValue!)];
    })
  );

  const allLoadsValues = Array.from(allLoads.values());

  const visibleLoads = allLoadsValues
    .filter(x => x.visible)
    .map(x => x.sourceName);

  const initialLoads = generateActiveLoadsFromMoistureLoadSettings(
    allLoads,
    initialSettings,
    initialOperationTimes
  );

  const filteredLoads = allLoadsValues
    .filter(l => !initialLoads.map(x => x.sourceName).includes(l.sourceName))
    .map(x => x.sourceName);

  const inittialSortOrder = initialLoads.map(x => x.sourceName);

  return [
    {
      ...baseState,
      currentPageTab: "moisture-heat-load-types",
      loadsState: {
        selectableLoads: filteredLoads,
        activeLoads: [...initialLoads],
        selectedLoadsSortOrder: inittialSortOrder,
        visibleLoads: visibleLoads,
        selectedLoad: undefined
      },
      // allLoadsDefMap: allLoads,
      currentTopMenuTab: "moisture",
      systemOverridesState: SystemOverride.init(baseState)[0]
    }
  ];
};
