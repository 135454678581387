import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../shared-state";
import { ToolType, ToolState } from "./types";
import * as AirMixing from "./air-mixing/state";
import * as AirPower from "./air-power/state";
import * as HumidityConversion from "./humidity-conversion/state";
import * as PsychrometricChart from "./psychrometric-chart/state";
import * as BinPageTool from "./weather-data-binning";

export type State = ToolState;

export const init = (
  toolType: ToolType,
  sharedState: SharedState.State
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  switch (toolType) {
    case "air-mixing": {
      const [airMixingState, airMixingCmd] = AirMixing.init();
      return [
        { type: "air-mixing", state: airMixingState },
        Cmd.map(Action.dispatchAirMixing, airMixingCmd),
        SharedState.Action.loadLastOpenedSystemsAndFavorites()
      ];
    }
    case "air-power": {
      const [airPowerState, airPowerCmd] = AirPower.init();
      return [
        { type: "air-power", state: airPowerState },
        Cmd.map(Action.dispatchAirPower, airPowerCmd),
        SharedState.Action.loadLastOpenedSystemsAndFavorites()
      ];
    }
    case "humidity-conversion": {
      const [humidityConversionState, humidityConversionCmd] =
        HumidityConversion.init();
      return [
        { type: "humidity-conversion", state: humidityConversionState },
        Cmd.map(Action.dispatchHumidityConversion, humidityConversionCmd),
        SharedState.Action.loadLastOpenedSystemsAndFavorites()
      ];
    }
    case "psychrometric-chart": {
      const [psychrometricChartState, psychrometricChartCmd] =
        PsychrometricChart.init(
          sharedState.user.settings.selectedAmountProfile.name,
          sharedState.user.settings.psychrometricChartSettings
        );
      return [
        { type: "psychrometric-chart", state: psychrometricChartState },
        Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd),
        SharedState.Action.loadLastOpenedSystemsAndFavorites()
      ];
    }

    case "weather-data-binning": {
      const [state, cmd] = BinPageTool.init(sharedState);
      return [
        { type: "weather-data-binning", state },
        Cmd.map(Action.dispatchBinPage, cmd),
        SharedState.Action.loadLastOpenedSystemsAndFavorites()
      ];
    }
    default:
      return [{ type: "404" }];
  }
};

export const Action = ctorsUnion({
  dispatchAirMixing: (action: AirMixing.Action) => ({ action }),
  dispatchAirPower: (action: AirPower.Action) => ({ action }),
  dispatchHumidityConversion: (action: HumidityConversion.Action) => ({
    action
  }),
  dispatchBinPage: (action: BinPageTool.Action) => ({ action }),
  dispatchPsychrometricChart: (action: PsychrometricChart.Action) => ({
    action
  })
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "dispatchAirMixing": {
      if (state.type !== "air-mixing") {
        return [state];
      }
      const [airMixingState, airMixingCmd, sharedStateActions] =
        AirMixing.update(action.action, state.state, sharedState);
      return [
        {
          ...state,
          state: airMixingState
        },
        Cmd.map(Action.dispatchAirMixing, airMixingCmd),
        sharedStateActions
      ];
    }
    case "dispatchAirPower": {
      if (state.type !== "air-power") {
        return [state];
      }
      const [airPowerState, airPowerCmd, sharedStateActions] = AirPower.update(
        action.action,
        state.state,
        sharedState
      );
      return [
        {
          ...state,
          state: airPowerState
        },
        Cmd.map(Action.dispatchAirPower, airPowerCmd),
        sharedStateActions
      ];
    }
    case "dispatchHumidityConversion": {
      if (state.type !== "humidity-conversion") {
        return [state];
      }
      const [
        humidityConversionState,
        humidityConversionCmd,
        sharedStateActions
      ] = HumidityConversion.update(action.action, state.state, sharedState);
      return [
        {
          ...state,
          state: humidityConversionState
        },
        Cmd.map(Action.dispatchHumidityConversion, humidityConversionCmd),
        sharedStateActions
      ];
    }
    case "dispatchPsychrometricChart": {
      if (state.type !== "psychrometric-chart") {
        return [state];
      }
      const [
        psychrometricChartState,
        psychrometricChartCmd,
        sharedStateActions
      ] = PsychrometricChart.update(action.action, state.state, sharedState);
      return [
        {
          ...state,
          state: psychrometricChartState
        },
        Cmd.map(Action.dispatchPsychrometricChart, psychrometricChartCmd),
        sharedStateActions
      ];
    }

    case "dispatchBinPage": {
      if (state.type !== "weather-data-binning") {
        return [state];
      }
      const [binPageState, binPageCmd, sharedStateActions] = BinPageTool.update(
        action.action,
        state.state,
        sharedState
      );
      return [
        {
          ...state,
          state: binPageState
        },
        Cmd.map(Action.dispatchBinPage, binPageCmd),
        [sharedStateActions]
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}
