import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { Amount, Units } from "@genesys/uom";
import {
  MoistureHeatLoadTypeEnum,
  OperatingCaseGroupEnum
} from "../../../../graphql-types";
import * as KnownProperties from "../../known-properties";
import { changedFromBoth0, changedToBoth0 } from "../../helper-functions";
import { EvaporationFromMaterialsIcon } from "@genesys/ui-elements";
import { LoadOperationTime } from "../../types";
import * as OperationTimeGen2 from "../../../../operation-time-manager";

//Icon
export const Icon = EvaporationFromMaterialsIcon;

// Visibility
const visibleClaimValue = 1;
export function getVisibility(moistureLoadClaimValue: number): boolean {
  return moistureLoadClaimValue >= visibleClaimValue;
}

// Moisture load selection
export const useMoistProperty =
  KnownProperties.useEvaporationFromMaterialsMoistureLoad;
const moistureLoadSelectClaimValue = 1;
export function getMoistureLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= moistureLoadSelectClaimValue;
}

// Heat load selection
const heatLoadSelectClaimValue = 5;
export const useHeatProperty =
  KnownProperties.useEvaporationFromMaterialsHeatLoad;
export function getHeatLoadSelectionVisibility(
  moistureLoadClaimValue: number
): boolean {
  return moistureLoadClaimValue >= heatLoadSelectClaimValue;
}

export const title = "mlcevaporationfrommaterials";
export const sourceName = MoistureHeatLoadTypeEnum.EVAPORATION_FROM_MATERIALS;
export const description = "mlcevaporationfrommaterialsexplanation";
export const useLoadKey = "mlcuseevaporationfrommaterials";

export const manualInputProperty =
  KnownProperties.evaporationFromMaterialsManualInput;

export const properties = [
  KnownProperties.useEvaporationFromMaterialsMoistureLoad,
  KnownProperties.useEvaporationFromMaterialsHeatLoad,
  KnownProperties.evaporationFromMaterialsManualInput,
  KnownProperties.materialEnteringRate,
  KnownProperties.initialMoistureContentOfMaterial,
  KnownProperties.equilibriumMoistureContentOfMaterial,
  KnownProperties.timeToReachEquilibrium
];
export const overridableProperties = [
  KnownProperties.materialEnteringRate,
  KnownProperties.initialMoistureContentOfMaterial,
  KnownProperties.equilibriumMoistureContentOfMaterial,
  KnownProperties.timeToReachEquilibrium
];

export const caseOverridesDefault: {
  readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
} = {
  DESIGN_SUMMER: PropertyValueSet.Empty,
  DESIGN_WINTER: PropertyValueSet.Empty,
  JANUARY: PropertyValueSet.Empty,
  FEBRUARY: PropertyValueSet.Empty,
  MARCH: PropertyValueSet.Empty,
  APRIL: PropertyValueSet.Empty,
  MAY: PropertyValueSet.Empty,
  JUNE: PropertyValueSet.Empty,
  JULY: PropertyValueSet.Empty,
  AUGUST: PropertyValueSet.Empty,
  SEPTEMBER: PropertyValueSet.Empty,
  OCTOBER: PropertyValueSet.Empty,
  NOVEMBER: PropertyValueSet.Empty,
  DECEMBER: PropertyValueSet.Empty
};

export function getPropertyUpdates(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let allUpdates = getPropertyDefaults(prevProperties, selectedProperties);

  return allUpdates;
}

export function getDefaultOperationTime(
  _selectedProperties: PropertyValueSet.PropertyValueSet,
  _loadOperationTimes: LoadOperationTime
) {
  return OperationTimeGen2.presets.allDay;
}

function getPropertyDefaults(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  if (
    changedFromBoth0(
      prevSelections,
      currentSelections,
      KnownProperties.useEvaporationFromMaterialsMoistureLoad,
      KnownProperties.useEvaporationFromMaterialsHeatLoad
    )
  ) {
    return useMoistureAndHeatLoadDefaults(1);
  }

  if (
    changedToBoth0(
      prevSelections,
      currentSelections,
      KnownProperties.useEvaporationFromMaterialsMoistureLoad,
      KnownProperties.useEvaporationFromMaterialsHeatLoad
    )
  ) {
    return useMoistureAndHeatLoadDefaults(0);
  }

  return PropertyValueSet.Empty;
}

function useMoistureAndHeatLoadDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
      return {
        [KnownProperties.materialEnteringRate]: PropertyValue.fromAmount(
          Amount.create(0, Units.KilogramPerHour)
        ),
        [KnownProperties.initialMoistureContentOfMaterial]:
          PropertyValue.fromAmount(Amount.create(0, Units.Percent)),
        [KnownProperties.equilibriumMoistureContentOfMaterial]:
          PropertyValue.fromAmount(Amount.create(0, Units.Percent)),
        [KnownProperties.timeToReachEquilibrium]: PropertyValue.fromAmount(
          Amount.create(0, Units.Hour)
        ),
        [KnownProperties.evaporationFromMaterialsManualInput]:
          PropertyValue.fromInteger(0)
      };

    case 1:
      return {
        [KnownProperties.materialEnteringRate]: PropertyValue.fromAmount(
          Amount.create(1000, Units.KilogramPerHour)
        ),
        [KnownProperties.initialMoistureContentOfMaterial]:
          PropertyValue.fromAmount(Amount.create(70, Units.Percent)),
        [KnownProperties.equilibriumMoistureContentOfMaterial]:
          PropertyValue.fromAmount(Amount.create(50, Units.Percent)),
        [KnownProperties.timeToReachEquilibrium]: PropertyValue.fromAmount(
          Amount.create(72, Units.Hour)
        ),
        [KnownProperties.evaporationFromMaterialsManualInput]:
          PropertyValue.fromInteger(0)
      };
    default:
      throw new Error("Invalid value.");
  }
}
