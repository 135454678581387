import React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { Label, P2, P1 } from "@genesys/ui-elements";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Skeleton from "@mui/material/Skeleton";
import {
  ArrowDown,
  MenuDots,
  FavoriteActive,
  ImageDisplayer,
  FavoriteInactive
} from "@genesys/ui-elements";
import * as DateHelper from "@genesys/shared/lib/date-helper";
import * as StatusIcon from "../status-icon-manager";
import * as SystemActions from "../system-actions";
import { Dispatch } from "@typescript-tea/core";
import { Action, State } from "./state";
import { clientConfig } from "../config";
import * as SharedState from "../shared-state";
import {
  SystemImage,
  SystemActionsContainer,
  SkeletonContainer,
  IconsContainer,
  RevisionContainer,
  RevisionOptionsContainer,
  RevisionsDropDown,
  RevisionsDropDownButton,
  Root,
  ErrorCard,
  ErrorTextContainer,
  SkeletonIconsContainer,
  SkeletonSystemImage,
  SystemContainer,
  TextContainer,
  TextsContainer
} from "./elements";
import * as SystemStatusText from "./system-status";

export function View(props: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly plenumSizeProperty: string;
  readonly recentSystem: SharedState.BreifSystem;
  readonly showErrorDetails: () => void;
}) {
  if (props.recentSystem.type === "loading") {
    return (
      <SkeletonContainer>
        <SkeletonIconsContainer>
          <Skeleton variant="rectangular" width={87} height={30} />
        </SkeletonIconsContainer>

        <SkeletonSystemImage>
          <Skeleton variant="rectangular" width={161} height={114} />
        </SkeletonSystemImage>
        <Skeleton variant="rectangular" width={248} height={66} />
        <Skeleton
          style={{ marginTop: "7px" }}
          variant="rectangular"
          width={248}
          height={33}
        />
      </SkeletonContainer>
    );
  }

  if (props.recentSystem.type === "error") {
    return (
      <ErrorCard onClick={props.showErrorDetails}>
        <ErrorTextContainer>
          <P1 color="white" weight="bold">
            Unable to load system
          </P1>
        </ErrorTextContainer>
      </ErrorCard>
    );
  }

  const { sharedState, dispatch, state } = props;

  const formatDate = (date: number) =>
    DateHelper.getDateString(
      DateHelper.fromUTC(date),
      sharedState.user.settings.locale
    );
  const effectiveRevision =
    state.userSelectedRevision || props.recentSystem.system.id;
  const effectiveSystem = props.recentSystem.system.systems.find(
    s => s.id === effectiveRevision
  )!;

  const revisionNumber = sharedState.genesysPrefix.genesysNo(
    props.recentSystem.system.genesysNo,
    effectiveSystem.revisionNo
  );

  const routeEndpoint = clientConfig.genesysBackend;

  const genesysNo = sharedState.genesysPrefix.genesysNo(
    props.recentSystem.system.genesysNo,
    effectiveSystem.revisionNo
  );

  const isFavorite = sharedState.user.favoritesSystems.find(fs =>
    fs.type === "loaded"
      ? fs.system.id === effectiveSystem.id
      : fs.id === effectiveSystem.id
  );

  const revisionOptions: {
    readonly systemId: string;
    readonly revisionNo: string;
    readonly comment?: string | null;
    readonly statusDate: string;
  }[] = props.recentSystem.system.systems.map(s => {
    return {
      systemId: s.id,
      revisionNo: s.revisionNo.toString(),
      comment: s.comment,
      statusDate: formatDate(s.statusDate)
    };
  });

  const onToggleSystemActions = (coordinates?: {
    readonly x: number;
    readonly y: number;
  }) => dispatch(Action.toggleSystemActions(coordinates));

  const maybeToggleSystemActions = (coordinates?: {
    readonly x: number;
    readonly y: number;
  }) => {
    if (!state.systemActionsState) {
      onToggleSystemActions(coordinates);
    } else if (
      state.systemActionsState &&
      SystemActions.isInMenu(state.systemActionsState.state)
    ) {
      onToggleSystemActions(coordinates);
    } else {
      return;
    }
  };

  const onToggleRevisionDropdown = () =>
    dispatch(Action.toggleOpenRevisonDropdown());

  const onToggleIsFavorite = (systemId: string) =>
    dispatch(Action.toggleFavorites(systemId));

  const onChangeActiveRevision = (systemId: string) =>
    dispatch(Action.changeActiveRevision(systemId));

  return (
    <Root>
      <IconsContainer>
        <div onClick={() => onToggleIsFavorite(effectiveSystem.id)}>
          {isFavorite ? (
            <FavoriteActive height="1.2rem" />
          ) : (
            <FavoriteInactive height="1.2rem" />
          )}
        </div>
        <div>
          <StatusIcon.StatusIconManager
            sharedState={sharedState}
            statusNumber={effectiveSystem.status}
            height="21.81px"
            width="21.81px"
          ></StatusIcon.StatusIconManager>
        </div>
        <div
          onMouseEnter={e => {
            maybeToggleSystemActions({
              x: (e.target as HTMLAnchorElement).getBoundingClientRect().x,
              y: (e.target as HTMLAnchorElement).getBoundingClientRect().y
            });
          }}
          onMouseLeave={() => {
            maybeToggleSystemActions();
          }}
        >
          <a>
            <MenuDots height="1.4rem" />
            {state.systemActionsState && (
              <SystemActionsContainer
                x={state.systemActionsState.coordinates?.x || 0}
                y={state.systemActionsState.coordinates?.y || 0}
              >
                <SystemActions.SystemActionsView
                  dispatch={Dispatch.map(
                    Action.dispatchSystemActions,
                    dispatch
                  )}
                  state={state.systemActionsState.state}
                  systemActions={SystemActions.createActions(
                    {
                      dispatch: Dispatch.map(
                        Action.dispatchSystemActions,
                        dispatch
                      ),
                      sharedState,
                      state: state.systemActionsState.state,
                      systemFile: {
                        id: props.recentSystem.system.systemFileId,
                        name: props.recentSystem.system.name,
                        labels: props.recentSystem.system.labels,
                        owner: props.recentSystem.system.owner,
                        systems: props.recentSystem.system.systems,
                        genesysNo: props.recentSystem.system.genesysNo
                      }
                    },
                    [
                      SystemActions.openInNewTabAction,
                      SystemActions.renameAction,
                      SystemActions.assignLabelsAction,
                      SystemActions.createNewRevisionAction,
                      SystemActions.transferToUserAction,
                      SystemActions.copyTransferOpenAction
                    ]
                  )}
                />
              </SystemActionsContainer>
            )}
          </a>
        </div>
      </IconsContainer>

      <SystemContainer>
        <a href={`/system/${genesysNo}`}>
          <ImageDisplayer
            routeEndpoint={routeEndpoint}
            accessToken={sharedState.accessToken}
            effectiveSystemId={effectiveSystem.id}
            margin={{
              marginLeft: "-10px"
            }}
          >
            <SystemImage
              accessToken={sharedState.accessToken}
              src={clientConfig.getWizardImageUrl(props.recentSystem.system.id)}
            />
          </ImageDisplayer>

          <TextsContainer>
            <TextContainer>
              <P2 weight="bold" color="dark">
                {props.recentSystem.system.systemTypeId} {revisionNumber}
              </P2>
            </TextContainer>

            <TextContainer>
              <P2 weight="normal" color="dark">
                {props.recentSystem.system.name}
              </P2>
            </TextContainer>

            <TextContainer>
              <P2 weight="normal" color="secondary">
                {sharedState.translate(LanguageTexts.lastChange())}{" "}
                {formatDate(effectiveSystem.statusDate)}
              </P2>
            </TextContainer>

            <TextContainer>
              <P2 weight="normal" color="secondary">
                {effectiveSystem.salesOrganisationId}
              </P2>
              <SystemStatusText.View
                plenumSizeProperty={props.plenumSizeProperty}
                systemTypeId={props.recentSystem.system.systemTypeId}
                sharedState={props.sharedState}
                state={state.systemStatusTextState}
              />
            </TextContainer>
          </TextsContainer>
        </a>
      </SystemContainer>

      <RevisionContainer>
        <Label weight="normal" color="secondary">
          {sharedState.translate(LanguageTexts.revisions()).toUpperCase()}
        </Label>

        <RevisionsDropDownButton onClick={() => onToggleRevisionDropdown()}>
          <Label weight="normal" color="secondary">
            {props.recentSystem.system.systems.length}
          </Label>
          <ArrowDown height="0.4rem" />
        </RevisionsDropDownButton>

        {state.isRevisionDropdownOpen && (
          <ClickAwayListener
            onClickAway={() => {
              onToggleRevisionDropdown();
            }}
          >
            <RevisionsDropDown>
              <RevisionOptionsContainer>
                {revisionOptions.map(r => (
                  <button
                    key={r.systemId}
                    onClick={() => {
                      onChangeActiveRevision(r.systemId);
                    }}
                  >
                    <Label weight="bold" color="dark">
                      {r.revisionNo} &nbsp; {r.comment}
                    </Label>
                    <Label weight="bold" color="dark">
                      {r.statusDate}
                    </Label>
                  </button>
                ))}
              </RevisionOptionsContainer>
            </RevisionsDropDown>
          </ClickAwayListener>
        )}
      </RevisionContainer>
    </Root>
  );
}
