import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import { Amount, Quantity, Units, Serialize } from "@genesys/uom";
import { PropertyValueSet } from "@genesys/property";
import { Dispatch } from "@typescript-tea/core";
import * as System from "../../../../system";
import { Action } from "../state";
import {
  getOperatingCaseSettings,
  getSupplyOutletAirFlowPropertyName
} from "./functions";
import { Physics } from "@munters/calculations";

export function importMoistureLoadBinCases(
  system: System.System,
  data: ReadonlyArray<ReadonlyArray<string>>,
  dispatch: Dispatch<Action>
) {
  if (data.length === 0 || (data[0].length !== 11 && data[0].length !== 23)) {
    return;
  }
  const binCases: Array<SharedEnergyTools.BinCase> = parsetMoistureLoadBinCases(
    system,
    data
  );
  dispatch(Action.setBinCases(binCases));
}

export function parsetMoistureLoadBinCases(
  system: System.System,
  data: ReadonlyArray<ReadonlyArray<string>>
): Array<SharedEnergyTools.BinCase> {
  const unitStrFromHeader = (header: string, defaultUnit?: string) => {
    const res = new RegExp(/\(([^)]+)\)/).exec(header);
    return res ? res[1] : defaultUnit!;
  };

  const midPointUnit = Serialize.stringToUnit(unitStrFromHeader(data[0][1]))!;
  const averageTemperatureUnit = Serialize.stringToUnit<Quantity.Temperature>(
    unitStrFromHeader(data[0][2])
  )!;
  const averageHumidityUnit = Serialize.stringToUnit<
    Quantity.HumidityRatio | Quantity.RelativeHumidity
  >(unitStrFromHeader(data[0][3]))!;
  const windSpeedUnit = Serialize.stringToUnit<Quantity.Velocity>(
    unitStrFromHeader(data[0][4])
  )!;

  const timeUnit = Serialize.stringToUnit<Quantity.Duration>(
    unitStrFromHeader(data[0][5])
  )!;
  const returnAirHumidityUnit = Serialize.stringToUnit<Quantity.HumidityRatio>(
    unitStrFromHeader(data[0][6])
  )!;
  const returnAirTemperatureUnit = Serialize.stringToUnit<Quantity.Temperature>(
    unitStrFromHeader(data[0][7])
  )!;
  const totalMoistureLoadUnit = Serialize.stringToUnit<Quantity.MassFlow>(
    unitStrFromHeader(data[0][8])
  )!;

  const totalHeatLoadUnit = Serialize.stringToUnit<Quantity.Power>(
    unitStrFromHeader(data[0][9], "KiloWatt")
  )!;

  const binPressureUnit = Serialize.stringToUnit<Quantity.Pressure>(
    unitStrFromHeader(data[0][10])
  )!;

  const operatingCase = getOperatingCaseSettings(system);
  const supplyOutletAirFlow = PropertyValueSet.getAmount<Quantity.MassFlow>(
    getSupplyOutletAirFlowPropertyName(operatingCase),
    operatingCase
  );

  const getTargetTemperature = (
    returnAirTemperature: Amount.Amount<Quantity.Temperature>,
    totalHeatLoad: Amount.Amount<Quantity.Power>,
    supplyOutletAirFlow: Amount.Amount<Quantity.MassFlow>
  ) => {
    const returnAirTemp = Amount.valueAs(Units.Celsius, returnAirTemperature);
    const totHeatLoad = Amount.valueAs(Units.KiloWatt, totalHeatLoad);
    const supplyAirFlow = Amount.valueAs(
      Units.KilogramPerSecond,
      supplyOutletAirFlow
    );
    const targetTemperature = returnAirTemp - totHeatLoad / supplyAirFlow;
    return Amount.create(targetTemperature, Units.Celsius);
  };

  const getTargetHumidity = (
    returnAirHumidity: Amount.Amount<Quantity.HumidityRatio>,
    totalMoistureLoad: Amount.Amount<Quantity.MassFlow>,
    supplyOutletAirFlow: Amount.Amount<Quantity.MassFlow>
  ) => {
    const returnAirHum = Amount.valueAs(
      Units.GramPerKilogram,
      returnAirHumidity
    );
    const totalMoistLoad = Amount.valueAs(
      Units.KilogramPerHour,
      totalMoistureLoad
    );
    const supplyAirFlow = Amount.valueAs(
      Units.KilogramPerHour,
      supplyOutletAirFlow
    );
    const targetHumdity =
      returnAirHum - (1000 * totalMoistLoad) / supplyAirFlow;
    return Amount.create(targetHumdity, Units.GramPerKilogram);
  };

  const noTotalMoisture = data.slice(1).every(x => parseFloat(x[8]) === 0);

  return data.slice(1).map(d => {
    const binId = parseFloat(d[0]);
    const midPointTemp =
      midPointUnit.quantity === "Temperature"
        ? Amount.create(parseFloat(d[1]), midPointUnit)
        : undefined;
    const midPointWetTemp =
      midPointUnit.quantity === "WetTemperature"
        ? Amount.create(parseFloat(d[1]), midPointUnit)
        : undefined;
    const midPointDewPointTemp =
      midPointUnit.quantity === "DewPointTemperature"
        ? Amount.create(parseFloat(d[1]), midPointUnit)
        : undefined;
    const midPointSpecificEnthalpy =
      midPointUnit.quantity === "SpecificEnthalpy"
        ? Amount.create(parseFloat(d[1]), midPointUnit)
        : undefined;

    const returnAirHumidity = Amount.create(
      parseFloat(d[6]),
      returnAirHumidityUnit
    );
    const returnAirTemperature = Amount.create(
      parseFloat(d[7]),
      returnAirTemperatureUnit
    );
    const totalMoistureLoad = !noTotalMoisture
      ? Amount.create(parseFloat(d[8]), totalMoistureLoadUnit)
      : undefined;

    const totalHeatLoad = parseFloat(d[9])
      ? Amount.create(parseFloat(d[9]), totalHeatLoadUnit)
      : undefined;
    const targetHumidity =
      supplyOutletAirFlow && totalMoistureLoad
        ? getTargetHumidity(
            returnAirHumidity,
            totalMoistureLoad,
            supplyOutletAirFlow
          )
        : undefined;
    const targetTemperature =
      supplyOutletAirFlow && totalHeatLoad
        ? getTargetTemperature(
            returnAirTemperature,
            totalHeatLoad,
            supplyOutletAirFlow
          )
        : undefined;

    const averageTemperature = Amount.create(
      parseFloat(d[2]),
      averageTemperatureUnit
    );

    const binPressure = Amount.create(parseFloat(d[10]), binPressureUnit);
    const averageHumidity = parseAverageHumidity(
      Amount.create(parseFloat(d[3]), averageHumidityUnit),
      binPressure,
      averageTemperature
    );

    const binTimesMonthly = parseMonthlyBintimes(d, 11, index =>
      unitStrFromHeader(data[0][index])
    );

    return {
      binId: binId,
      midPointTemp: midPointTemp,
      midPointWetTemp: midPointWetTemp,
      midPointDewPointTemp: midPointDewPointTemp,
      midPointSpecificEnthalpy: midPointSpecificEnthalpy,
      averageTemperature: averageTemperature,
      averageHumidity: averageHumidity,
      binTime: Amount.create(parseFloat(d[5]), timeUnit),
      windSpeed: Amount.create(parseFloat(d[4]), windSpeedUnit),
      binPressure: binPressure,
      returnAirHumidity: returnAirHumidity,
      returnAirTemperature: returnAirTemperature,
      totalMoistureLoad: totalMoistureLoad,
      totalHeatLoad: totalHeatLoad,
      targetHumidity: targetHumidity,
      targetTemperature: targetTemperature,
      binTimeJanuary: binTimesMonthly[0],
      binTimeFebruary: binTimesMonthly[1],
      binTimeMarch: binTimesMonthly[2],
      binTimeApril: binTimesMonthly[3],
      binTimeMay: binTimesMonthly[4],
      binTimeJune: binTimesMonthly[5],
      binTimeJuly: binTimesMonthly[6],
      binTimeAugust: binTimesMonthly[7],
      binTimeSeptember: binTimesMonthly[8],
      binTimeOctober: binTimesMonthly[9],
      binTimeNovember: binTimesMonthly[10],
      binTimeDecember: binTimesMonthly[11]
    } as SharedEnergyTools.BinCase;
  });
}

function parseMonthlyBintimes(
  data: ReadonlyArray<string>,
  offset: number,
  unitStrFromHeader: (index: number) => string
) {
  return Array.from({ length: 12 }, (_, i) => {
    const index = i + offset;
    const value = data[index];
    if (!value) {
      return undefined;
    }
    const timeUnit = Serialize.stringToUnit<Quantity.Duration>(
      unitStrFromHeader(index)
    )!;
    return Amount.create(parseFloat(value), timeUnit);
  });
}

function parseAverageHumidity(
  averageHumidity: Amount.Amount<"HumidityRatio" | "RelativeHumidity">,
  binPressure: Amount.Amount<"Pressure">,
  averageTemperature: Amount.Amount<"Temperature">
) {
  if (averageHumidity.unit.quantity === "HumidityRatio") {
    return averageHumidity;
  } else {
    return Physics.RP1485.AmountConversions.relativeHumidityToHumidityRatio(
      binPressure,
      averageTemperature,
      averageHumidity as Amount.Amount<Quantity.RelativeHumidity>
    );
  }
}
