// tslint:disable

import * as React from "react";
import styled from "../styled-components";

export interface IconProps {
  readonly width?: number | string;
  readonly height?: number | string;
  readonly disabled?: boolean;
  readonly onClick?: (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => void;
}

type Props = IconProps &
  Pick<React.HTMLProps<HTMLImageElement>, "onMouseDown" | "onMouseUp">;

export type Icon = (props: Props) => JSX.Element;

const StyledImg = styled.img<{
  readonly width?: number | string;
  readonly height?: number | string;
  readonly useOpacity: boolean;
  readonly pointer: boolean;
}>`
  ${props => props.width && `width: ${props.width}`};
  ${props => props.height && `height: ${props.height}`};
  ${props => (props.useOpacity ? "opacity: 0.5" : "opacity:1")};
  ${props => props.pointer && `cursor: pointer`};
`;

function wrap(
  src:
    | string
    | {
        readonly default: string;
      },
  hoverSrc?:
    | string
    | {
        readonly default: string;
      }
) {
  return (props: Props): JSX.Element => {
    const [isHover, setHover] = React.useState(false);

    const activeSrc: string | { readonly default: string } =
      isHover && hoverSrc
        ? typeof hoverSrc === "string"
          ? hoverSrc
          : hoverSrc.default
        : typeof src === "string"
        ? src
        : src.default;

    const { onClick, onMouseDown, onMouseUp, disabled, ...rest } = props;

    return (
      <StyledImg
        useOpacity={!!disabled}
        pointer={!!(!disabled && onClick)}
        onMouseLeave={event => {
          if (hoverSrc) {
            event.stopPropagation();
            event.preventDefault();
            setHover(false);
          }
        }}
        onMouseUp={event => {
          if (disabled || !onMouseUp) {
            return;
          }
          event.stopPropagation();
          event.preventDefault();
          onMouseUp(event);
        }}
        onMouseDown={event => {
          if (disabled || !onMouseDown) {
            return;
          }
          event.stopPropagation();
          event.preventDefault();
          onMouseDown(event);
        }}
        onMouseEnter={event => {
          if (hoverSrc) {
            event.stopPropagation();
            event.preventDefault();
            setHover(true);
          }
        }}
        src={activeSrc}
        onClick={event => {
          if (onClick && !disabled) {
            event.preventDefault();

            onClick(event);
          }
        }}
        {...rest}
      />
    );
  };
}

// System Configuration

export const Files = wrap(
  require("ui-elements/assets/system-configurator/files.svg")
);
export const Price = wrap(
  require("ui-elements/assets/system-configurator/price.svg")
);
export const Printer = wrap(
  require("ui-elements/assets/system-configurator/printer.svg")
);
export const MenuDots = wrap(
  require("ui-elements/assets/system-configurator/menu-dots.svg")
);
export const StatusComplete = wrap(
  require("ui-elements/assets/system-configurator/status-complete.svg")
);
export const StatusError = wrap(
  require("ui-elements/assets/system-configurator/status-error.svg")
);
export const StatusInProgress100 = wrap(
  require("ui-elements/assets/system-configurator/status-in-progress-100.svg")
);
export const StatusInProgressYellow = wrap(
  require("ui-elements/assets/system-configurator/status-in-progress-yellow.svg")
);
export const StatusLocked = wrap(
  require("ui-elements/assets/system-configurator/status-locked.svg")
);
export const AddComponent = wrap(
  require("ui-elements/assets/system-configurator/add-component.svg")
);
export const BuildInRevit = wrap(
  require("ui-elements/assets/system-configurator/build-in-revit.svg"),
  require("ui-elements/assets/system-configurator/hover/build-in-revit-hover.svg")
);

export const SolidContact = wrap(
  require("ui-elements/assets/system-configurator/solid-contact.svg"),
  require("ui-elements/assets/system-configurator/hover/solid-contact-hover.svg")
);

export const Cooler = wrap(
  require("ui-elements/assets/system-configurator/cooler.svg")
);

export const Condenser = wrap(
  require("ui-elements/assets/system-configurator/air-cooled-condenser.svg")
);
export const ElectricalOptions = wrap(
  require("ui-elements/assets/system-configurator/electrical-options.svg"),
  require("ui-elements/assets/system-configurator/hover/electrical-options-hover.svg")
);
export const Energy = wrap(
  require("ui-elements/assets/system-configurator/energy.svg"),
  require("ui-elements/assets/system-configurator/hover/energy-hover.svg")
);
export const Fan = wrap(
  require("ui-elements/assets/system-configurator/fan.svg")
);
export const Filter = wrap(
  require("ui-elements/assets/system-configurator/filter.svg")
);

export const FilterIcon = wrap(require("ui-elements/assets/filter/filter.svg"));

export const Heater = wrap(
  require("ui-elements/assets/system-configurator/heater.svg")
);
export const MechanicalOptions = wrap(
  require("ui-elements/assets/system-configurator/mechanical-options.svg"),
  require("ui-elements/assets/system-configurator/hover/mechanical-options-hover.svg")
);
export const MechanicalSketch = wrap(
  require("ui-elements/assets/system-configurator/mechanical-sketch.svg"),
  require("ui-elements/assets/system-configurator/hover/mechanical-sketch-hover.svg")
);
export const Measurements = wrap(
  require("ui-elements/assets/system-configurator/measurements.svg")
);
export const OperatingCases = wrap(
  require("ui-elements/assets/system-configurator/operating-cases.svg"),
  require("ui-elements/assets/system-configurator/hover/operating-cases-hover.svg")
);
export const Pid = wrap(
  require("ui-elements/assets/system-configurator/p-id.svg"),
  require("ui-elements/assets/system-configurator/hover/p-id-hover.svg")
);
export const PlenumSize = wrap(
  require("ui-elements/assets/system-configurator/plenum-size.svg"),
  require("ui-elements/assets/system-configurator/hover/plenum-size-hover.svg")
);
export const PropertyGroups = wrap(
  require("ui-elements/assets/system-configurator/property-groups.svg"),
  require("ui-elements/assets/system-configurator/hover/property-groups-hover.svg")
);
export const PsychrometricChart = wrap(
  require("ui-elements/assets/system-configurator/psychrometric-chart.svg"),
  require("ui-elements/assets/system-configurator/hover/psychrometric-chart-hover.svg")
);
export const SectionDefault = wrap(
  require("ui-elements/assets/system-configurator/section.svg")
);

export const Section = wrap(
  require("ui-elements/assets/system-configurator/new-section.svg")
);
export const SpareParts = wrap(
  require("ui-elements/assets/system-configurator/spare-parts.svg"),
  require("ui-elements/assets/system-configurator/hover/spare-parts-hover.svg")
);

export const BlackBox = wrap(
  require("ui-elements/assets/system-configurator/blackbox.svg")
);

export const ServiceOptions = wrap(
  require("ui-elements/assets/system-configurator/service-option.svg")
);
export const SystemAccessories = wrap(
  require("ui-elements/assets/system-configurator/system-accessories.svg"),
  require("ui-elements/assets/system-configurator/hover/system-accessories-hover.svg")
);
export const SystemFeatures = wrap(
  require("ui-elements/assets/system-configurator/system-features.svg"),
  require("ui-elements/assets/system-configurator/hover/system-features-hover.svg")
);
export const EditBlue = wrap(
  require("ui-elements/assets/system-configurator/edit-blue.svg")
);

export const EditGrey = wrap(
  require("ui-elements/assets/system-configurator/edit-grey.svg")
);

export const EnhanceBlue = wrap(
  require("ui-elements/assets/system-configurator/enhance-blue.svg")
);
export const MoveBlue = wrap(
  require("ui-elements/assets/system-configurator/move-blue.svg")
);
export const NotesBlue = wrap(
  require("ui-elements/assets/system-configurator/notes-blue.svg")
);
export const AccessoriesBlue = wrap(
  require("ui-elements/assets/system-configurator/accessories-blue.svg")
);

export const ExpandIcon = wrap(
  require("ui-elements/assets/system-configurator/expand.svg")
);

export const CollapseIcon = wrap(
  require("ui-elements/assets/system-configurator/collapse.svg")
);

// Action
export const ExportBlue = wrap(
  require("ui-elements/assets/action/export-blue.svg")
);
export const ExportDisabled = wrap(
  require("ui-elements/assets/action/export-disabled.svg")
);
export const Export = wrap(require("ui-elements/assets/action/export.svg"));
export const LCCCompare = wrap(
  require("ui-elements/assets/action/lcc-compare.svg")
);
export const LCCCompareDisabled = wrap(
  require("ui-elements/assets/action/lcc-compare-disabled.svg")
);
export const LCCCompareBlue = wrap(
  require("ui-elements/assets/action/lcc-compare-blue.svg")
);
export const LabelSelection = wrap(
  require("ui-elements/assets/action/project-selection.svg")
);
export const LabelSelectionBlue = wrap(
  require("ui-elements/assets/action/project-selection-blue.svg")
);
export const LabelSelectionDisabled = wrap(
  require("ui-elements/assets/action/project-selection-disabled.svg")
);
export const Summary = wrap(require("ui-elements/assets/action/summary.svg"));
export const SummaryBlue = wrap(
  require("ui-elements/assets/action/summary-blue.svg")
);
export const SummaryDisabled = wrap(
  require("ui-elements/assets/action/summary-disabled.svg")
);
export const Trash = wrap(require("ui-elements/assets/action/trash.svg"));
export const TrashDisabled = wrap(
  require("ui-elements/assets/action/trash-disabled.svg")
);
export const TrashBlue = wrap(
  require("ui-elements/assets/action/trash-blue.svg")
);

export const Location = wrap(require("ui-elements/assets/action/location.svg"));

// Button
export const ArrowDownBlue = wrap(
  require("ui-elements/assets/button/arrow-down-blue.svg")
);
export const ArrowDownGreen = wrap(
  require("ui-elements/assets/button/arrow-down-green.svg")
);
export const ArrowDown = wrap(
  require("ui-elements/assets/button/arrow-down.svg")
);
export const ArrowUp = wrap(require("ui-elements/assets/button/arrow-up.svg"));

export const ArrowRight = wrap(
  require("ui-elements/assets/button/arrow-right.svg")
);

export const ArrowLeft = wrap(
  require("ui-elements/assets/button/arrow-left.svg")
);

export const ArrowDownWhite = wrap(
  require("ui-elements/assets/button/arrow-down-white.svg")
);
export const OpenBlue = wrap(
  require("ui-elements/assets/button/open-blue.svg")
);
export const OpenGreen = wrap(
  require("ui-elements/assets/button/open-green.svg")
);

// Dashboard
export const Next = wrap(require("ui-elements/assets/dashboard/next.svg"));
export const Previous = wrap(
  require("ui-elements/assets/dashboard/previous.svg")
);

export const MainLogo = wrap(require("ui-elements/assets/logos/main-logo.svg"));
export const MainLogoYellow = wrap(
  require("ui-elements/assets/logos/main-logo-yellow.svg")
);
export const MainLogoGreen = wrap(
  require("ui-elements/assets/logos/main-logo-green.svg")
);
export const MainLogoPurple = wrap(
  require("ui-elements/assets/logos/main-logo-purple.svg")
);
export const HelpIcon = wrap(require("ui-elements/assets/main-menu/help.svg"));
export const HelpGrayIcon = wrap(
  require("ui-elements/assets/main-menu/help-gray.svg")
);
export const ProfileIcon = wrap(
  require("ui-elements/assets/main-menu/profile.svg")
);
export const SettingsIcon = wrap(
  require("ui-elements/assets/main-menu/settings.svg")
);

//spinners
export const Spinner = wrap(
  require("ui-elements/assets/spinners/animated-spinner.svg")
);

// System manager

export const SearchIcon = wrap(
  require("ui-elements/assets/system-manager/search-icon.svg")
);
export const ClearIcon = wrap(
  require("ui-elements/assets/system-manager/clear.svg")
);

export const TableIcon = wrap(
  require("ui-elements/assets/system-manager/table-icon.svg")
);

export const ListIcon = wrap(
  require("ui-elements/assets/system-manager/list-icon.svg")
);

export const GridIcon = wrap(
  require("ui-elements/assets/system-manager/grid-icon.svg")
);

// system card
export const FavoriteActive = wrap(
  require("ui-elements/assets/system-card/active.svg")
);

export const FavoriteInactive = wrap(
  require("ui-elements/assets/system-card/inactive.svg")
);

// Warning
export const InfoWhite = wrap(
  require("ui-elements/assets/alert-accordion/info.svg")
);

export const InfoGray = wrap(
  require("ui-elements/assets/alert-accordion/info-grej.svg")
);

// MoistureLoad
export const HeatLoadIcon = wrap(
  require("ui-elements/assets/moisture-load/heat-load-icon.png")
);

export const MoistureLoadIcon = wrap(
  require("ui-elements/assets/moisture-load/moisture-load-icon.png")
);

export const EmissionFromCombustionIcon = wrap(
  require("ui-elements/assets/moisture-load/emission-from-combustion.svg")
);

export const EmissionFromPeopleIcon = wrap(
  require("ui-elements/assets/moisture-load/emission-from-people.svg")
);

export const EvaporationFromMaterialsIcon = wrap(
  require("ui-elements/assets/moisture-load/evaporation-from-materials.svg")
);

export const EvaporationOfWaterIcon = wrap(
  require("ui-elements/assets/moisture-load/evaporation-of-water.svg")
);

export const HeatTransmissionIcon = wrap(
  require("ui-elements/assets/moisture-load/heat-transmission.svg")
);

export const IntenitionalVentilationIcon = wrap(
  require("ui-elements/assets/moisture-load/Intentional-ventilation.svg")
);

export const StaticHeatLoadIcon = wrap(
  require("ui-elements/assets/moisture-load/static-heat-load.svg")
);

export const StaticMoistureLoadIcon = wrap(
  require("ui-elements/assets/moisture-load/static-moisture-load.svg")
);

export const UnintendedVentilationIcon = wrap(
  require("ui-elements/assets/moisture-load/unintended-ventilation.svg")
);

export const OpenDoorIcon = wrap(
  require("ui-elements/assets/moisture-load/open-door.svg")
);
// System-selection-guide

export const WarningIcon = wrap(
  require("ui-elements/assets/system-selection-guide/warning.svg")
);

// Logs
export const LogsIcon = wrap(
  require("ui-elements/assets/logs/log-file-icon.svg")
);

export const WeatherIcon = wrap(
  require("ui-elements/assets/system-performance-overview/weather.svg")
);

export const RedCrossIcon = wrap(
  require("ui-elements/assets/system-configurator/red-cross.svg")
);
