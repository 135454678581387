import styled from "styled-components";
import { PrimaryColors, zIndex } from "@genesys/ui-elements";

export const TopMenuContainer = styled.div`
  position: absolute;
  left: 50vw;
  flex-direction: row;
  justify-content: center;
  transform: translate(-50%, 0px);
  min-width: 765px;
  z-index: ${zIndex.Dropdown};
`;

const MenuCardBase = styled.div`
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);

  padding-left: 20px;
  padding-right: 20px;
`;

export const MenuCardCollapsed = styled(MenuCardBase)`
  margin-top: -20px;
`;

export const MenuCardExpanded = styled(MenuCardBase)`
  margin-top: -20px;
  display: flex;
  flex-direction: column;
`;

export const MenuCardContent = styled.div`
  padding: 20px;
`;

export const TopMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TopMenuItem = styled.div<{ readonly disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #023442;
  font-family: Futura;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.133rem;
  margin-left: 20px;
  margin-right: 20px;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  padding-top: 8px;
  padding-bottom: 8px;
  ${props => props.disabled && "opacity: 0.5;"}
`;

export const TopMenuItemSelected = styled(TopMenuItem)`
  border-bottom: 4px solid ${PrimaryColors.muntersBlue};
  padding-bottom: 4px;
`;

export const TopMenuItemEndIconContainer = styled.div`
  margin-left: 11px;
`;

export const TopMenuItemStartIconContainer = styled.div`
  margin-right: 8px;
`;

export const ScreenMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

export const ScreenMenuRow = styled.div`
  display: flex;
  flex-direction: row;

  div:first-child {
    margin-left: 0px;
  }

  div:last-child {
    margin-right: 0px;
  }
`;

export const ScreenMenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 80px;
  margin-left: 60px;
  margin-right: 60px;
  height: 60px;
  width: 60px;
  cursor: pointer;

  white-space: nowrap;

  &:hover span {
    color: ${PrimaryColors.muntersBlue};
  }
`;

export const ScreenMenuItemIconContainer = styled.div`
  padding-bottom: 8px;
`;

export const ScreenMenuLabelContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-left: 4px;
  }
`;
