// tslint:disable-next-line
import styled from "../../styled-components";

export const StyledTable = styled.table<{
  readonly alignHeader: "left" | "center" | "right";
  readonly alignRows: "left" | "center" | "right";
}>`
  letter-spacing: 0;
  font-size: 13px;

  th {
    text-align: ${props => props.alignHeader};
    padding-left: 7px;
    vertical-align: top;
    background-color: white;
  }

  tr:nth-child(odd) {
    background-color: #f7f9fc;
    td {
    }
  }
  tr {
    color: #959695;
    td {
      font-size: 13px;
      line-height: 17px;
      text-align: ${props => props.alignRows};
      height: 23px;
      padding-left: 15px;
      padding-right: 10px;
    }
  }
`;
