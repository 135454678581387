import * as React from "react";
import * as SharedState from "../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "styled-components";
import { Input, StandardButton, H2 } from "@genesys/ui-elements";

interface InputActionProps {
  readonly sharedState: SharedState.State;
  readonly header: string;
  readonly inputValue: string;
  readonly placeHolder: string;
  readonly onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  readonly onSave: () => void;
  readonly onCancel: () => void;
  readonly customTranslation?: (arg: LanguageTexts.StaticText) => string;
}

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 370px;
  height: 160px;
  padding: 15px 20px;
  border: 1px solid #dddddd;
  h3 {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }
  button {
    margin: 15px 10px 0 0;
  }
`;

export function InputComponent({
  sharedState,
  header,
  inputValue,
  placeHolder,
  onChange,
  onSave,
  onCancel,
  customTranslation
}: InputActionProps) {
  const translate = customTranslation || sharedState.translate;
  return (
    <InputContainer>
      <H2 color="primary" weight="normal">
        {header}
      </H2>
      <Input
        onKeyDown={e => {
          if (e.keyCode === 13) {
            onSave();
          }
        }}
        value={inputValue}
        onChange={onChange}
        placeholder={placeHolder}
      />
      <div>
        <StandardButton
          buttonType="SecondaryGreen"
          size="Small"
          onClick={onCancel}
        >
          {translate(LanguageTexts.cancel())}
        </StandardButton>
        <StandardButton buttonType="PrimaryGreen" size="Small" onClick={onSave}>
          {translate(LanguageTexts.ok())}
        </StandardButton>
      </div>
    </InputContainer>
  );
}
