import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  padding: 24px;
`;

export const CalculateBtnContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 24px;
`;

export const InputContainer = styled.div`
  padding-bottom: 16px;
`;

export const CasesContainer = styled.div`
  display: flex;
`;

export const AddCaseBtnContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 4px 0px;
`;

export const CaseContainer = styled.div`
  position: relative;
`;

export const DeleteButtonContainer = styled.div`
  position: absolute;
  left: 96px;
  bottom: -25px;
  cursor: pointer;
`;

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 20px;
`;

export const ErrorsContainer = styled.div`
  padding: 0px 0px 8px 16px;
  max-height: 90px;
  overflow: auto;
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 300px;
  left: 620px;
`;
