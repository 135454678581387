import * as Authorization from "@genesys/shared/lib/authorization";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import { Quantity, Amount } from "@genesys/uom";
import { PropertyValueSet } from "@genesys/property";
import * as SharedState from "../../shared-state";

import {
  getGraphQLUserCmd,
  getGraphQLProductCmd,
  getGraphQLUser,
  getGraphQLProduct
} from "../../effects";
import * as GQLTypes from "../../graphql-types";
import { State } from "./types";

export function buildSharedState(state: State): SharedState.State | undefined {
  const { user, quantityDefaults, fieldDefaults, routes } = state;
  if (
    user === undefined ||
    quantityDefaults === undefined ||
    fieldDefaults === undefined ||
    routes === undefined
  ) {
    return undefined;
  }
  // Typescript doesn't get the typechecking otherwise
  const texts = state.translationTexts
    ? state.translationTexts![state.selectedLang]!
    : {};
  return {
    accessToken: state.accessToken,
    user: {
      ...user,
      lastOpenedSystems: user.lastOpenedSystems,
      favoritesSystems: user.favoritesSystems
    },
    // user: {
    //   ...user,
    //   lastOpenedSystems: user.lastOpenedSystems.map(id =>
    //     typeof id === "string" ? state.briefSystemInfos[id] ?? id : id
    //   ),
    //   favoritesSystems: user.favoritesSystems.map(id =>
    //     typeof id === "string" ? state.briefSystemInfos[id] ?? id : id
    //   )
    // },
    genesysPrefixNotation: {
      genesysNo: state.genesysPrefixes!.genesysNo,
      moistureLoadNo: state.genesysPrefixes!.moistureLoadNo,
      pricingNo: state.genesysPrefixes!.pricingNo
    },
    translate: textDef =>
      LanguageTexts.translate(
        texts || {},
        textDef,
        state.debugSettings.showTextsDB,
        state.debugSettings.showTextsWithTextId
      ),
    screenAmounts: {
      getPropertyFormats: (
        fieldGroup: string,
        properties: PropertyValueSet.PropertyValueSet
      ) => {
        return ScreenAmounts.buildPropertyFormatsMap(
          quantityDefaults,
          fieldDefaults,
          user.settings.userAmountFieldsMap,
          user.settings.selectedAmountProfile.measureSystem,
          fieldGroup,
          properties
        );
      },
      getAmountFormat: (
        fieldGroup: string,
        fieldName: string,
        amountOrQuantity: Amount.Amount<Quantity.Quantity> | Quantity.Quantity
      ) => {
        return ScreenAmounts.getAmountFormat(
          quantityDefaults,
          fieldDefaults,
          user.settings.userAmountFieldsMap,
          user.settings.selectedAmountProfile.measureSystem,
          fieldGroup,
          fieldName,
          amountOrQuantity
        );
      }
    },
    graphQL: {
      queryUser: (query, variables) =>
        getGraphQLUser(
          state.accessToken,
          query,
          variables,
          state.debugSettings.includeServerLog,
          state.debugSettings.useCalculationWorkers
        ),
      queryUserCmd: (query, variables, onResolved, onRejected) =>
        getGraphQLUserCmd(
          state.accessToken,
          query,
          variables,
          state.debugSettings.includeServerLog,
          state.debugSettings.useCalculationWorkers,
          onResolved,
          onRejected
        ),
      queryProduct: (query, variables) =>
        getGraphQLProduct(
          state.accessToken,
          query,
          variables,
          state.debugSettings.includeServerLog
        ),
      queryProductCmd: (query, variables, onResolved, onRejected) =>
        getGraphQLProductCmd(
          state.accessToken,
          query,
          variables,
          state.debugSettings.includeServerLog,
          onResolved,
          onRejected
        )
    },
    genesysPrefix: {
      genesysNo: (genesysNo, revision) =>
        `${state.genesysPrefixes!.genesysNo}${genesysNo}-${revision}`,
      pricingNo: (pricingNo, revision) =>
        `${state.genesysPrefixes!.pricingNo}${pricingNo}-${revision}`,
      moistureLoadNo: (moistureLoadNo, revision) =>
        `${state.genesysPrefixes!.moistureLoadNo}${moistureLoadNo}-${revision}`
    },
    debugSettings: state.debugSettings,
    routes
  };
}

export function buildUser(data: GQLTypes.RootUserQuery): SharedState.User {
  const {
    id,
    name,
    userName,
    hasAcceptedTerms,
    labels,
    applicationClaims,
    currencies,
    userSettings,
    salesOrganisations
  } = data.user.userProfile;
  const selectedAmountProfileId =
    (userSettings.selectedAmountFieldProfile &&
      userSettings.selectedAmountFieldProfile.id) ||
    ScreenAmounts.SIProfileId;
  const selectedAmountFieldProfile = userSettings.amountFieldProfiles.find(
    x => x.id === selectedAmountProfileId
  )!;
  const userAmountFields = ScreenAmounts.createUserAmountFieldsMap(
    selectedAmountFieldProfile.amountFieldFormats
  );
  const createSystemType = userSettings.wizardSettings.createSystemType.reduce(
    (a, b) => ({ ...a, [b.systemType]: b.counter }),
    {} as SharedState.CreateSystemType
  );
  let user: SharedState.User = {
    id,
    name,
    userName,
    labels,
    hasAcceptedTerms,
    applicationClaims: Authorization.toObjectClaims(applicationClaims),
    currencies: currencies.map(c => ({
      id: c.id,
      name: c.name,
      signAfter: c.signAfter || undefined,
      signBefore: c.signBefore || undefined
    })),
    salesOrganisations: salesOrganisations,
    lastOpenedSystems: userSettings.lastOpenedSystems.map(l => ({
      type: "loading",
      id: l.id
    })),
    favoritesSystems: userSettings.favoritesSystems.map(id => ({
      type: "loading",
      id: id
    })),
    createSystemType: createSystemType,
    lastCreatedSystemType:
      userSettings.wizardSettings.lastCreatedSystemType ?? undefined,
    searchScope: userSettings.systemManagerSettings.lastSearchScopeType ?? "MY",
    settings: {
      amountFieldProfiles: userSettings.amountFieldProfiles,
      energySettings: userSettings.energySettings,
      reportSettings: userSettings.reportSettings,
      psychrometricChartSettings: buildPsychrometricChartSettings(
        userSettings.psychrometricChartSettings
      ),
      systemSummarySettings: userSettings.systemsSummaryUserSettings,
      selectedAmountProfile: selectedAmountFieldProfile,
      userAmountFieldsMap: userAmountFields,
      currency: userSettings.culture.currency?.id || "",
      language: userSettings.culture.language?.id || "",
      locale: userSettings.culture.locale?.id || "",
      defaultSalesOrganisation:
        userSettings.sales.defaultSalesOrganisation || undefined,
      climate: {
        country: userSettings.climate.country?.id || "not-set",
        region: userSettings.climate.region?.id || "not-set",
        location: userSettings.climate.location?.id || "not-set",
        climateDataSource: userSettings.climate.climateDataSource || undefined,
        coolingDataType: userSettings.climate.coolingDataType || undefined,
        coolingOccurence: userSettings.climate.coolingOccurence || undefined,
        heatingDataType: userSettings.climate.heatingDataType || undefined,
        heatingOccurence: userSettings.climate.heatingOccurence || undefined,
        manualData: userSettings.climate.manualData || undefined
      }
    }
  };

  return user;
}

function buildPsychrometricChartSettings(
  settings: ReadonlyArray<SharedState.KVPSettings>
): SharedState.PsychrometricChartSettings {
  const getSetting = (key: string, fallback: string): string =>
    settings.find(s => s.settingName === key)?.settingValue ?? fallback;

  return {
    chartType: getSetting("ChartType", "mollier"),
    limits: getSetting("Limits", "smart"),
    humidityMax: getSetting("HumidityMax", "40:GramPerKilogram"),
    temperatureMin: getSetting("TemperatureMin", "0:Celsius"),
    temperatureMax: getSetting("TemperatureMax", "40:Celsius")
  };
}
