import React from "react";
import styled from "../../styled-components";
import { getStyles, Options } from "./options";

export type P1Props = Partial<Options> & {
  readonly children: React.ReactNode;
};

// tslint:disable-next-line
const StyledP1 = styled.p<P1Props>`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.467rem;
  letter-spacing: 0;

  ${({ weight = "light", color = "dark" }) => getStyles({ weight, color })}
`;

// tslint:disable-next-line
export function P1(props: P1Props): JSX.Element {
  return <StyledP1 {...props} />;
}
