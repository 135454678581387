import React, { useState } from "react";
import styled from "../../styled-components";
import { ArrowDown, ArrowDownBlue } from "../../icons";

const ButtonInline = styled.button<{
  readonly isDisabled: boolean;
}>`
  color: #00adf2;
  font-family: Futura;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0.5px;
  /* line-height: 24px; */
  display: inline-flex;
  background-color: white;
  align-items: center;
  text-transform: uppercase;
  opacity: ${props => (props.isDisabled ? "0.5" : "1")};

  label {
    margin-right: 2px;
    ${props => !props.isDisabled && "cursor: pointer"};
  }

  img {
    ${props => !props.isDisabled && "cursor: pointer"};
    height: 5px;
    width: 11px;
    transform: rotate(-90deg);
    margin-bottom: 0.3px;
  }
`;

export function InlineButton({
  children,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const [state, setState] = useState({
    imgSrcForInline: ArrowDown
  });

  const SomeImage = state.imgSrcForInline;

  function onMouseEnterhandler(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.stopPropagation();
    event.preventDefault();
    if (!rest.disabled && state.imgSrcForInline !== ArrowDownBlue) {
      setState({
        imgSrcForInline: ArrowDownBlue
      });
    }
  }

  return (
    <ButtonInline
      {...rest}
      isDisabled={!!rest.disabled}
      onMouseEnter={onMouseEnterhandler}
      onMouseLeave={event => {
        event.stopPropagation();
        event.preventDefault();
        if (state.imgSrcForInline !== ArrowDown) {
          setState({
            imgSrcForInline: ArrowDown
          });
        }
      }}
    >
      <label>{children}</label> <SomeImage />
    </ButtonInline>
  );
}
