import * as React from "react";
import { State, Action } from "./state";
import * as SharedState from "../shared-state";
import { Dispatch } from "@typescript-tea/core";
import {
  Root,
  FlexContainer,
  ErrorMessageContainer,
  ButtonsContainer
} from "./elements";
import { LabelSelector } from "../shared-manager-components";
import { StandardButton, OverlayLoader, S2 } from "@genesys/ui-elements";
import { ModalComponent } from "../modal-component";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Authorization from "@genesys/shared/lib/authorization";

export function PricingWizardView({
  state,
  sharedState,
  systemId,
  dispatch,
  onCancel
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly systemId?: string;
  readonly onCancel?: () => void;
}): JSX.Element {
  const canUsePricing = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysPricingClaims.canUsePricing
  );

  return (
    <Root>
      {state.pricingUrl && (
        <ModalComponent
          sharedState={sharedState}
          header="Pricing Created"
          content={"Open in new tab?"}
          cancelLabel={sharedState.translate(LanguageTexts.openInCurrentTab())}
          confirmLabel={sharedState.translate(LanguageTexts.openInNewTab())}
          onClose={() => {
            dispatch(Action.openPricing(false));
            if (onCancel) {
              onCancel();
            }
          }}
          onConfirm={() => {
            dispatch(Action.openPricing(true));
            if (onCancel) {
              onCancel();
            }
          }}
        />
      )}

      {canUsePricing ? (
        <>
          <FlexContainer>
            {!state.error ? (
              <>
                <LabelSelector
                  inputName={sharedState.translate(LanguageTexts.pricingName())}
                  inputValue={state.name}
                  inputOnChange={value => dispatch(Action.setName(value))}
                  sharedState={sharedState}
                  labelEditorInputs={{
                    assignedLabels: state.assignedLabels,
                    labelAnchor: state.labelAnchor, // check
                    labelManagerState: state.labelManagerState,
                    onAssign: values => {
                      dispatch(Action.setAssignedlables(values));
                      dispatch(Action.toggleLabelManager(null));
                    },
                    dispatchMap: Dispatch.map(
                      Action.dispatchLabelManager,
                      dispatch
                    ),
                    onToggleLabelManager(element) {
                      dispatch(Action.toggleLabelManager(element));
                    }
                  }}
                />
              </>
            ) : (
              <ErrorMessageContainer>
                <S2 weight="normal" color="dark">
                  {sharedState.translate(
                    LanguageTexts.errorMessage(state.error)
                  )}
                </S2>
              </ErrorMessageContainer>
            )}
          </FlexContainer>

          <ButtonsContainer>
            {onCancel && (
              <StandardButton
                buttonType="SecondaryGreen"
                size="Small"
                onClick={onCancel}
              >
                {sharedState.translate(LanguageTexts.cancel())}
              </StandardButton>
            )}

            {!state.error && (
              <StandardButton
                buttonType="PrimaryGreen"
                size="Small"
                onClick={() =>
                  systemId
                    ? dispatch(Action.validateSystem(systemId))
                    : dispatch(Action.createPricing(undefined))
                }
              >
                {sharedState.translate(LanguageTexts.create())}
              </StandardButton>
            )}
          </ButtonsContainer>
          {state.showLoader && <OverlayLoader />}
        </>
      ) : (
        <div>Missing Claim</div>
      )}
    </Root>
  );
}
