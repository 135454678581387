import * as React from "react";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as MoistureLoadActions from "../../../moisture-load-actions";
import * as GraphQLTypes from "../../../graphql-types";
import styled from "styled-components";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { ToolTipWrapper, MenuDots, HasPopOver } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { Action, AclMessage } from "../../state";
import { MenuMode } from "./types";
import { VisbilityEditor } from "./visibility-editor";

interface ContextValue {
  readonly menuMode: MenuMode;
  readonly anchorEl: Element | null;
  readonly setMenuMode: React.Dispatch<React.SetStateAction<MenuMode>>;
  readonly dispatch: Dispatch<Action>;
  readonly setAnchorEl: React.Dispatch<React.SetStateAction<Element | null>>;
}

const ActionMenuContext = React.createContext<ContextValue | null>(null);

const MenuListContainer = styled.div`
  background-color: white;
  border: 1px solid #dddddd;
`;

export function MenuContainer({
  children,
  dispatch
}: {
  readonly children: React.ReactNode;
  readonly dispatch: Dispatch<Action>;
}) {
  const [menuMode, setMenuMode] = React.useState<MenuMode>("list");
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const providerValue = {
    menuMode,
    anchorEl,
    setAnchorEl,
    dispatch,
    setMenuMode
  };

  return (
    <>
      <ActionMenuContext.Provider value={providerValue}>
        {children}
      </ActionMenuContext.Provider>
    </>
  );
}

export function Button({
  sharedState,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  if (ActionMenuContext === null) {
    throw new Error();
  }
  const buttonRef = React.useRef<HTMLAnchorElement>(null);

  const { setAnchorEl } = React.useContext<ContextValue>(
    ActionMenuContext as any
  );
  return (
    <a
      ref={buttonRef}
      onClick={() => {
        setAnchorEl(buttonRef.current);
        dispatch(Action.toggleMoistureLoadActions());
      }}
    >
      <ToolTipWrapper
        title={sharedState.translate(LanguageTexts.actions())}
        placement="top"
      >
        <MenuDots onClick={() => undefined} height="1.4rem" />
      </ToolTipWrapper>
    </a>
  );
}

export function MenuList({
  actionState,
  actionMenuIsOpen,
  moistureLoadFile,
  sharedState,
  mno,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly actionMenuIsOpen: boolean;
  readonly actionState: MoistureLoadActions.State;
  readonly mno: number;
  readonly moistureLoadFile: NonNullable<
    GraphQLTypes.MoistureLoadCalculationUserQuery["user"]["moistureLoadByMoistureLoadNo"]
  >["moistureloadFile"];
  readonly dispatch: Dispatch<Action>;
}) {
  if (ActionMenuContext === null) {
    throw new Error("Menu List is runnings outside of context");
  }

  const { menuMode, anchorEl, setMenuMode, setAnchorEl } =
    React.useContext<ContextValue>(ActionMenuContext as any);

  if (menuMode !== "list" || !actionMenuIsOpen) {
    return null;
  }

  const parsedMoistureLoadFile = {
    id: moistureLoadFile.id,
    mno: mno,
    labels: moistureLoadFile.labels,
    latestMoistureLoad: {
      id: moistureLoadFile.latestMoistureLoad.id,
      name: moistureLoadFile.latestMoistureLoad.name,
      status: moistureLoadFile.latestMoistureLoad.status,
      revisionNo: moistureLoadFile.latestMoistureLoad.revisionNo
    },
    name: moistureLoadFile.name,
    owner: moistureLoadFile.owner.userName
  };

  const renderPopOver = (component: React.ReactNode) => (
    <HasPopOver
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      onClose={() => {
        setMenuMode("list");
        dispatch(Action.toggleMoistureLoadActions());
      }}
    >
      {component}
    </HasPopOver>
  );

  return (
    <>
      {renderPopOver(
        <MenuListContainer>
          <MoistureLoadActions.MenuContainer
            sharedState={sharedState}
            state={actionState}
            dispatch={Dispatch.map(Action.dispatchMoistureLoadAction, dispatch)}
          >
            <MoistureLoadActions.CustomAction
              text={sharedState.translate(LanguageTexts.mlcAccesControl())}
              onClick={() => {
                setMenuMode("visibility-content");
              }}
            />

            <MoistureLoadActions.CreateNewRevision
              moistureLoadFile={parsedMoistureLoadFile}
            />
            <MoistureLoadActions.Rename
              moistureLoadFile={parsedMoistureLoadFile}
            />
            <MoistureLoadActions.AssignLabels
              moistureLoadFile={parsedMoistureLoadFile}
            />
            <MoistureLoadActions.CopyLatestRevToNewFile
              moistureLoadFile={parsedMoistureLoadFile}
            />
            <MoistureLoadActions.TransferToUser
              moistureLoadFile={parsedMoistureLoadFile}
            />
          </MoistureLoadActions.MenuContainer>
        </MenuListContainer>
      )}
    </>
  );
}

export function Content(props: {
  readonly sharedState: SharedState.State;
  readonly disabled: boolean;
  readonly canChangeVisiblity: boolean;
  readonly message: AclMessage | undefined;
  readonly userInputSearchValue: string;
  readonly moistureLoadItem: NonNullable<
    GraphQLTypes.MoistureLoadCalculationUserQuery["user"]["moistureLoadByMoistureLoadNo"]
  >;
  readonly dispatch: Dispatch<Action>;
}) {
  if (ActionMenuContext === null) {
    throw new Error("Content Component is running outside of context");
  }
  const { menuMode, anchorEl, setMenuMode, setAnchorEl } =
    React.useContext<ContextValue>(ActionMenuContext as any);

  if (menuMode === "list") {
    return null;
  }

  const renderPopOver = (component: React.ReactNode) => (
    <HasPopOver
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      onClose={() => {
        setMenuMode("list");
        props.dispatch(Action.toggleMoistureLoadActions());
      }}
    >
      {component}
    </HasPopOver>
  );

  switch (menuMode) {
    case "visibility-content": {
      return renderPopOver(
        <VisbilityEditor
          {...props}
          aclUsers={props.moistureLoadItem.moistureloadFile.aclUsers}
          aclClaims={props.moistureLoadItem.moistureloadFile.aclClaims}
          setMenuMode={setMenuMode}
        />
      );
    }

    default:
      return exhaustiveCheck(menuMode, true);
  }
}

// tslint:disable-next-line
