import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as SharedState from "../shared-state";
import * as Navigation from "../navigation-effect-manager";

export type State = {
  readonly isPricingDropdownOpen: boolean;
  readonly isMoistureLoadDropdownOpen: boolean;
  readonly isToolsDropdownOpen: boolean;
  readonly systemsMenuExpanded: boolean;
};
export const init = (_sharedState: SharedState.State): readonly [State] => {
  return [
    {
      isPricingDropdownOpen: false,
      isMoistureLoadDropdownOpen: false,
      isToolsDropdownOpen: false,
      systemsMenuExpanded: false
    }
  ];
};

export const Action = ctorsUnion({
  togglePricingDrowdown: () => ({}),
  toggleMoistureLoadDropdown: () => ({}),
  toggleToolsDrowdown: () => ({}),
  closeSystemDropDown: () => ({}),
  navigate: (url: string) => ({ url })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "togglePricingDrowdown": {
      return [
        { ...state, isPricingDropdownOpen: !state.isPricingDropdownOpen }
      ];
    }

    case "toggleMoistureLoadDropdown": {
      return [
        {
          ...state,
          isMoistureLoadDropdownOpen: !state.isMoistureLoadDropdownOpen
        }
      ];
    }
    case "toggleToolsDrowdown": {
      return [{ ...state, isToolsDropdownOpen: !state.isToolsDropdownOpen }];
    }

    case "closeSystemDropDown": {
      return [{ ...state, systemsMenuExpanded: false }];
    }

    case "navigate": {
      return [state, Navigation.pushUrl(action.url)];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
