import * as React from "react";
import { InfoGray, Pop, P1, S1, H3 } from "@genesys/ui-elements";
import {
  PropertyFilter,
  PropertyValue,
  PropertyValueSet
} from "@genesys/property";
import {
  DescriptionContainer,
  ValueDescriptionsContainer,
  DescriptionIconContainer
} from "../elements";
import { PropertyInfo, DescriptionText } from "../types";

export function PropertyDescription({
  language,
  properties,
  property,
  showCodes,
  translatePropertyValue,
  translatePropertyName
}: {
  readonly language: string;
  readonly showCodes: boolean;
  readonly property: PropertyInfo;
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly translatePropertyName: (propertyName: string) => string;
  readonly translatePropertyValue: (
    propertyName: string,
    value: number
  ) => string;
}) {
  const labelRef = React.useRef(null);
  const [anchor, setAnchor] = React.useState<Element | null>(null);

  const getText = (
    descriptionTexts: ReadonlyArray<DescriptionText>
  ): string | undefined => {
    return descriptionTexts.length === 0
      ? undefined
      : (
          descriptionTexts.find(x => x.language === language) ??
          descriptionTexts.find(x => x.language === "en-GB") ??
          descriptionTexts[0]
        ).text ?? undefined;
  };

  const propertyDescription = getText(
    property.descriptionTexts.filter(x =>
      PropertyFilter.isValid(properties, x.propertyFilter)
    )
  );

  const valueDescriptions = property.items.reduce(
    (soFar, current) => {
      return current.value?.type === "integer" &&
        current.descriptionValuesTexts.length > 0
        ? soFar.concat([
            {
              value: PropertyValue.getInteger(current.value)!,
              text: getText(
                current.descriptionValuesTexts.filter(x =>
                  PropertyFilter.isValid(properties, x.propertyFilter)
                )
              )
            }
          ])
        : soFar;
    },
    [] as ReadonlyArray<{
      readonly value: number;
      readonly text: string | undefined;
    }>
  );

  if (propertyDescription === undefined) {
    return <></>;
  }

  return (
    <div>
      <DescriptionIconContainer
        ref={labelRef}
        onClick={() => setAnchor(labelRef.current)}
      >
        <InfoGray />
      </DescriptionIconContainer>

      {anchor && (
        <Pop
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          anchor={anchor}
          onClose={() => setAnchor(null)}
        >
          <DescriptionContainer>
            <H3>
              {translatePropertyName(property.name)}
              {showCodes && ` (${property.name})`}
            </H3>
            <S1>{propertyDescription}</S1>
            <ValueDescriptionsContainer>
              {valueDescriptions.map(x => {
                return (
                  <>
                    <P1 weight="normal" color="dark">
                      {x.text && translatePropertyValue(property.name, x.value)}
                    </P1>
                    <P1 weight="normal" color="light">
                      {x.text}
                    </P1>
                  </>
                );
              })}
            </ValueDescriptionsContainer>
          </DescriptionContainer>
        </Pop>
      )}
    </div>
  );
}
