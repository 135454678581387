import React from "react";
import styled from "styled-components";
import { PriceInfo } from "../../types";
import {
  GenericTable,
  GenericTableColumnDefinitionType,
  Spinner
} from "@genesys/ui-elements";
import * as Uuid from "uuid";

const Container = styled.div``;

interface BaseTableColumn {
  readonly valueHeading: string;
  readonly descriptionHeading: string;
}

interface BreakDownTable extends BaseTableColumn {
  readonly nameHeading: string;
}

interface FactorsTable extends BaseTableColumn {
  readonly factorHeading: string;
}

const TableContainer = styled.div`
  margin-bottom: 10px;
`;

export function PriceBreakdownView({
  priceinfo,
  isLoading
}: {
  readonly isLoading: boolean;
  readonly priceinfo: ReadonlyArray<PriceInfo> | undefined;
}) {
  if (isLoading) {
    return <Spinner />;
  }

  if (!priceinfo) {
    return <div>No price information found</div>;
  }

  return (
    <Container>
      <div>{priceinfo.map(renderPriceInfoTable)}</div>
    </Container>
  );
}
function renderPriceInfoTable(info: PriceInfo, index: number) {
  return (
    <TableContainer key={index}>
      <h4>{info.type}</h4>
      {info.breakDownTable && (
        <GenericTable
          columns={generateBreakDownTableColumns(info.breakDownTable)}
          data={info.breakDownTable.rows.map(row => ({
            rowId: Uuid.v4(),
            rowValues: {
              descriptionHeading: {
                value: row.description,
                cellId: Uuid.v4()
              },
              valueHeading: {
                value: row.value,
                cellId: Uuid.v4()
              },
              nameHeading: {
                value: row.name,
                cellId: Uuid.v4()
              }
            }
          }))}
        />
      )}
      {info.factorsTable && (
        <GenericTable
          columns={generateFactorsTableColumns(info.factorsTable)}
          data={info.factorsTable.rows.map(row => ({
            rowId: Uuid.v4(),
            rowValues: {
              descriptionHeading: {
                value: row.description,
                cellId: Uuid.v4()
              },
              factorHeading: {
                value: row.factor,
                cellId: Uuid.v4()
              },
              valueHeading: {
                value: row.value,
                cellId: Uuid.v4()
              }
            }
          }))}
        />
      )}
    </TableContainer>
  );
}

function generateBreakDownTableColumns(table: BreakDownTable) {
  return generateTableColumns<BreakDownTable>([
    { key: "descriptionHeading", header: table.descriptionHeading },
    { key: "valueHeading", header: table.valueHeading },
    { key: "nameHeading", header: table.nameHeading }
  ]);
}

function generateFactorsTableColumns(table: FactorsTable) {
  return generateTableColumns<FactorsTable>([
    { key: "descriptionHeading", header: table.descriptionHeading },
    { key: "factorHeading", header: table.factorHeading },
    { key: "valueHeading", header: table.valueHeading }
  ]);
}

function generateTableColumns<T extends Record<keyof T, string | number>>(
  columns: ReadonlyArray<{
    readonly key: keyof T;
    readonly header: string;
    readonly align?: "left" | "right" | "center";
  }>
): GenericTableColumnDefinitionType<T>[] {
  return columns.map(col => ({
    key: col.key,
    header: col.header,
    align: col.align || "left"
  }));
}

// function getRows<X, T extends Record<keyof T, string | number>>(lol: {
//   readonly rowId: string;
//   readonly rowValues: Row<T>;
// }): ReadonlyArray<GenericTableRowType<T>> {
//   // Assuming you have logic to generate rows based on the table data
//   // Placeholder for row generation
//   return [{ rowId: lol.rowId, rowValues: lol.rowValues }];
// }
