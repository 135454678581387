import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as FetchDocument from "@genesys/client-core/lib/fetch-document";
import { Action } from "../state";
import { StandardButton } from "@genesys/ui-elements";
import { ImageExportRoot } from "../elements";
import { GenesysSelect } from "@genesys/ui-elements";
import { Format } from "../types";

export function ImageExport({
  url,
  fileName,
  params,
  formats,
  selectedFormatId,
  sharedState,
  dispatch
}: {
  readonly url: string;
  readonly fileName: string;
  readonly params: {
    readonly [key: string]: string | number;
  };
  readonly selectedFormatId: string | undefined;
  readonly formats: ReadonlyArray<Format>;
  readonly sharedState: SharedState.State;
  readonly dispatch: (action: Action) => void;
}): JSX.Element {
  return (
    <ImageExportRoot>
      <GenesysSelect
        width={50}
        height={28}
        fontSize={14}
        value={selectedFormatId}
        onChange={e =>
          dispatch(
            Action.setFormat(formats.find(f => f.id === e.target.value)!)
          )
        }
        options={formats.map(f => ({
          value: f.id,
          title: f.id
        }))}
      />
      <FetchDocument.FetchDocumentComponent
        url={url}
        filename={fileName}
        params={params}
        type={"download"}
        authentication={{
          type: "Bearer",
          accessToken: sharedState.accessToken
        }}
        onComplete={() => {
          dispatch(Action.setIsLoading(false));
        }}
      >
        {onClick => (
          <StandardButton
            size="Small"
            buttonType="PrimaryBlue"
            onClick={() => {
              dispatch(Action.setIsLoading(true));
              onClick();
            }}
          >
            {sharedState.translate(LanguageTexts.exportSketch())}
          </StandardButton>
        )}
      </FetchDocument.FetchDocumentComponent>
    </ImageExportRoot>
  );
}
