import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../shared-state";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import { StandardButton, Confirm, Pop, H3 } from "@genesys/ui-elements/";
import styled from "styled-components";

export function CalculateButton({
  onCalculateEnergy,
  onCalculate,
  translate,
  onLock,
  toggleConfirmBox,
  toggleEnergyBinSelectionsValidationError,
  onCalculateEnergyComparisons,
  status,
  calculateEnergy,
  showConfirmLockRevision,
  calculateEnergyConparison,
  sharedState,
  showEnergyBinSelectionsValidationError
}: {
  readonly translate: LanguageTexts.Translate;
  readonly onCalculate: () => void;
  readonly onLock: () => void;
  readonly toggleConfirmBox: () => void;
  readonly toggleEnergyBinSelectionsValidationError: () => void;
  readonly status: number;
  readonly calculateEnergy: boolean;
  readonly calculateEnergyConparison: boolean;
  readonly sharedState: SharedState.State;
  readonly showConfirmLockRevision: boolean;
  readonly onCalculateEnergy: () => void;
  readonly onCalculateEnergyComparisons: () => void;
  readonly showEnergyBinSelectionsValidationError: boolean;
}) {
  if (status === SystemStatus.LockSuccess) {
    return (
      <StandardButton buttonType="SecondaryBlue" disabled size="Large">
        {translate(LanguageTexts.locked())}
      </StandardButton>
    );
  }

  if (status === SystemStatus.PriceCalculationSuccess && calculateEnergy) {
    const myRef = React.useRef<HTMLDivElement>(null);
    return (
      <>
        <div ref={myRef}>
          <StandardButton
            onClick={onCalculateEnergy}
            buttonType="PrimaryGreen"
            size="Large"
          >
            {translate(LanguageTexts.calculateEnergy())}
          </StandardButton>
        </div>

        {showEnergyBinSelectionsValidationError && (
          <EnergyValidationMessage
            labelRef={myRef}
            toggleEnergyBinSelectionsValidationError={
              toggleEnergyBinSelectionsValidationError
            }
            translate={translate}
          />
        )}
      </>
    );
  }

  if (
    status === SystemStatus.EnergyCalculationSuccess &&
    calculateEnergyConparison
  ) {
    return (
      <div>
        <StandardButton
          onClick={onCalculateEnergyComparisons}
          buttonType="SecondaryGreen"
          size="Large"
        >
          {translate(LanguageTexts.calculateMcompare())}
        </StandardButton>
      </div>
    );
  }

  if (
    status === SystemStatus.EnergyCalculationSuccess ||
    status === SystemStatus.MCompareCalculationSuccess ||
    (status === SystemStatus.PriceCalculationSuccess && !calculateEnergy)
  ) {
    const myRef = React.useRef<HTMLDivElement>(null);
    return (
      <>
        <div ref={myRef}>
          <StandardButton
            onClick={toggleConfirmBox}
            buttonType="SecondaryBlue"
            size="Large"
          >
            {translate(LanguageTexts.lockRevision())}
          </StandardButton>
        </div>

        <Confirm
          headerText={sharedState.translate(LanguageTexts.confirmation())}
          labelRef={myRef}
          cancelTranslated={sharedState.translate(LanguageTexts.cancel())}
          confirmTranslated={sharedState.translate(LanguageTexts.confirm())}
          showDialog={showConfirmLockRevision}
          onConfirm={() => {
            onLock();
            toggleConfirmBox();
          }}
          onCancel={toggleConfirmBox}
        />
      </>
    );
  }

  return (
    <StandardButton
      onClick={onCalculate}
      buttonType="PrimaryGreen"
      size="Large"
    >
      {translate(LanguageTexts.calculate())}
    </StandardButton>
  );
}

function EnergyValidationMessage({
  labelRef,
  toggleEnergyBinSelectionsValidationError,
  translate
}: {
  readonly labelRef: React.MutableRefObject<HTMLElement | null>;
  readonly toggleEnergyBinSelectionsValidationError: () => void;
  readonly translate: LanguageTexts.Translate;
}) {
  const Container = styled.div`
    padding: 15px 20px;
    border: 1px solid #dddddd;
    background-color: white;
    > div {
      display: flex;
      button {
        margin: 15px 10px 0 0;
      }
    }
  `;

  const ref = labelRef?.current as Element;

  return (
    <div>
      {ref && (
        <Pop
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          anchor={ref}
          onClose={toggleEnergyBinSelectionsValidationError}
        >
          <Container>
            <H3 color="dark" weight="normal">
              {translate(LanguageTexts.currencyHasBeenChanged())}
            </H3>
            <div>
              <StandardButton
                buttonType="SecondaryGreen"
                size="Small"
                onClick={toggleEnergyBinSelectionsValidationError}
              >
                {translate(LanguageTexts.close())}
              </StandardButton>
            </div>
          </Container>
        </Pop>
      )}
    </div>
  );
}
