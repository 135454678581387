import { Sub } from "@typescript-tea/core";
import * as State from "./state";
import * as SharedState from "../shared-state";
import * as SystemConfigurator from "../system-configurator";
import * as DebugSettings from "../debug-settings";

export function subscriptions(
  sharedState: SharedState.State,
  state: State.State
): Sub<State.Action> | undefined {
  const debugSettingsSubs = Sub.map(
    State.Action.dispatchDebugSettings,
    state.debugSettingsState && DebugSettings.subscriptions()
  );
  if (state.urlMatch === undefined) {
    return debugSettingsSubs;
  }

  switch (state.urlMatch.location.type) {
    case "SystemConfiguration": {
      return (
        state.systemConfiguratorState &&
        Sub.batch<State.Action>([
          debugSettingsSubs,
          Sub.map(
            State.Action.dispatchSystemConfigurator,
            SystemConfigurator.subscriptions(
              sharedState,
              state.systemConfiguratorState
            )
          )
        ])
      );
    }
    default: {
      return debugSettingsSubs;
    }
  }
}
