import * as React from "react";
import {
  MainLogoYellow,
  MainLogo,
  MainLogoGreen,
  MainLogoPurple
} from "@genesys/ui-elements";
import { Environment } from "../../config";
import { exhaustiveCheck } from "ts-exhaustive-check";

export function GenesysLogo({
  env
}: {
  readonly env: Environment;
}): JSX.Element {
  switch (env) {
    case "Localhost":
      return <MainLogoPurple width="106px" />;
    case "Develop": {
      return <MainLogoGreen width="106px" />;
    }
    case "Staging": {
      return <MainLogoYellow width="106px" />;
    }
    case "Production": {
      return <MainLogo width="106px" />;
    }
    default: {
      return exhaustiveCheck(env);
    }
  }
}
