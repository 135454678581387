import React from "react";
import styled from "styled-components";
import { ExpandIcon, CollapseIcon, P1 } from "@genesys/ui-elements";
import * as TimerLogsNew from "./timer-grouping";

const TopContainer = styled.div`
  display: flex;
  position: relative;
`;

const OpenableRelativeWithMarginContainer = styled.div`
  margin-left: 32px;
  position: relative;
  ${(props: { readonly isOpen: boolean }) =>
    props.isOpen ? "" : "display: none"};
`;

const OpenableWithMarginContainer = styled.div`
  margin-left: 32px;
  ${(props: { readonly isOpen: boolean }) =>
    props.isOpen ? "" : "display: none"};
`;

const RelativeContainer = styled.div`
  position: relative;
`;
// const OpenableRelativeContainer = styled.div`
//   position: relative;
//   ${(props: { readonly isOpen: boolean }) =>
//     props.isOpen ? "" : "display: none"};
// `;

const IconContainer = styled.div`
  position: absolute;
  top: 1px;
  left: -20px;
  img {
    cursor: pointer;
  }
`;

export function getStandardTimerLogElement(
  singleNode: TimerLogsNew.SingleNode,
  shouldBeExpanded: boolean
): JSX.Element {
  // Time
  let nodeLog = `: ${formatTimeSpan(singleNode.totalNs)} s`;

  // Percent of parent
  if (singleNode.percentOfParent !== undefined) {
    nodeLog += `, (${formatPercentOfParent(
      singleNode.percentOfParent
    )} % of parent)`;
  }

  // Count
  nodeLog += `, Calls: ${formatCalls(singleNode.count)}`;

  // Other
  if (singleNode.otherNs !== undefined) {
    nodeLog += `, Other: ${formatTimeSpan(singleNode.otherNs)} s`;
  }

  // Idle
  if (singleNode.idleNs !== undefined) {
    nodeLog += `, Idle: ${formatTimeSpan(singleNode.idleNs)} s`;
  }

  if (singleNode.isFireAndForget) {
    nodeLog +=
      ", call starts a job in another thread and exits, sub-totals will be found in subcalls.";
  }

  const subNodes = singleNode.singleNodes
    .map(s => s)
    .sort((a, b) => a.startNs - b.startNs);

  const parallels = singleNode.parallellNode;

  const [isOpen, setState] = React.useState(shouldBeExpanded);
  const Icon = () => {
    if (isOpen) {
      return <CollapseIcon height={"16px"} onClick={() => setState(false)} />;
    } else {
      return <ExpandIcon height={"16px"} onClick={() => setState(true)} />;
    }
  };

  return (
    <div>
      <TopContainer>
        {subNodes.length > 0 && (
          <IconContainer>
            <Icon />
          </IconContainer>
        )}
        <P1
          color="dark"
          weight={subNodes.length > 0 || parallels ? "bold" : "normal"}
        >
          {formatNodeName(singleNode.name)}
        </P1>
        <P1 color="secondary" weight="normal">
          {nodeLog}
        </P1>
      </TopContainer>
      <OpenableRelativeWithMarginContainer isOpen={isOpen}>
        {subNodes.length > 0 &&
          subNodes.map((subNode, index) => (
            <div key={`${subNode.name}-${index}`}>
              {getStandardTimerLogElement(subNode, true)}
            </div>
          ))}
        {parallels && (
          <div key="hej">{getGroupedParallellNodesElement(parallels)}</div>
        )}
      </OpenableRelativeWithMarginContainer>
    </div>
  );
}

export function getGroupedParallellNodesElement(
  node: TimerLogsNew.MultipleParallellNode
): JSX.Element {
  // Time
  let nodeLog = `: ${formatTimeSpan(node.totalNs)} s`;

  // Percent of parent
  if (node.percentOfParent !== undefined) {
    nodeLog += `, (${formatPercentOfParent(node.percentOfParent)} % of parent)`;
  }

  // Count
  nodeLog += `, Calls: ${formatCalls(node.count)}`;

  const orderedDeepSummedNodes = node.deepSummedNodes
    .map(s => s)
    .sort((a, b) => a.startNs - b.startNs);

  const orderedIndividualNodes = node.individualParallellNodes
    .map(s => s)
    .sort((a, b) => a.startNs - b.startNs);

  const hasSubNodes = orderedIndividualNodes.length > 0;

  const [isOpen, setState] = React.useState({
    isIndividualCallsOpen: false,
    isSubNodesOpen: true
  });
  const IndividualCallsIcon = () => {
    if (isOpen.isIndividualCallsOpen) {
      return (
        <CollapseIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isIndividualCallsOpen: false })}
        />
      );
    } else {
      return (
        <ExpandIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isIndividualCallsOpen: true })}
        />
      );
    }
  };

  const SubNodesIcon = () => {
    if (isOpen.isSubNodesOpen) {
      return (
        <CollapseIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isSubNodesOpen: false })}
        />
      );
    } else {
      return (
        <ExpandIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isSubNodesOpen: true })}
        />
      );
    }
  };

  return (
    <div>
      <TopContainer>
        {hasSubNodes && (
          <IconContainer>
            <SubNodesIcon />
          </IconContainer>
        )}
        <P1 color="dark" weight={hasSubNodes ? "bold" : "normal"}>
          All parallell calls
        </P1>
        <P1 color="secondary" weight="normal">
          {nodeLog}
        </P1>
      </TopContainer>
      <OpenableRelativeWithMarginContainer isOpen={isOpen.isSubNodesOpen}>
        <P1 color="dark" weight="bold">
          Sum of parallell calls
        </P1>
        <OpenableWithMarginContainer isOpen={isOpen.isSubNodesOpen}>
          {orderedDeepSummedNodes.map((subNode, index) => (
            <div key={`${subNode.name}-${index}`}>
              {getNodeInfoLogElement(subNode, false)}
            </div>
          ))}
        </OpenableWithMarginContainer>
        <RelativeContainer>
          <IconContainer>
            <IndividualCallsIcon />
          </IconContainer>
          <P1 color="dark" weight="bold">
            Individual calls
          </P1>
        </RelativeContainer>
        <OpenableWithMarginContainer isOpen={isOpen.isIndividualCallsOpen}>
          {hasSubNodes &&
            orderedIndividualNodes.map((subNode, index) => (
              <div key={`${subNode.name}-${index}`}>
                {getParallellNodeElement(subNode, false)}
              </div>
            ))}
        </OpenableWithMarginContainer>
      </OpenableRelativeWithMarginContainer>
    </div>
  );
}

function getParallellNodeElement(
  node: TimerLogsNew.ParallellNode,
  shouldBeExpanded: boolean
): JSX.Element {
  // Time
  let nodeLog = `: ${formatTimeSpan(node.totalNs)} s`;

  // Percent of parent
  if (node.percentOfParent !== undefined) {
    nodeLog += `, (${formatPercentOfParent(node.percentOfParent)} % of parent)`;
  }

  // Count
  nodeLog += `, Calls: ${formatCalls(node.count)}`;

  // Idle
  if (node.idleNs !== undefined) {
    nodeLog += `, Idle: ${formatTimeSpan(node.idleNs)} s`;
  }

  const hasSubNodes = node.individualSingleNodes.length > 0;

  const orderedSingleNodes = node.individualSingleNodes
    .map(s => s)
    .sort((a, b) => a.startNs - b.startNs);

  const orderedAverageNodes = node.deepAveragedNodes
    .map(s => s)
    .sort((a, b) => a.startNs - b.startNs);

  const [isOpen, setState] = React.useState({
    isIndividualCallsOpen: false,
    isSubNodesOpen: true
  });
  const IndividualCallsIcon = () => {
    if (isOpen.isIndividualCallsOpen) {
      return (
        <CollapseIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isIndividualCallsOpen: false })}
        />
      );
    } else {
      return (
        <ExpandIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isIndividualCallsOpen: true })}
        />
      );
    }
  };

  const SubNodesIcon = () => {
    if (isOpen.isSubNodesOpen) {
      return (
        <CollapseIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isSubNodesOpen: false })}
        />
      );
    } else {
      return (
        <ExpandIcon
          height={"16px"}
          onClick={() => setState({ ...isOpen, isSubNodesOpen: true })}
        />
      );
    }
  };

  return (
    <div>
      <TopContainer>
        {hasSubNodes && (
          <IconContainer>
            <SubNodesIcon />
          </IconContainer>
        )}
        <P1 color="dark" weight={hasSubNodes ? "bold" : "normal"}>
          {formatNodeName(node.name)}
        </P1>
        <P1 color="secondary" weight="normal">
          {nodeLog}
        </P1>
      </TopContainer>
      <OpenableRelativeWithMarginContainer isOpen={isOpen.isSubNodesOpen}>
        <P1 color="dark" weight="bold">
          Average of parallell calls
        </P1>
        <OpenableWithMarginContainer isOpen={isOpen.isSubNodesOpen}>
          {orderedAverageNodes.map((subNode, index) => (
            <div key={`${subNode.name}-${index}`}>
              {getNodeInfoLogElement(subNode, shouldBeExpanded)}
            </div>
          ))}
        </OpenableWithMarginContainer>
        <RelativeContainer>
          <IconContainer>
            <IndividualCallsIcon />
          </IconContainer>
          <P1 color="dark" weight="bold">
            Individual calls
          </P1>
        </RelativeContainer>
        <OpenableWithMarginContainer isOpen={isOpen.isIndividualCallsOpen}>
          {orderedSingleNodes.map((subNode, index) => (
            <div key={`${subNode.name}-${index}`}>
              {getStandardTimerLogElement(subNode, false)}
            </div>
          ))}
        </OpenableWithMarginContainer>
      </OpenableRelativeWithMarginContainer>
    </div>
  );
}

function getNodeInfoLogElement(
  nodeInfo: TimerLogsNew.NodeInfo,
  shouldBeExpanded: boolean
): JSX.Element {
  let nodeLog = "";
  if (nodeInfo.type === "Sum") {
    // Time
    nodeLog += `: ${formatTimeSpan(nodeInfo.totalNs)} s`;

    // Percent of parent
    if (nodeInfo.percentOfParent !== undefined) {
      nodeLog += `, (${formatPercentOfParent(
        nodeInfo.percentOfParent
      )} % of parent)`;
    }

    // Count
    nodeLog += `, Calls: ${formatCalls(nodeInfo.count)}`;

    // Other
    if (nodeInfo.otherNs !== undefined) {
      nodeLog += `, Other: ${formatTimeSpan(nodeInfo.otherNs)} s`;
    }

    // Idle
    if (nodeInfo.idleNs !== undefined) {
      nodeLog += `, Idle: ${formatTimeSpan(nodeInfo.idleNs)} s`;
    }
  } else {
    // Time
    nodeLog += `: ${formatTimeSpan(
      nodeInfo.averageTotalNs
    )} s, [Min: ${formatTimeSpan(nodeInfo.minTotalNs)} s, Max: ${formatTimeSpan(
      nodeInfo.maxTotalNs
    )} s]`;

    // Percent of parent
    if (nodeInfo.averagePercentOfParent !== undefined) {
      nodeLog += `, (${formatPercentOfParent(
        nodeInfo.averagePercentOfParent
      )} % of parent)`;
    }

    // Count
    nodeLog += `, Calls: ${formatCalls(
      nodeInfo.averageCount
    )}, [Min: ${formatCalls(nodeInfo.minCount)}, Max: ${formatCalls(
      nodeInfo.maxCount
    )}]`;

    // Other
    if (nodeInfo.averageOtherNs !== undefined) {
      nodeLog += `, Other: ${formatTimeSpan(
        nodeInfo.averageOtherNs
      )} s, [Min: ${formatTimeSpan(
        nodeInfo.minOtherNs || 0
      )} s, Max: ${formatTimeSpan(nodeInfo.maxOtherNs || 0)} s]`;
    }

    // Idle
    if (nodeInfo.averageIdleNs !== undefined) {
      nodeLog += `, Idle: ${formatTimeSpan(
        nodeInfo.averageIdleNs
      )} s, [Min: ${formatTimeSpan(
        nodeInfo.minIdleNs || 0
      )} s, Max: ${formatTimeSpan(nodeInfo.maxIdleNs || 0)} s]`;
    }
  }

  const subNodes = nodeInfo.subNodes
    .map(s => s)
    .sort((a, b) => a.startNs - b.startNs);

  const [isOpen, setState] = React.useState(shouldBeExpanded);
  const Icon = () => {
    if (isOpen) {
      return <CollapseIcon height={"16px"} onClick={() => setState(false)} />;
    } else {
      return <ExpandIcon height={"16px"} onClick={() => setState(true)} />;
    }
  };

  return (
    <div>
      <TopContainer>
        {subNodes.length > 0 && (
          <IconContainer>
            <Icon />
          </IconContainer>
        )}
        <P1 color="dark" weight={subNodes.length > 0 ? "bold" : "normal"}>
          {formatNodeName(nodeInfo.name)}
        </P1>
        <P1 color="secondary" weight="normal">
          {nodeLog}
        </P1>
      </TopContainer>
      <OpenableRelativeWithMarginContainer isOpen={isOpen}>
        {subNodes.length > 0 &&
          subNodes.map((subNode, index) => (
            <div key={`${subNode.name}-${index}`}>
              {getNodeInfoLogElement(subNode, true)}
            </div>
          ))}
      </OpenableRelativeWithMarginContainer>
    </div>
  );
}

function formatTimeSpan(nanoSeconds: number): string {
  const seconds = nanoSeconds / 1000000000;
  return `${Number.parseFloat(seconds.toFixed(3))}`;
}

function formatCalls(numberOfCalls: number): string {
  return `${Number.parseFloat(numberOfCalls.toFixed(2))}`;
}

function formatPercentOfParent(percentOfParent: number): string {
  return `${Number.parseFloat(percentOfParent.toFixed(2))}`;
}

function formatNodeName(name: string): string {
  return name.length > 50 ? name.substring(0, 50) + " ..." : name;
}
