import * as React from "react";
import styled from "styled-components";
import { StandardButton, S2 } from "@genesys/ui-elements";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { NewSystemState, NewPricingRow } from "../../types";
import { InputContainer } from "../../elements";
import { NewRowInput } from "./new-row-selection";

interface SystemSelectorProps {
  readonly sharedState: SharedState.State;
  readonly newSystemState: NewSystemState;
  readonly rowNo: number;
  readonly onChange: (newSystemState: NewSystemState) => void;
  readonly onAddRow: (row: NewPricingRow) => void;
  readonly onSystemSearch: () => void;
}

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export function SystemSelector({
  sharedState,
  newSystemState,
  onChange,
  onSystemSearch
}: SystemSelectorProps) {
  const onSearchTextChange = (searchText: string) => {
    onChange({ ...newSystemState, searchText: searchText });
  };

  return (
    <Root>
      <InnerContainer>
        <InputContainer>
          <S2 weight="normal" color="dark">
            {sharedState.translate(LanguageTexts.system())}
          </S2>
          <NewRowInput
            value={newSystemState.searchText}
            onChange={e => onSearchTextChange(e.target.value)}
          />
        </InputContainer>
        <StandardButton
          buttonType="PrimaryBlue"
          size="Small"
          onClick={() => onSystemSearch()}
        >
          {sharedState.translate(LanguageTexts.search())}
        </StandardButton>
      </InnerContainer>
    </Root>
  );
}
