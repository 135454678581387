import { exhaustiveCheck } from "ts-exhaustive-check";
import * as ProductData from "@genesys/shared/lib/product-data";
import * as ComponentEditor from "../../../modals/component-editor";
import * as OperatingCases from "../../../../operating-case-selector";
import * as Accessories from "../../../modals/accessories";
import * as Price from "../../../modals/price";
import * as Energy from "../../../modals/energy";
import * as PropertyGroups from "../../../modals/property-groups";
import * as SystemAccessories from "../../../modals/system-accessories";
import * as PlenumSize from "../../../modals/plenum-size";
import * as NotesAndDocuments from "../../../modals/notes-and-documents";
import * as MultipleSystemsEditor from "../../../modals/multiple-systems-editor";
import * as DataCenterEditor from "../../../modals/data-center-editor";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import { ConfiguratorAction } from "../../../shell-system-configurator/configurator-actions";
import { State, UpdateReturnType } from "../../types";

export function onSave(state: State): UpdateReturnType {
  const modal = state.modal;
  switch (modal.type) {
    case "ComponentEditor": {
      const configuratorActions = ComponentEditor.getConfiguratorActions(
        modal.state,
        modal.selectedComponentId,
        "Save"
      );
      return [{ ...state, modal: { type: "NoModal" } }, configuratorActions];
    }
    case "OperatingCases": {
      const configurationActions = OperatingCases.getConfigurationActions(
        modal.state,
        "save"
      );
      return [{ ...state, modal: { type: "NoModal" } }, configurationActions];
    }
    case "Price": {
      const systemPriceInformation = Price.getSystemPriceInformation(
        modal.state,
        state.system
      );
      return [
        { ...state, modal: { type: "NoModal" } },
        [
          ConfiguratorAction.setSystemPricingInformation(
            systemPriceInformation.systemId,
            systemPriceInformation.salesOrganisationId,
            systemPriceInformation.currencyCode,
            systemPriceInformation.masterCurrencyCode!,
            systemPriceInformation.masterOrganisationId!,
            false
          )
        ]
      ];
    }
    case "Accessories": {
      const properties = Accessories.getComponentProperties(modal.state);

      return [
        { ...state, modal: { type: "NoModal" } },
        [
          ConfiguratorAction.updateComponentAccessories([
            {
              parentComponentId: properties.parentComponentId,
              parentComponentProperties: properties.parentComponentProperties,
              accessoriesProperties: properties.accessoriesProperties
            }
          ])
        ]
      ];
    }
    case "SystemAccessories": {
      const configurationActions = SystemAccessories.getConfigurationActions(
        modal.state,
        state.system,
        "save"
      );
      return [{ ...state, modal: { type: "NoModal" } }, configurationActions];
    }
    case "PropertyGroup": {
      const configurationActions = PropertyGroups.getConfigurationActions(
        modal.state,
        "save"
      );
      return [{ ...state, modal: { type: "NoModal" } }, configurationActions];
    }
    case "Energy": {
      const energySettings = Energy.getEnergyInformation(modal.state);

      return [
        { ...state, modal: { type: "NoModal" } },
        [
          ConfiguratorAction.saveEnergy(
            energySettings.energySettings,
            energySettings.binSelections,
            energySettings.binOperatingCases
          )
        ]
      ];
    }
    case "PlenumSize": {
      const configurationActions = PlenumSize.getConfigurationActions(
        modal.state,
        state.system,
        "save"
      );
      return [{ ...state, modal: { type: "NoModal" } }, configurationActions];
    }
    case "NotesAndDocuments": {
      const configurationActions = NotesAndDocuments.getConfigurationActions(
        modal.state
      );

      return [{ ...state, modal: { type: "NoModal" } }, configurationActions];
    }
    case "MultipleSystemsEditor": {
      const sysComponent = state.system.components.find(component =>
        component.productId.endsWith("SYS")
      );
      const dhuComponent = state.system.components.find(component =>
        component.productId.endsWith("DHU")
      );

      const configurationActions = MultipleSystemsEditor.getConfiguratorActions(
        modal.state,
        sysComponent,
        dhuComponent,
        state.system.climateSettings,
        "Save"
      );
      return [{ ...state, modal: { type: "NoModal" } }, configurationActions];
    }
    case "DataCenterEditor": {
      const sysComponent = state.system.components.find(component =>
        component.productId.endsWith("SYS")
      );

      const configurationActions = DataCenterEditor.getConfiguratorActions(
        modal.state,
        sysComponent,
        "Save"
      );
      return [{ ...state, modal: { type: "NoModal" } }, configurationActions];
    }
    case "MCompare": {
      const system = state.system;

      if (modal.state.calculateMCompare) {
        const product = state.productData.products.find(
          obj => obj.id === system.file.systemTypeId + "MCS"
        )!;
        const sysComponent = system.components.find(c =>
          c.productId.endsWith("SYS")
        )!;

        const mcs = ProductData.filterProductForRange(
          product,
          sysComponent.properties
        );
        return [
          { ...state, modal: { type: "NoModal" } },
          [
            ConfiguratorAction.addOrUpdateMCompareComponent(
              state.system.file.systemTypeId + "MCS",
              ProductProperties.createDefaultProperties(
                mcs.properties.map(m => ({
                  name: m.name,
                  quantity: m.quantity,
                  values: m.items as any,
                  defaultValues: m.defaultValues
                })),
                true
              )
            )
          ]
        ];
      }

      return [
        { ...state, modal: { type: "NoModal" } },
        [ConfiguratorAction.deleteMCompareComponent(system.id)]
      ];
    }
    case "BuildInRevit":
    case "BuildInSc":
    case "MechanicalSketch":
    case "NoModal":
    case "Pid":
    case "Print":
    case "CondensationAnalysis":
    case "LockedSystemCalculation":
    case "PsychrometricChart": {
      return [state];
    }
    default: {
      return exhaustiveCheck(modal, true);
    }
  }
}

export function onSaveAndCalculate(state: State): UpdateReturnType {
  const modal = state.modal;
  if (modal.type === "ComponentEditor") {
    const configuratorActions = ComponentEditor.getConfiguratorActions(
      modal.state,
      modal.selectedComponentId,
      "SaveAndCalculate"
    );

    return [{ ...state, modal: { type: "NoModal" } }, configuratorActions];
  } else if (modal.type === "OperatingCases") {
    const configurationActions = OperatingCases.getConfigurationActions(
      modal.state,
      "saveAndCalculate"
    );
    return [{ ...state, modal: { type: "NoModal" } }, configurationActions];
  } else if (modal.type === "PropertyGroup") {
    const configurationActions = PropertyGroups.getConfigurationActions(
      modal.state,
      "saveAndCalculate"
    );
    return [{ ...state, modal: { type: "NoModal" } }, configurationActions];
  } else if (modal.type === "SystemAccessories") {
    const configurationActions = SystemAccessories.getConfigurationActions(
      modal.state,
      state.system,
      "saveAndCalculate"
    );
    return [{ ...state, modal: { type: "NoModal" } }, configurationActions];
  } else if (modal.type === "PlenumSize") {
    const configurationActions = PlenumSize.getConfigurationActions(
      modal.state,
      state.system,
      "saveAndCalculate"
    );
    return [{ ...state, modal: { type: "NoModal" } }, configurationActions];
  } else if (modal.type === "Accessories") {
    const properties = Accessories.getComponentProperties(modal.state);
    return [
      { ...state, modal: { type: "NoModal" } },
      [
        ConfiguratorAction.updateComponentAccessories(
          [
            {
              parentComponentId: properties.parentComponentId,
              parentComponentProperties: properties.parentComponentProperties,
              accessoriesProperties: properties.accessoriesProperties
            }
          ],
          true
        )
      ]
    ];
  } else if (modal.type === "Price") {
    const systemPriceInformation = Price.getSystemPriceInformation(
      modal.state,
      state.system
    );
    return [
      { ...state, modal: { type: "NoModal" } },
      [
        ConfiguratorAction.setSystemPricingInformation(
          systemPriceInformation.systemId,
          systemPriceInformation.salesOrganisationId,
          systemPriceInformation.currencyCode,
          systemPriceInformation.masterCurrencyCode!,
          systemPriceInformation.masterOrganisationId!,
          true
        )
      ]
    ];
  } else if (modal.type === "Energy") {
    const energySettings = Energy.getEnergyInformation(modal.state);
    return [
      { ...state, modal: { type: "NoModal" } },
      [
        ConfiguratorAction.saveEnergy(
          energySettings.energySettings,
          energySettings.binSelections,
          energySettings.binOperatingCases,
          true
        )
      ]
    ];
  } else if (modal.type === "MultipleSystemsEditor") {
    const sysComponent = state.system.components.find(component =>
      component.productId.endsWith("SYS")
    );
    const dhuComponent = state.system.components.find(component =>
      component.productId.endsWith("DHU")
    );

    const configurationActions = MultipleSystemsEditor.getConfiguratorActions(
      modal.state,
      sysComponent,
      dhuComponent,
      state.system.climateSettings,
      "SaveAndCalculate"
    );
    return [{ ...state, modal: { type: "NoModal" } }, configurationActions];
  } else if (modal.type === "DataCenterEditor") {
    const sysComponent = state.system.components.find(component =>
      component.productId.endsWith("SYS")
    );
    const configurationActions = DataCenterEditor.getConfiguratorActions(
      modal.state,
      sysComponent,
      "SaveAndCalculate"
    );
    return [{ ...state, modal: { type: "NoModal" } }, configurationActions];
  } else {
    return [state];
  }
}
