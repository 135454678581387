import React from "react";
import styled from "styled-components";

const StyledImg = styled.img`
  ${(props: { readonly isLoading: boolean }) =>
    props.isLoading ? "max-width: 200px; max-height: 200px;" : ""};
`;

// tslint:disable-next-line: no-require-imports no-var-requires
const spinner = require("ui-elements/assets/spinners/animated-spinner.svg");

export function Img({
  accessToken,
  src,
  ...imgProps
}: {
  readonly accessToken: string;
} & React.ImgHTMLAttributes<HTMLImageElement>): JSX.Element {
  const [imageSrc, setImage] = React.useState(spinner);
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      if (!src) {
        return;
      }

      try {
        const response = await fetch(src, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          signal: abortController.signal
        });

        const blob = await response.blob();
        setImage(URL.createObjectURL(blob));
        setIsLoading(false);
      } catch (e) {
        return;
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [src]);

  return <StyledImg {...imgProps} src={imageSrc} isLoading={isLoading} />;
}
