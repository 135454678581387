import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../../shared-state";
import * as SystemActions from "../../../../../system-actions";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as StatusIcon from "../../../../../status-icon-manager";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import styled from "styled-components";
import { Action, State } from "../../../../state";
import { Columns, SystemRow } from "../../../../types";
import { Dispatch } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  MenuContainer,
  RevisionsContainer,
  StatusContainer
} from "../../../../../shared-manager-components";
import { NameDiagramDisplayer } from "../../../name-and-diagram-displayer";
import {
  P2,
  FavoriteActive,
  FavoriteInactive,
  MenuDots,
  ArrowUp,
  ArrowDown
} from "@genesys/ui-elements";
import { labelFormatter, systemLabelFormatter, dateFormatter } from "./tools";

const StyledA = styled.a`
  text-decoration: none;
`;

const LabelsP2 = styled(P2)`
  width: 150px;
`;

const CenterP2 = styled(P2)`
  text-align: center;
`;

const LastChangedContainer = styled.div`
  display: flex;
  align-items: center;

  > span {
    font-size: 11.7px;
    font-style: italic;
  }
`;

export function getFileAndRevisionContent(
  state: State,
  cellName: Columns,
  sharedState: SharedState.State,
  dispatch: Dispatch<Action>,
  systemRow: SystemRow
) {
  switch (cellName) {
    case "g-no": {
      const prefix = sharedState.genesysPrefixNotation.genesysNo;
      if (systemRow.type === "file") {
        return (
          <P2 weight="normal" color="secondary">
            {prefix + systemRow.genesysNo}
          </P2>
        );
      }

      return (
        <CenterP2 weight="normal" color="secondary">
          {systemRow.revisionNo}
        </CenterP2>
      );
    }

    case "favorites": {
      if (systemRow.type === "revision") {
        return <></>;
      }
      const isFavorite = sharedState.user.favoritesSystems.find(fs =>
        fs.type === "loaded"
          ? fs.system.id === systemRow.latestSystem.id
          : fs.id === systemRow.latestSystem.id
      );
      return (
        <a
          onClick={() =>
            dispatch(Action.toggleFavoriteSystem(systemRow.latestSystem.id))
          }
        >
          {isFavorite ? (
            <FavoriteActive height="1.2rem" />
          ) : (
            <FavoriteInactive height="1.2rem" />
          )}
        </a>
      );
    }

    case "system-name": {
      const revisionNo =
        systemRow.type === "file"
          ? systemRow.latestSystem.revisionNo
          : systemRow.revisionNo;

      const name =
        systemRow.type === "file" ? systemRow.name : systemRow.comment;

      const effectiveSytemdId =
        systemRow.type === "file" ? systemRow.latestSystem.id : systemRow.id;

      return (
        <StyledA
          href={`/system/${sharedState.genesysPrefix.genesysNo(
            systemRow.genesysNo,
            revisionNo
          )}`}
        >
          <NameDiagramDisplayer
            effectiveSystemId={effectiveSytemdId}
            sharedState={sharedState}
            systemName={
              name || sharedState.translate(LanguageTexts.nocomment())
            }
          />
        </StyledA>
      );
    }

    case "system-type": {
      return (
        <P2 weight="normal" color="secondary">
          {systemLabelFormatter(
            systemRow.systemLabel || "",
            systemRow.systemTypeId,
            sharedState,
            systemRow.type === "file"
          )}
        </P2>
      );
    }

    case "actions-menu": {
      const buttonRef = React.createRef<HTMLAnchorElement>();

      const maybeToggleSystemActions = (id: string) => {
        if (!state.systemActionsState) {
          dispatch(Action.toggleOpenSystemActions(id));
        } else if (
          state.systemActionsState &&
          SystemActions.isInMenu(state.systemActionsState.state)
        ) {
          dispatch(Action.toggleOpenSystemActions(id));
        } else {
          return;
        }
      };

      return (
        <>
          <a
            onMouseEnter={() => maybeToggleSystemActions(systemRow.id)}
            onMouseLeave={() => maybeToggleSystemActions(systemRow.id)}
          >
            <span ref={buttonRef}>
              <MenuDots height="1.8rem" />
            </span>

            {systemRow.isActionMenuOpen && state.systemActionsState && (
              <MenuContainer
                menuIsOpen={systemRow.isActionMenuOpen}
                menuParentRef={buttonRef as any}
              >
                {systemRow.type === "file" ? (
                  <SystemActions.SystemActionsView
                    state={state.systemActionsState.state}
                    // sharedState={sharedState}
                    dispatch={Dispatch.map(
                      Action.dispatchSystemActions,
                      dispatch
                    )}
                    systemActions={SystemActions.createActions(
                      {
                        dispatch: Dispatch.map(
                          Action.dispatchSystemActions,
                          dispatch
                        ),
                        sharedState,
                        state: state.systemActionsState.state,
                        systemFile: {
                          id: systemRow.id,
                          name: systemRow.name,
                          labels: systemRow.labels,
                          genesysNo: systemRow.genesysNo,
                          owner: systemRow.owner.userName,
                          systems: systemRow.systems.map(s => {
                            return {
                              id: s.id,
                              revisionNo: s.revisionNo,
                              status: s.status,
                              statusDate: s.statusDate,
                              comment: s.comment || ""
                            };
                          })
                        }
                      },
                      [
                        SystemActions.openInNewTabAction,
                        ...(systemRow.isDeleted
                          ? [SystemActions.recoverSystemAction]
                          : [
                              SystemActions.renameAction,
                              SystemActions.assignLabelsAction,
                              SystemActions.deleteSystemAction,
                              SystemActions.createNewRevisionAction,
                              SystemActions.transferToUserAction,
                              SystemActions.copyTransferOpenAction
                            ]),
                        ...(Authorization.checkPermission(
                          sharedState.user.applicationClaims,
                          Authorization.genesysUserClaims.developer
                        ) && !systemRow.isDeleted
                          ? [SystemActions.exportSystemAction]
                          : [])
                      ]
                    )}
                  />
                ) : (
                  <SystemActions.SystemActionsView
                    state={state.systemActionsState.state}
                    dispatch={Dispatch.map(
                      Action.dispatchSystemActions,
                      dispatch
                    )}
                    systemActions={SystemActions.createActions(
                      {
                        dispatch: Dispatch.map(
                          Action.dispatchSystemActions,
                          dispatch
                        ),
                        sharedState,
                        revisionId: systemRow.id,
                        state: state.systemActionsState.state,
                        systemFile: {
                          id: systemRow.systemFileId,
                          name: "",
                          labels: [],
                          owner: "",
                          genesysNo: systemRow.genesysNo,
                          systems: systemRow.systems
                        }
                      },
                      [
                        SystemActions.openInNewTabAction,
                        ...(systemRow.isDeleted
                          ? [SystemActions.recoverSystemAction]
                          : [
                              SystemActions.editCommentAction,
                              SystemActions.copyTransferOpenAction
                            ]),
                        ...(Authorization.checkPermission(
                          sharedState.user.applicationClaims,
                          Authorization.genesysUserClaims.developer
                        ) && !systemRow.isDeleted
                          ? [SystemActions.exportSystemAction]
                          : [])
                      ]
                    )}
                  />
                )}
              </MenuContainer>
            )}
          </a>
        </>
      );
    }

    case "owner": {
      return (
        <P2 weight="normal" color="secondary">
          {systemRow.type === "file" ? systemRow.owner.userName : ""}
        </P2>
      );
    }

    case "date-changed": {
      const { type } = systemRow;
      const formattedDate = dateFormatter(
        type === "file"
          ? systemRow.latestSystem.statusDate
          : systemRow.statusDate,
        sharedState
      );
      const modifiedBy =
        type === "file"
          ? systemRow.latestSystem.lastModifiedBy.userName
          : systemRow.lastModifiedBy.userName;
      const modifiedByToLower = modifiedBy.toLowerCase();

      const isDeveloper = AuthorizationTools.checkPermission(
        sharedState.user.applicationClaims,
        AuthorizationTools.genesysUserClaims.developer
      );

      return (
        <LastChangedContainer>
          <P2 weight="normal" color="secondary">
            {formattedDate}
          </P2>
          {isDeveloper && <span>({modifiedByToLower})</span>}
        </LastChangedContainer>
      );
    }

    case "status": {
      const status =
        systemRow.type === "file"
          ? systemRow.latestSystem.status
          : systemRow.status;
      return (
        <StatusContainer>
          <StatusIcon.StatusIconManager
            sharedState={sharedState}
            statusNumber={status}
          />
        </StatusContainer>
      );
    }

    case "labels": {
      return (
        <LabelsP2 weight="normal" color="secondary">
          {labelFormatter(systemRow)}
        </LabelsP2>
      );
    }

    case "revisions": {
      if (systemRow.type !== "file") {
        return null;
      }

      return (
        <RevisionsContainer>
          <P2 weight="normal" color="secondary">
            {systemRow.latestSystem.revisionNo}
          </P2>
          <span onClick={() => dispatch(Action.toggleRevision(systemRow.id))}>
            {systemRow.isRevisionExpanded ? (
              <ArrowUp height="0.4rem" />
            ) : (
              <ArrowDown height="0.4rem" />
            )}
          </span>
        </RevisionsContainer>
      );
    }

    default:
      exhaustiveCheck(cellName);
  }
}
