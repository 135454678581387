import * as React from "react";
import * as SharedState from "../shared-state";
import * as AirMixing from "./air-mixing/view";
import * as AirPower from "./air-power/view";
import * as HumidityConversion from "./humidity-conversion/view";
import * as PsychrometricChart from "./psychrometric-chart/view";
import * as BinPageTool from "./weather-data-binning";
import styled from "styled-components";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";

const Error = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  text-align: center;
  color: #66a;
`;

const ErrorContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 620px;
`;

export function ToolsView({
  state,
  sharedState,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  switch (state.type) {
    case "air-mixing": {
      return (
        <AirMixing.AirMixingToolView
          state={state.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchAirMixing, dispatch)}
        />
      );
    }
    case "air-power": {
      return (
        <AirPower.AirPowerToolView
          state={state.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchAirPower, dispatch)}
        />
      );
    }
    case "humidity-conversion": {
      return (
        <HumidityConversion.HumidityConversionToolView
          state={state.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchHumidityConversion, dispatch)}
        />
      );
    }
    case "psychrometric-chart": {
      return (
        <PsychrometricChart.PsychrometricChartToolView
          state={state.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchPsychrometricChart, dispatch)}
        />
      );
    }
    case "weather-data-binning": {
      return (
        <BinPageTool.View
          sharedState={sharedState}
          dispatch={Dispatch.map(Action.dispatchBinPage, dispatch)}
          state={state.state}
        />
      );
    }

    default:
      return (
        <ErrorContainer>
          <Error>The Tools page you are looking for could not be found</Error>
        </ErrorContainer>
      );
  }
}
