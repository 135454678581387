import * as React from "react";
import * as GraphQLTypes from "../../../graphql-types";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SystemSuggestion from "./system-flow-suggestion";
import * as DetailedResultsAndCharts from "./detailed-results-and-charts";
import * as AnnualSummary from "./annual-summary";
import * as DevView from "./dev-view";
import * as CustomDescriptionBox from "../../components/custom-description-box";
import styled from "styled-components";
import {
  Spinner,
  zIndex,
  ExpandIcon,
  H3,
  HelpGrayIcon,
  CollapseIcon
} from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "./state";
import { CalculationInput } from "./types";
import { PropertyValueSet } from "@genesys/property";
import { Unit, Quantity } from "@genesys/uom";
import { hasPermissionForDevView } from "../../tools";

const Container = styled.div``;

const Error = styled.p`
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  text-align: center;
  color: #66a;
`;

const LoadersContainer = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndex.FullOverlay};
`;

const SectionContainer = styled.div<{
  readonly border: boolean;
}>`
  height: 75px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${props => props.border && "border-bottom:1px solid #dde4f0"};
  align-items: center;
`;

const SectionContainerExpanded = styled.div`
  display: flex;
  min-height: 100px;
  width: 100%;
  border-bottom: 1px solid #dde4f0;
  overflow: auto;
`;

const StyledPropertyValueDesc = styled.span`
  color: #959695;
  font-family: Futura;
  font-size: 13px;
  font-weight: 500;
`;

const FlexContainer = styled.div`
  display: flex;

  > span {
    margin-right: 10px;
    cursor: pointer;
  }
`;

const DevViewContainer = styled.div`
  overflow: auto;
  padding: 10px;
  height: 35vh;
  width: 90%;
`;

const SectionsContainer = styled.div``;
const SectionContent = styled.div`
  width: 100%;
`;

export function View(props: {
  readonly moistureLoadClaimValue: number;
  readonly dispatch: Dispatch<Action>;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly disableOpcButtons: boolean;
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
  readonly moistureLoadItem: NonNullable<
    GraphQLTypes.MoistureLoadCalculationUserQuery["user"]["moistureLoadByMoistureLoadNo"]
  >;
  readonly calculationInput: CalculationInput;
  readonly isCalculating: boolean;
  readonly calcResult: GraphQLTypes.SaveMoistureLoadInputsAndCalculate["saveMoistureLoadInputsAndCalculate"];
}) {
  const { state, isCalculating, ...rest } = props;

  if (!props.calcResult) {
    if (!isCalculating) {
      return null;
    }
    return (
      <LoadersContainer>
        <Spinner />
      </LoadersContainer>
    );
  }

  const moistureLoadCalculationResult = props.calcResult;

  if (!moistureLoadCalculationResult) {
    return (
      <Error>No response received, Please Check inputs and try again </Error>
    );
  }

  if (
    moistureLoadCalculationResult.success &&
    !moistureLoadCalculationResult.result
  ) {
    return <Error>Succesful calculation but no result received</Error>;
  }

  if (
    !moistureLoadCalculationResult.success ||
    !moistureLoadCalculationResult.result
  ) {
    if (props.moistureLoadClaimValue === 10) {
      return (
        <>
          <Error>Detailed error: Level 10 only</Error>
          <p />
          <p>
            {moistureLoadCalculationResult.moistureLoadSettingsErrors.map(
              mls => {
                return (
                  <>
                    <p>Case: {mls.resultGroup} </p>
                    <p>Settings: {mls.moistureLoadSettings.toString()}</p>
                    {mls.errors.map(e => {
                      return <p key={e}>{e} </p>;
                    })}
                  </>
                );
              }
            )}
          </p>
        </>
      );
    }
    return <Error>Error</Error>;
  }

  return (
    <Container>
      <SucessContent
        moistureLoadCalculationResult={moistureLoadCalculationResult}
        state={state}
        {...rest}
      />
    </Container>
  );
}

interface SuccessProps {
  readonly moistureLoadClaimValue: number;
  readonly calculationInput: CalculationInput;
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
  readonly moistureLoadItem: NonNullable<
    GraphQLTypes.MoistureLoadCalculationUserQuery["user"]["moistureLoadByMoistureLoadNo"]
  >;
  readonly state: State;
  readonly disableOpcButtons: boolean;
  readonly sharedState: SharedState.State;
  readonly moistureLoadCalculationResult: NonNullable<
    GraphQLTypes.MoistureLoadCalculationResult["user"]["moistureLoadCalculation"]
  >;
  readonly calcResult: GraphQLTypes.SaveMoistureLoadInputsAndCalculate["saveMoistureLoadInputsAndCalculate"];
  readonly dispatch: Dispatch<Action>;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
}

function SucessContent(props: SuccessProps) {
  const result = props.moistureLoadCalculationResult.result!;
  const showDetailedResults =
    props.moistureLoadClaimValue >= 1 && result.diagramResults.length;

  const showSystemFlowSuggestions =
    props.moistureLoadClaimValue >= 1 && result.maxLoadResults.length > 0;

  const showAnnualSummary =
    props.moistureLoadClaimValue >= 1 &&
    (result.monthlyResults.length > 0 || result.summaryResults.length > 0);

  const canShowDevView =
    hasPermissionForDevView(props.sharedState.user.applicationClaims) &&
    result.allBinResults.length > 0;

  return (
    <>
      <SectionsContainer>
        {showSystemFlowSuggestions && <SystemSuggestionSection {...props} />}

        {showDetailedResults && <DetailedResultsSection {...props} />}

        {showAnnualSummary && <AnnualSummarySection {...props} />}

        {canShowDevView && <DevViewSection {...props} />}
      </SectionsContainer>
    </>
  );
}

function AnnualSummarySection(props: SuccessProps) {
  const result = props.moistureLoadCalculationResult.result!;
  return (
    <Section
      dispatch={props.dispatch}
      sectionId="mlcannualsummary"
      state={props.state}
      title={props.sharedState.translate(
        LanguageTexts.globalPropertyName("mlcannualsummary")
      )}
    >
      <AnnualSummary.View
        onFormatChanged={props.onFormatChanged}
        onFormatCleared={props.onFormatCleared}
        sharedState={props.sharedState}
        summaryResults={result.summaryResults}
        monthlyResults={result.monthlyResults}
        resultsToShow={result.summaryAndMonthlyResultsToShow}
      />
    </Section>
  );
}

function DevViewSection(props: SuccessProps) {
  const result = props.moistureLoadCalculationResult.result!;
  return (
    <Section
      dispatch={props.dispatch}
      sectionId="mlcrawresult"
      state={props.state}
      title={props.sharedState.translate(
        LanguageTexts.globalPropertyName("mlcrawresult")
      )}
    >
      <DevViewContainer>
        <DevView.View
          onFormatChanged={props.onFormatChanged}
          onFormatCleared={props.onFormatCleared}
          sharedState={props.sharedState}
          results={result.allBinResults.map(PropertyValueSet.fromString)}
          moistureLoadItem={props.moistureLoadItem}
        />
      </DevViewContainer>
    </Section>
  );
}

function DetailedResultsSection(props: SuccessProps) {
  const result = props.moistureLoadCalculationResult.result!;
  return (
    <Section
      dispatch={props.dispatch}
      sectionId="mlcdetailedresultscharts"
      state={props.state}
      title={props.sharedState.translate(
        LanguageTexts.globalPropertyName("mlcdetailedresultscharts")
      )}
    >
      <SectionContent>
        <DetailedResultsAndCharts.View
          resultsToShow={result.diagramResultsToShow}
          moistureLoadItem={props.moistureLoadItem}
          onFormatChanged={props.onFormatChanged}
          onFormatCleared={props.onFormatCleared}
          state={props.state}
          sharedState={props.sharedState}
          dispatch={props.dispatch}
          diagramResults={result.diagramResults}
        />
      </SectionContent>
    </Section>
  );
}

function SystemSuggestionSection(props: SuccessProps) {
  const result = props.moistureLoadCalculationResult.result!;
  return (
    <Section
      dispatch={props.dispatch}
      sectionId="mlcsystemsuggestions"
      state={props.state}
      additionalIcon={
        <CustomDescriptionBox.View
          icon={<HelpGrayIcon height="18px" />}
          subtitle="System flows"
          title={"Help"}
        >
          <StyledPropertyValueDesc>
            {`
      The flows presented below represents possible alternatives to meet the specified demands.
      Black figures means all demands are met.
      Red figures means demand(s) are not met for one or more demands.
      Basically you should select lowest possible air flow due to cost reasons.
      `}
          </StyledPropertyValueDesc>
        </CustomDescriptionBox.View>
      }
      title={props.sharedState.translate(
        LanguageTexts.globalPropertyName("mlcsystemsuggestions")
      )}
    >
      <SystemSuggestion.View
        moistureLoadItem={props.moistureLoadItem}
        disableOpcButtons={props.disableOpcButtons}
        dispatch={props.dispatch}
        state={props.state}
        onFormatChanged={props.onFormatChanged}
        onFormatCleared={props.onFormatCleared}
        sharedState={props.sharedState}
        maxResults={result.maxLoadResults}
      />
    </Section>
  );
}

function Section({
  title,
  children,
  additionalIcon,
  sectionId,
  state,
  dispatch
}: {
  readonly title: string;
  readonly sectionId: string;
  readonly additionalIcon?: JSX.Element;
  readonly children: React.ReactNode;
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
}) {
  const isOpen = state.sectionState[sectionId]?.isOpen;

  const toggle = () => {
    dispatch(
      Action.updateSectionState({
        ...state.sectionState,
        [sectionId]: {
          isOpen: !isOpen
        }
      })
    );
  };

  const Icon = () => {
    if (isOpen) {
      return <CollapseIcon onClick={toggle} height={"33px"} />;
    }

    return <ExpandIcon onClick={toggle} height={"33px"} />;
  };

  const header = (
    <SectionContainer border={!isOpen}>
      <FlexContainer>
        <span onClick={toggle}>
          <H3>{title}</H3>
        </span>
        {isOpen && additionalIcon}
      </FlexContainer>
      <Icon />
    </SectionContainer>
  );

  if (!isOpen) {
    return header;
  }
  return (
    <>
      {header}
      <SectionContainerExpanded>{children}</SectionContainerExpanded>
    </>
  );
}
