import {
  OverlayLoader,
  S2,
  S1,
  P1,
  EditGrey,
  PrimaryColors,
  TextArea
} from "@genesys/ui-elements";
import * as React from "react";
import { State, Action } from "./state";
import * as SharedState from "../shared-state";
import styled from "styled-components";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SystemStatusEnum from "@genesys/shared/lib/enums/system-status";
import { Dispatch } from "@typescript-tea/core";
import * as Authorization from "@genesys/shared/lib/authorization";

const Root = styled.div`
  padding: 10px;
`;

const HeaderContainer = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #dde4f0;
`;

const NotesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dde4f0;

  div:first-of-type {
    width: 90%;
    padding-bottom: 16px;
    h5 {
      padding: 16px 0 8px 0;
    }
  }

  div:last-of-type {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      height: 24px;
      width: 24px;
      border-radius: 12px;
      cursor: pointer;
      &:hover {
        background-color: ${PrimaryColors.lightBlue};
      }
    }
  }
`;

export function NotesView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  if (!state) {
    return <OverlayLoader />;
  }

  const isSystemLocked =
    state.systemStatus >= SystemStatusEnum.SystemStatus.LockSuccess;

  return (
    <Root>
      <HeaderContainer>
        <S1 weight="bold" color="dark">
          {sharedState.translate(LanguageTexts.notes())}
        </S1>
      </HeaderContainer>

      {Authorization.checkPermission(
        sharedState.user.applicationClaims,
        Authorization.genesysUserClaims.canUseInternalFactoryNotes
      ) && (
        <NotesContainer>
          <div>
            <S2 weight="bold" color="dark">
              {sharedState.translate(LanguageTexts.internalNotes())}
            </S2>

            {isSystemLocked || state.internalNotes.isReadonly ? (
              <P1 weight="normal" color="secondary">
                {state.internalNotes.text}
              </P1>
            ) : (
              <TextArea
                value={state.internalNotes.text || ""}
                onChange={e =>
                  dispatch(
                    Action.onNoteTextChange(
                      state.internalNotes.noteType,
                      e.target.value
                    )
                  )
                }
                cols={68}
                rows={10}
              ></TextArea>
            )}
          </div>
          <div>
            <a
              onClick={() =>
                dispatch(
                  Action.toggleNoteIsReadonly(state.internalNotes.noteType)
                )
              }
            >
              <EditGrey />
            </a>
          </div>
        </NotesContainer>
      )}

      <NotesContainer>
        <div>
          <S2 weight="bold" color="dark">
            {sharedState.translate(LanguageTexts.externalNotes())}
          </S2>

          {isSystemLocked || state.externalNotes.isReadonly ? (
            <P1 weight="normal" color="secondary">
              {state.externalNotes.text}
            </P1>
          ) : (
            <TextArea
              value={state.externalNotes.text || ""}
              onChange={e =>
                dispatch(
                  Action.onNoteTextChange(
                    state.externalNotes.noteType,
                    e.target.value
                  )
                )
              }
              cols={68}
              rows={10}
            ></TextArea>
          )}
        </div>
        <div>
          <a
            onClick={() =>
              dispatch(
                Action.toggleNoteIsReadonly(state.externalNotes.noteType)
              )
            }
          >
            <EditGrey />
          </a>
        </div>
      </NotesContainer>
    </Root>
  );
}
