import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { HelpIcon, WithMenu } from "@genesys/ui-elements";
import { Action } from "../state";
import { clientConfig } from "../../config";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../shared-state";

export function HelpMenu({
  sharedState,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  return (
    <WithMenu
      icon={<HelpIcon width="20px" />}
      values={[
        {
          value: sharedState.translate(LanguageTexts.wiki()),
          onClick: () => window.open("https://wiki.genesys.munters.com/")
        },
        {
          value: sharedState.translate(LanguageTexts.changeLog()),
          onClick: () => {
            window.open(
              `${clientConfig.wikiJsBaseUrl.replace("/graphql", "/Changelogs")}`
            );
          }
        },
        {
          value: sharedState.translate(LanguageTexts.about()),
          onClick: e => {
            if (e.ctrlKey) {
              return window.open("/about");
            }
            return dispatch(Action.navigate("/about"));
          }
        }
      ]}
    />
  );
}
