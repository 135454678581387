import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as OperationTime from "@genesys/client-core/lib/operation-time-dialog";
import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import * as CaseTypeEnum from "@genesys/shared/lib/enums/case-type";
import * as ValueSources from "@genesys/shared/lib/value-sources";
import { Amount, Quantity, Units } from "@genesys/uom";
import { Physics } from "@munters/calculations";
import * as PropertiesSelector from "../../../../../properties-selector";
import * as PsychrometricChart from "../../../../../psychrometric-chart";
import * as GraphQlTypes from "../../../../../graphql-types";
import * as SharedState from "../../../../../shared-state";
import * as Product from "../../../../product";
import * as System from "../../../../system";
import {
  BinSize,
  BinType,
  ChartType,
  ClimateCoolingDataType,
  TemperatureCompensation,
  AllDataTypes
} from "../../types";
import * as BinGeneration from "../state";
import {
  getTargetHumidityPropertyName,
  getTargetTemperaturePropertyName,
  getReturnAirTemperaturePropertyName,
  getReturnAirHumidityPropertyName,
  getOperatingCaseSettings,
  getOutdoorAirHumidityPropertyName,
  getOutdoorAirTemperaturePropertyName,
  binCaseToPvs
} from "./functions";
import { exhaustiveCheck } from "ts-exhaustive-check";

export function initializeDefaultValuesSelectorState(
  operatingCaseSelectorStates:
    | ReadonlyArray<PropertiesSelector.State>
    | undefined
) {
  const defaultValuesSelectorState = operatingCaseSelectorStates
    ? operatingCaseSelectorStates[0]
    : undefined;

  return defaultValuesSelectorState;
}

export function initializeOperatingCasePropertySelectors(
  binCases: ReadonlyArray<SharedEnergyTools.BinCase> | undefined,
  system: System.System,
  energyProduct: Product.Product | undefined
): ReadonlyArray<PropertiesSelector.State> | undefined {
  if (!binCases) {
    return undefined;
  }
  const opeProductPvs = getOperatingCaseSettings(system);
  const binCasesPvs = binCases.map(b => binCaseToPvs(b));

  const outdoorTemperaturePropertyName =
    getOutdoorAirTemperaturePropertyName(opeProductPvs);
  const outdoorHumidityPropertyName =
    getOutdoorAirHumidityPropertyName(opeProductPvs);
  const returnAirTemperaturePropertyName =
    getReturnAirTemperaturePropertyName(opeProductPvs);
  const returnAirHumidityPropertyName =
    getReturnAirHumidityPropertyName(opeProductPvs);
  const targetHumidityPropertyName =
    getTargetHumidityPropertyName(opeProductPvs);
  const targetTemperaturePropertyName =
    getTargetTemperaturePropertyName(opeProductPvs);

  const operatingCases = binCasesPvs.map(b =>
    updateOperatingCasesPropertiesFromBinCase(
      b,
      opeProductPvs,
      outdoorTemperaturePropertyName,
      outdoorHumidityPropertyName,
      returnAirTemperaturePropertyName,
      returnAirHumidityPropertyName,
      targetHumidityPropertyName,
      targetTemperaturePropertyName
    )
  );

  if (!energyProduct) {
    return operatingCases.map(o => PropertiesSelector.init(o));
  }

  const operatingCasesFixedValueSources = operatingCases.map(o => {
    const valueSourceMap = ValueSources.createValueSourcesDict(
      energyProduct.properties,
      o
    );
    const selectedPropertiesCheckedForValueSources =
      ValueSources.fixValueSources(energyProduct.properties, valueSourceMap, o);

    return selectedPropertiesCheckedForValueSources;
  });

  return operatingCasesFixedValueSources.map(o => PropertiesSelector.init(o));
}

export function getInitialBinType(
  binSelection: PropertyValueSet.PropertyValueSet,
  system: System.System
): BinType {
  const climateSettings = system.climateSettings;
  return (
    getBinTypeFromSystemSelection(binSelection) ||
    useMLBinTypeIfMl(system) ||
    getBinTypeFromClimateSettings(climateSettings)
  );
}

function getBinTypeFromSystemSelection(
  binSelection: PropertyValueSet.PropertyValueSet
) {
  return PropertyValueSet.getText(
    KnownProperties.binType,
    binSelection
  ) as BinType;
}

function useMLBinTypeIfMl(system: System.System): BinType | undefined {
  if (system.moistureLoadInfo) {
    return "MoistureLoad";
  }
  return undefined;
}

function getBinTypeFromClimateSettings(
  climateSettings: PropertyValueSet.PropertyValueSet
) {
  return (
    PropertyValueSet.getText("locationname", climateSettings) === "Manual"
      ? "Custom"
      : "Generated"
  ) as BinType;
}

export function getInitialBinSize(
  binSelection: PropertyValueSet.PropertyValueSet,
  userEnergySettings: { readonly [key: string]: string }
): BinSize {
  return (
    (PropertyValueSet.getInteger(
      KnownProperties.binSize,
      binSelection
    ) as BinSize) ||
    (userEnergySettings[KnownProperties.binSize] &&
      // tslint:disable-next-line:radix
      (parseInt(userEnergySettings[KnownProperties.binSize]) as number)) ||
    2
  );
}

export function getUseEnglishUnits(
  binSelection: PropertyValueSet.PropertyValueSet,
  userEnergySettings: { readonly [key: string]: string },
  englishUnits: boolean
): number {
  return (
    PropertyValueSet.getInteger(
      KnownProperties.binUseEnglishUnits,
      binSelection
    ) ||
    (userEnergySettings[KnownProperties.binUseEnglishUnits] &&
      // tslint:disable-next-line:radix
      (parseInt(
        userEnergySettings[KnownProperties.binUseEnglishUnits]
      ) as number)) ||
    (englishUnits ? 1 : 0) ||
    0
  );
}

export function getInitialOperationTime(
  binSelection: PropertyValueSet.PropertyValueSet
): OperationTime.OperationTime {
  const parse = (time: string) =>
    PropertyValueSet.getText(time, binSelection)!
      .split("")
      .reduce(
        (a, b, ix) => ({ ...a, [ix + 1]: b === "1" }),
        {} as OperationTime.Checks
      );

  if (!PropertyValueSet.isEmpty(binSelection)) {
    const settings = PropertyValueSet.getText(
      KnownProperties.binOperationTime,
      binSelection
    );
    if (settings && settings !== "custom") {
      return getOperationTimePreset(settings);
    }
    return {
      months: parse(KnownProperties.binMonths),
      monday: parse(KnownProperties.binMonday),
      tuesday: parse(KnownProperties.binTuesday),
      wednesday: parse(KnownProperties.binWednesday),
      thursday: parse(KnownProperties.binThursday),
      friday: parse(KnownProperties.binFriday),
      saturday: parse(KnownProperties.binSaturday),
      sunday: parse(KnownProperties.binSunday)
    };
  }

  return OperationTime.presets.allDay;
}

export function getInitialBinSelections(
  selectedBinSize: BinSize,
  selectedOperationTime: OperationTime.OperationTime,
  binType: BinType,
  selectedDataType: ClimateCoolingDataType,
  si: boolean,
  selectedRoofTemperatureCompensation:
    | SharedEnergyTools.RoofTemperatureCompensation
    | undefined,
  hourlyDataVersion: string,
  climatePressure: PropertyValue.PropertyValue,
  binLocation?: GraphQlTypes.EnergyInputQueryProduct["product"]["binDataLocations"][0]
) {
  const roofTemperatureCompensationSelection =
    getRoofTemperatureCompensationSelection(
      selectedRoofTemperatureCompensation
    );

  const propertiesToSet = [
    KnownProperties.binSize,
    KnownProperties.binUseEnglishUnits,
    KnownProperties.binDataLocationId,
    KnownProperties.binLocation,
    KnownProperties.binOperationTime,
    KnownProperties.climateCoolingDataType,
    KnownProperties.binType,
    KnownProperties.binDataHourlyVersion,
    KnownProperties.binInputCustomPressure
  ] as const;

  type Property = typeof propertiesToSet[number];

  const setValue = (
    property: Property,
    pvs: PropertyValueSet.PropertyValueSet
  ) => {
    switch (property) {
      case KnownProperties.binSize:
        return PropertyValueSet.setInteger(
          KnownProperties.binSize,
          selectedBinSize,
          pvs
        );

      case KnownProperties.binUseEnglishUnits:
        return PropertyValueSet.setInteger(
          KnownProperties.binUseEnglishUnits,
          si ? 0 : 1,
          pvs
        );

      case KnownProperties.binDataLocationId:
        return PropertyValueSet.setText(
          KnownProperties.binDataLocationId,
          binLocation?.binDataLocationId || "", // When the location ID does not exist in the new webservice
          pvs
        );

      case KnownProperties.binLocation:
        return PropertyValueSet.setText(
          KnownProperties.binLocation,
          binLocation?.locationName || "", // When the location ID does not exist in the new webservice
          pvs
        );

      case KnownProperties.binOperationTime:
        return PropertyValueSet.setText(
          KnownProperties.binOperationTime,
          OperationTime.presetName(selectedOperationTime),
          pvs
        );

      case KnownProperties.climateCoolingDataType:
        return PropertyValueSet.setText(
          KnownProperties.climateCoolingDataType,
          selectedDataType,
          pvs
        );

      case KnownProperties.binType:
        return PropertyValueSet.setText(KnownProperties.binType, binType, pvs);

      case KnownProperties.binDataHourlyVersion:
        return PropertyValueSet.setText(
          KnownProperties.binDataHourlyVersion,
          hourlyDataVersion,
          pvs
        );

      case KnownProperties.binInputCustomPressure:
        return PropertyValueSet.setAmount(
          KnownProperties.binInputCustomPressure,
          PropertyValue.getAmount(climatePressure)!,
          pvs
        );

      default:
        exhaustiveCheck(property);
    }
  };

  let pvs = roofTemperatureCompensationSelection;

  for (const property of propertiesToSet) {
    pvs = setValue(property, pvs);
  }

  return PropertyValueSet.merge(
    pvs,
    OperationTime.toPropertyValueSet(selectedOperationTime)
  );
}

export function getInitialClimateCoolingDataType(
  binSelection: PropertyValueSet.PropertyValueSet,
  climateSettings: PropertyValueSet.PropertyValueSet
): ClimateCoolingDataType {
  return (
    (PropertyValueSet.getText(
      KnownProperties.climateCoolingDataType,
      binSelection
    ) as ClimateCoolingDataType) ||
    parseClimateCoolingDataType(
      PropertyValueSet.getText(
        KnownProperties.climateCoolingDataType,
        climateSettings
      ) as AllDataTypes
    )!
  );
}

function parseClimateCoolingDataType(
  coolingType: AllDataTypes
): ClimateCoolingDataType {
  switch (coolingType) {
    case "DB/MCWB":
    case "DP/MCDB":
    case "WB/MCDB":
    case "h/MCDB": {
      return coolingType;
    }

    case "DB/MCWB-MONTHLY-PEAK":
    case "N-YEAR-MAX-DB/EXTREME-MAX-WB":
    case "N-YEAR-MAX-DB/WB": {
      return "DB/MCWB";
    }

    case "WB/MCDB-MONTHLY-PEAK": {
      return "WB/MCDB";
    }

    default: {
      exhaustiveCheck(coolingType);
    }
  }
}

export function getRoofTemperatureCompensationForEPC(
  binSelection: PropertyValueSet.PropertyValueSet
): SharedEnergyTools.RoofTemperatureCompensation {
  const compensation = PropertyValueSet.getInteger(
    KnownProperties.roofTempCompensation,
    binSelection
  ) as TemperatureCompensation | undefined;
  const temperature = PropertyValueSet.getAmount<Quantity.Temperature>(
    KnownProperties.maxRoofTempCompensation,
    binSelection
  );

  if (compensation === undefined || temperature === undefined) {
    return {
      compensation: 0,
      temperature: Amount.create(0, Units.Celsius)
    };
  }

  return {
    compensation: compensation,
    temperature: temperature
  };
}

export function getInitialOperatingCaseSelectors(
  binCases: ReadonlyArray<SharedEnergyTools.BinCase> | undefined,
  system: System.System,
  energyProduct: Product.Product | undefined
) {
  const binOperatingCases = system.operatingCases
    .filter(oc => oc.caseType === CaseTypeEnum.CaseType.Bin)
    .map(opc => PropertyValueSet.fromString(opc.settings ?? ""));

  if (binOperatingCases.length) {
    return binOperatingCases.map(opc => PropertiesSelector.init(opc));
  } else {
    return binCases
      ? initializeOperatingCasePropertySelectors(
          binCases,
          system,
          energyProduct
        )
      : [];
  }
}

export function getInitialBinsCasesFromSystem(
  system: System.System
): ReadonlyArray<SharedEnergyTools.BinCase> | undefined {
  const binOperatingCases = system.operatingCases.filter(
    oc => oc.caseType === CaseTypeEnum.CaseType.Bin
  );
  if (binOperatingCases.length === 0) {
    return undefined;
  }

  return binOperatingCases.map(opc => {
    const binDataPvs = PropertyValueSet.fromString(opc.binData || "");

    const standardValues = {
      binId: PropertyValueSet.getInteger(KnownProperties.id, binDataPvs)!,
      averageTemperature: PropertyValueSet.getAmount<Quantity.Temperature>(
        KnownProperties.airTemperature,
        binDataPvs
      )!,
      averageHumidity: PropertyValueSet.getAmount<Quantity.HumidityRatio>(
        KnownProperties.airHumidity,
        binDataPvs
      )!,
      windSpeed: PropertyValueSet.getAmount<Quantity.Velocity>(
        KnownProperties.wind,
        binDataPvs
      )!,
      binPressure: PropertyValueSet.getAmount<Quantity.Pressure>(
        KnownProperties.binPressure,
        binDataPvs
      )!,
      binTime: PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.time,
        binDataPvs
      )!
    };

    const midPointValues = {
      midPointTemp: PropertyValueSet.getAmount<Quantity.Temperature>(
        KnownProperties.airMidPointTemperature,
        binDataPvs
      ),
      midPointWetTemp: PropertyValueSet.getAmount<Quantity.WetTemperature>(
        KnownProperties.airMidPointWetTemperature,
        binDataPvs
      ),
      midPointDewPointTemp:
        PropertyValueSet.getAmount<Quantity.DewPointTemperature>(
          KnownProperties.airMidPointDewPointTemperature,
          binDataPvs
        ),
      midPointSpecificEnthalpy:
        PropertyValueSet.getAmount<Quantity.SpecificEnthalpy>(
          KnownProperties.airMidPointSpecificEnthalpy,
          binDataPvs
        ),
      midPointHumidityRatio:
        PropertyValueSet.getAmount<Quantity.SpecificEnthalpy>(
          KnownProperties.airMidPointHumidityRatio,
          binDataPvs
        )
    };

    const moistureLoadValues = {
      returnAirHumidity: PropertyValueSet.getAmount<Quantity.HumidityRatio>(
        KnownProperties.returnAirHumidity,
        binDataPvs
      ),
      returnAirTemperature: PropertyValueSet.getAmount<Quantity.Temperature>(
        KnownProperties.returnAirTemperature,
        binDataPvs
      ),
      totalMoistureLoad: PropertyValueSet.getAmount<Quantity.MassFlow>(
        KnownProperties.totalMoistureLoad,
        binDataPvs
      ),
      totalHeatLoad: PropertyValueSet.getAmount<Quantity.Power>(
        KnownProperties.totalHeatLoad,
        binDataPvs
      ),
      targetHumidity: PropertyValueSet.getAmount<Quantity.HumidityRatio>(
        KnownProperties.supplyTargetHumidity,
        binDataPvs
      ),
      targetTemperature: PropertyValueSet.getAmount<Quantity.Temperature>(
        KnownProperties.supplyTargetTemperature,
        binDataPvs
      )
    };

    const months = {
      binTimeJanuary: PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.binTimeJanuary,
        binDataPvs
      ),
      binTimeFebruary: PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.binTimeFebruary,
        binDataPvs
      ),
      binTimeMarch: PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.binTimeMarch,
        binDataPvs
      ),
      binTimeApril: PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.binTimeApril,
        binDataPvs
      ),
      binTimeMay: PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.binTimeMay,
        binDataPvs
      ),
      binTimeJune: PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.binTimeJune,
        binDataPvs
      ),
      binTimeJuly: PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.binTimeJuly,
        binDataPvs
      ),
      binTimeAugust: PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.binTimeAugust,
        binDataPvs
      ),
      binTimeSeptember: PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.binTimeSeptember,
        binDataPvs
      ),
      binTimeOctober: PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.binTimeOctober,
        binDataPvs
      ),
      binTimeNovember: PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.binTimeNovember,
        binDataPvs
      ),
      binTimeDecember: PropertyValueSet.getAmount<Quantity.Duration>(
        KnownProperties.binTimeDecember,
        binDataPvs
      )
    };

    let bincases = {
      ...standardValues,
      ...midPointValues
    } as SharedEnergyTools.BinCase;

    const hasMoistureLoadValues =
      moistureLoadValues.returnAirHumidity !== undefined;
    const hasMonths = months.binTimeJanuary !== undefined;

    bincases = hasMoistureLoadValues
      ? { ...bincases, ...moistureLoadValues }
      : bincases;
    bincases = hasMonths ? { ...bincases, ...months } : bincases;

    return bincases;
  });
}

function getRoofTemperatureCompensationSelection(
  selectedRoofTemperatureCompensation:
    | SharedEnergyTools.RoofTemperatureCompensation
    | undefined
) {
  return selectedRoofTemperatureCompensation
    ? PropertyValueSet.setInteger(
        KnownProperties.roofTempCompensation,
        selectedRoofTemperatureCompensation.compensation,
        PropertyValueSet.setAmount(
          KnownProperties.maxRoofTempCompensation,
          selectedRoofTemperatureCompensation.temperature,
          PropertyValueSet.Empty
        )
      )
    : PropertyValueSet.Empty;
}

function getOperationTimePreset(name: string): OperationTime.OperationTime {
  const map: {
    readonly [key: string]: OperationTime.OperationTime;
  } = {
    allday: OperationTime.presets.allDay,
    factory: OperationTime.presets.factoryHours,
    office: OperationTime.presets.officeHours,
    twoshift: OperationTime.presets.twoShiftHours
  };

  return map[name];
}

function updateOperatingCasesPropertiesFromBinCase(
  binCase: PropertyValueSet.PropertyValueSet,
  operatingCaseProperties: PropertyValueSet.PropertyValueSet,
  outdoorTemperaturePropertyName: string,
  outdoorHumidityPropertyName: string,
  returnAirTemperaturePropertyName: string,
  returnAirHumidityPropertyName: string,
  targetHumidityPropertyName: string,
  targetTemperaturePropertyName: string
): PropertyValueSet.PropertyValueSet {
  const id = PropertyValueSet.get(KnownProperties.id, binCase);
  const airTemperature = PropertyValueSet.get(
    KnownProperties.airTemperature,
    binCase
  );
  const airHumidity = PropertyValueSet.get(
    KnownProperties.airHumidity,
    binCase
  );
  const binPressure = PropertyValueSet.get(
    KnownProperties.binPressure,
    binCase
  );
  const returnAirTemperature = PropertyValueSet.get(
    KnownProperties.returnAirTemperature,
    binCase
  );
  const returnAirHumidity = PropertyValueSet.get(
    KnownProperties.returnAirHumidity,
    binCase
  );
  const targetTemperature = PropertyValueSet.get(
    KnownProperties.supplyTargetTemperature,
    binCase
  );

  // Target humidity, from MLC its always HumidityRatio but in operating case it can also be RelativeHumidity
  let targetHumidity:
    | Amount.Amount<Quantity.HumidityRatio | Quantity.RelativeHumidity>
    | undefined;

  const binCaseTargetHumidityAmount =
    PropertyValueSet.getAmount<Quantity.HumidityRatio>(
      KnownProperties.supplyTargetHumidity,
      binCase
    );

  if (binCaseTargetHumidityAmount) {
    const operatingCaseTargetHumidityQuantity =
      PropertyValueSet.getAmount<
        Quantity.HumidityRatio | Quantity.RelativeHumidity
      >(targetHumidityPropertyName, operatingCaseProperties)?.unit.quantity ??
      "HumidityRatio";

    targetHumidity =
      operatingCaseTargetHumidityQuantity === "RelativeHumidity" &&
      binPressure &&
      targetTemperature
        ? Physics.RP1485.AmountConversions.humidityRatioToRelativeHumidity(
            PropertyValue.getAmount<Quantity.Pressure>(binPressure)!,
            PropertyValue.getAmount<Quantity.Temperature>(targetTemperature)!,
            binCaseTargetHumidityAmount
          )
        : binCaseTargetHumidityAmount;
  }

  let newOperatingCaseProperties = operatingCaseProperties;

  newOperatingCaseProperties = id
    ? PropertyValueSet.set(
        KnownProperties.binId,
        id,
        newOperatingCaseProperties
      )
    : newOperatingCaseProperties;

  newOperatingCaseProperties = airTemperature
    ? PropertyValueSet.set(
        outdoorTemperaturePropertyName,
        airTemperature,
        newOperatingCaseProperties
      )
    : newOperatingCaseProperties;

  newOperatingCaseProperties = airHumidity
    ? PropertyValueSet.set(
        outdoorHumidityPropertyName,
        airHumidity,
        newOperatingCaseProperties
      )
    : newOperatingCaseProperties;

  newOperatingCaseProperties = binPressure
    ? PropertyValueSet.set(
        KnownProperties.atmosphericPressure,
        binPressure,
        newOperatingCaseProperties
      )
    : newOperatingCaseProperties;

  newOperatingCaseProperties = returnAirTemperature
    ? PropertyValueSet.set(
        returnAirTemperaturePropertyName,
        returnAirTemperature,
        newOperatingCaseProperties
      )
    : newOperatingCaseProperties;

  newOperatingCaseProperties = returnAirHumidity
    ? PropertyValueSet.set(
        returnAirHumidityPropertyName,
        returnAirHumidity,
        newOperatingCaseProperties
      )
    : newOperatingCaseProperties;

  newOperatingCaseProperties = targetHumidity
    ? PropertyValueSet.setAmount(
        targetHumidityPropertyName,
        targetHumidity,
        newOperatingCaseProperties
      )
    : newOperatingCaseProperties;

  newOperatingCaseProperties = targetTemperature
    ? PropertyValueSet.set(
        targetTemperaturePropertyName,
        targetTemperature,
        newOperatingCaseProperties
      )
    : newOperatingCaseProperties;

  return newOperatingCaseProperties;
}

export function getInitialPsychrometricChartSettings(
  state: BinGeneration.State,
  chartType: ChartType,
  binFields:
    | ReadonlyArray<{
        readonly temperature: Amount.Amount<"Temperature">;
        readonly humidity: Amount.Amount<"HumidityRatio">;
        readonly time: Amount.Amount<"Duration">;
      }>
    | undefined,
  sharedState: SharedState.State,
  binCases: ReadonlyArray<SharedEnergyTools.BinCase>
): PsychrometricChart.InitProps {
  const binSize =
    (PropertyValueSet.getValue(
      KnownProperties.binSize,
      state.binSelections ?? PropertyValueSet.Empty
    ).value as number) ?? 2;

  return {
    source: "custom",
    binCases: binCases.map(bin => ({
      temperature: bin.averageTemperature,
      humidity: bin.averageHumidity,
      time: bin.binTime
    })),
    si: PsychrometricChart.infoFromPreset(chartType).measureSystem === "SI",
    points: [],
    binFields: binFields
      ? binFields.map(f => ({
          ...f,
          temperatureInterval: Amount.create(binSize, Units.Celsius),
          humidityInterval: Amount.create(binSize, Units.GramPerKilogram)
        }))
      : [],
    pressure:
      binCases && binCases.length
        ? binCases[0].binPressure
        : Amount.create(101325, Units.Pascal),
    type: PsychrometricChart.infoFromPreset(chartType).chartType,
    accessToken: sharedState.accessToken,
    limits: false
  };
}

//tslint:disable-next-line
