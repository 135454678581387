import * as ProductProperties from "@genesys/shared/lib/product-properties";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  PropertyFilter,
  PropertyValue,
  PropertyValueSet
} from "@genesys/property";
import * as Types from "./types";
import * as BaseTypes from "../step-state-base";
import * as GraphQlTypes from "../../../graphql-types";
import { Quantity } from "@genesys/uom";
import {
  PropertyInfo,
  PropertyItem,
  PropertyValueSource,
  DefaultValue,
  DescriptionText
} from "../../../properties-selector";

export function getEffectiveTemplateComponentsProperties(
  productData: GraphQlTypes.WizardProductDataQueryProduct,
  templateComponents: ReadonlyArray<{
    readonly id: string;
    readonly productId: string;
    readonly properties?: string | null;
  }>,
  stateTemplateComponentsProperties:
    | {
        readonly [key: string]: BaseTypes.DivergentTemplateComponent;
      }
    | undefined
): { readonly [key: string]: PropertyValueSet.PropertyValueSet } {
  return templateComponents.reduce((acc, templateComponent) => {
    const defaultProperties = ProductProperties.createDefaultProperties(
      productData.properties.map(p => ({
        ...p,
        defaultValues: p.defaultValues.map(d => ({
          value: PropertyValue.fromString(d.value)!,
          propertyFilter: PropertyFilter.fromStringOrEmpty(d.propertyFilter)
        }))
      })),
      false
    );

    const stateTemplateComponent =
      stateTemplateComponentsProperties &&
      stateTemplateComponentsProperties[templateComponent.id];

    const effectiveProperties: PropertyValueSet.PropertyValueSet =
      stateTemplateComponent !== undefined
        ? stateTemplateComponent.properties
        : (templateComponent.properties &&
            PropertyValueSet.fromString(templateComponent.properties)) ||
          PropertyValueSet.Empty;

    return {
      ...acc,
      [templateComponent.id]: PropertyValueSet.merge(
        effectiveProperties,
        defaultProperties
      )
    };
  }, {});
}

export function parseProduct(
  product: GraphQlTypes.WizardProductDataQueryProduct,
  translate: LanguageTexts.Translate
): Types.ProductData {
  return {
    id: product.id,
    properties: product.properties.map(p =>
      parseProperty(p, product.id, translate)
    )
  };
}

function parseProperty(
  property: GraphQlTypes.WizardProductDataQueryProduct["properties"][0],
  productId: string,
  translate: LanguageTexts.Translate
): PropertyInfo {
  const getText = (value: number) =>
    translate(
      LanguageTexts.productPropertyValue(productId, property.name, value)
    );

  return {
    name: property.name,
    quantity: property.quantity as Quantity.Quantity,
    group: property.groupName,
    sortNo: numberOrZero(property.sortNo),
    validationFilter: PropertyFilter.fromStringOrEmpty(
      property.validationFilter
    ),
    visibilityFilter: PropertyFilter.fromStringOrEmpty(
      property.visibilityFilter
    ),
    items: property.values.map(v => parsePropertyItem(v, getText)),
    descriptionTexts: property.descriptionTexts.map(parseDescriptionTexts),
    valueSources: property.valueSources.map(parsePropertyValueSource),
    conversionParameters: property.quantityConversionParams,
    defaultValues: property.defaultValues.map(parseDefaultValue)
  };
}

function parseDescriptionTexts(
  x:
    | GraphQlTypes.WizardProductDataQueryProduct["properties"][0]["descriptionTexts"][0]
    | GraphQlTypes.WizardProductDataQueryProduct["properties"][0]["values"][0]["descriptionTexts"][0]
): DescriptionText {
  return {
    id: x.id,
    propertyFilter: PropertyFilter.fromStringOrEmpty(x.propertyFilter),
    language: x.language,
    text: x.text
  };
}

function parsePropertyItem(
  propertyValue: GraphQlTypes.WizardProductDataQueryProduct["properties"][0]["values"][0],
  getText: (value: number) => string
): PropertyItem {
  const value = PropertyValue.fromString(propertyValue.value)!;
  return {
    id: propertyValue.id,
    rangeFilter: PropertyFilter.fromStringOrEmpty(
      propertyValue.rangeFilter || ""
    ),
    validationFilter: PropertyFilter.fromStringOrEmpty(
      propertyValue.validationFilter || ""
    ),
    value: value,
    sortNo: numberOrZero(propertyValue.sortNo),
    image: propertyValue.image || undefined,
    descriptionValuesTexts: propertyValue.descriptionTexts.map(
      parseDescriptionTexts
    ),
    text: getText(value.value as number)
  };
}
function parsePropertyValueSource(
  valueSource: GraphQlTypes.WizardProductDataQueryProduct["properties"][0]["valueSources"][0]
): PropertyValueSource {
  return {
    id: valueSource.id,
    claimFilter: valueSource.claimFilter,
    propertyFilter: PropertyFilter.fromStringOrEmpty(
      valueSource.propertyFilter || ""
    ),
    propertyValueSourceId: valueSource.propertyValueSourceId,
    value: valueSource.value,
    parameters: valueSource.parameters
  };
}

function numberOrZero(value: number | null | undefined): number {
  return value !== null && value !== undefined ? value : 0;
}

function parseDefaultValue(
  defaultValue: GraphQlTypes.SystemConfiguratorProduct["product"]["systemType"]["allProducts"][0]["properties"][0]["defaultValues"][0]
): DefaultValue {
  return {
    value: PropertyValue.fromString(defaultValue.value)!,
    propertyFilter: PropertyFilter.fromStringOrEmpty(
      defaultValue.propertyFilter
    )
  };
}
