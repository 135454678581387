import styled from "styled-components";
import { TableInteractive } from "@genesys/ui-elements";

export const Root = styled(TableInteractive)`
  width: auto;
  border-spacing: 0 1em;
`;

export const PropertyLabel = styled.label`
  font-family: Futura;
  color: #8f9bb3;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  ${(props: { readonly isHidden: boolean }) =>
    props.isHidden && `border: 1px solid black;`}
`;

export const ValueSourceImage = styled.img`
  width: 18px;
  vertical-align: middle;
`;

export const DescriptionIconContainer = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export const DescriptionContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  width: 460px;
  padding: 12px 24px;

  h3 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  h4 {
    display: block;
    white-space: pre-wrap;
    margin: 8px 0px;
  }
`;

export const ValueDescriptionsContainer = styled.div`
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  max-height: 200px;
  ::-webkit-scrollbar {
    width: 3px;
    margin-right: 20px;
    opacity: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: #023442;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
