import { PropertyValueSet } from "@genesys/property";
import { PropertyFilter } from "@genesys/property";
import { PropertyInfo, PropertyItem } from "../../properties-selector";

const summerCalcCasePvsString = `
    name="Summer";
    highTemperatureStageInUse=0;
    highTemperatureStageCondenserInletTemperature=95:Celsius;
    highTemperatureStageCondenserOutletTemperature=115:Celsius;
    highTemperatureStageHeatingCapacityRequirement=100:Watt;
    highTemperatureStageCondenserPipeLength=25:Meter;
    lowTemperatureStageCondenserInletTemperature=20:Celsius;
    lowTemperatureStageCondenserOutletTemperature=65:Celsius;
    lowTemperatureStageCondenserPipeLength=25.1:Meter;
    lowTemperatureStageEvaporatorInletTemperature=8:Celsius;
    lowTemperatureStageEvaporatorOutletTemperature=5:Celsius;
    lowTemperatureStageEvaporatorPipeLength=25:Meter;
    lowTemperatureStageHeatingCapacityRequirement=100:Watt;
    lowTemperatureStageCoolingCapacityRequirement=100:Watt;`;

const newCalcCasePvsString = `
    name="";
    highTemperatureStageInUse=0;
    highTemperatureStageCondenserInletTemperature=0:Celsius;
    highTemperatureStageCondenserOutletTemperature=0:Celsius;
    highTemperatureStageHeatingCapacityRequirement=0:Watt;
    highTemperatureStageCondenserPipeLength=0:Meter;
    lowTemperatureStageCondenserInletTemperature=0:Celsius;
    lowTemperatureStageCondenserOutletTemperature=0:Celsius;
    lowTemperatureStageCondenserPipeLength=0:Meter;
    lowTemperatureStageEvaporatorInletTemperature=0:Celsius;
    lowTemperatureStageEvaporatorOutletTemperature=0:Celsius;
    lowTemperatureStageEvaporatorPipeLength=0:Meter;
    lowTemperatureStageHeatingCapacityRequirement=0:Watt;
    lowTemperatureStageCoolingCapacityRequirement=0:Watt;`;

const configPvsString = `
    reference="MP37615";
    heatPumpConfigurationType=1;
    wetAirAvailableAsHeatSource=0;
    wetAirAvailableAsHeatSink=0;
    evaporatorFluidType=0;
    evaporatorFluidConcentration=35:Percent;
    `;

export const initialConfigPvs: PropertyValueSet.PropertyValueSet =
  PropertyValueSet.fromString(configPvsString.replace(/\s+/g, ""));

export const initialCalculationCasePvs: PropertyValueSet.PropertyValueSet =
  PropertyValueSet.fromString(summerCalcCasePvsString.replace(/\s+/g, ""));

export const newCalculationCasePvs: PropertyValueSet.PropertyValueSet =
  PropertyValueSet.fromString(newCalcCasePvsString.replace(/\s+/g, ""));

const discreteValues: ReadonlyArray<PropertyItem> = [1, 2, 3, 4, 5].map(v => ({
  id: v.toString(),
  text: v.toString(),
  value: { type: "integer", value: v },
  sortNo: v,
  descriptionValuesTexts: [],
  rangeFilter: PropertyFilter.Empty,
  validationFilter: PropertyFilter.Empty
}));

const booleanValues: ReadonlyArray<PropertyItem> = [
  {
    id: "false",
    text: "No",
    value: { type: "integer", value: 0 },
    sortNo: 0,
    descriptionValuesTexts: [],
    rangeFilter: PropertyFilter.Empty,
    validationFilter: PropertyFilter.Empty
  },
  {
    id: "true",
    text: "Yes",
    value: { type: "integer", value: 1 },
    sortNo: 1,
    descriptionValuesTexts: [],
    rangeFilter: PropertyFilter.Empty,
    validationFilter: PropertyFilter.Empty
  }
];

export const configPropertyInfo: ReadonlyArray<PropertyInfo> = [
  {
    name: "heatPumpConfigurationType",
    quantity: "Discrete",
    group: "mainselections",
    sortNo: 0,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: discreteValues,
    descriptionTexts: [
      {
        id: "heatPumpConfigurationType",
        propertyFilter: PropertyFilter.Empty,
        text: "Number of the configuration of the heat pump system to be used in the calculation."
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "wetAirAvailableAsHeatSource",
    quantity: "Discrete",
    group: "mainselections",
    sortNo: 10,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: booleanValues,
    descriptionTexts: [
      {
        id: "wetAirAvailableAsHeatSource",
        propertyFilter: PropertyFilter.Empty,
        text: "Whether or not the water air heat source is available"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "wetAirAvailableAsHeatSink",
    quantity: "Discrete",
    group: "mainselections",
    sortNo: 20,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: booleanValues,
    descriptionTexts: [
      {
        id: "wetAirAvailableAsHeatSink",
        propertyFilter: PropertyFilter.Empty,
        text: "Whether or not the wet air heat sink is available"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "evaporatorFluidType",
    quantity: "Discrete",
    group: "mainselections",
    sortNo: 30,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [
      {
        id: "water",
        text: "Water",
        value: { type: "integer", value: 0 },
        sortNo: 0,
        descriptionValuesTexts: [],
        rangeFilter: PropertyFilter.Empty,
        validationFilter: PropertyFilter.Empty
      },
      {
        id: "Propylene glycol/Water solution",
        text: "Propylene glycol/Water solution",
        value: { type: "integer", value: 1 },
        sortNo: 1,
        descriptionValuesTexts: [],
        rangeFilter: PropertyFilter.Empty,
        validationFilter: PropertyFilter.Empty
      },
      {
        id: "Ethylene glycol/Water solution",
        text: "Ethylene glycol/Water solution",
        value: { type: "integer", value: 2 },
        sortNo: 2,
        descriptionValuesTexts: [],
        rangeFilter: PropertyFilter.Empty,
        validationFilter: PropertyFilter.Empty
      },
      {
        id: "Ethanol/Water solution",
        text: "Ethanol/Water solution",
        value: { type: "integer", value: 3 },
        sortNo: 3,
        descriptionValuesTexts: [],
        rangeFilter: PropertyFilter.Empty,
        validationFilter: PropertyFilter.Empty
      }
    ],
    descriptionTexts: [
      {
        id: "evaporatorFluidType",
        propertyFilter: PropertyFilter.Empty,
        text: "Fluid type used in the evaporator."
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "evaporatorFluidConcentration",
    quantity: "Dimensionless",
    group: "mainselections",
    sortNo: 40,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [
      {
        id: "evaporatorFluidConcentration",
        propertyFilter: PropertyFilter.Empty,
        text: "Concentration in mass-% of the evaporator fluid. For example, if the evaporatorFluidType is 1 and evaporatorFluidConcentration 35, the evaporator fluid is 35 % propylene glycol and 65 % water."
      }
    ],
    valueSources: [],
    conversionParameters: []
  }
];

export const productId = "OIL";

export const calculationCasePropertyInfo: ReadonlyArray<PropertyInfo> = [
  {
    name: "name",
    quantity: "Text",
    group: "mainselections",
    sortNo: 0,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [
      {
        id: "name",
        propertyFilter: PropertyFilter.Empty,
        text: "Name of the calculation case"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "highTemperatureStageInUse",
    quantity: "Discrete",
    group: "mainselections",
    sortNo: 10,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: booleanValues,
    descriptionTexts: [
      {
        id: "highTemperatureStageInUse",
        propertyFilter: PropertyFilter.Empty,
        text: "Whether or not the HT stage is used"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "highTemperatureStageCondenserInletTemperature",
    quantity: "Temperature",
    group: "mainselections",
    sortNo: 20,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [
      {
        id: "highTemperatureStageCondenserInletTemperature",
        propertyFilter: PropertyFilter.Empty,
        text: "Inlet temperature to the condenser of the HT stage in [C]"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "highTemperatureStageCondenserOutletTemperature",
    quantity: "Temperature",
    group: "mainselections",
    sortNo: 30,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [
      {
        id: "highTemperatureStageCondenserOutletTemperature",
        propertyFilter: PropertyFilter.Empty,
        text: "Outlet temperature of the condenser of the HT stage in [C]"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "highTemperatureStageHeatingCapacityRequirement",
    quantity: "Power",
    group: "mainselections",
    sortNo: 40,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [
      {
        id: "highTemperatureStageHeatingCapacityRequirement",
        propertyFilter: PropertyFilter.Empty,
        text: "Heating capacity required from the HT stage in [kW]"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "highTemperatureStageCondenserPipeLength",
    quantity: "Length",
    group: "mainselections",
    sortNo: 50,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [
      {
        id: "highTemperatureStageCondenserPipeLength",
        propertyFilter: PropertyFilter.Empty,
        text: "Piping length of the condenser of the HT stage in [m]. Used to estimate the circulation pump power consumption"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageCondenserInletTemperature",
    quantity: "Temperature",
    group: "mainselections",
    sortNo: 60,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [
      {
        id: "lowTemperatureStageCondenserInletTemperature",
        propertyFilter: PropertyFilter.Empty,
        text: "Inlet temperature to the condenser of the LT stage in [C]"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageCondenserOutletTemperature",
    quantity: "Temperature",
    group: "mainselections",
    sortNo: 70,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [
      {
        id: "lowTemperatureStageCondenserOutletTemperature",
        propertyFilter: PropertyFilter.Empty,
        text: "Outlet temperature of the condenser of the HT stage in [C]"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageCondenserPipeLength",
    quantity: "Length",
    group: "mainselections",
    sortNo: 80,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [
      {
        id: "lowTemperatureStageCondenserPipeLength",
        propertyFilter: PropertyFilter.Empty,
        text: "Piping length of the condenser of the LT stage in [m]. Used to estimate the circulation pump power consumption"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageEvaporatorInletTemperature",
    quantity: "Temperature",
    group: "mainselections",
    sortNo: 90,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [
      {
        id: "lowTemperatureStageEvaporatorInletTemperature",
        propertyFilter: PropertyFilter.Empty,
        text: "Inlet temperature to the evaporator of the LT stage in [C]"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageEvaporatorOutletTemperature",
    quantity: "Temperature",
    group: "mainselections",
    sortNo: 100,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [
      {
        id: "lowTemperatureStageEvaporatorOutletTemperature",
        propertyFilter: PropertyFilter.Empty,
        text: "Outlet temperature of the evaporator of the LT stage in [C]"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageEvaporatorPipeLength",
    quantity: "Length",
    group: "mainselections",
    sortNo: 110,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [
      {
        id: "lowTemperatureStageEvaporatorPipeLength",
        propertyFilter: PropertyFilter.Empty,
        text: "Piping length of the evaporator of the LT stage in [m]. Used to estimate the circulation pump power consumption"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageHeatingCapacityRequirement",
    quantity: "Power",
    group: "mainselections",
    sortNo: 120,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [
      {
        id: "lowTemperatureStageHeatingCapacityRequirement",
        propertyFilter: PropertyFilter.Empty,
        text: "Heating capacity required from the LT stage in [kW]"
      }
    ],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageCoolingCapacityRequirement",
    quantity: "Power",
    group: "mainselections",
    sortNo: 130,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [
      {
        id: "lowTemperatureStageCoolingCapacityRequirement",
        propertyFilter: PropertyFilter.Empty,
        text: "Cooling capacity required from the LT stage in [kW]"
      }
    ],
    valueSources: [],
    conversionParameters: []
  }
];

export const calculationCaseResultPropertyInfo: ReadonlyArray<PropertyInfo> = [
  {
    name: "name",
    quantity: "Text",
    group: "mainselections",
    sortNo: 0,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "cop",
    quantity: "Dimensionless",
    group: "mainselections",
    sortNo: 1,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "wetAirCapacityRequired",
    quantity: "Power",
    group: "mainselections",
    sortNo: 2,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "highTemperatureStageCop",
    quantity: "Dimensionless",
    group: "mainselections",
    sortNo: 3,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "highTemperatureStageCirculationPumpPower",
    quantity: "Power",
    group: "mainselections",
    sortNo: 10,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "highTemperatureStageCondenserFlowRate",
    quantity: "MassFlow",
    group: "mainselections",
    sortNo: 20,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "highTemperatureStageElectricPowerConsumed",
    quantity: "Power",
    group: "mainselections",
    sortNo: 30,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "highTemperatureStageHeatingCapacity",
    quantity: "Power",
    group: "mainselections",
    sortNo: 40,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "highTemperatureStageLiquidOutletTemperature",
    quantity: "Temperature",
    group: "mainselections",
    sortNo: 50,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "highTemperatureStageRelativeCapacity",
    quantity: "Dimensionless",
    group: "mainselections",
    sortNo: 60,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "highTemperatureStageRelativeCapacityAtMinimumSpeed",
    quantity: "Dimensionless",
    group: "mainselections",
    sortNo: 70,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageCop",
    quantity: "Dimensionless",
    group: "mainselections",
    sortNo: 55,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageCirculationPumpPower",
    quantity: "Power",
    group: "mainselections",
    sortNo: 80,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageCondenserFlowRate",
    quantity: "MassFlow",
    group: "mainselections",
    sortNo: 90,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageCondenserLiquidOutletTemperature",
    quantity: "Temperature",
    group: "mainselections",
    sortNo: 100,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageCoolingCapacity",
    quantity: "Power",
    group: "mainselections",
    sortNo: 110,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageElectricPowerConsumed",
    quantity: "Power",
    group: "mainselections",
    sortNo: 120,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageEvaporatorFlowRate",
    quantity: "MassFlow",
    group: "mainselections",
    sortNo: 130,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageEvaporatorLiquidOutletTemperature",
    quantity: "Temperature",
    group: "mainselections",
    sortNo: 140,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageHeatingCapacity",
    quantity: "Power",
    group: "mainselections",
    sortNo: 150,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageRelativeCapacity",
    quantity: "Dimensionless",
    group: "mainselections",
    sortNo: 160,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  },
  {
    name: "lowTemperatureStageRelativeCapacityAtMinimumSpeed",
    quantity: "Dimensionless",
    group: "mainselections",
    sortNo: 170,
    defaultValues: [],
    validationFilter: PropertyFilter.Empty,
    visibilityFilter: PropertyFilter.Empty,
    items: [],
    descriptionTexts: [],
    valueSources: [],
    conversionParameters: []
  }
];

// tslint:disable-next-line
