import * as React from "react";
import * as FlowDiagram from "@genesys/flow-diagram-gen2";
import * as AbstractImage from "abstract-image";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { Quantity } from "@genesys/uom";
import { PropertyValue } from "@genesys/property";
import * as SharedState from "../../shared-state";
import { getValue } from "@genesys/shared/lib/product-properties";
import * as Types from "../types";
import * as System from "../system";
import * as SystemStatusEnum from "@genesys/shared/lib/enums/system-status";
import * as Product from "../product";
import * as ComponentOrder from "@genesys/shared/lib/component-order-2";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import {
  DiagramContainer,
  SelectedComponentMenuContainer,
  DiagramRoot,
  SelectedComponentMenu,
  SelectedComponentMenuItem
} from "../elements";
import { clientConfig } from "../../config";
import {
  EditBlue,
  EnhanceBlue,
  MoveBlue,
  NotesBlue,
  TrashBlue,
  AccessoriesBlue,
  InfoGray
} from "@genesys/ui-elements/lib/icons";
import * as ComponentPlacement from "../component-placement";
import { v4 } from "uuid";
import { ComponentSection } from "@genesys/flow-diagram-gen2/lib/types";
import * as ComponentsMessages from "@genesys/shared/lib/components-messages";

export interface DiagramProps {
  readonly claimFilteredComponentsMessages: ReadonlyArray<ComponentsMessages.ComponentMessage>;
  readonly productData: Product.ProductData;
  readonly system: System.System;
  readonly sharedState: SharedState.State;
  readonly symbols: ReadonlyArray<Product.SymbolDefinition>;
  readonly mode: Types.DiagramMode;
  readonly diagramHoverState: Types.DiagramHoverState;
  readonly selectedAirPosition: string | undefined;
  readonly setGenericDialogState: (
    state: Types.GenericDialogState | undefined
  ) => void;
  readonly setDiagramHoverState: (state: Types.DiagramHoverState) => void;
  readonly setSelectedAirPosition: (airPosition: string | undefined) => void;
  readonly selectComponent: (id: string | undefined) => void;
  readonly selectComponentMenu: (id: string, menu: Types.Point) => void;
  readonly addToDiagram: (
    componentId: string,
    productId: string,
    productSectionIds: ReadonlyArray<string>,
    selectedPositions: ReadonlyArray<Types.ComponentSectionPosition>
  ) => void;
  readonly moveComponent: (
    componentId: string,
    sections: ReadonlyArray<{
      readonly productSectionId: string;
      readonly componentSectionId: string;
    }>
  ) => void;
  readonly moveComponentSection: (
    componentId: string,
    sections: ReadonlyArray<{
      readonly productSectionId: string;
      readonly componentSectionId: string;
    }>,
    selectedPositions: ReadonlyArray<Types.ComponentSectionPosition>
  ) => void;
  readonly editComponent: (id: string) => void;
  readonly openAccessories: (id: string) => void;
  readonly deleteComponent: (componentId: string) => void;
  readonly exchangeComponent: (
    exchangeComponent: Types.ExchangeComponent
  ) => void;
  readonly openNotes: (componentId: string) => void;
}

export function Diagram(props: DiagramProps): JSX.Element {
  const diagramExpandSize = 40;

  const diagramType = props.productData.products.find(
    p =>
      p.boxConnectionPoints.find(b => b.diagramType === "UnitConfiguration") ||
      p.boxLinePoints.find(b => b.diagramType === "UnitConfiguration") ||
      p.boxSymbols.find(b => b.diagramType === "UnitConfiguration") ||
      p.boxTexts.find(b => b.diagramType === "UnitConfiguration")
  )
    ? "UnitConfiguration"
    : "FlowDiagram";

  const textdb = props.system.file.systemTypeId;

  const system: System.System = {
    ...props.system,
    components: props.system.components.map(c => ({
      ...c,
      messages: c.messages.filter(
        m =>
          props.claimFilteredComponentsMessages.find(cm => cm.id === m.id) !==
          undefined
      )
    }))
  };

  const diagram = FlowDiagram.buildDiagram(
    true,
    diagramType,
    false,
    props.productData.products,
    system,
    (productId, propertyName, propertyValue) =>
      props.sharedState.translate(
        LanguageTexts.productPropertyValue(
          productId,
          propertyName,
          propertyValue
        )
      ),
    (key: string) =>
      props.sharedState.translate(LanguageTexts.dynamicText(key, textdb)),
    (key: string) =>
      props.sharedState.translate(LanguageTexts.curlyTranslate(key, textdb)),
    (
      fieldGroup: string,
      fieldName: string,
      quantity: Quantity.Quantity,
      property: PropertyValue.PropertyValue
    ) =>
      getValue(
        property,
        props.sharedState.screenAmounts.getAmountFormat(
          fieldGroup,
          fieldName,
          quantity
        )
      ),
    diagramExpandSize
  );

  const airPositions =
    diagram.type === "PidDiagram"
      ? []
      : ComponentOrder.getAirPositions(
          diagram.type,
          props.system.components,
          props.system.airstreams,
          props.productData.products.reduce(
            (a: ReadonlyArray<Product.BoxConnectionPoint>, b) =>
              a.concat(b.boxConnectionPoints),
            []
          ),
          false
        );

  const allPorts = diagram.nodes.reduce(
    (soFar: ReadonlyArray<FlowDiagram.Types.Port>, node) =>
      soFar.concat(node.ports),
    []
  );
  const inletsByComponentSectionId: Types.PortsByComponentSectionId =
    allPorts.reduce((soFar: Types.PortsByComponentSectionIdMutable, port) => {
      if (port.connectionType === "Inlet") {
        soFar[port.componentSectionId] = port;
        return soFar;
      }
      return soFar;
    }, {});

  const outletsByComponentSectionId: Types.PortsByComponentSectionId =
    allPorts.reduce((soFar: Types.PortsByComponentSectionIdMutable, port) => {
      if (port.connectionType === "Outlet") {
        soFar[port.componentSectionId] = port;
        return soFar;
      }
      return soFar;
    }, {});

  const sys = props.system.components.find(c => c.productId.endsWith("SYS"))!;

  const openPositions =
    props.mode.type === "CreatingMode"
      ? ComponentPlacement.getOpenPositions(
          v4(),
          props.mode.sectionsLeftToAdd[0],
          props.productData.systemType.positionValidation,
          props.productData.systemType.placementRules,
          props.productData.systemType.sequenceRules,
          sys.properties,
          props.system.airstreams,
          allPorts
        )
      : props.mode.type === "MovingMode"
      ? ComponentPlacement.getOpenPositions(
          props.mode.sectionsLeftToMove[0].componentSectionId,
          props.mode.sectionsLeftToMove[0].productSectionId,
          props.productData.systemType.positionValidation,
          props.productData.systemType.placementRules,
          props.productData.systemType.sequenceRules,
          sys.properties,
          props.system.airstreams,
          allPorts
        )
      : [];

  const positionLabels = getPositionLabels(
    props.system.status,
    airPositions,
    inletsByComponentSectionId,
    outletsByComponentSectionId
  );

  const selections =
    props.mode.type === "SelectionMode" ||
    props.mode.type === "SelectionMenuMode"
      ? [
          {
            componentId: props.mode.selectedComponentId,
            symbolId: undefined
          }
        ]
      : [];

  const hoverText =
    props.diagramHoverState === "edit"
      ? props.sharedState.translate(LanguageTexts.edit())
      : props.diagramHoverState === "accessory"
      ? props.sharedState.translate(LanguageTexts.accessories())
      : props.diagramHoverState === "menu"
      ? props.sharedState.translate(LanguageTexts.actions())
      : "";

  const abstractImage = FlowDiagram.buildAbstractImage(
    diagram,
    selections,
    positionLabels,
    openPositions,
    props.symbols,
    diagramExpandSize,
    props.diagramHoverState,
    hoverText,
    props.selectedAirPosition
  );
  const wikiJsUrl = clientConfig.wikiJsBaseUrl.replace("/graphql", "");

  const menu = (selectedComponentId: string) => {
    const component = props.system.components.find(
      c => c.id === selectedComponentId
    )!;
    const product = props.productData.products.find(
      p => p.id === component.productId
    )!;

    const systemStatus = props.system.status;

    const ComponentMenuItem = ({
      children
    }: {
      readonly children: JSX.Element;
    }) => {
      if (systemStatus === SystemStatusEnum.SystemStatus.LockSuccess) {
        return null;
      }
      return children;
    };

    return (
      <SelectedComponentMenu>
        <ComponentMenuItem>
          <SelectedComponentMenuItem
            onClick={() => props.editComponent(selectedComponentId)}
          >
            <EditBlue />
            <span>{props.sharedState.translate(LanguageTexts.edit())}</span>
          </SelectedComponentMenuItem>
        </ComponentMenuItem>

        <ComponentMenuItem>
          <SelectedComponentMenuItem
            onClick={() => {
              const component = props.system.components.find(
                c => c.id === selectedComponentId
              );
              if (!component) {
                return props.selectComponent(undefined);
              }
              const productSections = props.productData.products
                .find(p => p.id === component.productId)!
                .sections.filter(s => s.movable)
                .sort((a, b) => (a.sortNo < b.sortNo ? -1 : 1))
                .map(s => s.id);

              const componentSections = productSections.reduce(
                (soFar: Array<ComponentSection>, current) => {
                  const componentSection = component.sections.find(
                    s => s.productSectionId === current
                  );
                  if (componentSection) {
                    soFar.push(componentSection);
                  }
                  return soFar;
                },
                []
              );

              if (!componentSections.length) {
                return props.selectComponent(undefined);
              }
              const sectionLeftToMove = componentSections.map(s => ({
                productSectionId: s.productSectionId,
                componentSectionId: s.id
              }));

              const openPositions = ComponentPlacement.getOpenPositions(
                sectionLeftToMove[0].componentSectionId,
                sectionLeftToMove[0].productSectionId,
                props.productData.systemType.positionValidation,
                props.productData.systemType.placementRules,
                props.productData.systemType.sequenceRules,
                sys.properties,
                props.system.airstreams,
                allPorts
              );

              if (openPositions.length > 0) {
                return props.moveComponent(
                  selectedComponentId,
                  sectionLeftToMove
                );
              } else {
                return props.setGenericDialogState({
                  title: LanguageTexts.move(),
                  message: LanguageTexts.cannotAddProduct(component.productId)
                });
              }
            }}
          >
            <MoveBlue />
            <span>{props.sharedState.translate(LanguageTexts.move())}</span>
          </SelectedComponentMenuItem>
        </ComponentMenuItem>

        <ComponentMenuItem>
          <SelectedComponentMenuItem
            onClick={() => {
              const component = props.system.components.find(
                c => c.id === selectedComponentId
              );
              if (!component) {
                return props.selectComponent(undefined);
              }
              const product = props.productData.products.find(
                p => p.id === component.productId
              );
              if (!product) {
                return props.selectComponent(undefined);
              }
              if (product.optional) {
                props.setGenericDialogState({
                  title: LanguageTexts.confirmation(),
                  message: LanguageTexts.deleteComponent(),
                  onConfirm: () => props.deleteComponent(selectedComponentId)
                });
              } else {
                props.setGenericDialogState({
                  title: LanguageTexts.deleteText(),
                  message: LanguageTexts.cannotDeleteComponent()
                });
              }
            }}
          >
            <TrashBlue />
            <span>{props.sharedState.translate(LanguageTexts.del())}</span>
          </SelectedComponentMenuItem>
        </ComponentMenuItem>

        <SelectedComponentMenuItem
          onClick={() => props.openAccessories(selectedComponentId)}
        >
          <AccessoriesBlue />
          <span>
            {props.sharedState.translate(LanguageTexts.accessories())}
          </span>
        </SelectedComponentMenuItem>

        <ComponentMenuItem>
          <SelectedComponentMenuItem
            onClick={() => {
              const component = props.system.components.find(
                c => c.id === selectedComponentId
              );
              if (!component) {
                return props.selectComponent(undefined);
              }
              const product = props.productData.products.find(
                p => p.id === component.productId
              );
              if (!product) {
                return props.selectComponent(undefined);
              }
              if (product.optional) {
                props.exchangeComponent({
                  componentId: selectedComponentId
                });
              } else {
                props.setGenericDialogState({
                  title: LanguageTexts.exchangeComponent(),
                  message: LanguageTexts.cannotExchangeComponent()
                });
              }
            }}
          >
            <EnhanceBlue />
            <span>
              {props.sharedState.translate(LanguageTexts.exchangeComponent())}
            </span>
          </SelectedComponentMenuItem>
        </ComponentMenuItem>

        <SelectedComponentMenuItem
          onClick={() => props.openNotes(selectedComponentId)}
        >
          <NotesBlue />
          <span>{props.sharedState.translate(LanguageTexts.notes())}</span>
        </SelectedComponentMenuItem>

        {product.wikiJsPath && (
          <SelectedComponentMenuItem
            onClick={() => {
              const url = wikiJsUrl + "/" + product.wikiJsPath!;
              window.open(url);
            }}
          >
            <InfoGray />
            <span>
              {props.sharedState.translate(LanguageTexts.goToProductPage())}
            </span>
          </SelectedComponentMenuItem>
        )}
      </SelectedComponentMenu>
    );
  };
  const diagramPortClick = (inletId: string) => {
    const position = openPositions.find(
      p => p.inlet?.componentSectionId === inletId
    );
    if (!position) {
      return;
    }

    if (props.mode.type === "CreatingMode") {
      const nextProductSectionToAdd = props.mode.sectionsLeftToAdd[0];

      return props.addToDiagram(
        props.mode.componentId,
        props.mode.productId,
        props.mode.sectionsLeftToAdd.slice(1),
        props.mode.selectedPositions.concat({
          productSectionId: nextProductSectionToAdd,
          airstreamId: position.airstreamId,
          beforeSectionId: position.inlet!.componentSectionId
        })
      );
    }

    if (props.mode.type === "MovingMode") {
      const sectionToMove = props.mode.sectionsLeftToMove[0];
      const sectionsLeftToMove = props.mode.sectionsLeftToMove.slice(1).reduce(
        (
          soFar: Array<{
            readonly productSectionId: string;
            readonly componentSectionId: string;
          }>,
          current
        ) => {
          // Is there an open position? if there isn't we assume that the remaining sections are in their only allowed spot.
          const newOpenPostions = ComponentPlacement.getOpenPositions(
            current.componentSectionId,
            current.productSectionId,
            props.productData.systemType.positionValidation,
            props.productData.systemType.placementRules,
            props.productData.systemType.sequenceRules,
            sys.properties,
            props.system.airstreams,
            allPorts
          );

          if (newOpenPostions.length === 0) {
            return soFar;
          }

          soFar.push(current);

          return soFar;
        },
        []
      );

      return props.moveComponentSection(
        props.mode.componentId,
        sectionsLeftToMove,
        props.mode.selectedPositions.concat({
          productSectionId: sectionToMove.productSectionId,
          airstreamId: position.airstreamId,
          beforeSectionId: position.inlet!.componentSectionId
        })
      );
    }
  };

  return (
    <DiagramRoot>
      <ClickAwayListener
        onClickAway={() =>
          props.mode.type !== "DefaultMode" && props.selectComponent(undefined)
        }
      >
        <DiagramContainer>
          {props.mode.type === "SelectionMenuMode" && (
            <SelectedComponentMenuContainer
              left={props.mode.anchor.x}
              top={props.mode.anchor.y}
            >
              {menu(props.mode.selectedComponentId)}
            </SelectedComponentMenuContainer>
          )}
          {AbstractImage.createReactSvg(abstractImage, {
            onClick: (id, point) => {
              const clearSelections = () => {
                props.selectComponent(undefined);
                props.setSelectedAirPosition(undefined);
              };
              const selectAirPosition = (airPosition: string) => {
                props.setSelectedAirPosition(
                  props.selectedAirPosition === airPosition
                    ? undefined
                    : airPosition
                );
                props.selectComponent(undefined);
              };
              const selectComponent = (id: string) => {
                props.selectComponent(id);
                props.setSelectedAirPosition(undefined);
              };
              if (!id) {
                return clearSelections();
              }
              const split = id.split(".");
              if (split.length === 1) {
                const isAirPositionClick = split[0].length < 3;
                return isAirPositionClick
                  ? selectAirPosition(split[0])
                  : selectComponent(split[0]);
              }
              switch (split[0]) {
                case "menu":
                  return props.selectComponentMenu(split[1], point);
                case "edit":
                  return props.editComponent(split[1]);
                case "accessory":
                  return props.openAccessories(split[1]);
                case "openposition":
                  return diagramPortClick(split[1]);
                default:
                  return;
              }
            },
            onDoubleClick: id => id && props.editComponent(id),
            onMouseMove: id => {
              if (
                id &&
                (props.mode.type === "SelectionMenuMode" ||
                  props.mode.type === "SelectionMode")
              ) {
                const split = id.split(".");

                switch (split[0]) {
                  case "menu": {
                    if (props.diagramHoverState !== "menu") {
                      return props.setDiagramHoverState("menu");
                    }
                    return;
                  }
                  case "edit": {
                    if (props.diagramHoverState !== "edit") {
                      return props.setDiagramHoverState("edit");
                    }
                    return;
                  }
                  case "accessory": {
                    if (props.diagramHoverState !== "accessory") {
                      return props.setDiagramHoverState("accessory");
                    }
                    return;
                  }
                  default: {
                    if (props.diagramHoverState !== "no-hover") {
                      return props.setDiagramHoverState("no-hover");
                    }
                    return;
                  }
                }
              }
            }
          })}
        </DiagramContainer>
      </ClickAwayListener>
    </DiagramRoot>
  );
}

function getPositionLabels(
  systemStatus: number,
  airPositions: ReadonlyArray<ComponentOrder.AirPosition>,
  inlets: Types.PortsByComponentSectionId,
  outlets: Types.PortsByComponentSectionId
): ReadonlyArray<FlowDiagram.Types.PositionLabel> {
  if (
    airPositions.length < 1 ||
    systemStatus < SystemStatus.DesignCalculationSuccess
  ) {
    return [];
  }

  const positionLabels: Array<FlowDiagram.Types.PositionLabel> = [];
  for (const airPosition of airPositions) {
    const inlet =
      (airPosition.nextSectionId && inlets[airPosition.nextSectionId]) ||
      undefined;
    const outlet =
      (airPosition.prevSectionId && outlets[airPosition.prevSectionId]) ||
      undefined;
    positionLabels.push({
      label: String.fromCharCode(airPosition.label),
      position: {
        inlet,
        outlet
      }
    });
  }

  return positionLabels;
}

// tslint:disable-next-line
