export const contexts = {
  genesys: "munters.genesys",
  maintenance: "Munters.Genesys.Maintenance"
};

export const genesysUserClaims = {
  login: "Login",
  developer: "Developer",
  functionTester: "FunctionTester",
  impersonate: "Impersonate",
  resetCache: "ResetCache",
  primarySalesOrganisation: "PrimarySalesOrg",
  showProductCodes: "ShowProductCodes",
  showPrices: "ShowPrices",
  showPartPrices: "ShowPartPrices",
  showLCC: "ShowLCC",
  canEnterAdvancedClimateData: "CanEnterAdvancedClimateData",
  canUseManualPerformance: "CanUseManualPerformance",
  canUseInternalFactoryNotes: "CanUseInternalFactoryNotes",
  canSeeMRC: "CanSeeMRC",
  setSystemStatusOrdered: "SetSystemStatusOrdered",
  showUsageStatistics: "ShowUsageStatistics",
  systemPsychroChart: "SystemPsychroChart",
  canUseDocFormat: "CanUseDocFormat",
  useSCBuilder: "UseSCBuilder",
  updateFromErpAll: "UpdateFromErpAll",
  updateFromErpNew: "UpdateFromErpNew",
  drySize: "DrySize",
  moistureLoadCalculation: "MoistureLoadCalculation",
  mlcSalesOrgSearch: "MLCSalesOrgSearch",
  coilPro: "CoilPro",
  fanPro: "FanPro",
  showLanguageCodes: "ShowLanguageCodes",
  showFieldDefaultUnits: "ShowFieldDefaultUnits",
  nevadaUser: "Nevada",
  salesorgsearch: "SalesOrgSearch",
  canUseSystemSelectionGuide: "CanUseSystemSelectionGuide",
  canUseDrySize: "CanUseDrySize"
};

export const genesysPricingClaims = {
  canUsePricing: "CanUsePricing",
  canSeePricing: "CanSeePricing",
  canSeePricingCost: "CanSeePricingCost",
  CanSeePricingListPrice: "CanSeePricingListPrice",
  CanSeePricingTransferPrice: "CanSeePricingTransferPrice",
  allowArticleNoEdit: "AllowArticleNoEdit",
  allowArticleNoUse: "AllowArticleNoUse"
};

export const systemTypeClaims = {
  create: "SystemTypeCreate",
  rangeCreate: "SystemTypeRangeCreate",
  search: "SystemTypeSearch",
  calculateEnergy: "systemtypecalculateenergy",
  calculateMcompare: "systemtypecalculatemcompare",
  canUseBlackBoxComponent: "SystemTypeCanUseBlackboxComponent",
  dataCenterWizard: "DataCenterWizard"
};

export const maintenanceClaims = {
  login: "Login",
  topLevelEdit: "TopLevelEdit",
  topLevelRead: "TopLevelRead"
};

export const priceClaims = {
  canSeeMasterSettings: "CanSeeMasterSettings",
  canSeeProductPricePreview: "CanSeeProductPricePreview"
};
