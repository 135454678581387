import * as React from "react";
import * as SharedState from "../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as StatusIcon from "../../status-icon-manager";
import styled from "styled-components";
import { MoistureLoadStatus } from "@genesys/shared/lib/enums/moisture-load-status";
import { StandardButton, DropDownButton, Confirm } from "@genesys/ui-elements/";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "../state";
import { getCurrentSelectedLoads } from "../main-content/load-types";

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 35px;
`;

export function CtaButton({
  canLock,
  systemConfiguration,
  systemOverPressure,
  hasValidDefs,
  mainState,
  status,
  sharedState,
  isDeleted,
  dispatch
}: {
  readonly systemConfiguration: number;
  readonly systemOverPressure: number;
  readonly hasValidDefs: boolean;
  readonly mainState: State;
  readonly status: number;
  readonly sharedState: SharedState.State;
  readonly canLock: boolean;
  readonly isDeleted: boolean;
  readonly dispatch: Dispatch<Action>;
}) {
  const statusIcon = (
    <StatusIcon.StatusIconManager
      sharedState={sharedState}
      statusNumber={status}
      type="moistureload"
    />
  );

  const [isOpen, setOpen] = React.useState(false);
  const myRef = React.useRef<HTMLDivElement>(null);

  const loadSelectionOk =
    (systemConfiguration !== 0 &&
      systemOverPressure === 0 &&
      getCurrentSelectedLoads(
        mainState.mainContentState?.loadTypesContentState!
      ).some(l => l.moistureAdded)) ||
    (systemConfiguration !== 0 && systemOverPressure === 1) ||
    (systemConfiguration === 0 &&
      getCurrentSelectedLoads(
        mainState.mainContentState?.loadTypesContentState!
      ).length);

  const renderDeletedButton = () => (
    <StandardButton buttonType="SecondaryBlue" disabled size="Large">
      deleted
    </StandardButton>
  );

  const renderLockedButton = () => (
    <FlexContainer>
      {statusIcon}
      <StandardButton buttonType="SecondaryBlue" disabled size="Large">
        {sharedState.translate(LanguageTexts.locked())}
      </StandardButton>
    </FlexContainer>
  );

  const renderLockAndConfirmButtons = () => (
    <FlexContainer>
      {statusIcon}
      <div ref={myRef}>
        <StandardButton
          onClick={() => {
            setOpen(true);
          }}
          buttonType="SecondaryBlue"
          size="Large"
        >
          {sharedState.translate(LanguageTexts.lockRevision())}
        </StandardButton>
      </div>

      <Confirm
        headerText={sharedState.translate(LanguageTexts.confirmation())}
        labelRef={myRef}
        cancelTranslated={sharedState.translate(LanguageTexts.cancel())}
        confirmTranslated={sharedState.translate(LanguageTexts.confirm())}
        showDialog={isOpen}
        onConfirm={() => {
          dispatch(Action.lockMoistureLoad());
          setOpen(false);
        }}
        onCancel={() => setOpen(false)}
      />
    </FlexContainer>
  );

  const renderCalculateButton = () => (
    <FlexContainer>
      {statusIcon}
      <StandardButton
        onClick={() => dispatch(Action.calculate())}
        buttonType="PrimaryGreen"
        disabled={
          !!mainState.calculating ||
          mainState.showLoader ||
          !hasValidDefs ||
          !loadSelectionOk
        }
        size="Large"
      >
        {sharedState.translate(LanguageTexts.calculate())}
      </StandardButton>
    </FlexContainer>
  );

  const renderCalculateAndSaveButtons = () => (
    <FlexContainer>
      {statusIcon}
      <DropDownButton
        buttonType="PrimaryGreen"
        disabled={
          !!mainState.calculating ||
          mainState.showLoader ||
          !hasValidDefs ||
          !loadSelectionOk
        }
        options={[
          {
            value: sharedState.translate(
              status === MoistureLoadStatus.Saved ||
                status === MoistureLoadStatus.Created
                ? LanguageTexts.globalPropertyName("calculate")
                : LanguageTexts.globalPropertyName("mlcreccalculate")
            ),
            onClickHandler: () => dispatch(Action.calculate())
          },
          {
            value: sharedState.translate(LanguageTexts.save()),
            onClickHandler: () => dispatch(Action.saveInputs())
          }
        ]}
      />
    </FlexContainer>
  );

  if (isDeleted) {
    return renderDeletedButton();
  } else if (status === MoistureLoadStatus.LockSuccess) {
    return renderLockedButton();
  } else if (status === MoistureLoadStatus.CalculationSuccess && canLock) {
    return renderLockAndConfirmButtons();
  } else {
    if (status === MoistureLoadStatus.Saved && !mainState.inputsUpdated) {
      return renderCalculateButton();
    }
    return renderCalculateAndSaveButtons();
  }
}
