import * as React from "react";
import { Environment } from "../../config";
import { exhaustiveCheck } from "ts-exhaustive-check";

export function ColoredBorder({
  env
}: {
  readonly env: Environment;
}): JSX.Element {
  switch (env) {
    case "Localhost":
      return <div style={{ backgroundColor: "#A020F0", height: "8px" }} />;
    case "Develop": {
      return <div style={{ backgroundColor: "#46D580", height: "8px" }} />;
    }
    case "Staging": {
      return <div style={{ backgroundColor: "#F0C530", height: "8px" }} />;
    }
    case "Production": {
      return <></>;
    }
    default: {
      return exhaustiveCheck(env);
    }
  }
}
