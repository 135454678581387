import React from "react";
import { TableCell, TableRow, TableHead } from "../../atoms/table";
import { StyledTable } from "./styled-table";
import { PrimaryColors } from "../../colors";
import { GenericTableColumnDefinition, GenericTableRow, Props } from "./types";

export function GenericTable<T extends Record<keyof T, string | number>>(
  props: Props<T>
) {
  const alignHeader = props.defaultHeaderAlignment || "left";
  const alignRows = props.defaultRowAlignment || "left";

  return (
    <StyledTable alignRows={alignRows} alignHeader={alignHeader}>
      <TableHeader {...props} />
      <TableBody {...props} />
    </StyledTable>
  );
}

function TableBody<T extends Record<string, string | number>>(props: Props<T>) {
  const { data } = props;
  return (
    <tbody>
      {data.map(row => (
        <GenericTableRow key={row.rowId} row={row} {...props} />
      ))}
    </tbody>
  );
}
function GenericTableRow<T extends Record<string, string | number>>({
  row,
  columns,
  higlightedRowStyle,
  rowOnClick,
  rowOnHover
}: {
  readonly row: GenericTableRow<T>;
  readonly columns: Array<GenericTableColumnDefinition<T>>;
  readonly higlightedRowStyle?: React.CSSProperties;
  readonly rowOnClick?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    row: GenericTableRow<T>
  ) => void;
  readonly rowOnHover?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    row: GenericTableRow<T>
  ) => void;
}) {
  return (
    <TableRow
      rowStyle={getHighlitedRowStyle(!!row.highlighted, higlightedRowStyle)}
      onClick={event => {
        if (rowOnClick) {
          rowOnClick(event, row);
        }
      }}
      onMouseOver={event => {
        if (rowOnHover) {
          rowOnHover(event, row);
        }
      }}
    >
      {columns.map(column => {
        const cell = row.rowValues[column.key];
        const defaultCellRender = (val: string | number) => val;
        const cellRenderer = column.customCellRenderer || defaultCellRender;

        return (
          <TableCell
            key={cell.cellId}
            align={column.align}
            bold={cell.isBold || row.isBold}
          >
            {!cell.isHidden && cellRenderer(cell.value)}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function TableHeader<T extends Record<string, string | number>>({
  columns
}: Props<T>) {
  return (
    <thead>
      <TableRow>
        {columns.map((column, i) => (
          <TableHead width={column.width} align={column.align} key={i}>
            {column.header}
          </TableHead>
        ))}
      </TableRow>
    </thead>
  );
}

function getHighlitedRowStyle(
  rowIsHighlighted: boolean,
  higlightedRowStyle?: React.CSSProperties
): React.CSSProperties {
  if (rowIsHighlighted) {
    if (higlightedRowStyle) {
      return higlightedRowStyle;
    }
    return { backgroundColor: PrimaryColors.blue25 };
  }

  return {};
}
