import * as Types from "./types";
import { PropertyValueSet } from "@genesys/property";
import * as SystemStatus from "@genesys/shared/lib/enums/system-status";
import * as OpcPreProcess from "./steps/operating-cases/opc-pre-process";

export function steps(
  systemTypeId: string,
  newProperties: PropertyValueSet.PropertyValueSet
): Types.SystemTypeRegistryEntry {
  switch (systemTypeId) {
    case "DSP":
      return {
        steps: ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false,
        extraProps: {
          opcPreProcess: OpcPreProcess.dspPreProcessOpc
        }
      };
    case "MCD":
      return {
        steps: ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false,
        extraProps: {
          opcPreProcess: OpcPreProcess.mcdPreProcessOpc
        }
      };
    case "MXN":
      return {
        steps:
          PropertyValueSet.getInteger("systemarrangement", newProperties) === 0
            ? ["initial-configuration", "operating-cases", "dhu-size-selector"]
            : ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false,
        extraProps: { opcPreProcess: OpcPreProcess.mxnPreProcessOpc }
      };
    case "MXO":
      return {
        steps:
          PropertyValueSet.getInteger("systemarrangement", newProperties) === 0
            ? ["initial-configuration", "operating-cases", "dhu-size-selector"]
            : ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false,
        extraProps: { opcPreProcess: OpcPreProcess.mxoPreProcessOpc }
      };
    case "ICE":
      return {
        steps: ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false,
        extraProps: { opcPreProcess: OpcPreProcess.mlpPreProcessOpc } // TODO: See if this is still needed
      };
    case "MLP":
      return {
        steps:
          PropertyValueSet.getInteger("systemarrangement", newProperties) === 0
            ? ["initial-configuration", "operating-cases", "dhu-size-selector"]
            : ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false,
        extraProps: { opcPreProcess: OpcPreProcess.mlpPreProcessOpc }
      };
    case "FAA":
    case "FAU":
    case "HUM":
      return {
        steps: [
          "initial-configuration",
          "operating-cases",
          "hum-size-selector"
        ],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "DCX":
    case "DEB":
    case "DCA":
      return {
        steps: [
          "initial-configuration",
          "operating-cases",
          "data-center-size-selector"
        ],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "ECC":
      return {
        steps: [
          "initial-configuration",
          "operating-cases",
          "ecc-size-selector"
        ],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "ICA":
      return {
        steps: ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false,
        extraProps: { opcPreProcess: OpcPreProcess.icaPreProcessOpc }
      };

    case "HCD":
      return {
        steps: ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false,
        extraProps: { opcPreProcess: OpcPreProcess.hcdPreProcessOpc }
      };
    case "LDP":
      return {
        steps: ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false,
        extraProps: { opcPreProcess: OpcPreProcess.ldpPreProcessOpc }
      };
    case "SPA":
    case "PST":
    case "HCU":
    case "GES":
    case "MCA":
    case "MCX":
    case "HCD":
    case "MAA":
    case "MAB":
    case "HCX":
    case "DDS":
    case "BXA":
    case "DSS":
    case "MXO":
    case "DES":
    case "HYG":
    case "DS1":
    case "EPC":
      return {
        steps: ["initial-configuration", "operating-cases"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "EPX":
      return {
        steps: [],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    case "SB1":
      return {
        steps: ["initial-configuration"],
        targetStatus: SystemStatus.SystemStatus.PriceCalculationSuccess,
        generateBinCases: false,
        addMCompare: false
      };
    default:
      throw new Error("System type '" + systemTypeId + "' is not registred");
  }
}

export const isRegisteredSystemType = (systemType: string) => {
  try {
    steps(systemType, PropertyValueSet.Empty);
    return true;
  } catch (err) {
    return false;
  }
};
