import * as React from "react";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Futura"
  }
});

export function MaterialFontFamilyOverride({
  children
}: {
  readonly children: React.ReactNode;
}): JSX.Element {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
