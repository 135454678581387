import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";
const showOutdoorAirSource = "showoutdoorairsource";
const showReturnAirSource = "showreturnairsource";
const showCustomAirSource = "showcustomairsource";
const premMixingBoxExists = "premixingboxexists";
const preCoolerCount = "precoolercount";
const preHeaterExists = "preheaterexists";
const postCoolerOrHeater = "postcoolerorheater";
const dhuExists = "doesdhexist";

export function icaPreProcessOpc(
  newProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let pvs = PropertyValueSet.Empty;

  // DHU or Inline?
  pvs = PropertyValueSet.merge(
    {
      [dhuExists]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("icanewbasearrangement!=999")!
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Show Outdoor air source
  // for react -> always so no filter needed.
  pvs = PropertyValueSet.merge(
    {
      [showOutdoorAirSource]: PropertyValue.fromInteger(1)
    },
    pvs
  );

  // Show Return air source
  pvs = PropertyValueSet.merge(
    {
      [showReturnAirSource]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("inletairsource=2")!
        ) ||
          PropertyFilter.isValid(
            newProperties,
            PropertyFilter.fromStringOrEmpty("icanewbasearrangement=2,4,5,8,9")!
          )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Show Custom air source
  // always 0
  pvs = PropertyValueSet.merge(
    {
      [showCustomAirSource]: PropertyValue.fromInteger(0)
    },
    pvs
  );

  // Pre mixing box exists
  pvs = PropertyValueSet.merge(
    {
      [premMixingBoxExists]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("icanewbasearrangement=2,4,5,8,9")!
        ) ||
          PropertyFilter.isValid(
            newProperties,
            PropertyFilter.fromStringOrEmpty(
              "icanewbasearrangement=999&mixedair=1"
            )!
          )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Pre heater exists
  // Not for any ICA arrangement
  pvs = PropertyValueSet.merge(
    {
      [preHeaterExists]: PropertyValue.fromInteger(0)
    },
    pvs
  );

  // Pre cooler count
  // Only 1 in arrangments
  pvs = PropertyValueSet.merge(
    {
      [preCoolerCount]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("icanewbasearrangement=3,5,7,8,9")
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Post heater or cooler exists
  pvs = PropertyValueSet.merge(
    {
      [postCoolerOrHeater]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty(
            "icanewbasearrangement=1,2,7,8,9,999"
          )
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  return pvs;
}
