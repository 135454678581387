import * as KnownProperties from "../known-properties";
import { PropertyValue, PropertyValueSet } from "@genesys/property";

import { Amount, Units, Quantity } from "@genesys/uom";
import { getIfIntegerChanged, getIfPropertyChanged } from "../helper-functions";
import { OperatingCaseGroupEnum } from "../../../graphql-types";
export const manualInputProperty = KnownProperties.infiltrationModelManualInput;

export const properties = [
  KnownProperties.infiltrationModelManualInput,
  KnownProperties.infiltrationAirSource,
  KnownProperties.infiltrationAirTemperature,
  KnownProperties.infiltrationUseOutdoorAirHumidity,
  KnownProperties.infiltrationAirHumidity,
  KnownProperties.infiltrationModel,
  KnownProperties.useManualAirChanges,
  KnownProperties.airChanges,
  KnownProperties.windProtectionType,
  KnownProperties.useManualWindProtectionCoefficient,
  KnownProperties.windProtectionCoefficient
];

export const overridableProperties = [
  KnownProperties.infiltrationAirTemperature,
  KnownProperties.infiltrationAirHumidity
];

export const caseOverridesDefault: {
  readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
} = {
  DESIGN_SUMMER: PropertyValueSet.Empty,
  DESIGN_WINTER: PropertyValueSet.Empty,
  JANUARY: PropertyValueSet.Empty,
  FEBRUARY: PropertyValueSet.Empty,
  MARCH: PropertyValueSet.Empty,
  APRIL: PropertyValueSet.Empty,
  MAY: PropertyValueSet.Empty,
  JUNE: PropertyValueSet.Empty,
  JULY: PropertyValueSet.Empty,
  AUGUST: PropertyValueSet.Empty,
  SEPTEMBER: PropertyValueSet.Empty,
  OCTOBER: PropertyValueSet.Empty,
  NOVEMBER: PropertyValueSet.Empty,
  DECEMBER: PropertyValueSet.Empty
};

export function getPropertyUpdates(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = getPropertyDefaults(prevProperties, selectedProperties);

  return defaults;
}

function getPropertyDefaults(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = PropertyValueSet.Empty;

  // Room location
  const buildingLocation = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.buildingLocation
  );

  if (buildingLocation !== undefined) {
    defaults = PropertyValueSet.merge(roomLocationDefaults(), defaults);
  }

  const buildingLength = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.buildingLength
  );

  const buildingHeight = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.buildingHeight
  );

  const buildingWidth = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.buildingWidth
  );

  // Infiltration model
  // Air sources for infiiltration
  const infiltrationAirSource = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.infiltrationAirSource
  );

  if (infiltrationAirSource !== undefined) {
    defaults = PropertyValueSet.merge(
      infiltrationAirSourceDefaults(infiltrationAirSource),
      defaults
    );
  }

  let infiltrationUseOutdoorAirHumidity = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.infiltrationUseOutdoorAirHumidity
  );

  if (infiltrationUseOutdoorAirHumidity !== undefined) {
    defaults = PropertyValueSet.merge(
      infiltrationUseOutdoorAirHumidityDefaults(
        infiltrationUseOutdoorAirHumidity,
        PropertyValueSet.merge(defaults, currentSelections)
      ),
      defaults
    );
  }

  const infiltrationModel = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.infiltrationModel
  );

  if (infiltrationModel !== undefined) {
    defaults = PropertyValueSet.merge(
      infiltrationModelDefaults(
        PropertyValueSet.merge(defaults, currentSelections)
      ),
      defaults
    );
  }

  // wind protection
  const windProtectionType = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.windProtectionType
  );
  if (windProtectionType !== undefined) {
    defaults = PropertyValueSet.merge(
      setWindProtectionCoefficient(
        PropertyValueSet.merge(defaults, currentSelections)
      ),
      defaults
    );
  }

  // Air exchanges
  if (
    buildingLength !== undefined ||
    buildingHeight !== undefined ||
    buildingWidth !== undefined
  ) {
    defaults = PropertyValueSet.merge(
      updateAirChanges(PropertyValueSet.merge(defaults, currentSelections)),
      defaults
    );
  }
  return defaults;
}

function infiltrationUseOutdoorAirHumidityDefaults(
  propertyValue: number,
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 1:
    default:
      return {
        [KnownProperties.infiltrationAirHumidity]: PropertyValue.fromAmount(
          Amount.create(0, Units.GramPerKilogram)
        )
      };
    case 0:
      return {
        [KnownProperties.infiltrationAirHumidity]: PropertyValue.fromAmount(
          PropertyValueSet.getInteger(
            KnownProperties.infiltrationAirSource,
            currentSelections
          ) === 3
            ? Amount.create(8, Units.GramPerKilogram)
            : Amount.create(0, Units.GramPerKilogram)
        )
      };
  }
}

function infiltrationAirSourceDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
    case 1:
    case 2:
    default:
      return {
        [KnownProperties.infiltrationAirTemperature]: PropertyValue.fromAmount(
          Amount.create(0, Units.Celsius)
        ),
        [KnownProperties.infiltrationUseOutdoorAirHumidity]:
          PropertyValue.fromInteger(0),
        [KnownProperties.infiltrationAirHumidity]: PropertyValue.fromAmount(
          Amount.create(0, Units.GramPerKilogram)
        )
      };
    case 3:
      return {
        [KnownProperties.infiltrationAirTemperature]: PropertyValue.fromAmount(
          Amount.create(15, Units.Celsius)
        ),
        [KnownProperties.infiltrationUseOutdoorAirHumidity]:
          PropertyValue.fromInteger(1),
        [KnownProperties.infiltrationAirHumidity]: PropertyValue.fromAmount(
          Amount.create(0, Units.GramPerKilogram)
        )
      };
  }
}
function roomLocationDefaults(): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.infiltrationAirSource]: PropertyValue.fromInteger(1)
  };
}

function infiltrationModelDefaults(
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  switch (
    PropertyValueSet.getInteger(
      KnownProperties.infiltrationModel,
      currentSelections
    )
  ) {
    case 0:
      return {
        [KnownProperties.useManualAirChanges]: PropertyValue.fromInteger(0),
        [KnownProperties.windProtectionType]: PropertyValue.fromInteger(0),
        [KnownProperties.useManualWindProtectionCoefficient]:
          PropertyValue.fromInteger(0),
        [KnownProperties.airChanges]: PropertyValue.fromAmount(
          Amount.create(0.1, Units.OnePerHour)
        )
      };
    case 1:
      return {
        [KnownProperties.useManualAirChanges]: PropertyValue.fromInteger(0),
        [KnownProperties.windProtectionType]: PropertyValue.fromInteger(0),
        [KnownProperties.useManualWindProtectionCoefficient]:
          PropertyValue.fromInteger(0),
        [KnownProperties.airChanges]: PropertyValue.fromAmount(
          getAirChanges(currentSelections)
        )
      };
    case 2:
      return {
        [KnownProperties.useManualAirChanges]: PropertyValue.fromInteger(0),
        [KnownProperties.windProtectionType]: PropertyValue.fromInteger(2),
        [KnownProperties.useManualWindProtectionCoefficient]:
          PropertyValue.fromInteger(0),
        [KnownProperties.airChanges]: PropertyValue.fromAmount(
          Amount.create(0.1, Units.OnePerHour)
        )
      };
    default:
      throw new Error("Invalid value.");
  }
}
function updateAirChanges(
  selections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  return PropertyValueSet.getInteger(
    KnownProperties.useManualAirChanges,
    selections
  )
    ? PropertyValueSet.Empty
    : {
        [KnownProperties.airChanges]: PropertyValue.fromAmount(
          getAirChanges(selections)
        )
      };
}
function getAirChanges(
  selections: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.DimensionlessPerDuration> {
  if (
    PropertyValueSet.getInteger(
      KnownProperties.infiltrationModel,
      selections
    ) !== 1
  ) {
    return Amount.create(0.1, Units.OnePerHour);
  }

  const length = Amount.valueAs(
    Units.Meter,
    PropertyValueSet.getAmount<Quantity.Length>(
      KnownProperties.buildingLength,
      selections
    ) || Amount.create(0, Units.Meter)
  );

  const width = Amount.valueAs(
    Units.Meter,
    PropertyValueSet.getAmount<Quantity.Length>(
      KnownProperties.buildingWidth,
      selections
    ) || Amount.create(0, Units.Meter)
  );

  const height = Amount.valueAs(
    Units.Meter,
    PropertyValueSet.getAmount<Quantity.Length>(
      KnownProperties.buildingHeight,
      selections
    ) || Amount.create(0, Units.Meter)
  );

  const volume = length * width * height;

  return Amount.create(
    volume <= 500
      ? 0.3
      : volume <= 1000
      ? 0.3 - (0.1 * (volume - 500)) / 500
      : volume <= 5000
      ? 0.2
      : volume <= 10000
      ? 0.2 - (0.1 * (volume - 5000)) / 5000
      : 0.1,
    Units.OnePerHour
  );
}

function setWindProtectionCoefficient(
  selections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  return PropertyValueSet.getInteger(
    KnownProperties.useManualWindProtectionCoefficient,
    selections
  )
    ? PropertyValueSet.Empty
    : {
        [KnownProperties.windProtectionCoefficient]: PropertyValue.fromAmount(
          getWindProtectionCoefficient(
            PropertyValueSet.getInteger(
              KnownProperties.windProtectionType,
              selections
            ) || 0
          )
        )
      };
}

function getWindProtectionCoefficient(value: number) {
  return Amount.create([0, 1.15, 1, 0.85, 0.7, 0.4][value] || 0, Units.One);
}
