import gql from "graphql-tag";

export const calculateLockedSystem = gql`
  query CalculateLockedSystem(
    $lockedSystemInput: CalculateLockedSystemInputType!
  ) {
    user {
      calculateLockedSystem(lockedSystemInput: $lockedSystemInput) {
        hasResults
        operatingCases {
          id
          caseName
          customCaseName
          caseType
          settings
          binData
          sortNo
          results {
            id
            componentId
            calculationType
            settings
            isDesignCase
            sortNo
          }
        }
      }
    }
  }
`;
