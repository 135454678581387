import { H3, InfoGray, P1, GenesysSelect } from "@genesys/ui-elements";
import * as React from "react";
import * as SharedState from "../../shared-state";
import { CaseType } from "../types";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import {
  ComponentContainer,
  InputContainer,
  StyledLabel,
  DescriptionOuterContainer,
  DescriptionInnerContainer,
  DescriptionPopup
} from "../elements";
import {
  ClimateDataType,
  AnnualOccurance,
  DataPoint,
  CoolingClimateDataType,
  HeatingClimateDataType,
  CoolingAnnualOccurance,
  DataCenterCoolingClimateDataType,
  HeatingAnnualOccurance,
  Location
} from "../types";
import { datacenterCoolingClimateDataTypes, getDataPoints } from "../functions";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { AmountPropertySelector } from "../../amount-property-selector";
import { Amount, Quantity, Unit } from "@genesys/uom";
import * as QuantityConversion from "@genesys/shared/lib/quantity-conversion";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { exhaustiveCheck } from "ts-exhaustive-check";

const heatingClimateDataTypes: HeatingClimateDataType[] = ["DB", "DP/MCDB"];
const coolingClimateDataTypes: CoolingClimateDataType[] = [
  "DB/MCWB",
  "WB/MCDB",
  "DP/MCDB",
  "h/MCDB"
];

const heatingAnnualOccurances: HeatingAnnualOccurance[] = ["99 %", "99.6 %"];
const coolingAnnualOccurances: CoolingAnnualOccurance[] = [
  "0.4 %",
  "1 %",
  "2 %"
];

const nYears = [5, 10, 20, 50];

const genesysSelectStylingProps = {
  width: 14,
  height: 25,
  fontSize: 13
};

export function CaseComponent({
  climateSettings,
  dataPoints,
  caseType,
  sharedState,
  isManualDataSource,
  showWindVelocity,
  isDisabled,
  selectedMonth,
  currentLocation,
  onMonthChange,
  onSettingChange,
  onFormatChanged,
  onFormatCleared,
  translate
}: {
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
  readonly dataPoints: ReadonlyArray<DataPoint>;
  readonly currentLocation: Location;
  readonly caseType: CaseType;
  readonly sharedState: SharedState.State;
  readonly isDisabled: boolean;
  readonly isManualDataSource: boolean;
  readonly showWindVelocity: boolean;
  readonly selectedMonth: string | undefined;
  readonly onMonthChange: (mon: string | undefined) => void;
  readonly onSettingChange: (
    newClimateSettings: PropertyValueSet.PropertyValueSet
  ) => void;
  readonly onFormatChanged: (
    fieldgroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldgroup: string, fieldName: string) => void;
  readonly translate: (arg: LanguageTexts.StaticText) => string;
}) {
  const climateDataTypes =
    caseType === "H"
      ? heatingClimateDataTypes
      : [...coolingClimateDataTypes, ...datacenterCoolingClimateDataTypes];

  const selectedClimateDataType = PropertyValueSet.getText(
    caseType === "H" ? "climateheatingdatatype" : "climatecoolingdatatype",
    climateSettings
  )! as ClimateDataType;

  const selectedNYear =
    PropertyValueSet.getInteger("climatenyearextreme", climateSettings) ||
    nYears[0];

  const temperaturePropertyName =
    caseType === "H" ? "wintertemperature" : "summertemperature";
  const humidityPropertyName =
    caseType === "H" ? "winterhumidity" : "summerhumidity";
  const windspeedPropertyName =
    caseType === "H" ? "winterwindspeed" : "summerwindspeed";

  const temperature = PropertyValueSet.getAmount<Quantity.Temperature>(
    temperaturePropertyName,
    climateSettings
  )!;

  const pressure = PropertyValueSet.getAmount<Quantity.Pressure>(
    "atmosphericpressure",
    climateSettings
  )!;

  const getPossibleAnnualOccurences: () => AnnualOccurance[] = () => {
    if (caseType === "H") {
      return heatingAnnualOccurances;
    } else if (
      datacenterCoolingClimateDataTypes.includes(selectedClimateDataType as any)
    ) {
      switch (selectedClimateDataType as DataCenterCoolingClimateDataType) {
        case "DB/MCWB-MONTHLY-PEAK":
        case "WB/MCDB-MONTHLY-PEAK": {
          return ["0.4 %"];
        }

        default:
          return [];
      }
    }

    return coolingAnnualOccurances;
  };

  const onHumidityChange = (
    propertyName: string,
    climateSettings: PropertyValueSet.PropertyValueSet,
    newAmount: Amount.Amount<Quantity.Quantity>,
    conversionParamaters: QuantityConversion.ConversionParameters
  ) => {
    const originalQuantity = PropertyValueSet.getAmount(
      propertyName,
      climateSettings
    )!.unit.quantity;

    if (newAmount.unit.quantity === originalQuantity) {
      onSettingChange(
        PropertyValueSet.set(
          propertyName,
          PropertyValue.fromAmount(newAmount),
          climateSettings
        )
      );
    } else {
      const convertedAmount = QuantityConversion.convertQuantity(
        newAmount,
        originalQuantity as Quantity.Quantity,
        conversionParamaters as QuantityConversion.ConversionParameters
      );
      onSettingChange(
        PropertyValueSet.set(
          propertyName,
          PropertyValue.fromAmount(convertedAmount),
          climateSettings
        )
      );
    }
  };

  const annualOccurances: AnnualOccurance[] = getPossibleAnnualOccurences();

  const selectedAnnualOccurance = PropertyValueSet.getText(
    caseType === "H" ? "heatingannualoccurence" : "coolingannualoccurence",
    climateSettings
  );

  const showMonth = ["DB/MCWB-MONTHLY-PEAK", "WB/MCDB-MONTHLY-PEAK"].includes(
    selectedClimateDataType as any
  );

  return (
    <>
      <ComponentContainer>
        <H3 weight="normal" color="dark">
          {caseType === "H" ? "Heating" : "Cooling"}
        </H3>

        {!isManualDataSource && (
          <>
            <InputContainer>
              <Description
                climateDataTypes={climateDataTypes}
                caseType={caseType}
                translate={sharedState.translate}
              />
              <StyledLabel weight="normal" color="secondary">
                {translate(LanguageTexts.dataType())}
              </StyledLabel>
              <GenesysSelect
                {...genesysSelectStylingProps}
                disabled={isDisabled}
                value={selectedClimateDataType}
                options={climateDataTypes.map(cdt => {
                  return {
                    title: getDataTypesDisplayedText(cdt),
                    value: cdt
                  };
                })}
                onChange={e =>
                  onSettingChange(
                    getClimateSettingsForCaseChange(
                      currentLocation,
                      climateSettings,
                      dataPoints,
                      caseType,
                      e.target.value,
                      onMonthChange,
                      undefined,
                      selectedNYear
                    )
                  )
                }
              />
            </InputContainer>
            <InputContainer>
              {selectedClimateDataType === "N-YEAR-MAX-DB/WB" ||
              selectedClimateDataType === "N-YEAR-MAX-DB/EXTREME-MAX-WB" ? (
                <>
                  <StyledLabel weight="normal" color="secondary">
                    years
                  </StyledLabel>
                  <GenesysSelect
                    {...genesysSelectStylingProps}
                    value={selectedNYear}
                    disabled={isDisabled}
                    options={nYears.map(n => {
                      return { title: n.toString(), value: n.toString() };
                    })}
                    onChange={e =>
                      onSettingChange(
                        getClimateSettingsForCaseChange(
                          currentLocation,
                          climateSettings,
                          dataPoints,
                          caseType,
                          selectedClimateDataType,
                          onMonthChange,
                          undefined,
                          parseInt(e.target.value, 10)
                        )
                      )
                    }
                  />
                </>
              ) : (
                <>
                  <StyledLabel weight="normal" color="secondary">
                    Annual Occurence
                  </StyledLabel>
                  <GenesysSelect
                    {...genesysSelectStylingProps}
                    value={selectedAnnualOccurance}
                    disabled={isDisabled}
                    options={annualOccurances.map(ao => {
                      return { title: ao, value: ao };
                    })}
                    onChange={e =>
                      onSettingChange(
                        getClimateSettingsForCaseChange(
                          currentLocation,
                          climateSettings,
                          dataPoints,
                          caseType,
                          selectedClimateDataType,
                          onMonthChange,
                          e.target.value,
                          selectedNYear
                        )
                      )
                    }
                  />
                </>
              )}
            </InputContainer>
            {showMonth && (
              <InputContainer>
                <StyledLabel weight="normal" color="secondary">
                  Selected Month
                </StyledLabel>

                <StyledLabel weight="normal" color="secondary">
                  {selectedMonth}
                </StyledLabel>
              </InputContainer>
            )}
          </>
        )}

        <InputContainer>
          <StyledLabel weight="normal" color="secondary">
            {translate(LanguageTexts.temperature())}
          </StyledLabel>
          <AmountPropertySelector
            type="with-simplified-props"
            translate={translate}
            fieldGroup="ClimateDataCaseDialog"
            fieldName={temperaturePropertyName}
            readOnly={!isManualDataSource || isDisabled}
            propertyName={temperaturePropertyName}
            propertyValueSet={climateSettings}
            onFormatChanged={(unit, decimalCount) =>
              onFormatChanged(
                "ClimateDataCaseDialog",
                temperaturePropertyName,
                unit,
                decimalCount
              )
            }
            onFormatCleared={() =>
              onFormatCleared("ClimateDataCaseDialog", temperaturePropertyName)
            }
            onValueChange={value =>
              value
                ? onSettingChange(
                    PropertyValueSet.set(
                      temperaturePropertyName,
                      PropertyValue.fromAmount(value),
                      climateSettings
                    )
                  )
                : climateSettings
            }
            getAmountFormat={sharedState.screenAmounts.getAmountFormat}
            quantity="Temperature"
          />
        </InputContainer>

        <InputContainer>
          <StyledLabel weight="normal" color="secondary">
            {translate(LanguageTexts.humidity())}
          </StyledLabel>
          <AmountPropertySelector
            type="with-simplified-props"
            fieldGroup="ClimateDataCaseDialog"
            fieldName={humidityPropertyName}
            quantity="HumidityRatio"
            readOnly={!isManualDataSource || isDisabled}
            propertyName={humidityPropertyName}
            propertyValueSet={climateSettings}
            onFormatChanged={(unit, decimalCount) =>
              onFormatChanged(
                "ClimateDataCaseDialog",
                humidityPropertyName,
                unit,
                decimalCount
              )
            }
            onFormatCleared={() =>
              onFormatCleared("ClimateDataCaseDialog", humidityPropertyName)
            }
            onValueChange={value =>
              value
                ? onHumidityChange(
                    humidityPropertyName,
                    climateSettings,
                    value,
                    {
                      temperature: temperature,
                      pressure: pressure,
                      density: undefined,
                      humidityRatio: undefined
                    }
                  )
                : climateSettings
            }
            getAmountFormat={sharedState.screenAmounts.getAmountFormat}
            translate={translate}
            conversionParameters={{
              temperature: temperature,
              pressure: pressure,
              density: undefined,
              humidityRatio: undefined
            }}
          />
        </InputContainer>

        {showWindVelocity && (
          <InputContainer>
            <StyledLabel weight="normal" color="secondary">
              Wind speed
            </StyledLabel>
            <AmountPropertySelector
              type="with-simplified-props"
              fieldGroup="ClimateDataCaseDialog"
              fieldName={windspeedPropertyName}
              quantity="Velocity"
              readOnly={!isManualDataSource || isDisabled}
              propertyName={windspeedPropertyName}
              propertyValueSet={climateSettings}
              onFormatChanged={(unit, decimalCount) =>
                onFormatChanged(
                  "ClimateDataCaseDialog",
                  windspeedPropertyName,
                  unit,
                  decimalCount
                )
              }
              onFormatCleared={() =>
                onFormatCleared("ClimateDataCaseDialog", windspeedPropertyName)
              }
              onValueChange={value =>
                value
                  ? onSettingChange(
                      PropertyValueSet.set(
                        windspeedPropertyName,
                        PropertyValue.fromAmount(value),
                        climateSettings
                      )
                    )
                  : climateSettings
              }
              getAmountFormat={sharedState.screenAmounts.getAmountFormat}
              translate={translate}
            />
          </InputContainer>
        )}
      </ComponentContainer>
    </>
  );
}

function getClimateSettingsForCaseChange(
  location: Location,
  climateSettings: PropertyValueSet.PropertyValueSet,
  datapoints: ReadonlyArray<DataPoint>,
  caseType: CaseType,
  climateDataType: string,
  updateSelectedMonth: (mon: string) => void,
  newAnnualOccurence?: string | undefined,
  newNyearExtreme?: number | undefined
) {
  const annualOccurence =
    newAnnualOccurence ||
    (caseType === "H"
      ? heatingAnnualOccurances[0]
      : coolingAnnualOccurances[0]);

  const isdataCenterCoolingType = datacenterCoolingClimateDataTypes.includes(
    climateDataType as any
  );

  const dataPoint = getDataPoints(
    datapoints,
    location,
    climateDataType,
    isdataCenterCoolingType,
    annualOccurence,
    caseType,
    PropertyValueSet.getAmount("atmosphericpressure", climateSettings)!,
    updateSelectedMonth,
    newNyearExtreme
  );

  let newClimateSettings = PropertyValueSet.setText(
    caseType === "H" ? "climateheatingdatatype" : "climatecoolingdatatype",
    climateDataType,
    climateSettings
  );

  newClimateSettings = PropertyValueSet.setText(
    caseType === "H" ? "heatingannualoccurence" : "coolingannualoccurence",
    annualOccurence,
    newClimateSettings
  );

  newClimateSettings = PropertyValueSet.set(
    caseType === "H" ? "wintertemperature" : "summertemperature",
    dataPoint.temperature,
    newClimateSettings
  );

  newClimateSettings = PropertyValueSet.set(
    caseType === "H" ? "winterhumidity" : "summerhumidity",
    dataPoint.humidity,
    newClimateSettings
  );

  newClimateSettings = PropertyValueSet.set(
    caseType === "H" ? "winterwindspeed" : "summerwindspeed",
    dataPoint.windSpeed,
    newClimateSettings
  );

  if (newNyearExtreme) {
    newClimateSettings = PropertyValueSet.setInteger(
      "climatenyearextreme",
      newNyearExtreme,
      newClimateSettings
    );
  }

  const isNYearDataType = climateDataType.startsWith("N-YEAR");

  newClimateSettings = PropertyValueSet.setText(
    "occurrences",
    (isNYearDataType
      ? PropertyValueSet.getInteger("climatenyearextreme", newClimateSettings) +
        " Year "
      : PropertyValueSet.getText(
          "coolingannualoccurence",
          newClimateSettings
        )) +
      " " +
      getDataTypesDisplayedText(
        PropertyValueSet.getText(
          "climatecoolingdatatype",
          newClimateSettings
        ) as ClimateDataType
      ) +
      ", " +
      PropertyValueSet.getText("heatingannualoccurence", newClimateSettings) +
      " " +
      PropertyValueSet.getText("climateheatingdatatype", newClimateSettings),
    newClimateSettings
  );

  return newClimateSettings;
}

function Description({
  climateDataTypes,
  caseType,
  translate
}: {
  readonly climateDataTypes: ReadonlyArray<ClimateDataType>;
  readonly caseType: CaseType;
  readonly translate: (arg: LanguageTexts.StaticText) => string;
}): JSX.Element {
  const [isOpen, setState] = React.useState(false);

  const getDescription = (climateDataType: ClimateDataType) => {
    let text = "";

    switch (climateDataType) {
      case "DB": {
        text = "heatingdatatypeinteger_3_description";
        break;
      }
      case "DB/MCWB-MONTHLY-PEAK":
      case "DB/MCWB": {
        text = "coolingdatatypeinteger_1_description";
        break;
      }
      case "DP/MCDB": {
        text =
          caseType === "C"
            ? "coolingdatatypeinteger_4_description"
            : "heatingdatatypeinteger_4_description";
        break;
      }
      case "WB/MCDB-MONTHLY-PEAK":
      case "WB/MCDB": {
        text = "coolingdatatypeinteger_2_description";
        break;
      }
      case "h/MCDB": {
        text = "coolingdatatypeinteger_0_description";
        break;
      }

      case "N-YEAR-MAX-DB/WB": {
        text = "notavailable";
        break;
      }

      case "N-YEAR-MAX-DB/EXTREME-MAX-WB": {
        text = "notavailable";
        break;
      }
      default:
        return exhaustiveCheck(climateDataType, true);
    }

    return (
      <tr>
        <td>
          <P1 color="dark" weight="normal">
            {climateDataType + ": "}
          </P1>
        </td>
        <td>
          <P1 color="dark" weight="normal">
            {translate(LanguageTexts.dynamicText(text))}
          </P1>
        </td>
      </tr>
    );
  };

  return (
    <DescriptionOuterContainer>
      <DescriptionInnerContainer>
        <InfoGray onClick={() => setState(!isOpen)} />
        {isOpen && (
          <ClickAwayListener onClickAway={() => setState(false)}>
            <DescriptionPopup>
              <table>
                <tbody>{climateDataTypes.map(c => getDescription(c))}</tbody>
              </table>
            </DescriptionPopup>
          </ClickAwayListener>
        )}
      </DescriptionInnerContainer>
    </DescriptionOuterContainer>
  );
}

function getDataTypesDisplayedText(dataType: ClimateDataType) {
  switch (dataType) {
    case "DB":
    case "h/MCDB":
    case "DB/MCWB":
    case "DP/MCDB":
    case "WB/MCDB": {
      return dataType;
    }
    case "DB/MCWB-MONTHLY-PEAK":
    case "WB/MCDB-MONTHLY-PEAK": {
      return dataType.replace(/-/g, " ");
    }

    case "N-YEAR-MAX-DB/WB": {
      return "N-YEAR MAX DB/WB";
    }

    case "N-YEAR-MAX-DB/EXTREME-MAX-WB": {
      return "N-YEAR MAX DB/EXTREME MAX WB";
    }
    default:
      return exhaustiveCheck(dataType, true);
  }
}

//tslint:disable-next-line
