import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../shared-state";
import * as OperationTimeGen2 from "../../../../operation-time-manager";
import styled from "styled-components";
import { Trash } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { Action, Load } from "../state";
import { OperationTimeView } from "../../../components/operation-time";
import {
  PropertyValueSet
  // PropertyValue
} from "@genesys/property";
import { LoadOperationTime } from "../../type";

const DeleteContainer = styled.div`
  padding: 10px 10px 0 10px;
  margin-bottom: 10px;
`;

const StyledP = styled.p`
  color: rgb(0, 177, 247);
  margin-left: 10px;
  margin-bottom: 5px;
`;

const OperatiomeContainer = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;

  > label {
    margin-right: 17px;
    font-size: 14px;
  }

  > span {
    cursor: pointer;
  }
`;

export function Footer({
  isDisabled,
  load,
  sharedState,
  selectedProperties,
  activeLoadsOperationTime,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly load: Load;
  readonly isDisabled: boolean;
  readonly selectedProperties: PropertyValueSet.PropertyValueSet;
  readonly activeLoadsOperationTime: LoadOperationTime;
  readonly dispatch: Dispatch<Action>;
}) {
  const labelRef = React.useRef<HTMLLabelElement>(null);
  return (
    <>
      <StyledP>
        {sharedState.translate(
          LanguageTexts.globalPropertyName("mlcoperationtime")
        )}
      </StyledP>
      <OperatiomeContainer>
        <label ref={labelRef}>
          {sharedState.translate(
            LanguageTexts.dynamicText(
              "mlc" + OperationTimeGen2.presetName(load.operationTimeState!)
            )
          )}
        </label>
        <OperationTimeView
          labelRef={labelRef}
          sharedState={sharedState}
          dispatch={Dispatch.map(
            action => Action.dispatchOperationTime(action, load.sourceName),
            dispatch
          )}
          readonly={isDisabled}
          operationTimeState={load.operationTimeState}
          allowedSelections={
            load.operationalTime.getAllowedOperationTimes
              ? load.operationalTime.getAllowedOperationTimes(
                  selectedProperties,
                  activeLoadsOperationTime
                )
              : undefined
          }
        />
      </OperatiomeContainer>
      <DeleteContainer>
        <Trash
          disabled={isDisabled}
          onClick={() => {
            dispatch(Action.removeTypesFromLoad(load.sourceName, "both"));
          }}
          height="23px"
        />
      </DeleteContainer>
    </>
  );
}
