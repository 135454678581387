import { exhaustiveCheck } from "ts-exhaustive-check";
import { PropertyValueSet } from "@genesys/property";
import { SystemConfiguration } from "../types";

export function getInitialNewProperties(
  systemConfiguration: SystemConfiguration
) {
  const getAirSourceValue = () => {
    if (systemConfiguration === undefined) {
      return 2;
    }
    switch (systemConfiguration) {
      case "open-system":
        return 1;
      case "closed-system":
      case "closed-system-with-make-up-air":
        return 2;
      default:
        return exhaustiveCheck(systemConfiguration, true);
    }
  };

  const initialNewProperties = PropertyValueSet.fromString(
    `airsource=${getAirSourceValue()};humidityoption=0`
  );

  return initialNewProperties;
}
