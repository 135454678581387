import React from "react";
import { StandardButton } from "../../atoms/button/";
import { Input } from "../../atoms/input";

// tslint:disable-next-line
export function Search() {
  return (
    <div>
      <Input></Input>
      <StandardButton size="Large" buttonType="PrimaryBlue">
        Search
      </StandardButton>
    </div>
  );
}
