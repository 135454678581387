import * as React from "react";
import { Input, StandardButton, H3 } from "@genesys/ui-elements";
import * as SharedState from "../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { InputActionContainer } from "../elements";

interface InputActionProps {
  readonly sharedState: SharedState.State;
  readonly header: string;
  readonly inputValue: string;
  readonly isDisabled: boolean;
  readonly placeHolderText: string;
  readonly onChange: (e: any) => void;
  readonly onSave: () => void;
  readonly onCancel: () => void;
}

export function InputAction({
  sharedState,
  header,
  inputValue,
  isDisabled,
  placeHolderText,
  onChange,
  onSave,
  onCancel
}: InputActionProps) {
  return (
    <InputActionContainer>
      <H3 color="primary" weight="normal">
        {header}
      </H3>
      <Input
        value={inputValue}
        onChange={onChange}
        placeholder={placeHolderText}
      ></Input>
      <div>
        <StandardButton
          buttonType="SecondaryGreen"
          size="Small"
          onClick={onCancel}
        >
          {sharedState.translate(LanguageTexts.cancel())}
        </StandardButton>
        <StandardButton
          buttonType="PrimaryGreen"
          size="Small"
          disabled={isDisabled}
          onClick={() => {
            if (isDisabled) {
              return;
            }
            onSave();
          }}
        >
          {sharedState.translate(LanguageTexts.ok())}
        </StandardButton>
      </div>
    </InputActionContainer>
  );
}
