import styled from "styled-components";
import {
  zIndex,
  P2,
  PrimaryColors,
  StatusError,
  WarningIcon
} from "@genesys/ui-elements";

export const Root = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 50px;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 1000px;
`;

export const CheckBoxContainer = styled.div`
  margin-top: -10px;
  margin-left: 10px;
`;

export const PopupRoot = styled.div`
  display: flex;
  p {
    margin: 0 3px;
  }
`;

export const PopupContainer = styled.div`
  position: relative;
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 80px;
  top: -10px;
  z-index: ${zIndex.Dropdown};
  background-color: white;
  border-radius: 12px;
  border: 2px solid
    ${(props: {
      readonly borderColor: "blue" | "red" | "yellow";
      readonly width: "wide" | "narrow";
    }) =>
      props.borderColor === "blue"
        ? PrimaryColors.muntersBlue
        : props.borderColor === "red"
        ? PrimaryColors.red
        : "#F0C530"};
  padding: 8px 8px 0 8px;

  ${(props: {
    readonly borderColor: "blue" | "red" | "yellow";
    readonly width: "wide" | "narrow";
  }) => (props.width === "narrow" ? "width: 200px" : "max-width: 600px")};

  p {
    word-wrap: break-word;
  }
`;

export const P2WithCursor = styled(P2)`
  cursor: pointer;
`;

export const MainGridContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: ${(props: { readonly isDeveloper: boolean }) =>
    props.isDeveloper
      ? "75px 25px 75px 75px 95px 95px 130px 130px 75px 350px"
      : "75px 25px 75px 75px 95px 95px 130px 130px"};
  grid-auto-rows: minmax(100, auto);
  gap: 10px;
`;

export const SubGridContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${(props: {
    readonly index: number;
    readonly isDeveloper: boolean;
  }) =>
    props.isDeveloper
      ? "75px 25px 75px 75px 95px 95px 130px 130px 75px 350px"
      : "75px 25px 75px 75px 95px 95px 130px 130px"};
  grid-auto-rows: 24px;
  gap: 10px;
  background-color: ${(props: {
    readonly index: number;
    readonly isDeveloper: boolean;
  }) => (props.index % 2 === 0 ? "#f7f9fc" : "white")};
`;

export const GroupCell = styled.div`
  grid-column: 1/10;
  grid-row: ${(props: { readonly y: number }) => 1 / props.y};
`;

export const GridRowsCell = styled.div`
  grid-column: 1/10;
  grid-row: 2;
`;

export const GridRowCell = styled.div`
  display: flex;
  grid-column: ${(props: { readonly x: number; readonly y: number }) =>
    props.x};
  grid-row: ${(props: { readonly x: number; readonly y: number }) => props.y};
`;

export const AmountPropertyContainer = styled.div`
  & * label {
    color: ${PrimaryColors.grey};
    font-size: 0.867rem;
  }
`;

export const ShowInvalidSystemsCheckboxContainer = styled.div`
  margin-left: 24px;
`;

export const CreatedSystemDialogRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: ${zIndex.Modal};
  padding: 12px;
  border: 2px solid ${PrimaryColors.muntersBlue};
  border-radius: 6px;
  background-color: white;
  button {
    margin: 10px 0;
  }
`;

export const RedP2 = styled(P2)`
  color: ${PrimaryColors.red};
`;

export const ErrorIconWithCursor = styled(StatusError)`
  cursor: pointer;
`;
export const WarningIconWithCursor = styled(WarningIcon)`
  cursor: pointer;
`;

export const PaddingBottomContainer = styled.div`
  padding-bottom: 8px;

  div {
    padding-bottom: 4px;
  }
`;

export const MessageContainer = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
`;
