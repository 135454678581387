import * as React from "react";
import { Dispatch } from "@typescript-tea/core";
import { StandardButton } from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../shared-state";
import { State, Action } from "../state";
import { NavigationRoot } from "../elements";

export function Navigation({
  state,
  sharedState,
  showFluidCoils,
  dispatch
}: {
  readonly state: State;
  readonly showFluidCoils: boolean;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  return (
    <NavigationRoot>
      <StandardButton
        size="Large"
        buttonType={
          state.selectedView === "operating-cases"
            ? "PrimaryBlue"
            : "SecondaryBlue"
        }
        disabled={state.isLoading || state.hasCalculationFailed}
        onClick={() => dispatch(Action.setView("operating-cases"))}
      >
        {sharedState.translate(LanguageTexts.operatingCases())}
      </StandardButton>
      {showFluidCoils && (
        <StandardButton
          size="Large"
          buttonType={
            state.selectedView === "fluid-coils"
              ? "PrimaryBlue"
              : "SecondaryBlue"
          }
          disabled={state.isLoading || state.hasCalculationFailed}
          onClick={() => dispatch(Action.setView("fluid-coils"))}
        >
          {"Fluid Coils"}
        </StandardButton>
      )}
      <StandardButton
        size="Large"
        buttonType={
          state.selectedView === "performance" ? "PrimaryBlue" : "SecondaryBlue"
        }
        disabled={state.operatingCaseResults.length === 0}
        onClick={() => dispatch(Action.setView("performance"))}
      >
        {sharedState.translate(LanguageTexts.airLocations())}
      </StandardButton>
    </NavigationRoot>
  );
}
