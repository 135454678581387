import * as Types from "./types";
import * as LanguageTexts from "../language-texts";
import * as ScreenAmounts from "../screen-amounts";
import * as ChartAxisType from "../enums/chart-axis-type";
import { Quantity, Amount, Serialize } from "@genesys/uom";
import { ConversionParameters } from "../quantity-conversion";
import { getValue } from "../product-properties";
import { StaticText } from "../language-texts/types";
import { PropertyValue } from "@genesys/property";

export function createStandardBinList(
  binCases: ReadonlyArray<Types.BinCase>,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  formatProvider: ScreenAmounts.GetAmountFormat,
  listFieldGroup: string
): Types.EnergyResultTable {
  const midPointQuantity = getMidPointQuantity(binCases);
  const midPointFieldName = getMidPointFieldName(midPointQuantity);

  const binDataQuantities: Array<Types.ResultQuantity> = [
    {
      internalName: midPointFieldName,
      displayName: translate(getMidPointTextId(midPointQuantity)),
      format: getMidPointFormat(
        formatProvider,
        midPointQuantity,
        listFieldGroup
      ),
      createConversionParameters: (binCase: Types.BinCase) => ({
        pressure: binCase.binPressure,
        temperature: binCase.averageTemperature
      })
    },
    {
      internalName: "AverageTemp",
      displayName: translate(LanguageTexts.averageTemp()),
      format: formatProvider(listFieldGroup, "AverageTemp", "Temperature"),
      createConversionParameters: undefined
    },
    {
      internalName: "Humidity",
      displayName: translate(LanguageTexts.averageHumidity()),
      format: formatProvider(listFieldGroup, "Humidity", "HumidityRatio"),
      createConversionParameters: (binCase: Types.BinCase) => ({
        pressure: binCase.binPressure,
        temperature: binCase.averageTemperature
      })
    },
    {
      internalName: "BinPressure",
      displayName: translate(LanguageTexts.binPressure()),
      format: formatProvider(listFieldGroup, "BinPressure", "Pressure"),
      createConversionParameters: undefined
    },
    {
      internalName: "Time",
      displayName: translate(LanguageTexts.time()),
      format: formatProvider(listFieldGroup, "Time", "Duration"),
      createConversionParameters: undefined
    }
  ];

  const rows = binCases.map(bc => {
    return [...getBaseColumns(bc, midPointQuantity), bc.binTime];
  });

  const formattedHeader = [
    {
      internalName: "No",
      name: translate(LanguageTexts.no()),
      format: undefined
    } as Types.EnergyListColumn
  ].concat(
    binDataQuantities.map(bq => {
      return {
        internalName: bq.internalName,
        name: bq.displayName,
        format: bq.format,
        createConversionParameters: bq.createConversionParameters
      } as Types.EnergyListColumn;
    })
  );

  const formattedRows = binCases.map((bc, bcIndex) =>
    [bc.binId.toString()].concat(
      rows[bcIndex].map((c, cellIndex) =>
        c
          ? getValue(
              PropertyValue.fromAmount(c!),
              formattedHeader[cellIndex + 1].format!,
              formattedHeader[cellIndex + 1].createConversionParameters &&
                formattedHeader[cellIndex + 1].createConversionParameters!(bc)
            )
          : "-"
      )
    )
  );

  return { formattedHeader: formattedHeader, formattedRows: formattedRows };
}

export function createBinListWithMonths(
  binCases: ReadonlyArray<Types.BinCase>,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  formatProvider: ScreenAmounts.GetAmountFormat,
  listFieldGroup: string
): Types.EnergyResultTable {
  const midPointQuantity = getMidPointQuantity(binCases);
  const midPointFieldName = getMidPointFieldName(midPointQuantity);
  const createConversionParameters = (
    binCase: Types.BinCase
  ): ConversionParameters => {
    return {
      pressure: binCase.binPressure,
      temperature: binCase.averageTemperature
    };
  };
  const binDataQuantities: Array<Types.ResultQuantity> = [
    {
      internalName: midPointFieldName,
      displayName: translate(getMidPointTextId(midPointQuantity)),
      format: getMidPointFormat(
        formatProvider,
        midPointQuantity,
        listFieldGroup
      ),
      createConversionParameters: createConversionParameters
    },
    {
      internalName: "AverageTemp",
      displayName: translate(LanguageTexts.averageTemp()),
      format: formatProvider(listFieldGroup, "AverageTemp", "Temperature"),
      createConversionParameters: undefined
    },
    {
      internalName: "Humidity",
      displayName: translate(LanguageTexts.averageHumidity()),
      format: formatProvider(listFieldGroup, "Humidity", "HumidityRatio"),
      createConversionParameters: createConversionParameters
    },
    {
      internalName: "BinPressure",
      displayName: translate(LanguageTexts.binPressure()),
      format: formatProvider(listFieldGroup, "BinPressure", "Pressure"),
      createConversionParameters: undefined
    },
    ...createMonthlyBinTypes(listFieldGroup, translate, formatProvider)
  ];

  const rows = binCases.map(bc => {
    return [
      ...getBaseColumns(bc, midPointQuantity),
      ...addMonthlyColumnValues(bc)
    ];
  });

  const formattedHeader = [
    {
      internalName: "No",
      name: translate(LanguageTexts.no()),
      format: undefined
    } as Types.EnergyListColumn
  ].concat(
    binDataQuantities.map(bq => {
      return {
        internalName: bq.internalName,
        name: bq.displayName,
        format: bq.format,
        createConversionParameters: bq.createConversionParameters
      } as Types.EnergyListColumn;
    })
  );

  const formattedRows = binCases.map((bc, bcIndex) =>
    [bc.binId.toString()].concat(
      rows[bcIndex].map((c, cellIndex) =>
        c
          ? getValue(
              PropertyValue.fromAmount(c!),
              formattedHeader[cellIndex + 1].format!,
              formattedHeader[cellIndex + 1].createConversionParameters &&
                formattedHeader[cellIndex + 1].createConversionParameters!(bc)
            )
          : "-"
      )
    )
  );

  return { formattedHeader: formattedHeader, formattedRows: formattedRows };
}

export function createMoistureLoadBinList(
  binCases: ReadonlyArray<Types.BinCase>,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  formatProvider: ScreenAmounts.GetAmountFormat,
  listFieldGroup: string
): Types.EnergyResultTable {
  const midPointQuantity = getMidPointQuantity(binCases);
  const midPointFieldName = getMidPointFieldName(midPointQuantity);

  const binDataQuantities: Array<Types.ResultQuantity> =
    getBaseMoistureLoadQuantiies(
      listFieldGroup,
      midPointQuantity,
      midPointFieldName,
      translate,
      formatProvider
    );

  const rows = binCases.map(bc => {
    return [
      ...getBaseColumns(bc, midPointQuantity),
      bc.binTime,
      bc.returnAirTemperature,
      bc.returnAirHumidity,
      bc.targetTemperature!,
      bc.targetHumidity!,
      bc.totalMoistureLoad!,
      bc.totalHeatLoad!
    ];
  }) as (Amount.Amount<Quantity.Quantity> | undefined)[][];

  const formattedHeader = [
    {
      internalName: "No",
      name: translate(LanguageTexts.no()),
      format: undefined
    } as Types.EnergyListColumn
  ].concat(
    binDataQuantities.map(bq => {
      return {
        internalName: bq.internalName,
        name: bq.displayName,
        format: bq.format,
        createConversionParameters: bq.createConversionParameters
      } as Types.EnergyListColumn;
    })
  );

  const formattedRows = binCases.map((bc, bcIndex) =>
    [bc.binId.toString()].concat(
      rows[bcIndex].map((c, cellIndex) =>
        c
          ? getValue(
              PropertyValue.fromAmount(c!),
              formattedHeader[cellIndex + 1].format!,
              formattedHeader[cellIndex + 1].createConversionParameters &&
                formattedHeader[cellIndex + 1].createConversionParameters!(bc)
            )
          : "-"
      )
    )
  );

  return { formattedHeader: formattedHeader, formattedRows: formattedRows };
}

export function createMoistureLoadBinListMonthly(
  binCases: ReadonlyArray<Types.BinCase>,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  formatProvider: ScreenAmounts.GetAmountFormat,
  listFieldGroup: string
): Types.EnergyResultTable {
  const midPointQuantity = getMidPointQuantity(binCases);
  const midPointFieldName = getMidPointFieldName(midPointQuantity);

  createMonthlyBinTypes(listFieldGroup, translate, formatProvider);

  const binDataQuantities: Array<Types.ResultQuantity> = [
    ...getBaseMoistureLoadQuantiies(
      listFieldGroup,
      midPointQuantity,
      midPointFieldName,
      translate,
      formatProvider
    ),
    ...createMonthlyBinTypes(listFieldGroup, translate, formatProvider)
  ];

  const rows = binCases.map(bc => {
    return [
      ...getBaseColumns(bc, midPointQuantity),
      bc.binTime,
      bc.returnAirTemperature,
      bc.returnAirHumidity,
      bc.targetTemperature!,
      bc.targetHumidity!,
      bc.totalMoistureLoad!,
      bc.totalHeatLoad!,
      ...addMonthlyColumnValues(bc)
    ];
  }) as (Amount.Amount<Quantity.Quantity> | undefined)[][];

  const formattedHeader = [
    {
      internalName: "No",
      name: translate(LanguageTexts.no()),
      format: undefined
    } as Types.EnergyListColumn
  ].concat(
    binDataQuantities.map(bq => {
      return {
        internalName: bq.internalName,
        name: bq.displayName,
        format: bq.format,
        createConversionParameters: bq.createConversionParameters
      } as Types.EnergyListColumn;
    })
  );

  const formattedRows = binCases.map((bc, bcIndex) =>
    [bc.binId.toString()].concat(
      rows[bcIndex].map((c, cellIndex) =>
        c
          ? getValue(
              PropertyValue.fromAmount(c!),
              formattedHeader[cellIndex + 1].format!,
              formattedHeader[cellIndex + 1].createConversionParameters &&
                formattedHeader[cellIndex + 1].createConversionParameters!(bc)
            )
          : "-"
      )
    )
  );

  return { formattedHeader: formattedHeader, formattedRows: formattedRows };
}

export function getBaseColumns(
  bc: Types.BinCase,
  midPointQuantity: Quantity.Quantity
) {
  return [
    midPointValue(bc, midPointQuantity),
    bc.averageTemperature,
    bc.averageHumidity,
    bc.binPressure
  ];
}

export function parseEnergyChartPresets(presets: Types.EnergyChartPresetType) {
  return {
    id: presets.id,
    name: presets.name,
    y1Label: presets.y1Label,
    y1AxisType:
      ChartAxisType.ChartAxisType[
        presets.y1AxisType as keyof typeof ChartAxisType.ChartAxisType
      ],
    y1PerfParams: presets.y1PerfParams.map(pp => ({
      name: pp.split(",")[0],
      color: pp.split(",")[1]
    })),
    y1UnitSi: Serialize.stringToUnit(presets.y1UnitSi)!,
    y1UnitIp: Serialize.stringToUnit(presets.y1UnitIp)!,
    y2Label: presets.y2Label,
    y2AxisType:
      ChartAxisType.ChartAxisType[
        presets.y2AxisType as keyof typeof ChartAxisType.ChartAxisType
      ],
    y2PerfParams: presets.y2PerfParams.map(pp => ({
      name: pp.split(",")[0],
      color: pp.split(",")[1]
    })),
    y2UnitSi: Serialize.stringToUnit(presets.y2UnitSi)!,
    y2UnitIp: Serialize.stringToUnit(presets.y2UnitIp)!
  };
}

export function getMidPointQuantity(
  binCases: ReadonlyArray<Types.BinCase>
): Quantity.Quantity {
  if (!binCases || binCases.length === 0) {
    return "Temperature";
  }
  if (binCases[0].midPointTemp) {
    return "Temperature";
  }
  if (binCases[0].midPointWetTemp) {
    return "WetTemperature";
  }
  if (binCases[0].midPointDewPointTemp) {
    return "DewPointTemperature";
  }
  if (binCases[0].midPointSpecificEnthalpy) {
    return "SpecificEnthalpy";
  }
  if (binCases[0].midPointHumidityRatio) {
    return "HumidityRatio";
  }
  if (binCases[0].midPointHourly) {
    return "Dimensionless";
  }
  return "Temperature";
}

export function getMidPointTextId(quantity: Quantity.Quantity): StaticText {
  if (quantity === "Temperature") {
    return LanguageTexts.midPointTemp();
  }
  if (quantity === "WetTemperature") {
    return LanguageTexts.midPointWetTemp();
  }
  if (quantity === "DewPointTemperature") {
    return LanguageTexts.midPointDewPointTemp();
  }
  if (quantity === "SpecificEnthalpy") {
    return LanguageTexts.midPointSpecificEnthalpy();
  }
  if (quantity === "HumidityRatio") {
    return LanguageTexts.midPointHumidityRatio();
  }
  if (quantity === "Dimensionless") {
    return LanguageTexts.midPointHourly();
  }
  return LanguageTexts.midPointTemp();
}

export function getMidPointFormat(
  formatProvider: ScreenAmounts.GetAmountFormat,
  quantity: Quantity.Quantity,
  listFieldGroup: string
) {
  if (quantity === "Temperature") {
    return getMidPointTempFormat(formatProvider, listFieldGroup);
  }
  if (quantity === "WetTemperature") {
    return getMidPointWetTempFormat(formatProvider, listFieldGroup);
  }
  if (quantity === "DewPointTemperature") {
    return getMidPointDewPointTempFormat(formatProvider, listFieldGroup);
  }
  if (quantity === "SpecificEnthalpy") {
    return getMidPointSpecificEnthalpyFormat(formatProvider, listFieldGroup);
  }
  if (quantity === "HumidityRatio") {
    return getMidPointHumidityRatioFormat(formatProvider, listFieldGroup);
  }

  if (quantity === "Dimensionless") {
    return undefined;
  }
  return getMidPointTempFormat(formatProvider, listFieldGroup);
}
export function getMidPointFieldName(
  quantity: Quantity.Quantity
): Types.MidPointFieldName {
  if (quantity === "Temperature") {
    return "MidPointTemp";
  }
  if (quantity === "WetTemperature") {
    return "MidPointWetTemp";
  }
  if (quantity === "DewPointTemperature") {
    return "MidPointDewPointTemp";
  }
  if (quantity === "SpecificEnthalpy") {
    return "MidPointSpecificEnthalpy";
  }
  if (quantity === "HumidityRatio") {
    return "MidPointHumidityRatio";
  }

  if (quantity === "Dimensionless") {
    return "MidPointHourly";
  }
  return "MidPointTemp";
}

export function getMidPointTempFormat(
  formatProvider: ScreenAmounts.GetAmountFormat,
  listFieldGroup: string
) {
  return formatProvider(listFieldGroup, "MidPointTemp", "Temperature");
}
export function getMidPointWetTempFormat(
  formatProvider: ScreenAmounts.GetAmountFormat,
  listFieldGroup: string
) {
  return formatProvider(listFieldGroup, "MidPointWetTemp", "WetTemperature");
}
export function getMidPointDewPointTempFormat(
  formatProvider: ScreenAmounts.GetAmountFormat,
  listFieldGroup: string
) {
  return formatProvider(
    listFieldGroup,
    "MidPointDewPointTemp",
    "DewPointTemperature"
  );
}

export function getMidPointSpecificEnthalpyFormat(
  formatProvider: ScreenAmounts.GetAmountFormat,
  listFieldGroup: string
) {
  return formatProvider(
    listFieldGroup,
    "MidPointSpecificEnthalpy",
    "SpecificEnthalpy"
  );
}

export function getMidPointHumidityRatioFormat(
  formatProvider: ScreenAmounts.GetAmountFormat,
  listFieldGroup: string
) {
  return formatProvider(
    listFieldGroup,
    "MidPointHumidityRatio",
    "HumidityRatio"
  );
}

export function getMidPointHourlyFormat(
  formatProvider: ScreenAmounts.GetAmountFormat,
  listFieldGroup: string
) {
  return formatProvider(listFieldGroup, "MidPointHourly", "Dimensionless");
}

export function midPointValue(
  binCase: Types.BinCase,
  quantity: string
): Amount.Amount<Quantity.Quantity> {
  if (quantity === "Temperature") {
    return binCase.midPointTemp!;
  }
  if (quantity === "WetTemperature") {
    return binCase.midPointWetTemp!;
  }
  if (quantity === "DewPointTemperature") {
    return binCase.midPointDewPointTemp!;
  }
  if (quantity === "SpecificEnthalpy") {
    return binCase.midPointSpecificEnthalpy!;
  }
  if (quantity === "HumidityRatio") {
    return binCase.midPointHumidityRatio!;
  }
  if (quantity === "Dimensionless") {
    return binCase.midPointHourly!;
  }
  return binCase.midPointTemp!;
}

function getBaseMoistureLoadQuantiies(
  listFieldGroup: string,
  midPointQuantity: Quantity.Quantity,
  midPointFieldName: Types.BinFieldsInternalNames,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  formatProvider: ScreenAmounts.GetAmountFormat
): Array<Types.ResultQuantity> {
  return [
    {
      internalName: midPointFieldName,
      displayName: translate(getMidPointTextId(midPointQuantity)),
      format: getMidPointFormat(
        formatProvider,
        midPointQuantity,
        listFieldGroup
      ),
      createConversionParameters: undefined
    },
    {
      internalName: "AverageTemp",
      displayName: translate(LanguageTexts.averageTemp()),
      format: formatProvider(listFieldGroup, "AverageTemp", "Temperature"),
      createConversionParameters: undefined
    },
    {
      internalName: "Humidity",
      displayName: translate(LanguageTexts.averageHumidity()),
      format: formatProvider(listFieldGroup, "Humidity", "HumidityRatio"),
      createConversionParameters: (binCase: Types.BinCase) => ({
        pressure: binCase.binPressure,
        temperature: binCase.averageTemperature
      })
    },
    {
      internalName: "BinPressure",
      displayName: translate(LanguageTexts.binPressure()),
      format: formatProvider(listFieldGroup, "BinPressure", "Pressure"),
      createConversionParameters: undefined
    },
    {
      internalName: "Time",
      displayName: translate(LanguageTexts.time()),
      format: formatProvider(listFieldGroup, "Time", "Duration"),
      createConversionParameters: undefined
    },
    {
      internalName: "ReturnTemperature",
      displayName: translate(LanguageTexts.returnTemp()),
      format: formatProvider(
        listFieldGroup,
        "ReturnTemperature",
        "Temperature"
      ),
      createConversionParameters: undefined
    },
    {
      internalName: "ReturnHumidity",
      displayName: translate(LanguageTexts.returnHumidity()),
      format: formatProvider(listFieldGroup, "ReturnHumidity", "HumidityRatio"),
      createConversionParameters: (binCase: Types.BinCase) => ({
        pressure: binCase.binPressure,
        temperature: binCase.returnAirTemperature!
      })
    },
    {
      internalName: "TargetTemperature",
      displayName: translate(LanguageTexts.targetTemp()),
      format: formatProvider(
        listFieldGroup,
        "TargetTemperature",
        "Temperature"
      ),
      createConversionParameters: undefined
    },
    {
      internalName: "TargetHumidity",
      displayName: translate(LanguageTexts.targetHumidity()),
      format: formatProvider(listFieldGroup, "TargetHumidity", "HumidityRatio"),
      createConversionParameters: (binCase: Types.BinCase) => ({
        pressure: binCase.binPressure,
        temperature: binCase.targetTemperature!
      })
    },
    {
      internalName: "TotalMoistureLoad",
      displayName: "tot-moist-load", // todo eller ta bort
      format: formatProvider(listFieldGroup, "TotalMoistureLoad", "MassFlow"),
      createConversionParameters: undefined
    },
    {
      internalName: "TotalHeatLoad",
      displayName: "tot-heat-load", // todo eller ta bort
      format: formatProvider(listFieldGroup, "TotalHeatLoad", "Power"),
      createConversionParameters: undefined
    }
  ];
}

function addMonthlyColumnValues(bc: Types.BinCase) {
  return [
    bc.binTimeJanuary,
    bc.binTimeFebruary,
    bc.binTimeMarch,
    bc.binTimeApril,
    bc.binTimeMay,
    bc.binTimeJune,
    bc.binTimeJuly,
    bc.binTimeAugust,
    bc.binTimeSeptember,
    bc.binTimeOctober,
    bc.binTimeNovember,
    bc.binTimeDecember
  ];
}

function createMonthlyBinTypes(
  listFieldGroup: string,
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  formatProvider: ScreenAmounts.GetAmountFormat
): Array<Types.ResultQuantity> {
  return [
    {
      internalName: "BinTimeJanuary",
      displayName: translate(LanguageTexts.januaryHours()),
      format: formatProvider(listFieldGroup, "BinTimeJanuary", "Duration"),
      createConversionParameters: undefined
    },
    {
      internalName: "BinTimeFebruary",
      displayName: translate(LanguageTexts.februaryHours()),
      format: formatProvider(listFieldGroup, "BinTimeFebruary", "Duration"),
      createConversionParameters: undefined
    },
    {
      internalName: "BinTimeMarch",
      displayName: translate(LanguageTexts.marchHours()),
      format: formatProvider(listFieldGroup, "BinTimeMarch", "Duration"),
      createConversionParameters: undefined
    },
    {
      internalName: "BinTimeApril",
      displayName: translate(LanguageTexts.aprilHours()),
      format: formatProvider(listFieldGroup, "BinTimeApril", "Duration"),
      createConversionParameters: undefined
    },
    {
      internalName: "BinTimeMay",
      displayName: translate(LanguageTexts.mayHours()),
      format: formatProvider(listFieldGroup, "BinTimeMay", "Duration"),
      createConversionParameters: undefined
    },
    {
      internalName: "BinTimeJune",
      displayName: translate(LanguageTexts.juneHours()),
      format: formatProvider(listFieldGroup, "BinTimeJune", "Duration"),
      createConversionParameters: undefined
    },
    {
      internalName: "BinTimeJuly",
      displayName: translate(LanguageTexts.julyHours()),
      format: formatProvider(listFieldGroup, "BinTimeJuly", "Duration"),
      createConversionParameters: undefined
    },
    {
      internalName: "BinTimeAugust",
      displayName: translate(LanguageTexts.augustHours()),
      format: formatProvider(listFieldGroup, "BinTimeAugust", "Duration"),
      createConversionParameters: undefined
    },
    {
      internalName: "BinTimeSeptember",
      displayName: translate(LanguageTexts.septemberHours()),
      format: formatProvider(listFieldGroup, "BinTimeSeptember", "Duration"),
      createConversionParameters: undefined
    },
    {
      internalName: "BinTimeOctober",
      displayName: translate(LanguageTexts.octoberHours()),
      format: formatProvider(listFieldGroup, "BinTimeOctober", "Duration"),
      createConversionParameters: undefined
    },
    {
      internalName: "BinTimeNovember",
      displayName: translate(LanguageTexts.novemberHours()),
      format: formatProvider(listFieldGroup, "BinTimeNovember", "Duration"),
      createConversionParameters: undefined
    },
    {
      internalName: "BinTimeDecember",
      displayName: translate(LanguageTexts.decemberHours()),
      format: formatProvider(listFieldGroup, "BinTimeDecember", "Duration"),
      createConversionParameters: undefined
    }
  ];
}

//tslint:disable-next-line
