import React from "react";
import styled from "styled-components";
import * as StatusIcon from "../../status-icon-manager";
import {
  Column,
  AllSummaryType,
  ClimateColumnGroup,
  StandardColumnGroup,
  AddtionalTools,
  CellContent
} from "./types";
import { getClimateContent } from "./climate-content";
import { targetContent } from "./target-content";
import { flowsAndPressureContent } from "./flows-and-pressure-content";
import { tempAndHumContent } from "./temp-and-hum-content";
import { System } from "../system";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  GenesysTableSelect,
  GenesysTableOption,
  EditBlue,
  ToolTipWrapper
} from "@genesys/ui-elements";
import { Action } from "../state";
import { withOPCSelectionLogic } from "./shared";

const StyledP = styled.p`
  font-size: 12px;
`;

const StyledLabel = styled.label`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  text-align: left;
`;

const StyledA = styled.a`
  color: inherit;
  text-align: left;
`;

const EditContainer = styled.span``;

const targetContentWithOPCLogic = withOPCSelectionLogic(targetContent);
const flowsAndPressureWithOPCLogic = withOPCSelectionLogic(
  flowsAndPressureContent
);
const tempAndHumContentWithOPCLogic = withOPCSelectionLogic(tempAndHumContent);

// Instead of having a single gigantic switch case thought it was better to divide in their respective groups
export function cellContent(
  column: Column,
  summaryGroup: AllSummaryType,
  system: System,
  tools: AddtionalTools
): CellContent {
  switch (summaryGroup) {
    case "standard":
      return standardContent(column as StandardColumnGroup, system, tools);
    case "climate": {
      return getClimateContent(column as ClimateColumnGroup, system, tools);
    }
    case "target": {
      return targetContentWithOPCLogic({ columnDef: column, system, tools });
    }

    case "flows-and-pressure": {
      return flowsAndPressureWithOPCLogic({ columnDef: column, system, tools });
    }

    case "temperature-and-humidity": {
      return tempAndHumContentWithOPCLogic({
        columnDef: column,
        system,
        tools
      });
    }
    default: {
      exhaustiveCheck(summaryGroup);
    }
  }
}

function standardContent(
  columnDef: StandardColumnGroup,
  system: System,
  tools: AddtionalTools
): CellContent {
  const { sharedState } = tools;
  const columnName = columnDef.columnName;
  const allOpc = system.operatingCases.filter(x => x.caseType === 0);

  const { state, dispatch } = tools;

  const selectedOpcSortNo =
    state.selectedOpc.get(system.id) || allOpc[0]?.sortNo;

  switch (columnName) {
    case "edit": {
      const rowIsSelectedForEdit = state.editedRowId === system.id;
      const getEditTitle = () => {
        if (!system.isEditable) {
          return "";
        }

        if (!rowIsSelectedForEdit) {
          return "Edit row";
        }
        return "Deselect row";
      };
      const value = (
        <ToolTipWrapper title={getEditTitle()}>
          <EditContainer
            style={{ cursor: system.isEditable ? "pointer" : "default" }}
          >
            <EditBlue />
          </EditContainer>
        </ToolTipWrapper>
      );
      return {
        value,
        cellOnClick: e => {
          e.stopPropagation();
          if (!system.isEditable) {
            return;
          }
          if (rowIsSelectedForEdit) {
            dispatch(Action.setEditedRow(""));
            return;
          }

          dispatch(Action.setEditedRow(system.id));
        }
      };
    }
    case "opc": {
      if (selectedOpcSortNo === undefined) {
        return {
          value: <>No Opc found </>
        };
      }

      const selectedOpc = state.currentOpcValues
        .get(system.id)
        ?.get(selectedOpcSortNo)!;

      const value = (
        <GenesysTableSelect
          onChange={e => {
            e.stopPropagation();
            const id = e.target.value;
            const opc = allOpc.find(x => x.id === id)!;
            dispatch(Action.setSelectedOpc(system.id, opc.sortNo));
          }}
          value={selectedOpc.id}
        >
          {allOpc.map((x, i) => {
            return (
              <GenesysTableOption
                key={x.id}
                onClick={e => e.stopPropagation()}
                value={x.id}
              >
                <StyledP> {x.customCaseName || `Opc case ${i + 1}`}</StyledP>
              </GenesysTableOption>
            );
          })}
        </GenesysTableSelect>
      );

      return {
        value
      };
    }
    case "g-no": {
      const genesysNo = sharedState.genesysPrefix.genesysNo(
        system.genesysNo,
        system.revisionNo
      );

      const value = (
        <StyledA
          onClick={e => e.stopPropagation()}
          target="_blank"
          href={`/system/${genesysNo}`}
        >
          {genesysNo}
        </StyledA>
      );

      return {
        value
      };
    }

    case "system-name": {
      const value = <StyledLabel>{system.name}</StyledLabel>;
      return {
        value
      };
    }

    case "system-type": {
      const value = <StyledLabel>{system.systemTypeId} </StyledLabel>;
      return {
        value
      };
    }

    case "system-status": {
      const value = (
        <StatusIcon.StatusIconManager
          sharedState={sharedState}
          statusNumber={system.status}
        />
      );
      return {
        value
      };
    }
    default: {
      exhaustiveCheck(columnDef);
    }
  }
}
