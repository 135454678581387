import { PropertyValue, PropertyValueSet } from "@genesys/property";
import * as PropertiesSelector from "../../properties-selector";
import * as GraphQlTypes from "../../graphql-types";
import { State } from "./state";

export function getOilonInput(state: State) {
  const getIntValue = (name: string, pvs: PropertyValueSet.PropertyValueSet) =>
    PropertyValue.getInteger(PropertyValueSet.getValue(name, pvs))!;

  const getStringValue = (
    name: string,
    pvs: PropertyValueSet.PropertyValueSet
  ) => PropertyValue.toString(PropertyValueSet.getValue(name, pvs));

  const configPvs = PropertiesSelector.getSelectedProperties(
    state.propertiesSelectors["config"]
  );

  const calculationCasesPvs = Object.entries(state.propertiesSelectors)
    .filter(([id, _v]) => id.startsWith("case"))
    .map(([_id, state]) => PropertiesSelector.getSelectedProperties(state));

  const calulationCases = calculationCasesPvs.map(pvs => ({
    name: PropertyValueSet.getText("name", pvs)!, // todo
    highTemperatureStageInUse:
      getIntValue("highTemperatureStageInUse", pvs) === 1,
    highTemperatureStageCondenserInletTemperature: getStringValue(
      "highTemperatureStageCondenserInletTemperature",
      pvs
    ),
    highTemperatureStageCondenserOutletTemperature: getStringValue(
      "highTemperatureStageCondenserOutletTemperature",
      pvs
    ),
    highTemperatureStageHeatingCapacityRequirement: getStringValue(
      "highTemperatureStageHeatingCapacityRequirement",
      pvs
    ),
    highTemperatureStageCondenserPipeLength: getStringValue(
      "highTemperatureStageCondenserPipeLength",
      pvs
    ),
    lowTemperatureStageCondenserInletTemperature: getStringValue(
      "lowTemperatureStageCondenserInletTemperature",
      pvs
    ),
    lowTemperatureStageCondenserOutletTemperature: getStringValue(
      "lowTemperatureStageCondenserOutletTemperature",
      pvs
    ),
    lowTemperatureStageCondenserPipeLength: getStringValue(
      "lowTemperatureStageCondenserPipeLength",
      pvs
    ),
    lowTemperatureStageEvaporatorInletTemperature: getStringValue(
      "lowTemperatureStageEvaporatorInletTemperature",
      pvs
    ),
    lowTemperatureStageEvaporatorOutletTemperature: getStringValue(
      "lowTemperatureStageEvaporatorOutletTemperature",
      pvs
    ),
    lowTemperatureStageEvaporatorPipeLength: getStringValue(
      "lowTemperatureStageEvaporatorPipeLength",
      pvs
    ),
    lowTemperatureStageHeatingCapacityRequirement: getStringValue(
      "lowTemperatureStageHeatingCapacityRequirement",
      pvs
    ),
    lowTemperatureStageCoolingCapacityRequirement: getStringValue(
      "lowTemperatureStageCoolingCapacityRequirement",
      pvs
    )
  }));

  const oilonInput: GraphQlTypes.OilonInputType = {
    reference: "oilon-tool",
    heatPumpConfigurationType: getIntValue(
      "heatPumpConfigurationType",
      configPvs
    ),
    wetAirAvailableAsHeatSource:
      getIntValue("wetAirAvailableAsHeatSource", configPvs) === 1,
    wetAirAvailableAsHeatSink:
      getIntValue("wetAirAvailableAsHeatSink", configPvs) === 1,

    evaporatorFluidType: getIntValue("evaporatorFluidType", configPvs),
    evaporatorFluidConcentration: getStringValue(
      "evaporatorFluidConcentration",
      configPvs
    ),
    calculationCases: calulationCases
  };

  return oilonInput;
}

export function parseCalculationCasesResults(
  calculationCases: GraphQlTypes.CalculateOilon["user"]["calculateOilon"]["calculationCases"]
): ReadonlyArray<PropertyValueSet.PropertyValueSet> {
  if (!calculationCases) {
    return [];
  }

  const caseResults = calculationCases.map(calculationCase => {
    return Object.entries(calculationCase).reduce(
      (soFar, [propertyName, value]) => {
        if (propertyName === "name") {
          return PropertyValueSet.setText(propertyName, value, soFar);
        } else {
          return PropertyValueSet.set(
            propertyName,
            PropertyValue.fromString(value)!,
            soFar
          );
        }
      },
      PropertyValueSet.Empty
    );
  });

  return caseResults;
}
