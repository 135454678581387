import { TraceSpan } from "./state";

export type TimerNode = {
  readonly spanId: string;
  readonly name: string;
  readonly startTimeUnixNano: number;
  readonly endTimeUnixNano: number;
  readonly parallellGroupId: string;
  readonly isFireAndForget: boolean;
  readonly children: ReadonlyArray<TimerNode>;
};

type TraceSpanParent = {
  readonly spanId: string;
  readonly children: ReadonlyArray<string>;
};

export function createTimerNodes(
  traceSpans: ReadonlyArray<TraceSpan>
): ReadonlyArray<TimerNode> {
  //tslint:disable-next-line
  let traceSpanParents: { [id: string]: TraceSpanParent } = {};
  let topNodes: Array<string> = [];

  const traceIdsToAdd = traceSpans.reduce(
    (soFar, current) =>
      current.isSummarySpan ? soFar.concat([current.spanId]) : soFar,
    [] as ReadonlyArray<string>
  );

  traceIdsToAdd.forEach(spanIdToAdd => {
    if (traceSpanParents[spanIdToAdd] === undefined) {
      let currentId: string = spanIdToAdd;
      let currentParentId: string | undefined = traceSpans.find(
        ts => ts.spanId === currentId
      )!.parentSpanId;
      let currentNode: TraceSpanParent | undefined = undefined;

      do {
        if (traceIdsToAdd.some(a => a === currentId)) {
          if (traceSpanParents[currentId] === undefined) {
            const traceSpan = traceSpans.find(ts => ts.spanId === currentId)!;

            const newNode: TraceSpanParent = {
              spanId: traceSpan.spanId,
              children: currentNode === undefined ? [] : [currentNode.spanId]
            };

            traceSpanParents[currentId] = newNode;
            currentNode = newNode;
          } else {
            if (currentNode !== undefined) {
              const node = traceSpanParents[currentId];
              traceSpanParents[currentId] = {
                ...node,
                children: node.children.concat([currentNode.spanId])
              };
            }
            currentNode = undefined;
            break;
          }
        }

        currentId = currentParentId;
        const currentSpan = traceSpans.find(ts => ts.spanId === currentId);
        if (currentSpan !== undefined) {
          currentParentId = currentSpan.parentSpanId;
        } else {
          currentParentId = undefined;
        }
      } while (currentParentId !== undefined);

      if (currentNode !== undefined) {
        topNodes = topNodes.concat([currentNode.spanId]);
      }
    }
  });

  const addChildren = (
    childrenIds: ReadonlyArray<string>
  ): ReadonlyArray<TimerNode> => {
    return childrenIds.map(id => {
      const traceSpan = traceSpans.find(ts => ts.spanId === id)!;
      return {
        spanId: traceSpan.spanId,
        name: traceSpan.name,
        startTimeUnixNano: traceSpan.startTimeUnixNano,
        endTimeUnixNano: traceSpan.endTimeUnixNano,
        parallellGroupId: traceSpan.parallellGroupId,
        isFireAndForget: traceSpan.isFireAndForget,
        children: addChildren(traceSpanParents[id].children)
      };
    });
  };

  const tree = topNodes.map(tn => {
    const traceSpan = traceSpans.find(ts => ts.spanId === tn)!;
    return {
      spanId: traceSpan.spanId,
      name: traceSpan.name,
      startTimeUnixNano: traceSpan.startTimeUnixNano,
      endTimeUnixNano: traceSpan.endTimeUnixNano,
      parallellGroupId: traceSpan.parallellGroupId,
      isFireAndForget: traceSpan.isFireAndForget,
      children: addChildren(traceSpanParents[tn].children)
    };
  });

  return tree;
}
