import React from "react";
import { Label } from "../../atoms/typography";
import { TableAlignment } from "./types";

export function TableRow({
  rowStyle,
  children,
  onClick,
  onMouseOver
}: {
  readonly rowStyle?: React.CSSProperties;
  readonly children?: React.ReactNode;
  readonly onMouseOver?: (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => void;
  readonly onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}) {
  return (
    <tr
      style={rowStyle}
      onMouseOver={event => {
        if (onMouseOver) {
          onMouseOver(event);
        }
      }}
      onClick={event => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      {children}
      {/* {columns.map((column, j) => (
                    <TableCell
                        key={j}
                        align={column.align}
                        value={row.rowValues[column.key]}
                    />
                ))} */}
    </tr>
  );
}

export function TableCell({
  align,
  children,
  bold
}: {
  readonly children: React.ReactNode;
  readonly bold?: boolean;
  readonly align?: TableAlignment;
}) {
  return (
    <td style={{ textAlign: align }}>
      <Label color="light" weight={bold ? "bold" : "normal"}>
        {children}
      </Label>
    </td>
  );
}

type TableHeadProps = {
  readonly width?: number;
  readonly align?: TableAlignment;
  readonly children: React.ReactNode;
};

export function TableHead({ width, align, children }: TableHeadProps) {
  return (
    <th style={{ width, textAlign: align }}>
      <Label weight="bold">{children}</Label>
    </th>
  );
}
