import * as React from "react";
import { ProfileIcon, WithMenu } from "@genesys/ui-elements";
import { clientConfig } from "../../config";
import { UserManager } from "oidc-client";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../shared-state";

export function UserMenu({
  sharedState
}: {
  readonly sharedState: SharedState.State;
}) {
  return (
    <WithMenu
      icon={<ProfileIcon width="20px" />}
      values={[
        {
          value:
            sharedState.translate(LanguageTexts.loggedinuser()) +
            ": " +
            sharedState.user.userName,
          onClick: () => {
            return undefined;
          }
        },
        {
          value: sharedState.translate(LanguageTexts.requestAccessLevel()),
          onClick: () => window.open("https://stsusers.munters.com/")
        },
        {
          value: sharedState.translate(LanguageTexts.logout()),
          onClick: async () => {
            const manager = new UserManager({
              authority: clientConfig.oidcAuthority,
              client_id: clientConfig.oidcClientId,
              redirect_uri: clientConfig.oidcRedirectUri,
              post_logout_redirect_uri: window.location.origin,
              response_type: clientConfig.oidcResponseType,
              scope: clientConfig.oidcScope,
              monitorSession: false,
              loadUserInfo: false
            });
            await manager.signoutRedirect();
          }
        }
      ]}
    />
  );
}
