import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Exporter from "@genesys/client-core/lib/exporter";
import * as GraphQLTypes from "../../../../graphql-types";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import styled from "styled-components";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { getValue } from "@genesys/shared/lib/product-properties";
import { ToolTipWrapper, StandardButton } from "@genesys/ui-elements";
import { Unit, Quantity } from "@genesys/uom";
import { AmountFormatSelector } from "../../../../amount-format-selector";
import { MoistureLoadTable, OverflowTableContainer } from "../shared/table";

const OuterTableContainer = styled.div`
  display: flex;
  overflow-x: auto;
  > div {
    margin-bottom: 10px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
`;
const StyledDiv = styled.div`
  font-size: 13px;
  /* font-weight: 500; */
  color: #959695;
`;

const SubContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
  > span {
    margin-right: 5px;
  }
`;

const fieldGroupName = "MoistureLoadResult.ListVisualizer";

export function ListView({
  moistureLoadItem,
  results,
  sharedState,
  propertiesToShow,
  onFormatChanged,
  onFormatCleared
}: {
  readonly moistureLoadItem: NonNullable<
    GraphQLTypes.MoistureLoadCalculationUserQuery["user"]["moistureLoadByMoistureLoadNo"]
  >;
  readonly results: ReadonlyArray<PropertyValueSet.PropertyValueSet>;
  readonly sharedState: SharedState.State;
  readonly propertiesToShow: ReadonlyArray<string>;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
}) {
  const filteredResults = results.map(x =>
    PropertyValueSet.keepProperties(propertiesToShow, x)
  );

  const distinctPropertyNames = filteredResults.reduce(
    (propertyNames: string[], pvs) => {
      propertyNames = propertyNames.concat(
        PropertyValueSet.getPropertyNames(pvs)
      );
      return Array.from(new Set(propertyNames));
    },
    []
  );

  const tableCut = 2;

  const [height, setHeight] = React.useState(10);

  const thRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    setHeight(thRef.current?.getBoundingClientRect().height!);
  });

  return (
    <>
      <SubContainer>
        <Exporter.XlsxSingleSheetExport
          data={createDataForExcelExporter(
            createMoistureLoadRows(results, staticBinNames, sharedState),
            createHeaders(
              results,
              staticBinNames,
              sharedState,
              onFormatChanged,
              onFormatCleared
            )
          )}
          filename={
            sharedState.genesysPrefix.moistureLoadNo(
              moistureLoadItem.moistureloadFile.moistureLoadNo,
              moistureLoadItem.revisionNo
            ) + "MLBinCases"
          }
        >
          {onClick => (
            <StandardButton
              buttonType={"SecondaryBlue"}
              size={"Small"}
              onClick={onClick}
            >
              {sharedState.translate(LanguageTexts.exportBinsAsStandard())}
            </StandardButton>
          )}
        </Exporter.XlsxSingleSheetExport>
      </SubContainer>
      <OuterTableContainer>
        <div>
          <MoistureLoadTable
            useMaxContent
            alignRows="center"
            tableHeaderHeight={`${height}px`}
            headers={createHeaders(
              filteredResults,
              distinctPropertyNames.slice(0, tableCut),
              sharedState,
              onFormatChanged,
              onFormatCleared
            )}
            rows={createMoistureLoadRows(
              filteredResults,
              distinctPropertyNames.slice(0, tableCut),
              sharedState
            )}
          />
        </div>

        <OverflowTableContainer>
          <MoistureLoadTable
            thRef={thRef}
            useMaxContent
            alignRows="center"
            headers={createHeaders(
              filteredResults,
              distinctPropertyNames.slice(tableCut),
              sharedState,
              onFormatChanged,
              onFormatCleared
            )}
            rows={createMoistureLoadRows(
              filteredResults,
              distinctPropertyNames.slice(tableCut),
              sharedState
            )}
          />
        </OverflowTableContainer>
      </OuterTableContainer>
    </>
  );
}

export function createMoistureLoadRows(
  results: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  distinctPropertyNames: ReadonlyArray<string>,
  sharedState: SharedState.State,
  useMaxDecimalCount?: true
) {
  return results.map(res => {
    const results = distinctPropertyNames.map(propertyName => {
      const propertyValue = PropertyValueSet.getValue(propertyName, res);
      if (propertyValue !== undefined) {
        if (propertyValue.type === "amount") {
          return getValue(
            propertyValue,
            sharedState.screenAmounts.getAmountFormat(
              fieldGroupName,
              propertyName,
              PropertyValue.getAmount(propertyValue)!
            ),
            undefined,
            useMaxDecimalCount
          );
        }
        return propertyValue.value.toString();
      }
      return "";
    });

    return {
      results: results
    };
  });
}

export const staticBinNames = [
  "mlcbinid",
  "airmidpointtemperature",
  "mlcoutdoortemperature",
  "mlcoutdoorabshumidity",
  "mlcwindvelocity",
  "mlctime",
  "mlcindoorabshumidity",
  "mlcindoortemperature",
  "mlcbuildingmoistureload",
  "mlcbuildingheatload",
  "mlcatmosphericpressure",
  "mlctimejanuary",
  "mlctimefebruary",
  "mlctimemarch",
  "mlctimeapril",
  "mlctimemay",
  "mlctimejune",
  "mlctimejuly",
  "mlctimeaugust",
  "mlctimeseptember",
  "mlctimeoctober",
  "mlctimejunenovember",
  "mlctimejunedecember"
];

function createDataForExcelExporter(
  rows: ReadonlyArray<{
    readonly results: string[];
  }>,
  headers: ReadonlyArray<{
    readonly header: React.ReactNode;
    readonly amountName: string | undefined;
    readonly name: string;
    readonly visible: boolean;
    readonly amountFormatSelector: () => JSX.Element | null;
  }>
) {
  return [
    headers.map(x => `${x.name}${x.amountName ? "(" + x.amountName + ")" : ""}`)
  ].concat(rows.map(x => [...x.results]));
}

function createHeaders(
  results: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  distinctPropertyNames: string[],
  sharedState: SharedState.State,
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void,
  onFormatCleared: (fieldGroup: string, fieldName: string) => void
): ReadonlyArray<{
  readonly header: React.ReactNode;
  readonly name: string;
  readonly visible: boolean;
  readonly amountName: string | undefined;
  readonly amountFormatSelector: () => JSX.Element | null;
}> {
  const uniquePropertieValues = getUniquePropertieValues(results);
  return distinctPropertyNames.map(propertyName => {
    const name = sharedState.translate(
      LanguageTexts.globalPropertyName("pp_" + propertyName + "_st")
    );

    const propertyValue = uniquePropertieValues[propertyName];

    let amountFormat: ScreenAmounts.AmountFormat | undefined = undefined;

    //can only happen for bindata
    if (propertyValue && propertyValue.type === "amount") {
      amountFormat = sharedState.screenAmounts.getAmountFormat(
        fieldGroupName,
        propertyName,
        PropertyValue.getAmount(propertyValue)!
      );
    }
    return {
      name,
      amountName: amountFormat?.unit.name,
      header: (
        <ToolTipWrapper
          padding="0px"
          title={sharedState.translate(
            LanguageTexts.globalPropertyName("pp_" + propertyName)
          )}
        >
          <StyledDiv>{name}</StyledDiv>
        </ToolTipWrapper>
      ),
      visible: true,
      amountFormatSelector: () => {
        if (!amountFormat) {
          return null;
        }

        return (
          <FlexContainer>
            <span> [ </span>
            <AmountFormatSelector
              type="AmountFormatSelectorProps"
              fieldName={propertyName}
              fieldGroup={fieldGroupName}
              amountFormat={amountFormat}
              conversionParameters={undefined}
              translate={sharedState.translate}
              onFormatCleared={() => {
                onFormatCleared(fieldGroupName, propertyName);
              }}
              onFormatChanged={(unit, decimalCount) => {
                onFormatChanged(
                  fieldGroupName,
                  propertyName,
                  unit,
                  decimalCount
                );
              }}
            />
            <span> ] </span>
          </FlexContainer>
        );
      }
    };
  });
}

function getUniquePropertieValues(
  results: ReadonlyArray<PropertyValueSet.PropertyValueSet>
) {
  return results.reduce(
    (
      propertyValueMap: {
        // tslint:disable-next-line:readonly-keyword
        [propertyName: string]: PropertyValue.PropertyValue;
      },
      result
    ) => {
      for (const propertyName of PropertyValueSet.getPropertyNames(result)) {
        propertyValueMap[propertyName] = PropertyValueSet.get(
          propertyName,
          result
        )!;
      }

      return propertyValueMap;
    },
    {}
  ) as {
    readonly [propertyName: string]: PropertyValue.PropertyValue;
  };
}
