import * as React from "react";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import * as SharedState from "../../../shared-state";
import * as Product from "../../product";
import * as PropertiesSelector from "../../../properties-selector";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as KnownProperties from "./known-properties";
import {
  Root,
  OpcSelectorsContainer,
  OptionsTable,
  CheckBoxContainer,
  CasesContainer,
  PropertyNamesContainer,
  CardContainer,
  PropertiesSelectorJsxContainer,
  DesignContainer,
  OptionsTableContainer,
  LocationContainer,
  ClimateSelectorContainer,
  DeleteButtonContainer,
  AddNewCaseButtonContainer,
  StyledP2,
  CaseNameValidation,
  GreyDiv
} from "./elements";
import { Amount } from "uom";
import {
  PropertyValueSet,
  PropertyValue,
  PropertyFilter
} from "@genesys/property";
import { Quantity } from "@genesys/uom";
import {
  P1,
  P2,
  CheckBox,
  StandardButton,
  Trash,
  OpenBlue
} from "@genesys/ui-elements";
import { AmountPropertySelector } from "../../../amount-property-selector";
import { ClimateDataPresenter } from "../../../climate-data-presenter";
import * as ClimateSelector from "../../../climate-selector";
import * as Guid from "@genesys/shared/lib/guid";

export function DataCenterEditorView({
  state,
  sharedState,
  dispatch,
  systemStatus,
  sysProduct,
  opcProduct
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly systemStatus: number;
  readonly sysProduct: Product.Product;
  readonly opcProduct: Product.Product;
}): JSX.Element {
  const getUserValueSource = (filterString: string) => ({
    id: Guid.createGuid().value,
    value: 1,
    propertyValueSourceId: "User",
    claimFilter: "",
    propertyFilter:
      PropertyFilter.fromString(filterString) ?? PropertyFilter.Empty,
    parameters: undefined
  });

  const numberOfUnitsOperating = PropertyValueSet.getInteger(
    KnownProperties.numberOfNormalUnits,
    PropertiesSelector.getSelectedProperties(state.designSelector)
  )!;
  const operatingRedundancy = PropertyValueSet.getInteger(
    KnownProperties.numberOfRedundantUnits,
    PropertiesSelector.getSelectedProperties(state.designSelector)
  )!;

  const modifiedOpcProduct = {
    ...opcProduct,
    properties: opcProduct.properties.map(p => {
      const values =
        p.name === KnownProperties.numberOfNormalUnitsOperating
          ? p.items.filter(
              v => PropertyValue.getInteger(v.value)! <= numberOfUnitsOperating
            )
          : p.name === KnownProperties.numberOfRedundantUnitsOperating
          ? p.items.filter(
              v => PropertyValue.getInteger(v.value)! <= operatingRedundancy
            )
          : p.items;

      const valueSources =
        p.name === KnownProperties.supplyTargetTemperature ||
        p.name === KnownProperties.returnAirTemperature ||
        p.name === KnownProperties.iteLoadPercent ||
        p.name === KnownProperties.numberOfNormalUnitsOperating ||
        p.name === KnownProperties.numberOfRedundantUnitsOperating
          ? p.valueSources.concat([getUserValueSource("sortno>1")])
          : p.valueSources;

      return { ...p, values: values, valueSources: valueSources };
    })
  };

  const designProperties = [
    ...opcProduct.properties.filter(p =>
      [
        KnownProperties.supplyTargetTemperature,
        KnownProperties.returnAirTemperature,
        KnownProperties.returnAirHumidity
      ].some(n => n === p.name)
    ),
    ...sysProduct.properties.filter(p =>
      [
        KnownProperties.numberOfNormalUnits,
        KnownProperties.numberOfRedundantUnits,
        KnownProperties.iteLoad
      ].some(n => n === p.name)
    )
  ];

  const designPropertyInfo = designProperties.map(p => {
    return {
      ...p,
      valueSources:
        p.name === KnownProperties.numberOfNormalUnits
          ? p.valueSources.concat([getUserValueSource("")])
          : []
    };
  });

  const showCodes = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  const isNumberOfUnitsSourceUser =
    PropertyValueSet.getInteger(
      "source_" + KnownProperties.numberOfNormalUnits,
      PropertiesSelector.getSelectedProperties(state.designSelector)
    ) === 1;

  const opcSelectorStates = state.opcSelectors;

  const isNumberOfUnitsSameAsRecommended = state.options.find(
    o => o.sizeValue === state.selectedSize
  )
    ? state.options.find(o => o.sizeValue === state.selectedSize)!
        .numberOfUnits ===
      PropertyValueSet.getInteger(
        KnownProperties.numberOfNormalUnits,
        PropertiesSelector.getSelectedProperties(state.designSelector)
      )
    : true;

  const isLocked = systemStatus >= SystemStatus.LockSuccess;

  return (
    <Root>
      {state.isOpenClimateSelector ? (
        <ClimateSelectorContainer>
          <ClimateSelector.ClimateSelectorView
            state={state.climateSelectorState}
            isDisabled={isLocked}
            sharedState={sharedState}
            showWindVelocity={false}
            dispatch={Dispatch.map(Action.dispatchClimateSelector, dispatch)}
            onChange={() =>
              dispatch(
                Action.onSaveClimateSelector(
                  ClimateSelector.getClimateSettings(
                    state.climateSelectorState
                  ),
                  opcProduct.properties
                )
              )
            }
            onClose={() => dispatch(Action.setIsOpenClimateSelector(false))}
          />
        </ClimateSelectorContainer>
      ) : (
        <LocationContainer>
          <ClimateDataPresenter
            title={sharedState.translate(LanguageTexts.location())}
            climateSettings={state.climateSelectorState.climateSettings}
            sharedState={sharedState}
            onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) =>
              dispatch(
                Action.onFormatChanged(
                  fieldGroup,
                  fieldName,
                  unit,
                  decimalCount
                )
              )
            }
            onFormatCleared={(fieldGroup, fieldName) =>
              dispatch(Action.onFormatCleared(fieldGroup, fieldName))
            }
          />
          <StandardButton
            buttonType="PrimaryBlue"
            size="Large"
            onClick={() => dispatch(Action.setIsOpenClimateSelector(true))}
          >
            {sharedState.translate(LanguageTexts.selectLocation())}
          </StandardButton>
        </LocationContainer>
      )}

      <DesignContainer>
        <PropertiesSelector.PropertiesSelectorView
          dispatch={Dispatch.map(
            action =>
              Action.dispatchDesignSelector(action, isNumberOfUnitsSourceUser),
            dispatch
          )}
          productId={modifiedOpcProduct.id}
          sharedState={sharedState}
          propertiesInfo={designPropertyInfo}
          fieldGroup={`Settings.${modifiedOpcProduct.id}`}
          hideGroupNames={true}
          isReadonly={isLocked}
          showCodes={showCodes}
          state={state.designSelector}
        />

        <OptionsTableContainer>
          <OptionsTable>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>
                  <P1 color="dark" weight="bold">
                    {"N + 0"}
                  </P1>
                </th>
                <th>
                  <P1 color="dark" weight="bold">
                    {"N + 0"}
                  </P1>
                </th>
                <th>
                  <P1 color="dark" weight="bold">
                    {"N + " + operatingRedundancy}
                  </P1>
                </th>
                <th>
                  <P1 color="dark" weight="bold">
                    {"N + " + operatingRedundancy}
                  </P1>
                </th>
              </tr>
              <tr>
                <th></th>
                <th>
                  <P1 color="dark" weight="bold">
                    {sharedState.translate(
                      LanguageTexts.productProperty(
                        sysProduct.id,
                        KnownProperties.epxSize
                      )
                    )}
                  </P1>
                </th>
                <th>
                  <P1 color="dark" weight="bold">
                    {sharedState.translate(
                      LanguageTexts.suggestedNumberOfUnits()
                    )}
                  </P1>
                </th>
                <th>
                  <P1 color="dark" weight="bold">
                    {sharedState.translate(LanguageTexts.unitNominalAirflow())}
                  </P1>
                </th>
                <th>
                  <P1 color="dark" weight="bold">
                    {sharedState.translate(LanguageTexts.flowPerUnit())}
                  </P1>
                </th>
                <th>
                  <P1 color="dark" weight="bold">
                    {sharedState.translate(LanguageTexts.powerPerUnit())}
                  </P1>
                </th>
                <th>
                  <P1 color="dark" weight="bold">
                    {sharedState.translate(LanguageTexts.flowPerUnit())}
                  </P1>
                </th>
                <th>
                  <P1 color="dark" weight="bold">
                    {sharedState.translate(LanguageTexts.powerPerUnit())}
                  </P1>
                </th>
              </tr>
            </thead>

            {state.options.map(option => {
              const showBeLineThrough =
                option.sizeValue === state.selectedSize &&
                !isNumberOfUnitsSameAsRecommended;

              return (
                <tbody key={option.sizeValue}>
                  <tr>
                    <td>
                      <CheckBoxContainer>
                        <CheckBox
                          isChecked={state.selectedSize === option.sizeValue}
                          children=""
                          onClick={() =>
                            dispatch(
                              Action.selectOption(
                                option,
                                isNumberOfUnitsSourceUser
                              )
                            )
                          }
                        />
                      </CheckBoxContainer>
                    </td>
                    <td>
                      <P2 color="secondary" weight="normal">
                        {sharedState.translate(
                          LanguageTexts.productPropertyValue(
                            sysProduct.id,
                            KnownProperties.epxSize,
                            option.sizeValue
                          )
                        )}
                      </P2>
                    </td>
                    <td>
                      <StyledP2
                        color="secondary"
                        weight="normal"
                        showLineThrough={showBeLineThrough}
                      >
                        {option.numberOfUnits ? option.numberOfUnits : "-"}
                      </StyledP2>
                    </td>
                    <td>
                      <StyledP2
                        color="secondary"
                        weight="normal"
                        showLineThrough={showBeLineThrough}
                      >
                        {option.nominalAirflow
                          ? amountDisplayer<Quantity.VolumeFlow>(
                              "nominalFlowPerUnit",
                              option.nominalAirflow,
                              sharedState,
                              dispatch
                            )
                          : "-"}
                      </StyledP2>
                    </td>
                    <td>
                      <StyledP2
                        color="secondary"
                        weight="normal"
                        showLineThrough={showBeLineThrough}
                      >
                        {option.volumeFlowPerUnit
                          ? amountDisplayer<Quantity.VolumeFlow>(
                              "flowPerUnit",
                              option.volumeFlowPerUnit,
                              sharedState,
                              dispatch
                            )
                          : "-"}
                      </StyledP2>
                    </td>
                    <td>
                      <StyledP2
                        color="secondary"
                        weight="normal"
                        showLineThrough={showBeLineThrough}
                      >
                        {option.powerPerUnit
                          ? amountDisplayer<Quantity.Power>(
                              "powerPerUnit",
                              option.powerPerUnit,
                              sharedState,
                              dispatch
                            )
                          : "-"}
                      </StyledP2>
                    </td>
                    <td>
                      <StyledP2
                        color="secondary"
                        weight="normal"
                        showLineThrough={showBeLineThrough}
                      >
                        {option.volumeFlowPerUnitWithRedundancy
                          ? amountDisplayer<Quantity.VolumeFlow>(
                              "flowPerUnit",
                              option.volumeFlowPerUnitWithRedundancy,
                              sharedState,
                              dispatch
                            )
                          : "-"}
                      </StyledP2>
                    </td>
                    <td>
                      <StyledP2
                        color="secondary"
                        weight="normal"
                        showLineThrough={showBeLineThrough}
                      >
                        {option.powerPerUnitWithRedundancy
                          ? amountDisplayer<Quantity.Power>(
                              "powerPerUnit",
                              option.powerPerUnitWithRedundancy,
                              sharedState,
                              dispatch
                            )
                          : "-"}
                      </StyledP2>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </OptionsTable>
        </OptionsTableContainer>
      </DesignContainer>

      <OpcSelectorsContainer>
        <AddNewCaseButtonContainer>
          <StandardButton
            icon={OpenBlue}
            size="Small"
            buttonType="SecondaryBlue"
            onClick={() =>
              dispatch(
                Action.onAddNewOperatingCase(modifiedOpcProduct.properties)
              )
            }
          >
            {sharedState.translate(LanguageTexts.addNew())}
          </StandardButton>
        </AddNewCaseButtonContainer>
        <CasesContainer>
          <PropertyNamesContainer>
            <PropertiesSelectorJsxContainer isFirstCard={true}>
              <PropertiesSelector.PropertiesSelectorView
                dispatch={Dispatch.map(
                  action =>
                    Action.dispatchOpcSelector(
                      state.opcSelectors[0].id,
                      action,
                      modifiedOpcProduct
                    ),
                  dispatch
                )}
                productId={modifiedOpcProduct.id}
                sharedState={sharedState}
                propertiesInfo={modifiedOpcProduct.properties}
                fieldGroup={`Settings.${modifiedOpcProduct.id}`}
                isReadonly={isLocked}
                showCodes={showCodes}
                state={state.opcSelectors[0].state}
              />
            </PropertiesSelectorJsxContainer>
          </PropertyNamesContainer>

          {opcSelectorStates.map((opcs, ix) => (
            <CardContainer key={opcs.id}>
              {PropertyValueSet.getText("casename", opcs.state.properties)
                ?.length === 0 && <CaseNameValidation />}
              <>
                <PropertiesSelectorJsxContainer isFirstCard={false}>
                  <PropertiesSelector.PropertiesSelectorView
                    dispatch={Dispatch.map(
                      action =>
                        Action.dispatchOpcSelector(
                          opcs.id,
                          action,
                          modifiedOpcProduct
                        ),
                      dispatch
                    )}
                    productId={modifiedOpcProduct.id}
                    sharedState={sharedState}
                    propertiesInfo={modifiedOpcProduct.properties}
                    fieldGroup={`Settings.${modifiedOpcProduct.id}`}
                    isReadonly={isLocked}
                    showCodes={showCodes}
                    state={opcs.state}
                    hidePropertyNames={true}
                  />
                </PropertiesSelectorJsxContainer>
                <DeleteButtonContainer>
                  {ix > 1 && (
                    <a
                      onClick={() =>
                        dispatch(
                          Action.onDeleteOperatingCase(
                            opcs.id,
                            modifiedOpcProduct.properties
                          )
                        )
                      }
                    >
                      <Trash height="1.6rem" />
                    </a>
                  )}
                </DeleteButtonContainer>
              </>
            </CardContainer>
          ))}
        </CasesContainer>
      </OpcSelectorsContainer>
    </Root>
  );
}

function amountDisplayer<T extends Quantity.Quantity>(
  propertyName: string,
  amount: Amount.Amount<T>,
  sharedState: SharedState.State,
  dispatch: Dispatch<Action>
) {
  const fieldGroup = "DataCenterEditor";
  return (
    <GreyDiv>
      <AmountPropertySelector
        type="with-simplified-props"
        translate={sharedState.translate}
        fieldGroup={fieldGroup}
        fieldName={propertyName}
        readOnly={true}
        propertyName={propertyName}
        propertyValueSet={PropertyValueSet.fromProperty(
          propertyName,
          PropertyValue.fromAmount(amount)
        )}
        onFormatChanged={(unit, decimalCount) =>
          dispatch(
            Action.onFormatChanged(fieldGroup, propertyName, unit, decimalCount)
          )
        }
        onFormatCleared={() =>
          dispatch(Action.onFormatCleared(fieldGroup, propertyName))
        }
        onValueChange={value => console.log(value)}
        getAmountFormat={sharedState.screenAmounts.getAmountFormat}
        quantity={amount.unit.quantity}
      />
    </GreyDiv>
  );
}

//tslint:disable-next-line
