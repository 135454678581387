import React from "react";
import styled from "../../styled-components";
import { exhaustiveCheck } from "ts-exhaustive-check";

export type ButtonType =
  | "PrimaryBlue"
  | "SecondaryBlue"
  | "PrimaryGreen"
  | "SecondaryGreen";

export type Size = "Large" | "Small";

type ExtraProps = {
  readonly buttonType: ButtonType;
  readonly size: Size;
  readonly icon?: (props: {
    readonly width: number;
    readonly height: number;
  }) => JSX.Element;
};

// tslint:disable-next-line
const StyledButton = styled.button<ExtraProps>`
  border-radius: 3px;
  font-family: Futura;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  /* letter-spacing: 0.5px; */
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;

  img {
    margin-right: 8px;
  }
  ${props => !props.disabled && "cursor:pointer;"}

  &:disabled {
    opacity: 0.5;
  }

  padding: ${props => getPadding(props.size)};

  ${props => getColorStyles(props)}
  ${props => getBorderStyles(props)}
`;

function getPadding(size: Size): string {
  switch (size) {
    case "Large": {
      return "12px 28px";
    }
    case "Small": {
      return "7px 12px";
    }
    default: {
      return exhaustiveCheck(size, true);
    }
  }
}

export function getBorderStyles(
  props: ExtraProps & React.ButtonHTMLAttributes<HTMLButtonElement>
): string {
  switch (props.buttonType) {
    case "SecondaryBlue": {
      return `
      border: 1px solid #CBEEFD;
      &:hover {
        ${!props.disabled && "border: 1px solid #D2D5D8;"}
      
      }
      &:disabled {
        border: 1px solid rgba(0,173,242,0.3);
      }
      `;
    }
    case "SecondaryGreen": {
      return `
      border: 1px solid #46D580;
      &:hover {
        ${!props.disabled && " border: 1px solid #D2D5D8;"}
       
      }
      &:disabled {
        border: 1px solid #DAF6E5;
      }
      `;
    }
    case "PrimaryBlue":
    case "PrimaryGreen": {
      return "";
    }
    default: {
      return exhaustiveCheck(props.buttonType, true);
    }
  }
}
export function getColorStyles(
  props: ExtraProps & React.ButtonHTMLAttributes<HTMLButtonElement>
): string {
  switch (props.buttonType) {
    case "PrimaryBlue": {
      return `
        background-color: #00ADF2;
        color: white;

        &:hover {
          ${!props.disabled && " background-color:#4BCCFF;"}
          
        }
      `;
    }
    case "SecondaryBlue": {
      return `
        color: #00ADF2;
        background-color: #F3FBFE;

        &:hover {
          ${!props.disabled && "background-color: white;"}
         
        }
      `;
    }
    case "PrimaryGreen": {
      return `
        background-color: #46D580;
        color: white;

        &:hover {
          ${!props.disabled && " background-color:#54E990;"}
        
        }
      `;
    }
    case "SecondaryGreen": {
      return `
        color: #46D580;
        background-color:rgb(227,249,236);

        &:hover {
          ${!props.disabled && "background-color:white;"}
        }
      `;
    }
    default: {
      return exhaustiveCheck(props.buttonType, true);
    }
  }
}

export function StandardButton({
  children,
  icon: TheIcon,
  onClick,
  ...buttonProps
}: ExtraProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <StyledButton
      onClick={e => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick(e);
        }

        // tslint:disable-next-line
      }}
      {...buttonProps}
    >
      {TheIcon && <TheIcon width={20} height={20} />}
      {children}
    </StyledButton>
  );
}
