import { exhaustiveCheck } from "ts-exhaustive-check";
import { PrimaryColors } from "../../colors";

export type Options = {
  readonly color: "primary" | "dark" | "secondary" | "light" | "white";
  readonly weight: "light" | "normal" | "bold";
};
export function getStyles(opts: Options): string {
  return `
    color: ${getColor(opts.color)};
    font-weight: ${getWeight(opts.weight)};
    `;
}

function getWeight(weight: Options["weight"]): string {
  switch (weight) {
    case "light": {
      return "200";
    }
    case "normal": {
      return "normal";
    }
    case "bold": {
      return "bold";
    }
    default: {
      return exhaustiveCheck(weight, true);
    }
  }
}

function getColor(color: Options["color"]): string {
  switch (color) {
    case "primary": {
      return PrimaryColors.muntersBlue;
    }
    case "dark": {
      return PrimaryColors.darkBlue;
    }
    case "secondary": {
      return PrimaryColors.grey;
    }
    case "light": {
      return PrimaryColors.grey75;
    }
    case "white": {
      return "white";
    }
    default: {
      return exhaustiveCheck(color, true);
    }
  }
}
