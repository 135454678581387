import * as React from "react";
import * as PropertiesSelector from "../../../properties-selector";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as GraphQLTypes from "../../../graphql-types";
import styled from "styled-components";
import { Dispatch } from "@typescript-tea/core";
import { PropertyDefinitions, SystemOverrides } from "../../tools";
import { PropertyFilter } from "@genesys/property";
import { EditBlue, Pop, H3 } from "@genesys/ui-elements";
import { Action } from "./state";

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  padding: 10px;
`;
const NotOpenContainer = styled.span`
  :hover {
    cursor: pointer;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export function View({
  sharedState,
  overridableProperties,
  systemOverrides,
  useAsPop,
  isDisabled,
  fieldGroups,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly fieldGroups: string;
  readonly useAsPop?: boolean;
  readonly systemOverrides: SystemOverrides;
  readonly isDisabled: boolean;
  readonly overridableProperties: PropertyDefinitions;
  readonly dispatch: Dispatch<Action>;
}) {
  const labelRef = React.useRef(null);

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = () => {
    setAnchorEl(labelRef.current);
  };

  const handleClose = () => {
    // setOpen(false)
    setAnchorEl(null);
  };

  function getSelectedProperties(caseKey: GraphQLTypes.OperatingCaseGroupEnum) {
    return systemOverrides[caseKey];
  }

  const usedProperties = overridableProperties.reduce(
    (soFar, property) => {
      const used = (
        Object.keys(
          GraphQLTypes.OperatingCaseGroupEnum
        ) as ReadonlyArray<GraphQLTypes.OperatingCaseGroupEnum>
      ).some(operatingCaseGroup => {
        const selectedProperties = getSelectedProperties(operatingCaseGroup);
        return PropertyFilter.isValid(
          selectedProperties,
          property.visibilityFilter
        );
      });

      soFar[property.name] = used;
      return soFar;
    },
    // tslint:disable-next-line:readonly-keyword
    {} as { [propertyName: string]: boolean }
  );

  const visibleProperties = overridableProperties.filter(
    property => usedProperties[property.name]
  );

  const fields = [
    {
      key: GraphQLTypes.OperatingCaseGroupEnum.DESIGN_SUMMER,
      title: "mlcdesignsummer"
    },
    {
      key: GraphQLTypes.OperatingCaseGroupEnum.DESIGN_WINTER,
      title: "mlcdesignwinter"
    }
  ].concat(
    [
      GraphQLTypes.OperatingCaseGroupEnum.JANUARY,
      GraphQLTypes.OperatingCaseGroupEnum.FEBRUARY,
      GraphQLTypes.OperatingCaseGroupEnum.MARCH,
      GraphQLTypes.OperatingCaseGroupEnum.APRIL,
      GraphQLTypes.OperatingCaseGroupEnum.MAY,
      GraphQLTypes.OperatingCaseGroupEnum.JUNE,
      GraphQLTypes.OperatingCaseGroupEnum.JULY,
      GraphQLTypes.OperatingCaseGroupEnum.AUGUST,
      GraphQLTypes.OperatingCaseGroupEnum.SEPTEMBER,
      GraphQLTypes.OperatingCaseGroupEnum.OCTOBER,
      GraphQLTypes.OperatingCaseGroupEnum.NOVEMBER,
      GraphQLTypes.OperatingCaseGroupEnum.DECEMBER
    ].map(key => ({ key, title: key }))
  );

  const content = (
    <Container>
      {fields.map((x, i) => {
        return (
          <FlexContainer>
            <H3 weight="normal">
              {sharedState.translate(LanguageTexts.globalPropertyName(x.title))}
            </H3>
            <PropertiesSelector.PropertiesSelectorView
              dispatch={action =>
                dispatch(Action.dispatchPropertiesSelector(action, x.key))
              }
              hidePropertyNames={i % 5 !== 0}
              translatePropertyName={propertyName =>
                sharedState.translate(
                  LanguageTexts.globalPropertyName(propertyName)
                )
              }
              translatePropertyValue={(propertyName, propertyValue) =>
                sharedState.translate(
                  LanguageTexts.globalPropertyName(
                    propertyName + "_" + propertyValue
                  )
                )
              }
              productId={""}
              sharedState={sharedState}
              propertiesInfo={visibleProperties}
              readonlyProperties={visibleProperties.reduce(
                (definitionsSoFar, current) => {
                  if (
                    current.enabled_filter &&
                    !PropertyFilter.isValid(
                      PropertiesSelector.getSelectedProperties({
                        properties: systemOverrides[x.key]
                      }),
                      current.enabled_filter
                    )
                  ) {
                    definitionsSoFar.push(current.name);
                  }
                  return definitionsSoFar;
                },
                [] as Array<string>
              )}
              fieldGroup={`MoistureLoadTool.${fieldGroups}`}
              isReadonly={isDisabled}
              showCodes={false}
              state={{
                properties: systemOverrides[x.key]
              }}
            />
          </FlexContainer>
        );
      })}
    </Container>
  );

  if (useAsPop) {
    return (
      <>
        <NotOpenContainer ref={labelRef} onClick={handleClick}>
          <EditBlue height="20px" />
        </NotOpenContainer>

        {anchorEl && (
          <Pop
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            anchor={anchorEl}
            onClose={handleClose}
          >
            {content}
          </Pop>
        )}
      </>
    );
  } else {
    return content;
  }
}

// function createPropertyDefs() {}
