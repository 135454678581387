import * as React from "react";
import { State, Action } from "../../state";
import * as SharedState from "../../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import {
  P2,
  P1,
  OverlayLoader,
  StandardButton,
  CheckBox,
  ExpandIcon,
  CollapseIcon,
  Spinner,
  StatusComplete
} from "@genesys/ui-elements";
import {
  Root,
  CheckBoxContainer,
  PopupRoot,
  Popup,
  P2WithCursor,
  PopupContainer,
  MainGridContainer,
  GridRowCell,
  GridHeaderCell,
  InfoCell,
  GroupCell,
  GridRowsCell,
  SubGridContainer,
  ThickPaddingContainer,
  CreatedSystemDialogRoot,
  ShowInvalidSystemsCheckboxContainer,
  MessageContainer,
  ErrorIconWithCursor,
  WarningIconWithCursor,
  PaddingBottomContainer
} from "./elements";
import { hasVariantsGenerationDataChanged } from "../../system-variant-functions";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import * as Types from "../../types";
import { calculateSystems, createSystem } from "./functions";
import {
  PropertyFilter,
  PropertyValue,
  PropertyValueSet
} from "@genesys/property";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Amount, Units } from "@genesys/uom";
import { RootMessage } from "@genesys/shared/src/components-messages";
import { GetAmountFormat } from "@genesys/shared/src/screen-amounts";
import { formattedMessage } from "../../functions";

// TODO languagetexts

export function SuggestedOptionsView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  if (state.systemTypes.find(s => s.isSelected) === undefined) {
    return (
      <Root>
        <ThickPaddingContainer>
          <P1 color="dark" weight="normal">
            No Product range selected
          </P1>
        </ThickPaddingContainer>
      </Root>
    );
  }

  if (
    state.operatingCaseSelectorState === undefined ||
    state.systemVariantsGroups.length === 0 ||
    hasVariantsGenerationDataChanged(state)
  ) {
    return (
      <Root>
        <Spinner />
      </Root>
    );
  }

  return (
    <Root>
      {state.showLoader && <OverlayLoader />}
      {state.createdSystemUrl && (
        <CreatedSystemDialog
          url={state.createdSystemUrl!}
          dispatch={dispatch}
        />
      )}

      <MainGridContainer>
        <GridRowCell x={1} y={1}></GridRowCell>
        <GridRowCell x={2} y={1}>
          <P1 color="dark" weight="bold">
            Calculate
          </P1>
        </GridRowCell>
        <GridRowCell x={3} y={1}>
          <P1 color="dark" weight="bold">
            #
          </P1>
        </GridRowCell>
        <GridRowCell x={4} y={1}>
          <P1 color="dark" weight="bold">
            Product
          </P1>
        </GridRowCell>
        <GridRowCell x={5} y={1}>
          <P1 color="dark" weight="bold">
            Variant
          </P1>
        </GridRowCell>
        <GridRowCell x={6} y={1}>
          <P1 color="dark" weight="bold">
            Pre-Validation
          </P1>
          <ShowInvalidSystemsCheckboxContainer>
            <CheckBox
              isChecked={state.showInvalidSystems}
              onClick={() => dispatch(Action.toogleShowInvalidSystems())}
              children={"Show invalid systems"}
            />
          </ShowInvalidSystemsCheckboxContainer>
        </GridRowCell>
        <GridRowCell x={7} y={1}>
          <P1 color="dark" weight="bold">
            Quantity
          </P1>
        </GridRowCell>
        <GridRowCell x={8} y={1}>
          <P1 color="dark" weight="bold">
            Errors/Warnings
          </P1>
        </GridRowCell>
        <GridRowCell x={9} y={1}></GridRowCell>

        {state.systemVariantsGroups.map((svg, ix) => (
          <GroupCell y={svg.systemVariants.length + 1}>
            <MainGridContainer>
              <GridHeaderCell
                onClick={() => dispatch(Action.toggleExpandedVariantsGroup(ix))}
              >
                <div>
                  {svg.isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                  {!svg.isExpanded && (
                    <P2 color="secondary" weight="normal">
                      {svg.variantDetails.reduce((soFar, current, ix) => {
                        if (ix < svg.variantDetails.length - 1) {
                          return soFar + current + ", ";
                        } else {
                          return soFar + current;
                        }
                      }, "")}
                    </P2>
                  )}
                </div>
                <div>
                  <P2 color="secondary" weight="bold">
                    Valid systems:
                  </P2>
                  <P2 color="secondary" weight="normal">
                    {
                      svg.systemVariants.filter(
                        sv =>
                          sv.newProperties !== undefined &&
                          sv.invalidFlow === undefined
                      ).length
                    }
                  </P2>
                </div>
              </GridHeaderCell>

              <InfoCell y={svg.variantDetails.length + 1} show={svg.isExpanded}>
                {svg.variantDetails.map(vd => (
                  <P2 color="secondary" weight="normal">
                    {vd}
                  </P2>
                ))}
              </InfoCell>

              <GridRowsCell show={svg.isExpanded}>
                {svg.systemVariants
                  .filter(
                    sv =>
                      state.showInvalidSystems ||
                      (sv.newProperties !== undefined &&
                        sv.invalidFlow === undefined)
                  )
                  .map((sv, ix) => (
                    <SubGridContainer index={ix}>
                      <GridRowCell x={1} y={1}>
                        <CheckBoxContainer>
                          <CheckBox
                            children={""}
                            isChecked={
                              sv.shouldCalculate ||
                              sv.calculationResults !== undefined
                            }
                            onClick={() => {
                              if (!state.isCalculatingSystems) {
                                dispatch(
                                  Action.toogleCalculateSystem(sv.identifier)
                                );
                              }
                            }}
                            disabled={
                              sv.newProperties === undefined ||
                              sv.invalidFlow !== undefined ||
                              sv.calculationResults !== undefined
                            }
                          />
                        </CheckBoxContainer>
                      </GridRowCell>
                      <GridRowCell x={2} y={1}>
                        <P2 color="secondary" weight="normal">
                          {ix + 1}
                        </P2>
                      </GridRowCell>
                      <GridRowCell x={3} y={1}>
                        <P2 color="secondary" weight="normal">
                          {sv.systemType}
                        </P2>
                      </GridRowCell>
                      <GridRowCell x={4} y={1}>
                        <P2 color="secondary" weight="normal">
                          {sv.variant}
                        </P2>
                      </GridRowCell>
                      <GridRowCell x={5} y={1}>
                        <PreValidation systemVariant={sv} />
                      </GridRowCell>
                      <GridRowCell x={6} y={1}>
                        {sv.quantity !== 0 && (
                          <P2 color="secondary" weight="normal">
                            {sv.quantity}
                          </P2>
                        )}
                      </GridRowCell>
                      <GridRowCell x={7} y={1}>
                        {sv.calculationResults && (
                          <WarningsAndErrors
                            rootMessage={sv.calculationResults}
                            amountFormat={
                              sharedState.screenAmounts.getAmountFormat
                            }
                          />
                        )}
                      </GridRowCell>
                      <GridRowCell x={8} y={1}>
                        <MaybeCreateButton
                          systemVariant={sv}
                          state={state}
                          sharedState={sharedState}
                          dispatch={dispatch}
                        />
                      </GridRowCell>
                    </SubGridContainer>
                  ))}
              </GridRowsCell>
            </MainGridContainer>
          </GroupCell>
        ))}
      </MainGridContainer>

      <ThickPaddingContainer>
        <StandardButton
          size="Large"
          buttonType="PrimaryGreen"
          onClick={() => {
            const systemVariantToCalculate = state.systemVariantsGroups.reduce(
              (soFar, current) => {
                const hasSystemsToCalculate = current.systemVariants.some(
                  sv => sv.shouldCalculate
                );
                return hasSystemsToCalculate
                  ? soFar.concat(
                      current.systemVariants.filter(sv => sv.shouldCalculate)
                    )
                  : soFar;
              },
              [] as ReadonlyArray<Types.SystemVariant>
            );

            const systemInputs = systemVariantToCalculate.map(s => ({
              systemName: s.systemType + ": " + s.variant,
              systemTypeId: s.systemType,
              newProperties: s.newProperties!,
              operatingCases: s.operatingCases,
              climateData: state.operatingCaseSelectorState!.climateSettings,
              systemIdentifier: s.identifier
            }));
            calculateSystems(dispatch, sharedState, systemInputs);
          }}
          disabled={
            state.isCalculatingSystems ||
            state.systemVariantsGroups.find(svg =>
              svg.systemVariants.find(sv => sv.shouldCalculate)
            ) === undefined
          }
        >
          {state.isCalculatingSystems ? "Calculating" : "Calculate Systems"}
        </StandardButton>
      </ThickPaddingContainer>
    </Root>
  );
}

function WarningsAndErrors({
  amountFormat,
  rootMessage
}: {
  readonly rootMessage: RootMessage;
  readonly amountFormat: GetAmountFormat;
}): JSX.Element {
  const [isOpen, setState] = React.useState(false);
  const errors = [
    ...rootMessage.exception.componentMessages,
    ...rootMessage.error.componentMessages
  ];
  const warnings = rootMessage.warning.componentMessages;
  const hasErrors = errors.length > 0;
  const hasWarnings = warnings.length > 0;

  const icon = hasErrors ? (
    <ErrorIconWithCursor />
  ) : hasWarnings ? (
    <WarningIconWithCursor />
  ) : (
    <StatusComplete />
  );

  return (
    <PopupRoot>
      <PopupContainer
        onMouseEnter={() => setState(true)}
        onMouseLeave={() => setState(false)}
      >
        {icon}
        {isOpen && (hasErrors || hasWarnings) && (
          <Popup borderColor={hasErrors ? "red" : "yellow"} width={"narrow"}>
            <PaddingBottomContainer>
              {warnings.flatMap(w => (
                <MessageContainer key={w.componentId}>
                  <div>
                    {w.productName} {w.groups[0].entries[0].heading}
                  </div>
                  {w.groups.flatMap(g =>
                    g.entries.map((e, ix) => (
                      <P2 key={ix}>
                        {formattedMessage(g.code, e.parts, amountFormat)}
                      </P2>
                    ))
                  )}
                </MessageContainer>
              ))}
              {errors.flatMap(err => (
                <MessageContainer key={err.componentId}>
                  <div>
                    {err.productName} {err.groups[0].entries[0].heading}
                  </div>
                  {err.groups.flatMap(g =>
                    g.entries.map((e, ix) => (
                      <P2 key={ix}>
                        {formattedMessage(g.code, e.parts, amountFormat)}
                      </P2>
                    ))
                  )}
                </MessageContainer>
              ))}
            </PaddingBottomContainer>
          </Popup>
        )}
      </PopupContainer>
    </PopupRoot>
  );
}

function PreValidation({
  systemVariant
}: {
  readonly systemVariant: Types.SystemVariant;
}): JSX.Element {
  const [isOpen, setState] = React.useState(false);

  const popup = (
    label: string,
    width: "wide" | "narrow",
    content: JSX.Element
  ) => {
    return (
      <PopupRoot>
        <P2 color="secondary" weight="normal">
          {label}
        </P2>
        <PopupContainer
          onMouseEnter={() => setState(true)}
          onMouseLeave={() => setState(false)}
        >
          <P2WithCursor color="secondary" weight="normal">
            (Show details)
          </P2WithCursor>
          {isOpen && (
            <Popup borderColor="blue" width={width}>
              {content}
            </Popup>
          )}
        </PopupContainer>
      </PopupRoot>
    );
  };

  if (systemVariant.invalidSystemRequirements) {
    const propertyFilter =
      systemVariant.invalidSystemRequirements.propertyFilter;
    const selectionNewProperties =
      systemVariant.invalidSystemRequirements.selectionNewProperties;

    return popup(
      "System not compatible with Selection limitiations: ",
      "wide",
      propertyFilter ? (
        <PaddingBottomContainer>
          <div>
            <P2 color="dark" weight="normal">
              {`System requirements: `}
            </P2>
            <P2 color="secondary" weight="normal">
              {PropertyFilter.toString(propertyFilter)}
            </P2>
          </div>
          <div>
            <P2 color="dark" weight="normal">
              {`Selection limitations: `}
            </P2>
            <P2 color="secondary" weight="normal">
              {PropertyValueSet.toString(selectionNewProperties)}
            </P2>
          </div>
        </PaddingBottomContainer>
      ) : (
        <PaddingBottomContainer>
          <P2 color="dark" weight="normal">
            {`Missing systemrequirements row in promaster`}
          </P2>
        </PaddingBottomContainer>
      )
    );
  } else if (systemVariant.invalidProperties.length > 0) {
    return popup(
      "Invalid Properties: ",
      "wide",
      <div>
        {systemVariant.invalidProperties.map(p => {
          switch (p.type) {
            case "not-valid-with-configuration":
              return (
                <PaddingBottomContainer>
                  <div>
                    <P1 color="dark" weight="normal">{`${
                      p.propertyName
                    }=${PropertyValue.toString(
                      p.propertyValue
                    )} is not valid with generated configuration`}</P1>
                  </div>
                  <div>
                    <P2
                      color="secondary"
                      weight="normal"
                    >{`Property filter: ${PropertyFilter.toString(
                      p.propertyFilter
                    )}`}</P2>
                  </div>
                  <div>
                    <P2
                      color="secondary"
                      weight="normal"
                    >{`Generated configuration: ${PropertyValueSet.toString(
                      p.configuration
                    )}`}</P2>
                  </div>
                </PaddingBottomContainer>
              );
            case "undefined-value":
              return (
                <PaddingBottomContainer>
                  <P1 color="dark" weight="normal">{`${
                    p.propertyName
                  }=${PropertyValue.toString(
                    p.propertyValue
                  )} is not defined for this system`}</P1>
                </PaddingBottomContainer>
              );
            case "no-valid-mapping":
              return (
                <PaddingBottomContainer>
                  <div>
                    <P1 color="dark" weight="normal">
                      {`No valid mapping found for: ${p.propertyName}`}
                    </P1>
                  </div>
                  <div>
                    <P2 color="secondary" weight="normal">
                      {`Selection limitations: ${PropertyValueSet.toString(
                        p.selectionNewProperties
                      )}`}
                    </P2>
                  </div>
                  <div>
                    <P2 color="secondary" weight="normal">
                      Mapping rows:{" "}
                    </P2>
                    {p.mappingRowsFilter.map(f => (
                      <P2 color="secondary" weight="normal">
                        {PropertyFilter.toString(f)}
                      </P2>
                    ))}
                  </div>
                </PaddingBottomContainer>
              );
            default:
              return exhaustiveCheck(p, true);
          }
        })}
      </div>
    );
  } else if (systemVariant.invalidFlow) {
    switch (systemVariant.invalidFlow.type) {
      case "invalid-min-flow":
        return (
          <P2 color="secondary" weight="normal">
            {`Supply outlet airflow (${Math.trunc(
              Amount.valueAs(
                Units.StandardCubicMeterPerHour,
                systemVariant.invalidFlow.opcMinFlow
              )
            )} Sm³/h) is lower than variant min flow (${Math.trunc(
              Amount.valueAs(
                Units.StandardCubicMeterPerHour,
                systemVariant.invalidFlow.systemMinFlow
              )
            )} Sm³/h`}
          </P2>
        );
      case "invalid-flow":
        return popup(
          "Invalid flow",
          "narrow",
          <PaddingBottomContainer>
            <P2 color="secondary" weight="normal">
              {`Supply outlet airflow (${Math.trunc(
                Amount.valueAs(
                  Units.StandardCubicMeterPerHour,
                  systemVariant.invalidFlow.opcMaxFlow
                )
              )} Sm³/h) is higher than variant max flow (${Math.trunc(
                Amount.valueAs(
                  Units.StandardCubicMeterPerHour,
                  systemVariant.invalidFlow.systemMaxFlow
                )
              )} Sm³/h) and dividing it between more units would result in an airflow of: ${Math.ceil(
                Amount.valueAs(
                  Units.StandardCubicMeterPerHour,
                  systemVariant.invalidFlow.opcMinFlow
                )
              )} Sm³/h, which is lower than variant min flow (${Math.trunc(
                Amount.valueAs(
                  Units.StandardCubicMeterPerHour,
                  systemVariant.invalidFlow.systemMinFlow
                )
              )} Sm³/h`}
            </P2>
          </PaddingBottomContainer>
        );
      default:
        return exhaustiveCheck(systemVariant.invalidFlow.type, true);
    }
  } else {
    return (
      <P2 color="secondary" weight="normal">
        Passed pre-validation
      </P2>
    );
  }
}

function MaybeCreateButton({
  systemVariant,
  state,
  sharedState,
  dispatch
}: {
  readonly systemVariant: Types.SystemVariant;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  if (!systemVariant.newProperties || systemVariant.invalidFlow) {
    return <></>;
  } else if (
    systemVariant.shouldCalculate &&
    state.isCalculatingSystems &&
    !systemVariant.calculationResults
  ) {
    return (
      <StandardButton
        size="Small"
        buttonType="PrimaryGreen"
        onClick={() => {
          /* */
        }}
        disabled={true}
      >
        Calculating
      </StandardButton>
    );
  } else if (systemVariant.calculationResults) {
    return (
      <StandardButton
        size="Small"
        buttonType="PrimaryGreen"
        disabled={
          systemVariant.calculationResults.exception.componentMessages.length +
            systemVariant.calculationResults.error.componentMessages.length !==
          0
        }
        onClick={() => {
          createSystem(
            dispatch,
            sharedState,
            systemVariant.systemType + ": " + systemVariant.variant,
            systemVariant.systemType,
            systemVariant.newProperties!,
            systemVariant.operatingCases,
            state.operatingCaseSelectorState!.climateSettings,
            systemVariant.divergentSysComponent,
            state.moistureLoadInfo
          );
        }}
      >
        Create
      </StandardButton>
    );
  } else {
    return <></>;
  }
}

function CreatedSystemDialog({
  url,
  dispatch
}: {
  readonly url: string;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  return (
    <ClickAwayListener
      onClickAway={() => {
        window.open(url);
        dispatch(Action.setCreatedSystemUrl(undefined));
      }}
    >
      <CreatedSystemDialogRoot>
        <P1 color="dark" weight="normal">
          System created
        </P1>
        <StandardButton
          buttonType="PrimaryGreen"
          size="Large"
          onClick={() => {
            window.open(url);
            dispatch(Action.setCreatedSystemUrl(undefined));
          }}
        >
          Open in new tab
        </StandardButton>
        <StandardButton
          buttonType="SecondaryGreen"
          size="Large"
          onClick={() => dispatch(Action.redirect(url))}
        >
          Open in current tab
        </StandardButton>
      </CreatedSystemDialogRoot>
    </ClickAwayListener>
  );
}

//tslint:disable-next-line
