import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";
const showOutdoorAirSource = "showoutdoorairsource";
const showReturnAirSource = "showreturnairsource";
const showCustomAirSource = "showcustomairsource";
const postMixingBoxExists = "postmixingboxexists";
const premMxingBoxExists = "premixingboxexists";
const preCoolerPresent = "precoolerpresent";
const preHeaterPresent = "preheaterpresent";
const postCoolerExists = "postcoolerexists";
const postHeaterExists = "postheaterexists";
const reactPreHeaterExists = "reactpreheaterexists";
export function mcdPreProcessOpc(
  newProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let pvs = PropertyValueSet.Empty;

  // Show Outdoor air source
  // for react -> always so no filter needed.
  pvs = PropertyValueSet.merge(
    {
      [showOutdoorAirSource]: PropertyValue.fromInteger(1)
    },
    pvs
  );

  // Show Return air source
  if (
    PropertyFilter.isValid(
      newProperties,
      PropertyFilter.fromStringOrEmpty("airsource=2")!
    )
  ) {
    pvs = PropertyValueSet.merge(
      {
        [showReturnAirSource]: PropertyValue.fromInteger(1)
      },
      pvs
    );
  }

  // Show Custom air source

  if (
    PropertyFilter.isValid(
      newProperties,
      PropertyFilter.fromStringOrEmpty("airsource=3")!
    )
  ) {
    pvs = PropertyValueSet.merge(
      {
        [showCustomAirSource]: PropertyValue.fromInteger(1)
      },
      pvs
    );
  }

  // Pre mixing box exists
  pvs = PropertyValueSet.merge(
    {
      [premMxingBoxExists]: PropertyValue.fromInteger(0)
    },
    pvs
  );

  // Post mixing box exists
  pvs = PropertyValueSet.merge(
    {
      [postMixingBoxExists]: PropertyValue.fromInteger(0)
    },
    pvs
  );

  // Pre heater exists always 0
  pvs = PropertyValueSet.merge(
    {
      [preHeaterPresent]: PropertyValue.fromInteger(0)
    },
    pvs
  );

  // Pre cooler present
  pvs = PropertyValueSet.merge(
    {
      [preCoolerPresent]: PropertyValue.fromInteger(0)
    },
    pvs
  );

  // React pre heater exists always 0
  pvs = PropertyValueSet.merge(
    {
      [reactPreHeaterExists]: PropertyValue.fromInteger(0)
    },
    pvs
  );

  // Post cooler exists
  pvs = PropertyValueSet.merge(
    {
      [postCoolerExists]: PropertyValue.fromInteger(0)
    },
    pvs
  );

  // Post heater exists
  pvs = PropertyValueSet.merge(
    {
      [postHeaterExists]: PropertyValue.fromInteger(0)
    },
    pvs
  );

  return pvs;
}
