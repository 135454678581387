import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { dashboardProductQuery, recentAndFavoritesQuery } from "./queries";
import * as SharedState from "../shared-state";
import * as SystemActions from "../system-actions";
import * as RecentSystemCard from "../recent-system-card";
import * as GraphQlTypes from "../graphql-types";
import * as Navigation from "../navigation-effect-manager";
import { SystemActionsStateMap, ErrorDetails } from "./types";

export type State = {
  readonly productData: GraphQlTypes.DashboardProductQuery | undefined;
  readonly wikijsData: GraphQlTypes.WikiJsQuery | undefined;
  readonly systemActionsState: SystemActionsStateMap | undefined;
  readonly recentSystemCardState: {
    readonly [cardIndex: string]: RecentSystemCard.State;
  };
  readonly systemError: ErrorDetails | undefined;
};
export const init = (
  sharedState: SharedState.State
): readonly [State, SharedState.Action, Cmd<Action>?] => {
  const recentCardCmds: Array<{
    readonly id: string;
    readonly cmd: Cmd<RecentSystemCard.Action> | undefined;
  }> = [];
  const recentCardStateMap: {
    // tslint:disable-next-line: readonly-keyword
    [cardIndex: string]: RecentSystemCard.State;
  } = {};
  for (const [_i, system] of sharedState.user.lastOpenedSystems.entries()) {
    const id = system.type === "loaded" ? system.system.id : system.id;
    const [state, cmd] = RecentSystemCard.init(id, sharedState);
    recentCardStateMap[id] = state;
    recentCardCmds.push({ id, cmd });
  }
  return [
    {
      productData: undefined,
      wikijsData: undefined,
      systemActionsState: undefined,
      recentSystemCardState: recentCardStateMap,
      systemError: undefined
    },
    SharedState.Action.loadLastOpenedSystemsAndFavorites(),
    Cmd.batch<Action>([
      sharedState.graphQL.queryUserCmd<
        GraphQlTypes.WikiJsQuery,
        GraphQlTypes.WikiJsQueryVariables,
        Action
      >(recentAndFavoritesQuery, {}, Action.wikiJsQueryRecieved),

      sharedState.graphQL.queryProductCmd<
        GraphQlTypes.DashboardProductQuery,
        GraphQlTypes.DashboardProductQueryVariables,
        Action
      >(dashboardProductQuery, {}, Action.productDataRecieved),
      ...recentCardCmds.map(rCmd =>
        Cmd.map(
          action => Action.dispatchRecentSystemCard(rCmd.id, action),
          rCmd.cmd
        )
      )
    ])
  ];
};

export const Action = ctorsUnion({
  productDataRecieved: (data: GraphQlTypes.DashboardProductQuery) => ({
    data
  }),
  wikiJsQueryRecieved: (data: GraphQlTypes.WikiJsQuery) => ({ data }),
  toggleFavorites: (systemID: string) => ({ systemID }),
  toggleSystemActions: (
    cardIndex: number,
    coordinates?: { readonly x: number; readonly y: number }
  ) => ({
    cardIndex,
    coordinates
  }),
  dispatchSystemActions: (action: SystemActions.Action) => ({ action }),
  dispatchRecentSystemCard: (
    cardID: string,
    action: RecentSystemCard.Action
  ) => ({ cardID, action }),
  navigate: (url: string) => ({ url }),
  showSystemErrorDetails: (error: ErrorDetails | undefined) => ({ error })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "productDataRecieved": {
      return [{ ...state, productData: action.data }];
    }
    case "wikiJsQueryRecieved": {
      return [{ ...state, wikijsData: action.data }];
    }
    case "toggleFavorites": {
      return [
        state,
        undefined,
        [SharedState.Action.toggleFavoritesSystems(action.systemID)]
      ];
    }
    case "toggleSystemActions": {
      if (state.systemActionsState) {
        return [{ ...state, systemActionsState: undefined }];
      }
      const [systemActionsState] = SystemActions.init();
      return [
        {
          ...state,
          systemActionsState: {
            cardIndex: action.cardIndex,
            coordinates: action.coordinates,
            state: systemActionsState
          }
        }
      ];
    }
    case "dispatchSystemActions": {
      if (!state.systemActionsState) {
        return [state];
      }
      const [
        systemActionsState,
        SystemActionsCmd,
        sharedStateActions,
        isDone,
        url
      ] = SystemActions.update(
        action.action,
        state.systemActionsState.state,
        sharedState
      );
      if (isDone) {
        return [
          {
            ...state,
            systemActionsState: undefined
          },
          url ? Navigation.pushUrl(url.url) : undefined,
          [
            ...(sharedStateActions || []),
            SharedState.Action.loadLastOpenedSystemsAndFavorites(true)
          ]
        ];
      } else {
        return [
          {
            ...state,
            systemActionsState: {
              ...state.systemActionsState,
              state: systemActionsState
            }
          },
          Cmd.map(Action.dispatchSystemActions, SystemActionsCmd),
          sharedStateActions
        ];
      }
    }
    case "dispatchRecentSystemCard": {
      const prevState = state.recentSystemCardState[action.cardID];
      if (!prevState) {
        return [state];
      }

      const [newState, cmds, sharedStateActions] = RecentSystemCard.update(
        action.action,
        prevState,
        sharedState
      );

      return [
        {
          ...state,
          recentSystemCardState: {
            ...state.recentSystemCardState,
            [action.cardID]: newState
          }
        },
        Cmd.map(
          cmdAction =>
            Action.dispatchRecentSystemCard(action.cardID, cmdAction),
          cmds
        ),
        sharedStateActions
      ];
    }
    case "navigate": {
      return [state, Navigation.pushUrl(action.url)];
    }
    case "showSystemErrorDetails":
      return [{ ...state, systemError: action.error }];
    default:
      return exhaustiveCheck(action, true);
  }
}
