import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  PrimaryColors,
  MoistureLoadIcon,
  HeatLoadIcon
} from "@genesys/ui-elements";
import { TopMenuTabs, Action } from "../state";
import { Dispatch } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import styled from "styled-components";

const LeftMenuContainer = styled.ul`
  list-style: none;
  /* width: 35%; */
  margin-right: 5px;
`;

const StyledLi = styled.li<{ readonly isActive: boolean }>`
  min-height: 26px;
  margin-bottom: 5px;

  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;

  ${props => props.isActive && "background-color: #f2f4f7;"}
  /* justify-content: center; */

    :hover {
    background: ${PrimaryColors.lightBlue};
    width: 100%;
    border-radius: 5px;
  }
`;

export function LeftMenuView({
  tabs,
  activeTab,
  sharedState,
  dispatch
}: {
  readonly tabs: ReadonlyArray<TopMenuTabs>;
  readonly activeTab: TopMenuTabs;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}) {
  return (
    <LeftMenuContainer>
      {tabs.map(x => (
        <StyledLi
          key={x}
          onClick={() => {
            if (x !== activeTab) {
              dispatch(Action.changeTopMenuTab(x));
            }
          }}
          isActive={x === activeTab}
        >
          {getIcon(x)}
          {getTranslation(x, sharedState)}
        </StyledLi>
      ))}
    </LeftMenuContainer>
  );
}

function getIcon(tab: TopMenuTabs) {
  switch (tab) {
    case "moisture": {
      return <MoistureLoadIcon />;
    }

    case "heat": {
      return <HeatLoadIcon />;
    }

    case "both": {
      return (
        <>
          <MoistureLoadIcon />
          <HeatLoadIcon />
        </>
      );
    }
    default:
      return exhaustiveCheck(tab, true);
  }
}

function getTranslation(tab: TopMenuTabs, sharedState: SharedState.State) {
  switch (tab) {
    case "moisture": {
      return sharedState.translate(LanguageTexts.mlcLoadsWithMoisture());
    }

    case "heat": {
      return sharedState.translate(LanguageTexts.mlcLoadsWithHeat());
    }

    case "both": {
      return sharedState.translate(LanguageTexts.mlcLoadsWithBoth());
    }
    default:
      return exhaustiveCheck(tab, true);
  }
}
