import * as React from "react";
import {
  GenesysTableOption,
  GenesysTableSelect,
  StandardButton,
  ToolTipWrapper,
  P2
} from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  Root,
  HoverContainer,
  UnitLabelContainer,
  ButtonsContainer,
  UnitLabel,
  SelectorContainer
} from "./elements";
import {
  UseAmountFormatSelector,
  UseAmountFormatSelectorOptions
} from "./use-amount-format-selector/types";
import { getUseAmountFormatSelector } from "./use-amount-format-selector/use-amount-format-selector";

type AmountFormatSelectorProps = {
  readonly type: "AmountFormatSelectorProps";
} & UseAmountFormatSelectorOptions;

type Props = UseAmountFormatSelector | AmountFormatSelectorProps;

export function AmountFormatSelector(props: Props): JSX.Element {
  const getProps = (): UseAmountFormatSelector => {
    if (props.type === "UseAmountFormatSelector") {
      return props;
    } else {
      return getUseAmountFormatSelector(props);
    }
  };

  const {
    isOpen,
    unitItems,
    fieldName,
    fieldGroup,
    extraLabel,
    selectedUnit,
    isUserDefined,
    precisionValues,
    selectedPrecision,
    onClear,
    onCancel,
    translate,
    onChangeUnit,
    openSelector,
    onChangePrecision
  } = getProps();

  const [isHovering, setIsHovering] = React.useState(false);

  const OuterRef = React.useRef<HTMLAnchorElement>(undefined!);
  const InnerRef = React.useRef<HTMLDivElement>(undefined!);

  React.useLayoutEffect(() => {
    if (isHovering && InnerRef.current && OuterRef.current) {
      const OuterRect = OuterRef.current.getBoundingClientRect();
      InnerRef.current.style.left = `${OuterRect.left + 40}px`;
      InnerRef.current.style.top = `${OuterRect.bottom + 1}px`;
    }
  }, [isHovering, InnerRef?.current?.getBoundingClientRect()]);

  if (isOpen) {
    return (
      <Root>
        <SelectorContainer>
          <GenesysTableSelect onChange={onChangeUnit} value={selectedUnit}>
            {unitItems.map((unit, i) => (
              <GenesysTableOption key={i} value={unit.value}>
                {unit.text === " " ? "[ ]" : unit.text}
              </GenesysTableOption>
            ))}
          </GenesysTableSelect>
          <GenesysTableSelect
            onChange={onChangePrecision}
            value={selectedPrecision}
          >
            {precisionValues.map((precisionValue, index) => (
              <GenesysTableOption key={index} value={precisionValue}>
                {precisionValue}
              </GenesysTableOption>
            ))}
          </GenesysTableSelect>
          <ButtonsContainer>
            <ToolTipWrapper
              title={translate(LanguageTexts.clear())}
              padding="0px"
            >
              <StandardButton
                size="Small"
                buttonType="SecondaryGreen"
                onClick={onClear}
              >
                {"C"}
              </StandardButton>
            </ToolTipWrapper>
            <ToolTipWrapper
              title={translate(LanguageTexts.cancel())}
              padding="0px"
            >
              <StandardButton
                size="Small"
                buttonType="PrimaryGreen"
                onClick={onCancel}
              >
                {"X"}
              </StandardButton>
            </ToolTipWrapper>
          </ButtonsContainer>
        </SelectorContainer>
      </Root>
    );
  } else {
    return (
      <Root>
        <UnitLabelContainer
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          ref={OuterRef as any}
        >
          <UnitLabel
            userDefinedFormat={isUserDefined}
            onClick={() => {
              setIsHovering(false);
              openSelector();
            }}
          >
            {extraLabel}
            {selectedUnit === " " && "[ ]"}
            {selectedUnit}
          </UnitLabel>

          {isHovering && (
            <HoverContainer ref={InnerRef as any}>
              <P2
                weight="normal"
                color="white"
              >{`GroupName: ${fieldGroup}`}</P2>
              <P2 weight="normal" color="white">{`FieldName: ${fieldName}`}</P2>
            </HoverContainer>
          )}
        </UnitLabelContainer>
      </Root>
    );
  }
}
