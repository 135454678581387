import { exhaustiveCheck } from "ts-exhaustive-check";
import { createRoute /* , NoParams  */ } from "@genesys/route";
import { combineUrlParsers } from "@genesys/route/lib/url-parser";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";

export const MainLocation = ctorsUnion({
  Dashboard: () => ({}),
  SystemManager: () => ({}),
  UserSettings: () => ({}),
  SystemConfiguration: (location: SystemConfigurationLocation) => ({
    location
  }),
  Wizard: () => ({}),
  WizardWithMoistureLoad: (location: WithMoistureLoadLocation) => ({
    location
  }),
  PricingManager: () => ({}),
  PricingEditor: (location: PricingEditorLocation) => ({
    location
  }),
  PricingWizard: () => ({}),
  MoistureLoadCalc: (location: MoistureLoadCalcLocation) => ({ location }),
  MoistureLoadWizard: () => ({}),
  MoistureLoadManager: () => ({}),
  Tools: (location: ToolsLocation) => ({
    location
  }),
  About: () => ({}),
  SystemSelectionGuide: () => ({}),
  SystemSelectionGuideWithMoistureLoad: (
    location: WithMoistureLoadLocation
  ) => ({ location }),
  LogViewer: (location: LogViewerLocation) => ({
    location
  }),
  DrySize: (location: WithMoistureLoadLocation) => ({ location })
});
export type MainLocation = CtorsUnion<typeof MainLocation>;

export const SystemConfigurationLocation = ctorsUnion({
  Root: (genesysNo: string) => ({ params: { genesysNo } })
});
export type SystemConfigurationLocation = CtorsUnion<
  typeof SystemConfigurationLocation
>;

export const PricingEditorLocation = ctorsUnion({
  Root: (pricingNo: string) => ({ params: { pricingNo } })
});
export type PricingEditorLocation = CtorsUnion<typeof PricingEditorLocation>;

export const ToolsLocation = ctorsUnion({
  Root: (toolType: string) => ({ params: { toolType } })
});
export type ToolsLocation = CtorsUnion<typeof ToolsLocation>;

export const MoistureLoadCalcLocation = ctorsUnion({
  Root: (moistureLoadId: string) => ({ params: { moistureLoadId } })
});
export type MoistureLoadCalcLocation = CtorsUnion<
  typeof MoistureLoadCalcLocation
>;

export const WithMoistureLoadLocation = ctorsUnion({
  Root: (mNo: string) => ({ params: { mNo } })
});
export type WithMoistureLoadLocation = CtorsUnion<
  typeof WithMoistureLoadLocation
>;

export const LogViewerLocation = ctorsUnion({
  withTraceId: (traceId: string) => ({ params: { traceId } }),
  Root: () => ({})
});

export type LogViewerLocation = CtorsUnion<typeof LogViewerLocation>;

export const mainMap = {
  MoistureLoadManager: createRoute(
    "/moisture-load-manager",
    () => MainLocation.MoistureLoadManager(),
    () => ({})
  ),
  MoistureLoadWizard: createRoute(
    "/new-moisture-load",
    () => MainLocation.MoistureLoadWizard(),
    () => ({})
  ),
  UserSettings: createRoute(
    "/user-settings",
    () => MainLocation.UserSettings(),
    () => ({})
  ),
  SystemManager: createRoute(
    "/system-manager",
    () => MainLocation.SystemManager(),
    () => ({})
  ),
  PricingManager: createRoute(
    "/pricing-manager",
    () => MainLocation.PricingManager(),
    () => ({})
  ),
  PricingWizard: createRoute(
    "/new-pricing",
    () => MainLocation.PricingWizard(),
    () => ({})
  ),
  Wizard: createRoute(
    "/new-system",
    () => MainLocation.Wizard(),
    () => ({})
  ),
  About: createRoute(
    "/about",
    () => MainLocation.About(),
    () => ({})
  ),
  SystemSelectionGuide: createRoute(
    "/system-selection-guide",
    () => MainLocation.SystemSelectionGuide(),
    () => ({})
  ),
  Dashboard: createRoute(
    "/",
    () => MainLocation.Dashboard(),
    () => ({})
  )
};

export const systemConfigurationMap = {
  Root: createRoute(
    "/system/:genesysNo",
    params =>
      MainLocation.SystemConfiguration(
        SystemConfigurationLocation.Root(params["genesysNo"])
      ),
    location => location.location.params
  )
};

export const pricingEditorMap = {
  Root: createRoute(
    "/pricing/:pricingNo",
    params =>
      MainLocation.PricingEditor(
        PricingEditorLocation.Root(params["pricingNo"])
      ),
    location => location.location.params
  )
};

export const moistureLoadCalcMap = {
  Root: createRoute(
    "/moisture-load/:moistureLoadNo",
    params =>
      MainLocation.MoistureLoadCalc(
        MoistureLoadCalcLocation.Root(params["moistureLoadNo"])
      ),
    location => location.location.params
  )
};

export const ToolsMap = {
  Root: createRoute(
    "/tools/:toolType",
    params => MainLocation.Tools(ToolsLocation.Root(params["toolType"])),
    location => location.location.params
  )
};

export const NewSystemWithMoitureLoadMap = {
  Root: createRoute(
    "/new-system/with-mno/:mno",
    params =>
      MainLocation.WizardWithMoistureLoad(
        WithMoistureLoadLocation.Root(params["mno"])
      ),
    location => location.location.params
  )
};

export const SysSelWithMoitureLoadMap = {
  Root: createRoute(
    "/system-selection-guide/with-mno/:mno",
    params =>
      MainLocation.SystemSelectionGuideWithMoistureLoad(
        WithMoistureLoadLocation.Root(params["mno"])
      ),
    location => location.location.params
  )
};

export const DrySizeMap = {
  Root: createRoute(
    "/dry-size/:mno",
    params =>
      MainLocation.DrySize(WithMoistureLoadLocation.Root(params["mno"])),
    location => location.location.params
  )
};

export const LogViewerMap = {
  withTraceId: createRoute(
    "/log-viewer/:traceId",
    params =>
      MainLocation.LogViewer(LogViewerLocation.withTraceId(params["traceId"])),
    location => {
      switch (location.location.type) {
        case "Root":
          return {};
        case "withTraceId":
          return location.location.params;
        default:
          return exhaustiveCheck(location.location, true);
      }
    }
  ),
  Root: createRoute(
    "/log-viewer",
    _params => MainLocation.LogViewer(LogViewerLocation.Root()),
    _location => ({})
  )
};

export const parseUrl = combineUrlParsers<MainLocation>([
  ...Object.values(LogViewerMap).map(v => v.parseUrl),
  ...Object.values(moistureLoadCalcMap).map(v => v.parseUrl),
  ...Object.values(systemConfigurationMap).map(v => v.parseUrl),
  ...Object.values(pricingEditorMap).map(v => v.parseUrl),
  ...Object.values(ToolsMap).map(v => v.parseUrl),
  ...Object.values(NewSystemWithMoitureLoadMap).map(v => v.parseUrl),
  ...Object.values(SysSelWithMoitureLoadMap).map(v => v.parseUrl),
  ...Object.values(DrySizeMap).map(v => v.parseUrl),
  ...Object.values(mainMap).map(v => v.parseUrl)
]);
