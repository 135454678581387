import * as React from "react";
import * as Types from "./types";
import * as SharedState from "../../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "./state";
import * as OperatingCaseSelector from "../../../operating-case-selector";
import styled from "styled-components";
import { PrimaryColors, H1, OverlayLoader } from "@genesys/ui-elements";
import Alert from "@mui/lab/Alert";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SystemStatus from "@genesys/shared/lib/enums/system-status";

export interface Props {
  readonly dispatch: Dispatch<Action>;
  readonly state: Types.State;
  readonly sharedState: SharedState.State;
}

const Root = styled.div`
  padding-bottom: 12px;
`;

const AutoScrollOffSet = styled.div`
  height: 80px;
`;

const InnerRoot = styled.div`
  background-color: ${PrimaryColors.lightBlue};

  h1 {
    padding: 56px 0 0 64px;
  }
`;

const OperatingCaseSelectorContainer = styled.div`
  padding-left: 20px;
  padding-bottom: 10px;
  background-color: inherit;
`;

const ALertContainer = styled.div`
  width: 70%;
  margin: auto;
  margin-top: 10px;
`;

export function OperatingCases(props: Props): JSX.Element {
  if (!props.state) {
    return <OverlayLoader />;
  }

  if (
    !props.state.OperatingCaseSelectorState ||
    !props.state.PropertiesSelectorProductData
  ) {
    return <div>Error, could not get operating cases for AHU</div>;
  }

  return (
    <Root>
      <AutoScrollOffSet />
      <InnerRoot>
        <H1 weight="normal">
          {props.sharedState.translate(LanguageTexts.operatingCases())}
        </H1>

        {props.state.alert && (
          <ALertContainer>
            <Alert
              onClose={() => {
                props.dispatch(Action.closeAlert());
              }}
              severity={props.state.alert.type}
            >
              <p style={{ fontSize: "16px" }}> {props.state.alert.message} </p>
            </Alert>
          </ALertContainer>
        )}

        <OperatingCaseSelectorContainer>
          <OperatingCaseSelector.OperatingCasesView
            dispatch={Dispatch.map(
              Action.dispatchOperatingCaseSelector,
              props.dispatch
            )}
            sharedState={props.sharedState}
            state={props.state.OperatingCaseSelectorState}
            productData={props.state.PropertiesSelectorProductData}
            sysProperties={props.state.newProperties}
            systemStatus={SystemStatus.SystemStatus.PriceCalculationSuccess}
          />
        </OperatingCaseSelectorContainer>
      </InnerRoot>
    </Root>
  );
}
