import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import * as Types from "../types";
import * as TopMenu from "../top-menu";
import * as PerformanceOverview from "../performance-overview";
import * as Printouts from "../modals/printouts";
import * as ComponentEditor from "../modals/component-editor";
import * as OperatingCases from "../../operating-case-selector";
import * as MechanicalSketch from "../modals/mechanical-sketch";
import * as BuildInSc from "../modals/build-in-solid-contacts";
import * as BuildInRevit from "../modals/build-in-revit";
import * as Accessories from "../modals/accessories";
import * as Price from "../modals/price";
import * as PsychrometricChart from "../modals/psychrometric-chart-presenter";
import * as Energy from "../modals/energy";
import * as MCompare from "../modals/m-compare";
import * as Pid from "../modals/pid";
import * as PropertyGroups from "../modals/property-groups";
import * as SystemAccessories from "../modals/system-accessories";
import * as PlenumSize from "../modals/plenum-size";
import * as NotesAndDocuments from "../modals/notes-and-documents";
import * as MultipleSystemsEditor from "../modals/multiple-systems-editor";
import * as LockedSystemCalculation from "../modals/locked-system-calculation";
import * as DataCenterEditor from "../modals/data-center-editor";
import * as CondensationAnalysis from "../modals/condensation-analysis";
import * as SystemActions from "../../system-actions";

export const dispatchSub = "dispatchSub" as const;
export const DispatchActions = ctorsUnion({
  dispatchTopMenu: (action: TopMenu.Action) => ({ action, sub: dispatchSub }),
  dispatchPeformanceOverview: (action: PerformanceOverview.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchComponentEditor: (action: ComponentEditor.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchOperatingCases: (action: OperatingCases.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchPrintouts: (action: Printouts.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchMechanicalSketch: (action: MechanicalSketch.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchBuildInSc: (action: BuildInSc.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchBuildInRevit: (action: BuildInRevit.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchPrice: (action: Price.Action) => ({ action, sub: dispatchSub }),
  dispatchEnergy: (action: Energy.Action) => ({ action, sub: dispatchSub }),
  dispatchMCompare: (action: MCompare.Action) => ({ action, sub: dispatchSub }),
  dispatchAccessories: (action: Accessories.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchPsychrometricChart: (action: PsychrometricChart.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchPid: (action: Pid.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchPropertyGroups: (action: PropertyGroups.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchSystemAccessories: (action: SystemAccessories.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchPlenumSize: (action: PlenumSize.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchNotesAndDocuments: (action: NotesAndDocuments.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchSystemActions: (action: SystemActions.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchMultipleSystemsEditor: (action: MultipleSystemsEditor.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchDataCenterEditor: (action: DataCenterEditor.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchCondensationAnalysis: (action: CondensationAnalysis.Action) => ({
    action,
    sub: dispatchSub
  }),
  dispatchLockedSystemCalculation: (
    action: LockedSystemCalculation.Action
  ) => ({
    action,
    sub: dispatchSub
  })
});

export const restSub = "restSub" as const;
export const RestActions = ctorsUnion({
  setGenericDialogState: (state: Types.GenericDialogState | undefined) => ({
    state,
    sub: restSub
  }),
  setDiagramHoverState: (state: Types.DiagramHoverState) => ({
    state,
    sub: restSub
  }),
  setSelectedAirPosition: (airPosition: string | undefined) => ({
    airPosition,
    sub: restSub
  }),
  openModal: (initModal: Types.InitModal) => ({
    initModal,
    sub: restSub
  }),
  closeModal: () => ({ sub: restSub }),
  saveModal: () => ({ sub: restSub }),
  saveAndCalculate: () => ({ sub: restSub }),
  saveAndCalculateEnergy: () => ({ sub: restSub }),
  toggleAlertSlider: () => ({ sub: restSub }),
  toggleConfirmLockRevisonDialog: () => ({ sub: restSub }),
  togglesEnergyBinSelectionsValidationError: () => ({ sub: restSub }),
  setSelectedComponent: (id: string | undefined) => ({
    id,
    sub: restSub
  }),
  setSelectedComponentMenu: (id: string, menu: Types.Point) => ({
    id,
    menu,
    sub: restSub
  }),
  setAddComponent: (
    componentId: string,
    productId: string,
    productSectionIds: ReadonlyArray<string>
  ) => ({ componentId, productId, productSectionIds, sub: restSub }),
  addComponentSelectPosition: (
    componentId: string,
    productId: string,
    productSectionIds: ReadonlyArray<string>,
    selectedPositions: ReadonlyArray<Types.ComponentSectionPosition>
  ) => ({
    componentId,
    productId,
    productSectionIds,
    selectedPositions,
    sub: restSub
  }),
  setMoveComponent: (
    componentId: string,
    sections: ReadonlyArray<{
      readonly componentSectionId: string;
      readonly productSectionId: string;
    }>
  ) => ({ componentId, sections, sub: restSub }),
  moveComponentSelectPosition: (
    componentId: string,
    sections: ReadonlyArray<{
      readonly componentSectionId: string;
      readonly productSectionId: string;
    }>,
    selectedPositions: ReadonlyArray<Types.ComponentSectionPosition>
  ) => ({ componentId, sections, selectedPositions, sub: restSub }),
  deleteComponent: (componentId: string) => ({ componentId, sub: restSub }),
  setExchangeComponent: (
    exchangeComponent: Types.ExchangeComponent | undefined
  ) => ({
    exchangeComponent,
    sub: restSub
  }),
  exchangeComponent: (input: {
    readonly componentId: string;
    readonly productId: string;
    readonly componentSectionPositions: ReadonlyArray<{
      readonly productSectionId: string;
      readonly beforeSectionId: string;
      readonly airstreamId: string;
    }>;
  }) => ({ input, sub: restSub }),
  calculate: () => ({ sub: restSub }),
  tryCalculateEnergy: () => ({ sub: restSub }),
  tryCalculateEnergyComparison: () => ({ sub: restSub }),
  lock: () => ({ sub: restSub }),
  toggleSystemActions: () => ({ sub: restSub }),
  setActiveAmountField: (amountProfileId: string) => ({
    amountProfileId,
    sub: restSub
  }),
  openSystemConfirmation: (url: string, openInNewTab: boolean) => ({
    url,
    openInNewTab,
    sub: restSub
  }),
  expandGroupException: (groupKey: string) => ({ groupKey, sub: restSub }),
  collapseGroupException: (groupKey: string) => ({ groupKey, sub: restSub }),
  expandGroupError: (groupKey: string) => ({ groupKey, sub: restSub }),
  collapseGroupError: (groupKey: string) => ({ groupKey, sub: restSub }),
  expandGroupWarning: (groupKey: string) => ({ groupKey, sub: restSub }),
  collapseGroupWarning: (groupKey: string) => ({ groupKey, sub: restSub }),
  expandGroupCriticalWarning: (groupKey: string) => ({
    groupKey,
    sub: restSub
  }),
  collapseGroupCriticalWarning: (groupKey: string) => ({
    groupKey,
    sub: restSub
  })
});

export type DispatchActions = CtorsUnion<typeof DispatchActions>;
export type RestActions = CtorsUnion<typeof RestActions>;

export type CombinedActions =
  | CtorsUnion<typeof RestActions>
  | CtorsUnion<typeof DispatchActions>;

export const CombinedActions = {
  ...RestActions,
  ...DispatchActions
};
