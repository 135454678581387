import * as React from "react";
import * as SharedState from "../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import * as OperationTimeGen2 from "../operation-time-manager";
import * as LocationSelectorGen2 from "../location-selector";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import styled from "styled-components";
import {
  GenesysSelect,
  StandardButton,
  P1,
  S2,
  Measurements
} from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import { OperationTime } from "./operation-time";
import { BinSize, ClimateCoolingDataType, BinInfo } from "./types";
import { getValue } from "@genesys/shared/lib/product-properties";
import { Quantity, Unit } from "@genesys/uom";
import { AmountFormatSelector } from "../amount-format-selector";
import { RadioButton } from "@genesys/ui-elements";
const dataTypes: ReadonlyArray<ClimateCoolingDataType> = [
  "DB/MCWB",
  "WB/MCDB",
  "DP/MCDB",
  "h/MCDB",
  "w/MCDB",
  "t/MCDB"
];

const Root = styled.div``;

const RootError = styled.div`
  p {
    padding: 12px 0;
  }
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  > label {
    margin-right: 7px;
  }
`;

const TopSubContainer = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 6px;
  }
`;

const GenerateButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 16px;

  button {
    margin-top: 8px;
  }
`;

const MeasurementsContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    cursor: default;
  }

  > span {
    margin-right: 5px;
    margin-top: 2px;
  }
`;

const OperationTimeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  > label {
    margin-right: 10px;
  }

  img {
    cursor: pointer;
  }
`;

const PressureInfoContainer = styled.div`
  margin-top: 10px;
`;

const genesysSelectStylingProps = {
  width: 80,
  height: 35
};

const fieldGroup = "ClimateBinInputs";

export function InputsView(props: {
  readonly showGenerateInfo: boolean;
  readonly showGenerateButton: boolean;
  readonly sharedState: SharedState.State;
  readonly binInfo: BinInfo;
  readonly isSystemLocked: boolean;
  readonly operationTimeState: OperationTimeGen2.State;
  readonly energySettings: {
    readonly [key: string]: string;
  };
  readonly hasBinLocationId: boolean;
  readonly locationSelectorState: LocationSelectorGen2.State;
  readonly pressure?: PropertyValue.PropertyValue;
  readonly showDIfferentLocationWarning?: boolean;
  readonly onSelectionChange: (
    newBinSelections: PropertyValueSet.PropertyValueSet,
    newUserEnergySettings: {
      readonly [key: string]: string;
    }
  ) => void;
  readonly operationTimeDispatch: Dispatch<OperationTimeGen2.Action>;
  readonly locationSelectorDispatch: Dispatch<LocationSelectorGen2.Action>;
  readonly onGenerateBinCases: () => void;
  readonly onClick: () => void;
  readonly onClose: () => void;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
}) {
  const {
    showGenerateInfo,
    showGenerateButton,
    sharedState,
    binInfo,
    isSystemLocked,
    energySettings,
    locationSelectorState,
    hasBinLocationId,
    onSelectionChange,
    onGenerateBinCases,
    locationSelectorDispatch
  } = props;

  const setDefaultLocation = () => {
    const newBinSelections = PropertyValueSet.setText(
      KnownProperties.binDataLocationId,
      locationSelectorState.countries[0].regions[0].locations[0]
        .binDataLocationId,
      PropertyValueSet.setText(
        KnownProperties.binLocation,
        locationSelectorState.countries[0].regions[0].locations[0].locationName,
        binInfo.binSelections
      )
    );

    onSelectionChange(newBinSelections, energySettings);
  };

  if (!hasBinLocationId) {
    return (
      <RootError>
        <P1 weight="bold" color="dark">
          {"Prevoiusly chosen wmo/location could no longer be found"}
        </P1>
        {!isSystemLocked && (
          <StandardButton
            onClick={() => setDefaultLocation()}
            buttonType="PrimaryBlue"
            size="Large"
          >
            {"Click here to reselect location"}
          </StandardButton>
        )}
      </RootError>
    );
  }

  return (
    <Root>
      <TopContainer>
        <TopSubInfo {...props} />
        <MeasurementsInfo {...props} />
      </TopContainer>
      <LocationSelectorGen2.View
        state={locationSelectorState}
        sharedState={sharedState}
        dispatch={locationSelectorDispatch}
        readonly={isSystemLocked || binInfo.isBinCasesDataLoading}
      />
      <PressureInfo {...props} />
      <DifferentLocationWarning {...props} />
      <OperationTimeInfo {...props} />

      {!isSystemLocked && showGenerateButton && (
        <GenerateButtonContainer>
          {showGenerateInfo && (
            <P1 weight="bold">
              {sharedState.translate(LanguageTexts.binCasesMissing())}
            </P1>
          )}
          <StandardButton
            onClick={() => onGenerateBinCases()}
            buttonType="PrimaryBlue"
            disabled={isSystemLocked || binInfo.isBinCasesDataLoading}
            size="Small"
          >
            {sharedState.translate(LanguageTexts.generateBinCases())}
          </StandardButton>
        </GenerateButtonContainer>
      )}
    </Root>
  );
}

function MeasurementsInfo({
  sharedState
}: {
  readonly sharedState: SharedState.State;
}) {
  return (
    <MeasurementsContainer>
      <span>
        <Measurements />
      </span>

      <S2 weight="bold" color={"light"}>
        {sharedState
          .translate(
            LanguageTexts.globalPropertyName(
              sharedState.user.settings.selectedAmountProfile.name.toLowerCase()
            )
          )
          .toUpperCase()}
        {}
      </S2>
    </MeasurementsContainer>
  );
}

function TopSubInfo({
  sharedState,
  isSystemLocked,
  binInfo,
  energySettings,
  onSelectionChange
}: {
  readonly sharedState: SharedState.State;
  readonly isSystemLocked: boolean;
  readonly binInfo: BinInfo;
  readonly energySettings: {
    readonly [key: string]: string;
  };
  readonly onSelectionChange: (
    newBinSelections: PropertyValueSet.PropertyValueSet,
    newUserEnergySettings: {
      readonly [key: string]: string;
    }
  ) => void;
}) {
  const selectedDataType = PropertyValueSet.getValue(
    KnownProperties.climateCoolingDataType,
    binInfo.binSelections
  ).value as string;

  const selectedBinSize = PropertyValueSet.getValue(
    KnownProperties.binSize,
    binInfo.binSelections
  ).value;

  const selectedUseEnglishUnits =
    PropertyValueSet.getInteger(
      KnownProperties.binUseEnglishUnits,
      binInfo.binSelections
    ) || 0;

  const binSizes: ReadonlyArray<BinSize> = getBinSizes(
    selectedUseEnglishUnits !== 1,
    selectedDataType as ClimateCoolingDataType
  );

  const handleDataTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newBinSelections = PropertyValueSet.setText(
      KnownProperties.climateCoolingDataType,
      e.target.value,
      PropertyValueSet.setInteger(
        KnownProperties.binSize,
        5,
        binInfo.binSelections
      )
    );

    onSelectionChange(newBinSelections, energySettings);
  };

  const handleBinSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = parseInt(e.target.value, 10);
    const newBinSelections = PropertyValueSet.setInteger(
      KnownProperties.binSize,
      newSize,
      binInfo.binSelections
    );

    const newUserEnergySettings = {
      ...energySettings,
      [KnownProperties.energyBinSize]: newSize.toString()
    };

    onSelectionChange(newBinSelections, newUserEnergySettings);
  };

  const handleUseEnglishUnitsSelection = (measureSystem: string) => {
    const newUseEnglishUnits = measureSystem !== "SI" ? 1 : 0;
    const newBinSelections = PropertyValueSet.setInteger(
      KnownProperties.binUseEnglishUnits,
      newUseEnglishUnits,
      binInfo.binSelections
    );
    const newUserEnergySettings = {
      ...energySettings,
      [KnownProperties.binUseEnglishUnits]: newUseEnglishUnits.toString()
    };

    onSelectionChange(newBinSelections, newUserEnergySettings);
  };

  return (
    <TopSubContainer>
      <span>{sharedState.translate(LanguageTexts.binOn())}</span>
      <GenesysSelect
        {...genesysSelectStylingProps}
        disabled={isSystemLocked || binInfo.isBinCasesDataLoading}
        value={selectedDataType}
        onChange={handleDataTypeChange}
        options={dataTypes.map(dt => ({
          value: dt,
          title: getDataTypeTitle(dt, sharedState, selectedUseEnglishUnits)
        }))}
      />
      <span> {sharedState.translate(LanguageTexts.binSize())}</span>

      <GenesysSelect
        {...genesysSelectStylingProps}
        disabled={isSystemLocked || binInfo.isBinCasesDataLoading}
        value={selectedBinSize as string}
        onChange={handleBinSizeChange}
        options={binSizes.map(bz => ({
          value: bz.toString(),
          title: bz.toString()
        }))}
      />
      {["SI", "IP"].map(b => {
        return (
          <RadioButton
            isDisabled={isSystemLocked}
            key={b}
            onChange={() => handleUseEnglishUnitsSelection(b)}
            checked={b === (selectedUseEnglishUnits === 0 ? "SI" : "IP")}
          >
            <P1 weight="normal" color="dark">
              {b}
            </P1>
          </RadioButton>
        );
      })}
    </TopSubContainer>
  );
}

function DifferentLocationWarning({
  showDIfferentLocationWarning
}: {
  readonly pressure?: PropertyValue.PropertyValue;
  readonly sharedState: SharedState.State;
  readonly showDIfferentLocationWarning?: boolean;
}) {
  if (!showDIfferentLocationWarning) {
    return null;
  }

  return (
    <RootError>
      <P1 weight="bold" color="dark">
        {
          "OPC location and current location seems to be different. Pressure from OPC will be used"
        }
      </P1>
    </RootError>
  );
}

function OperationTimeInfo({
  sharedState,
  operationTimeState,
  isSystemLocked,
  binInfo,
  operationTimeDispatch,
  onClick,
  onClose
}: {
  readonly sharedState: SharedState.State;
  readonly operationTimeState: OperationTimeGen2.State;
  readonly isSystemLocked: boolean;
  readonly binInfo: BinInfo;
  readonly operationTimeDispatch: Dispatch<OperationTimeGen2.Action>;
  readonly onClick: () => void;
  readonly onClose: () => void;
}) {
  const labelRef = React.useRef<HTMLLabelElement>(null);

  return (
    <OperationTimeContainer>
      <label style={{ fontSize: "14px" }} ref={labelRef}>
        {sharedState.translate(LanguageTexts.operationTime())}:
      </label>
      <label style={{ fontStyle: "italic" }}>
        {sharedState.translate(
          LanguageTexts.dynamicText(
            OperationTimeGen2.presetName(operationTimeState!)
          )
        )}
      </label>
      <OperationTime
        onClick={onClick}
        onClose={onClose}
        operationTimeDispatch={operationTimeDispatch}
        labelRef={labelRef}
        sharedState={sharedState}
        readonly={isSystemLocked || binInfo.isBinCasesDataLoading}
        operationTimeState={operationTimeState}
      />
    </OperationTimeContainer>
  );
}

function PressureInfo({
  sharedState,
  pressure,
  onFormatChanged,
  onFormatCleared
}: {
  readonly sharedState: SharedState.State;
  readonly pressure?: PropertyValue.PropertyValue;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
}) {
  const fieldName = "pressure";
  const pressureFormat = pressure
    ? sharedState.screenAmounts.getAmountFormat(
        fieldGroup,
        fieldName,
        PropertyValue.getAmount(pressure)!
      )
    : undefined;

  if (!pressureFormat) {
    return null;
  }

  return (
    <PressureInfoContainer>
      <span>{sharedState.translate(LanguageTexts.binPressure())}: </span>
      {getValue(pressure!, pressureFormat)}{" "}
      {createAmountFormatSelector(
        pressureFormat,
        sharedState,
        fieldName,
        onFormatChanged,
        onFormatCleared
      )}
    </PressureInfoContainer>
  );
}

function getDataTypeTitle(
  dt: ClimateCoolingDataType,
  sharedState: SharedState.State,
  selectedUseEnglishUnits: number
) {
  const name = sharedState.translate(
    LanguageTexts.globalPropertyName(dt.toLowerCase())
  );
  const unitLabel =
    dt !== "t/MCDB"
      ? " (" + getUnitLabel(selectedUseEnglishUnits === 0, dt) + ")"
      : "";
  return capitalizeFirstLetter(name + unitLabel);
}

function getUnitLabel(si: boolean, dataType: ClimateCoolingDataType) {
  const SI_UNIT_MAP = {
    "DB/MCWB": "°C",
    "WB/MCDB": "wb°C",
    "DP/MCDB": "dp°C",
    "h/MCDB": "kJ/kg",
    "w/MCDB": "g/kg",
    "t/MCDB": " "
  };

  const US_UNIT_MAP = {
    "DB/MCWB": "°F",
    "WB/MCDB": "wb°F",
    "DP/MCDB": "dp°F",
    "h/MCDB": "Btu/lb",
    "w/MCDB": "gr/lb",
    "t/MCDB": " "
  };

  if (si) {
    return SI_UNIT_MAP[dataType] || "°C";
  }

  return US_UNIT_MAP[dataType] || "°F";
}

function getBinSizes(
  si: boolean,
  selectedDataType: ClimateCoolingDataType
): ReadonlyArray<BinSize> {
  if (si) {
    return [1, 2, 5];
  } else {
    switch (selectedDataType) {
      case "DB/MCWB":

      case "WB/MCDB":

      case "DP/MCDB":
        return [2, 5, 10];

      case "h/MCDB":
        return [1, 2, 5];

      case "w/MCDB": {
        return [5, 10, 20];
      }

      default:
        return [];
    }
  }
}

function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

function createAmountFormatSelector(
  format: ScreenAmounts.AmountFormat,
  sharedState: SharedState.State,
  fieldName: string,
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void,
  onFormatCleared: (fieldGroup: string, fieldName: string) => void
) {
  return (
    <AmountFormatSelector
      type="AmountFormatSelectorProps"
      amountFormat={format}
      fieldName={fieldName}
      fieldGroup={fieldGroup}
      conversionParameters={undefined}
      translate={sharedState.translate}
      onFormatCleared={() => onFormatCleared(fieldGroup, fieldName)}
      onFormatChanged={(unit, decimalCount) => {
        onFormatChanged(fieldGroup, fieldName, unit, decimalCount);
      }}
    />
  );
}
// tslint:disable-next-line
