import styled from "styled-components";
import { zIndex, Img } from "@genesys/ui-elements";

// Roots

export const Root = styled.div`
  padding: 8px;
  overflow: auto;
`;

export const ImageExportRoot = styled.div`
  display: flex;
  button {
    margin-left: 8px;
  }
`;

export const StaticImgRoot = styled(Img)`
  max-width: 1350px;
`;

export const SolidContactImgRoot = styled(Img)`
  min-width: 1350px;
`;

export const SketchTableRoot = styled.fieldset`
  border: 1px solid #000;
  border-radius: 5px;
`;

// Containers

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 1390px;
  height: 500px;
  z-index: ${zIndex.FullOverlay};
`;

export const SketchImageContainer = styled.div``;

export const SketchTablesContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// Sketch table

export const SketchTableGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  row-gap: 10px;
  column-gap: 8px;
  padding: 5px;
  max-width: 500px;
`;

export const StyledLegend = styled.legend`
  padding: 5px;
`;

export const GreyDiv = styled.div`
  color: #959695;
`;
