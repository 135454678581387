import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as PropertiesSelector from "../../../../properties-selector";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import styled from "styled-components";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { zIndex, AddComponent, Label } from "@genesys/ui-elements";
import { State, Action } from "../state";
import { Dispatch } from "@typescript-tea/core";
import { LoadDef } from "../types";
import { LeftMenuView } from "./left-menu-view";
import { RightMenuView } from "./right-menu-view";

const Container = styled.div`
  position: relative;
  height: 50px;
  margin-bottom: 25px;
  margin-top: 5px;
  padding-bottom: 10px;
`;

const TopMenuContainer = styled.div`
  position: absolute;
  left: 50%;
  flex-direction: row;
  justify-content: center;
  transform: translate(-50%, 0px);
  min-width: 500px;
  width: 63%;
  z-index: ${zIndex.Dropdown};
`;

const MenuCardBase = styled.div`
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);

  padding-left: 20px;
  /* padding-right: 20px; */

  font-size: 13px;

  display: flex;
`;

const MenuCardCollapsed = styled(MenuCardBase)``;

const TopHeaderContainer = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  display: inline-flex;
  margin: auto;
  align-items: center;

  > span {
    font-size: 13px;
    margin-left: 5px;
  }
`;

const MenuCardExpanded = styled(MenuCardBase)`
  flex-direction: column;

  > label {
    flex-grow: 1;
  }
`;

const ExpandedInnerContainer = styled.div`
  display: flex;
`;

const RightMenuContainer = styled.div`
  /* width: 65%; */
  flex-basis: 70%;
  padding: 10px;
`;

export function View(props: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly propertySelectorState: PropertiesSelector.State;
  readonly loadSources: ReadonlyArray<LoadDef>;
  readonly isDisabled: boolean;
  readonly dispatch: Dispatch<Action>;
}) {
  const [isOpen, setIsopen] = React.useState(false);

  return (
    <Container>
      <ClickAwayListener onClickAway={() => setIsopen(false)}>
        <TopMenuContainer
          style={{ cursor: !isOpen && !props.isDisabled ? "pointer" : "auto" }}
          onClick={() => {
            if (props.isDisabled) {
              return;
            }
            setIsopen(true);
          }}
        >
          <MenuContent {...props} expanded={isOpen} />
        </TopMenuContainer>
      </ClickAwayListener>
    </Container>
  );
}

function MenuContent({
  expanded,
  state,
  loadSources,
  sharedState,
  propertySelectorState,
  isDisabled,
  dispatch
}: {
  readonly expanded: boolean;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly loadSources: ReadonlyArray<LoadDef>;
  readonly propertySelectorState: PropertiesSelector.State;
  readonly isDisabled: boolean;
  readonly dispatch: Dispatch<Action>;
}) {
  const topHeader = (
    <TopHeaderContainer
      style={{
        opacity: isDisabled ? 0.5 : 1
      }}
    >
      <AddComponent height="1.6rem" />
      <Label weight="normal">
        {sharedState.translate(LanguageTexts.mlcAddMoistureAndHeaTypes())}{" "}
      </Label>
    </TopHeaderContainer>
  );
  if (expanded) {
    return (
      <MenuCardExpanded>
        {topHeader}
        <ExpandedInnerContainer>
          <LeftMenuView
            sharedState={sharedState}
            dispatch={dispatch}
            tabs={["moisture", "heat", "both"]}
            activeTab={state.currentTopMenuTab}
          />
          <RightMenuContainer>
            <RightMenuView
              isDisabled={isDisabled}
              propertyDefinitions={state.propertyDefinitions!}
              selectedProperties={
                PropertiesSelector.getSelectedProperties(propertySelectorState)!
              }
              dispatch={dispatch}
              sharedState={sharedState}
              loadSources={loadSources}
              tab={state.currentTopMenuTab}
            />
          </RightMenuContainer>
        </ExpandedInnerContainer>
      </MenuCardExpanded>
    );
  } else {
    return <MenuCardCollapsed>{topHeader}</MenuCardCollapsed>;
  }
}
