import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { Cmd } from "@typescript-tea/core";
import * as SharedState from "../shared-state";
import { ToolType, ToolState } from "./types";
import * as Oilon from "./oilon";

export type State = ToolState;

export const init = (
  toolType: ToolType,
  _sharedState: SharedState.State
): readonly [State, Cmd<Action>?, SharedState.Action?] => {
  switch (toolType) {
    case "oilon": {
      const [OilonState, OilonCmd] = Oilon.init();
      return [
        { type: "oilon", state: OilonState },
        Cmd.map(Action.dispatchOilon, OilonCmd),
        SharedState.Action.loadLastOpenedSystemsAndFavorites()
      ];
    }
    default:
      return exhaustiveCheck(toolType, true);
  }
};

export const Action = ctorsUnion({
  dispatchOilon: (action: Oilon.Action) => ({
    action
  }),
  dummy: () => ({})
});

export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "dispatchOilon": {
      if (state.type !== "oilon") {
        return [state];
      }
      const [OilonState, OilonCmd, sharedStateActions] = Oilon.update(
        action.action,
        state.state,
        sharedState
      );
      return [
        {
          ...state,
          state: OilonState
        },
        Cmd.map(Action.dispatchOilon, OilonCmd),
        sharedStateActions
      ];
    }
    case "dummy": {
      return [state];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
