import * as React from "react";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import styled from "styled-components";
import * as SharedState from "../../../shared-state";
import * as Notes from "../../../notes";
import { PropertyValueSet } from "@genesys/property";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { P1, PrimaryColors, H2 } from "@genesys/ui-elements";

const Root = styled.div`
  display: flex;
  min-height: 650px;
`;

const LeftContainer = styled.div`
  border-right: 3px solid #f7f7f7;
  padding: 15px 15px 15px 25px;
  max-height: 800px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 6px;
    margin-right: 20px;
    opacity: 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const RightContainer = styled.div`
  width: 50%;
  padding: 15px;
`;

const TitleContainer = styled.div`
  padding: 10px 10px;
`;

const ComponentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  width: 100%;
  height: 35px;
  border-radius: 17px;
  padding: 0 15px;

  background-color: ${(props: { readonly isSelected: boolean }) =>
    props.isSelected ? PrimaryColors.lightBlue : "inherit"};

  :hover {
    background-color: ${PrimaryColors.lightBlue};
  }
`;

const NumberOfNotesContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props: { readonly isSelected: boolean }) =>
    props.isSelected ? "#3D3D3D" : PrimaryColors.blue25};
  width: 30px;
  border-radius: 15px;
  margin-left: 25px;
`;

export function NotesAndDocumentsView({
  state,
  sharedState,
  dispatch
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const canShowProductCodes = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  const components = state.visibleComponents;

  const componentSelections: ReadonlyArray<{
    readonly id: string;
    readonly title: string;
    readonly numberOfNotes: number;
  }> = components
    .filter(c => !c.productId.endsWith("OPC") && !c.productId.endsWith("OPE"))
    .map(c => {
      const showProductCodesText = canShowProductCodes
        ? ` (${c.productId})`
        : "";

      const textDef = PropertyValueSet.hasProperty("label", c.properties)
        ? LanguageTexts.curlyTranslate(
            PropertyValueSet.getText("label", c.properties)! +
              showProductCodesText,
            c.productId.substring(0, 3)
          )
        : LanguageTexts.curlyTranslate(
            `{${c.productId}}` + showProductCodesText,
            c.productId.substring(0, 3)
          );

      let numberOfNotes = 0;
      numberOfNotes =
        c.externalNotes.length > 0 ? ++numberOfNotes : numberOfNotes;
      numberOfNotes =
        c.internalNotes.length > 0 ? ++numberOfNotes : numberOfNotes;

      return {
        id: c.id,
        title: sharedState.translate(textDef),
        numberOfNotes: numberOfNotes
      };
    })
    .sort((a, b) => b.numberOfNotes - a.numberOfNotes);

  const selectedComponentNote = state.componentsNotes.find(
    ns => ns.componentId === state.selectedComponent
  )!;

  return (
    <Root>
      <LeftContainer>
        {componentSelections.map(c => (
          <ComponentContainer
            key={c.id}
            onClick={() => dispatch(Action.setSelectedComponent(c.id))}
            isSelected={c.id === state.selectedComponent}
          >
            <P1 color="secondary" weight="normal">
              {c.title}
            </P1>

            <NumberOfNotesContainer
              isSelected={c.id === state.selectedComponent}
            >
              <P1 color="white" weight="normal">
                {c.numberOfNotes}
              </P1>
            </NumberOfNotesContainer>
          </ComponentContainer>
        ))}
      </LeftContainer>

      <RightContainer>
        <TitleContainer>
          <H2 color="dark" weight="bold">
            {
              componentSelections.find(c => c.id === state.selectedComponent)!
                .title
            }
          </H2>
        </TitleContainer>

        <Notes.NotesView
          state={selectedComponentNote.state}
          sharedState={sharedState}
          dispatch={Dispatch.map(
            action =>
              Action.dispatchNotes(selectedComponentNote.componentId, action),
            dispatch
          )}
        />
      </RightContainer>
    </Root>
  );
}
