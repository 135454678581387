import gql from "graphql-tag";

export const moistureLoadQuery = gql`
  query WizardMoistureLoadQuery($moistureLoadNo: Int!, $revisionNo: Int!) {
    user {
      moistureLoadByMoistureLoadNo(
        moistureLoadNo: $moistureLoadNo
        revisionNo: $revisionNo
      ) {
        id
        moistureloadInput {
          id
          climateSettings
        }
        moistureLoadResult {
          maxLoadResults {
            id
            selectedFlowId
            caseType
            needResourceString
            caseName
            usedRoomControlSettings
            operatingCaseResults
            roomWarnings
            flowSuggestions {
              id
              flowSourceId
              isValid
              result
            }
          }
        }
      }
    }
  }
`;

export const productDataQuery = gql`
  query WizardOperatingCasesProductQuery(
    $newSystemTypeInput: GetSystemTypeInputType!
    $newProductTypeInput: GetProductInputType!
  ) {
    product {
      systemType(input: $newSystemTypeInput) {
        id
        templates {
          id
          name
          propertyFilter
          climateDataDefaults
          components {
            ...WizardProductDataQueryTemplateComponent
          }
        }
        product(input: $newProductTypeInput) {
          ...WizardProductDataQueryProduct
        }
      }

      countries {
        id
        name
        regions {
          id
          name
          locations {
            id
            name
            latitude
            longitude
            elevation
            binLocationId
            extremeMaxWB
          }
        }
      }
    }
  }

  fragment WizardProductDataQueryTemplateComponent on SystemTemplateComponentType {
    id
    label
    productId
    properties
    propertyFilter
    visibleProperties
    componentSections {
      id
      productSectionId
      airstream
      sortNo
    }
  }

  fragment WizardProductDataQueryProduct on GenericProductType {
    id
    rangeFilter
    images {
      id
      imageFormat
      imageUsage
      url
      propertyFilter
    }
    properties {
      ...WizardProductDataQueryProperty
    }
  }

  fragment WizardProductDataQueryProperty on ProductPropertyType {
    id
    name
    defaultValues {
      value
      propertyFilter
    }
    quantity
    sortNo
    groupName
    validationFilter
    visibilityFilter
    quantityConversionParams
    descriptionTexts {
      id
      propertyFilter
      language
      text
    }
    values {
      ...WizardProductDataQueryPropertyValue
    }

    valueSources {
      ...WizardProductDataQueryValueSource
    }
  }

  fragment WizardProductDataQueryPropertyValue on ProductPropertyValueType {
    id
    value
    validationFilter
    rangeFilter
    sortNo
    image
    descriptionTexts {
      id
      propertyFilter
      language
      text
    }
  }

  fragment WizardProductDataQueryValueSource on ProductPropertyValueSourceType {
    id
    value
    propertyValueSourceId
    parameters
    propertyFilter
    claimFilter
  }
`;
