import styled from "styled-components";
import { SquarePattern } from "@genesys/ui-elements";

export const Base = styled.div`
  margin-left: 129px;
`;

export const Header = styled(Base)`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LabelForRecent = styled.label`
  color: #00adf2;
  font-family: Futura;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  display: block;
`;

export const Button = styled.button`
  color: #00adf2;
  font-family: Futura;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;

  box-sizing: border-box;
  label {
    display: block;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
  }
  border: 1px solid #00adf2;
  border-radius: 3px;
  background-color: rgba(0, 173, 242, 0.15);
  :hover {
    background-color: rgba(0, 173, 242, 0.3);
  }
`;

export const Body = styled(Base)`
  margin-top: 33px;
  height: 330px;
  overflow-x: hidden;
  white-space: nowrap;
  padding-bottom: 10px;
  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }
  ::-webkit-scrollbar {
    height: 8px;
    margin-right: 20px;
    opacity: 0;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #00adf2;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const SystemItem = styled.div`
  margin: 0 30px 0 10px;
  display: inline-block;
  cursor: auto;
`;

export const Container = styled(SquarePattern)`
  padding-top: 32px;
  height: 439px;
  width: 100%;
  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const RightButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 50px;

  > span {
    margin-top: 11px;
    margin-left: 20px;
  }
`;
