import * as React from "react";
import { State, Action } from "./state";
import * as SharedState from "../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import {
  StandardButton,
  P1,
  OpenBlue,
  Trash,
  Spinner
} from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import {
  Root,
  CasesContainer,
  CalculateBtnContainer,
  InputContainer,
  ResultContainer,
  ErrorsContainer,
  AddCaseBtnContainer,
  DeleteButtonContainer,
  SpinnerContainer,
  CaseContainer
} from "./elements";
import * as PropertiesSelector from "../../properties-selector";
import {
  configPropertyInfo,
  calculationCasePropertyInfo,
  productId,
  calculationCaseResultPropertyInfo
} from "./data";

export function OilonView({
  state,
  sharedState,
  dispatch
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  const caseSelectors = Object.entries(state.propertiesSelectors).filter(
    ([id, _v]) => id.startsWith("case")
  );

  return (
    <Root>
      <CalculateBtnContainer>
        <StandardButton
          size="Large"
          buttonType="PrimaryGreen"
          onClick={() => dispatch(Action.calculate())}
        >
          {sharedState.translate(LanguageTexts.calculate())}
        </StandardButton>
      </CalculateBtnContainer>

      <InputContainer>
        <PropertiesSelector.PropertiesSelectorView
          showCodes={false}
          isReadonly={false}
          hideGroupNames={true}
          productId={productId}
          sharedState={sharedState}
          propertiesInfo={configPropertyInfo}
          fieldGroup={`Settings.${productId}`}
          state={state.propertiesSelectors["config"]}
          dispatch={Dispatch.map(
            action => Action.dispatchPropertiesSelector("config", action),
            dispatch
          )}
        />

        <AddCaseBtnContainer>
          <StandardButton
            size="Small"
            icon={OpenBlue}
            buttonType="SecondaryBlue"
            onClick={() => dispatch(Action.addCase())}
          >
            {"Add Case"}
          </StandardButton>
        </AddCaseBtnContainer>

        <CasesContainer>
          {caseSelectors.map(([id, state], ix) => (
            <CaseContainer>
              <PropertiesSelector.PropertiesSelectorView
                key={id + ix}
                showCodes={false}
                isReadonly={false}
                hideGroupNames={true}
                hidePropertyNames={ix > 0}
                productId={productId}
                sharedState={sharedState}
                propertiesInfo={calculationCasePropertyInfo}
                fieldGroup={`Settings.${productId}`}
                state={state}
                dispatch={Dispatch.map(
                  action => Action.dispatchPropertiesSelector(id, action),
                  dispatch
                )}
              />

              {ix > 0 && (
                <DeleteButtonContainer>
                  <a onClick={() => dispatch(Action.deleteCase(id))}>
                    <Trash height="1.6rem" />
                  </a>
                </DeleteButtonContainer>
              )}
            </CaseContainer>
          ))}
        </CasesContainer>
      </InputContainer>

      {state.isLoading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}

      {state.results && (
        <ResultContainer>
          <ErrorsContainer>
            {state.results.errors.map(e => (
              <P1 weight="normal">{e}</P1>
            ))}
          </ErrorsContainer>
          <CasesContainer>
            {state.results.calculationCaseResults.map((state, ix) => (
              <PropertiesSelector.PropertiesSelectorView
                key={"case-result-" + ix}
                showCodes={false}
                isReadonly={true}
                hideGroupNames={true}
                hidePropertyNames={ix > 0}
                productId={productId}
                sharedState={sharedState}
                propertiesInfo={calculationCaseResultPropertyInfo}
                fieldGroup={`Settings.${productId}`}
                state={state}
                dispatch={Dispatch.map(
                  action =>
                    Action.dispatchPropertiesSelector(
                      "case-result-" + ix,
                      action
                    ),
                  dispatch
                )}
              />
            ))}
          </CasesContainer>
        </ResultContainer>
      )}
    </Root>
  );
}
