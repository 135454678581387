import { exhaustiveCheck } from "ts-exhaustive-check";
import { home } from "./home";

export type NavigationCmd<A> =
  | PushUrl<A>
  | ReplaceUrl<A>
  | Back<A>
  | Forward<A>
  | Load<A>
  | Reload<A>
  | ReloadAndSkipCache<A>;

export interface PushUrl<A> {
  readonly home: typeof home;
  readonly type: "PushUrl";
  readonly url: string;
  readonly onSuccess?: () => A;
}

export function pushUrl<A>(url: string, onSuccess?: () => A): PushUrl<A> {
  return {
    home: "navigation",
    type: "PushUrl",
    url,
    onSuccess
  };
}

export interface ReplaceUrl<A> {
  readonly home: typeof home;
  readonly type: "ReplaceUrl";
  readonly url: string;
  readonly onSuccess?: () => A;
}

export function replaceUrl<A>(url: string, onSuccess?: () => A): ReplaceUrl<A> {
  return {
    home: "navigation",
    type: "ReplaceUrl",
    url,
    onSuccess
  };
}

export interface Back<A> {
  readonly home: typeof home;
  readonly type: "Back";
  readonly n: number;
  readonly onSuccess?: () => A;
}

export function back<A>(n: number, onSuccess?: () => A): Back<A> {
  return {
    home: "navigation",
    type: "Back",
    n,
    onSuccess
  };
}

export interface Forward<A> {
  readonly home: typeof home;
  readonly type: "Forward";
  readonly n: number;
  readonly onSuccess?: () => A;
}

export function forward<A>(n: number, onSuccess?: () => A): Forward<A> {
  return {
    home: "navigation",
    type: "Forward",
    n,
    onSuccess
  };
}

export interface Load<A> {
  readonly home: typeof home;
  readonly type: "Load";
  readonly url: string;
  readonly onSuccess?: () => A;
}

export function load<A>(url: string, onSuccess?: () => A): Load<A> {
  return {
    home: "navigation",
    type: "Load",
    url,
    onSuccess
  };
}

export interface Reload<A> {
  readonly home: typeof home;
  readonly type: "Reload";
  readonly onSuccess?: () => A;
}

export function reload<A>(onSuccess?: () => A): Reload<A> {
  return {
    home: "navigation",
    type: "Reload",
    onSuccess
  };
}

export interface ReloadAndSkipCache<A> {
  readonly home: typeof home;
  readonly type: "ReloadAndSkipCache";
  readonly onSuccess?: () => A;
}

export function reloadAndSkipCache<A>(
  onSuccess?: () => A
): ReloadAndSkipCache<A> {
  return {
    home: "navigation",
    type: "ReloadAndSkipCache",
    onSuccess
  };
}

export function mapCmd<A1, A2>(
  actionMapper: (a: A1) => A2,
  cmd: NavigationCmd<A1>
): NavigationCmd<A2> {
  switch (cmd.type) {
    case "PushUrl": {
      const onSuccess = cmd.onSuccess;
      return {
        ...cmd,
        onSuccess: onSuccess && (() => actionMapper(onSuccess()))
      };
    }
    case "ReplaceUrl":
    case "Back":
    case "Forward":
    case "Load":
    case "Reload": {
      const onSuccess = cmd.onSuccess;
      return {
        ...cmd,
        onSuccess: onSuccess && (() => actionMapper(onSuccess()))
      };
    }
    case "ReloadAndSkipCache": {
      const onSuccess = cmd.onSuccess;
      return {
        ...cmd,
        onSuccess: onSuccess && (() => actionMapper(onSuccess()))
      };
    }
    default: {
      return exhaustiveCheck(cmd, true);
    }
  }
}
