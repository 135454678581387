import styled, { keyframes } from "styled-components";
import { Img } from "@genesys/ui-elements";
import {
  PrimaryColors,
  zIndex,
  StandardButton,
  Label
} from "@genesys/ui-elements";

const LabelWithLink = styled(Label)`
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const Container = styled.div`
  padding: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  > button:first-child {
    margin-right: 5px;
  }
`;

export const ComponentEditorHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonController = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    margin-left: 10px;
  }
`;

export const HeaderBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  max-width: 1440px;
  max-height: 77px;
  margin-left: auto;
  margin-right: auto;
`;

export const FastNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 34px;
  margin-right: 34px;
  > * {
    margin-left: 8px;
    margin-right: 8px;
  }
`;

export const StatusIndicatorContainer = styled.div`
  margin: 0 16px;
  position: relative;
  top: 3px;
`;

export const HeaderBarLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const HeaderBarRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 405px;
`;

export const HeaderBarInnerRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const WorkspaceArea = styled.div`
  background-color: #f4f7fc;
  background-image: linear-gradient(
      to right,
      rgba(200, 200, 200, 0.1) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(200, 200, 200, 0.1) 1px, transparent 1px);
  background-size: 10px 10px;
  height: 933px;
  width: 100%;
  overflow: auto;
`;

export const SystemInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: 12px;
  }
`;

export const SystemInfoTextSection = styled.div`
  display: flex;
  flex-direction: column;
  span {
    line-height: 1.2em;
  }
`;

export const SystemInfoImage = styled(Img)`
  height: 2.2rem;
  width: auto;
`;

export const MoistureLoadLabel = styled(LabelWithLink)``;

export const PricingLabel = styled(LabelWithLink)`
  max-width: 312px;
  overflow: hidden;
  height: 25px;
  text-overflow: ellipsis;
`;

interface SlectedComponentMenuContainerProps {
  readonly left: number;
  readonly top: number;
}

export const SelectedComponentMenuContainer = styled.div<SlectedComponentMenuContainerProps>`
  position: absolute;
  left: ${props => props.left}px;
  top: ${props => props.top}px;
  transform: translate(-50%, 10px);

  box-sizing: border-box;
  border: 1px solid #dddddd;

  border-radius: 9px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  z-index: ${zIndex.Dropdown};
`;

export const SelectedComponentMenu = styled.ul`
  margin: 0;
  padding-top: 19px;
  padding-bottom: 19px;
`;

export const SelectedComponentMenuItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 26px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #f3f3f3;
  }

  img {
    margin-right: 5px;
    height: 0.8667rem;
    width: 0.8667rem;
  }

  span {
    color: ${PrimaryColors.grey};
    font-weight: 500;
    font-size: 0.8667rem;
  }
`;

export const DiagramContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 400px;
  margin-top: 80px;
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

export const DiagramRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const slideInAnimation = keyframes`
 from {left: -200px;}
  to {left: 10px;}
`;

export const AlertContainer = styled.div<{ readonly expanded: boolean }>`
  position: absolute;
  z-index: ${zIndex.AlertView};
  top: ${props => (props.expanded ? "0" : "235px")};

  animation-name: ${slideInAnimation};
  animation-duration: 1s;
`;

export const SystemInfoMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SystemInfoLabel = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: ${PrimaryColors.muntersBlue};

  font-size: 0.8667rem;
  line-height: 1.1333rem;
  font-weight: 500;
`;

export const SystemInfoMenuLabels = styled.div`
  padding-top: 8px;
  padding-bottom: 24px;
  background-color: #ffffff;
`;

export const SystemInfoMenuNotes = styled.div`
  padding-top: 8px;
  padding-bottom: 24px;
  background-color: #f7f9fc;
`;

export const SystemInfoMenuNote = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  font-size: 0.8667rem;
  line-height: 1.1333rem;
  font-weight: 500;
`;

export const SystemInfoMenuHeader = styled.div`
  padding-left: 16px;
  padding-right: 16px;

  font-size: 0.5333rem;
  font-weight: bold;
  letter-spacing: 0.05333rem;
  line-height: 1.6rem;
  color: #8f9bb3;
  text-transform: uppercase;
`;

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DialogButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

export const DialogButton = styled(StandardButton)`
  display: block;
  width: 100%;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const SystemActionsContainer = styled.div`
  z-index: ${zIndex.MainMenu};
  position: absolute;
  border: 1px solid #dddddd;
`;

export const SystemInfoMenuRoot = styled.div`
  position: relative;
`;

export const MeasurementsContainer = styled.div`
  width: 75px;
  display: flex;
  justify-content: space-between;
  h5 {
    cursor: pointer;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  > h1 {
    margin-right: 5px;
  }
`;
