import * as React from "react";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import styled from "styled-components";
import * as SharedState from "../../../shared-state";
import * as SystemStatusEnum from "@genesys/shared/lib/enums/system-status";
import { StandardButton, P1, Spinner } from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";

const Root = styled.div`
  padding: 25px 50px;
`;

const SystemNotLockedContainer = styled.div``;

const BuilderContainer = styled.div``;

const ButtonsContainer = styled.div`
  padding: 10px 0;

  a {
    text-decoration: none;
  }

  button {
    margin-right: 10px;
  }
`;

const StyledAnchor = styled.a`
  border-radius: 3px;
  font-family: Futura;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 12px;
  background-color: #00adf2;
  color: white;

  :hover {
    background-color: #4bccff;
  }
`;

export function BuildInScView({
  state,
  dispatch,
  sharedState,
  systemId,
  systemStatus
}: {
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly systemId: string;
  readonly systemStatus: number;
}): JSX.Element {
  return (
    <Root>
      {systemStatus < SystemStatusEnum.SystemStatus.LockSuccess ? (
        <SystemNotLockedContainer>
          <P1 weight="normal" color="dark">
            {sharedState.translate(LanguageTexts.systemNotLocked())}
          </P1>
        </SystemNotLockedContainer>
      ) : state.isLoading ? (
        <Spinner />
      ) : (
        <BuilderContainer>
          <P1 weight="normal" color="dark">
            {sharedState.translate(LanguageTexts.buildState()) +
              ": " +
              state.stateDescription}
          </P1>

          {state.stateNumber === 1 && (
            <P1 weight="normal" color="dark">
              {sharedState.translate(LanguageTexts.longRunningTask())}
            </P1>
          )}

          {state.error && (
            <P1 weight="normal" color="dark">
              {sharedState.translate(LanguageTexts.couldNotBuild())}
            </P1>
          )}

          <ButtonsContainer>
            <StandardButton
              buttonType="PrimaryBlue"
              size="Small"
              onClick={() => dispatch(Action.requestBuild(systemId))}
              disabled={state.stateNumber > 0}
            >
              {sharedState.translate(LanguageTexts.requestBuild())}
            </StandardButton>

            <StandardButton
              buttonType="PrimaryBlue"
              size="Small"
              onClick={() => dispatch(Action.getBuildState(systemId))}
            >
              {sharedState.translate(LanguageTexts.refresh())}
            </StandardButton>

            {state.modelLink && (
              <StyledAnchor
                target={"_blank"}
                onClick={() => {
                  window.open(state.modelLink);
                }}
              >
                {sharedState.translate(LanguageTexts.open())}
              </StyledAnchor>
            )}
          </ButtonsContainer>

          {state.image && (
            <div>
              <img src={"data:image/png;base64, " + state.image} />
            </div>
          )}
        </BuilderContainer>
      )}
    </Root>
  );
}
