import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Types from "./types";
import * as Tools from "./tools";
import * as System from "../../../system";
import * as KnownProperties from "@genesys/shared/lib/energy-tools/known-properties";
import styled from "styled-components";
import { GenesysSelect } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { State, Action } from "./state";
import { PropertyValueSet } from "@genesys/property";
import {
  ListResultView,
  ChartResultView
} from "../../../../energy-result-visualizer";

const Container = styled.div`
  display: block;
`;

const Title = styled.label`
  color: #00b1f7;
  font-family: Futura;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 7px;
  display: block;
`;

const SubContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0 12px 15px 0;
  > span {
    margin-right: 5px;
  }
`;

export function DetailedView({
  sharedState,
  state,
  system,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly system: System.System;
  readonly state: State;
  readonly dispatch: Dispatch<Action>;
}) {
  const translate = sharedState.translate;
  if (!state) {
    return null;
  }

  const binVisualizers: ReadonlyArray<{
    readonly value: Types.BinVisualizer;
    readonly title: string;
  }> = [
    { title: translate(LanguageTexts.list()), value: "List" },
    { title: translate(LanguageTexts.chart()), value: "Chart" }
  ];

  const selectedBinVisualizer = state.binVisualizer;
  const selectedListPreset = state.listPreset;
  const energyResults = Tools.getEnergyResult(
    [system],
    state.productQuery!.product.systemType.energyTotals,
    state.productQuery!.product.systemType.energyList,
    "SYS"
  );

  return (
    <Container>
      <Title>{translate(LanguageTexts.detailedResults())}</Title>
      <SubContainer>
        <span>{translate(LanguageTexts.viewAs())}</span>
        <GenesysSelect
          width={80}
          height={35}
          options={binVisualizers.map(b => ({
            value: b.value,
            title: b.title
          }))}
          value={selectedBinVisualizer}
          onChange={e => {
            dispatch(
              Action.setBinVisualizer(e.target.value as Types.BinVisualizer)
            );
          }}
        />
      </SubContainer>
      {selectedBinVisualizer === "List" ? (
        <ListResultView
          onFormatCleared={(fieldGroup, fieldName) => {
            dispatch(Action.onFormatCleared(fieldGroup, fieldName));
          }}
          onFormatChanged={(fieldGroup, fieldName, unit, decimalCount) => {
            dispatch(
              Action.onFormatChanged(fieldGroup, fieldName, unit, decimalCount)
            );
          }}
          energyResults={[energyResults]}
          selectedListPreset={selectedListPreset}
          sharedState={sharedState}
          listPresetOnChange={preset => {
            dispatch(Action.setListPreset(preset));
          }}
        />
      ) : (
        <ChartResultView
          climateCoolingDataType={state.dataType!}
          binSize={
            PropertyValueSet.getInteger(
              KnownProperties.binSize,
              state.binSelections
            )!
          }
          energyResults={[energyResults]}
          selectedClimateCoolingDataType={state.climateCoolingDataType!}
          climateCoolingDataTypeOnChange={id =>
            dispatch(Action.setClimateCoolingType(id))
          }
          selectedChartPresetId={state.chartPreset!}
          sharedState={sharedState}
          chartPresets={state.energyChartPresets}
          chartPresetOnChange={id => dispatch(Action.setChartPreset(id))}
        />
      )}
    </Container>
  );
}
