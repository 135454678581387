import * as ActionHandlers from "./handlers";
import * as SharedState from "../../../../shared-state";
import { UpdateFunction } from "./types";

export const actionHandlersMap: Record<
  SharedState.Action["type"],
  UpdateFunction
> = {
  addAlertMessageToQueue: ActionHandlers.handleAddAlertMessageToQueue,
  clearAmountFormat: ActionHandlers.handleClearAmountFormat,
  createLabel: ActionHandlers.handleCreateLabel,
  deleteLabel: ActionHandlers.handleDeleteLabel,
  loadLastOpenedSystemsAndFavorites:
    ActionHandlers.handleLoadLastOpenedSystemsAndFavorites,
  removeSystemsFromLastOpenedSystemsAndFavorites:
    ActionHandlers.handleRemoveSystemsFromLastOpenedSystemsAndFavorites,
  resetUserSettings: ActionHandlers.handleResetUserSettings,
  saveAmountFormat: ActionHandlers.handleSaveAmountFormat,
  setActiveAmountFieldProfile: ActionHandlers.handleSetActiveAmountFieldProfile,
  setBrowserTitle: ActionHandlers.handleSetBrowserTitle,
  setDebugSettings: ActionHandlers.handleSetDebugSettings,
  setPsychrometricChartSettings:
    ActionHandlers.handleSetPsychrometricChartSettings,
  setSystemSummarySettings: ActionHandlers.handleSetSystemSummarySettings,
  setUserCurrency: ActionHandlers.handleSetUserCurrency,
  setUserDefaultSalesOrganisation:
    ActionHandlers.handleSetUserDefaultSalesOrganisation,
  setUserInitialSettings: ActionHandlers.handleSetUserInitialSettings,
  setUserLanguage: ActionHandlers.handleSetUserLanguage,
  setUserLocale: ActionHandlers.handleSetUserLocale,
  setUserSettingsClimate: ActionHandlers.handleSetUserSettingsClimate,
  toggleFavoritesSystems: ActionHandlers.handleToggleFavoritesSystems,
  toggleReportForSystemType: ActionHandlers.handleToggleReportForSystemType,
  updateCreateSystemType: ActionHandlers.handleUpdateCreateSystemType,
  updateLabel: ActionHandlers.handleUpdateLabel,
  updateLastCreatedSystemType: ActionHandlers.handleUpdateLastCreatedSystemType,
  updateLastOpenedSystems: ActionHandlers.handleUpdateLastOpenedSystems,
  updateSearchScope: ActionHandlers.handleUpdateSearchScope
};
