import styled from "styled-components";
import { isDesktop } from "react-device-detect";
import { PrimaryColors } from "@genesys/ui-elements";

export const Root = styled.div`
  margin-bottom: 25px;
`;

export const TopBarContainer = styled.div`
  height: 111px;
  border-top: 2px solid rgba(221, 228, 240, 0.3);
  border-bottom: 2px solid rgba(221, 228, 240, 0.3);
  display: flex;
  justify-content: space-between;
`;

export const TopLeftContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 18px 24px;
  h1 {
    margin: 0 15px;
    color: ${PrimaryColors.muntersBlue};
  }
`;

export const ViewSelectionAnchor = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  margin-right: 8px;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  background: ${(props: { readonly isSelected: boolean }) =>
    props.isSelected ? PrimaryColors.lightBlue : "inherit"};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  cursor: pointer;
  img {
    margin: 0;
  }
  :hover {
    background: ${PrimaryColors.lightBlue};
  }
`;

export const SearchOptionsContainer = styled.div`
  margin: 24px 12px 0 0;
`;

export const SystemManagerBody = styled.div`
  display: flex;
  align-items: stretch;
  height: 820px;
`;

export const ResultsRoot = styled.div`
  width: ${isDesktop ? "82%" : "100%"};
  margin-left: 16px;
`;

export const ResultsTopContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  height: 25px;
  margin: 24px 0 12px 0;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 14px;
  h3 {
    margin: 0 24px 0 12px;
  }
  button {
    margin: 0 4px;
  }
`;

export const SortBySelectContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 24px 2px 0;

  div:nth-child(2) {
    margin-left: 10px;
    width: 140px;
    div {
      div {
        padding: 0 24px 2px 5px;
      }
    }
  }
`;

export const SystemsPerPageContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 24px 2px 0;

  div:nth-child(2) {
    margin-left: 10px;
    width: 50px;
    div {
      div {
        padding: 0 24px 2px 5px;
      }
    }
  }
`;

export const NumberOfSearchResultsContainer = styled.div`
  display: flex;
  margin: 0 0 18px 0;

  h5,
  p {
    line-height: 20px;
    margin-right: 12px;
  }

  p {
    height: 20px;
    border-radius: 10px;
    background-color: #3d3d3d;
    color: white;
    text-align: center;
    padding: 0 8px;
  }
`;

export const SearchResultsTable = styled.table`
  th:nth-child(1),
  td:nth-child(1) {
    width: 30px;
    max-width: 30px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 30px;
    max-width: 30px;
    padding: 0 0 0 10px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 38px;
    max-width: 38px;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 80px;
    max-width: 80px;
  }

  th:nth-child(5),
  td:nth-child(5) {
    max-width: 290px;
    width: 290px;
  }

  th:nth-child(6),
  td:nth-child(6) {
    width: 140px;
    max-width: 140px;
  }

  th:nth-child(7),
  th:nth-child(8),
  td:nth-child(7),
  td:nth-child(8) {
    width: 130px;
    max-width: 130px;
  }

  th:nth-child(9),
  td:nth-child(9) {
    width: 70px;
    max-width: 70px;
  }

  th:nth-child(10),
  td:nth-child(10) {
    max-width: 190px;
    width: 190px;
  }

  th:nth-child(11),
  td:nth-child(11) {
    width: 85px;
    max-width: 85px;
  }

  td,
  th {
    padding: 0 5px;

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  th {
    height: 30px;
    text-align: left;
    a {
      cursor: pointer;
    }
    h5 {
      display: inline;
      img {
        margin-left: 8px;
      }
    }
  }

  td {
    height: 40px;
    a {
      cursor: pointer;
    }
  }

  tbody:nth-child(2n + 2) {
    background-color: #f7f9fc;
  }
`;

export const BottomPagnationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 25px;
`;

export const StyledDiv = styled.div`
  margin-top: -9px;
`;
