import * as React from "react";
import { State, Action } from "./state";
import * as SharedState from "../../shared-state";
import { Dispatch } from "@typescript-tea/core";
import {
  Root,
  OuterContainer,
  InnerContainer,
  PropertyNamesContainer,
  AirStreamContainer,
  CalculateContainer,
  AirOutContainer
} from "./elements";
import { MixResult } from "./components/mix-result";
import { StandardButton, H2, P1, H3 } from "@genesys/ui-elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as PropertiesSelector from "../../properties-selector";
import { PropertyFilter } from "@genesys/property";

export function AirMixingToolView({
  state,
  dispatch,
  sharedState
}: {
  readonly dispatch: Dispatch<Action>;
  readonly state: State;
  readonly sharedState: SharedState.State;
}): JSX.Element {
  return (
    <Root>
      <OuterContainer>
        <H2 weight="normal" color="primary">
          {sharedState.translate(LanguageTexts.airMixer())}
        </H2>
        <InnerContainer>
          <PropertyNamesContainer>
            <P1 weight="normal" color="dark">
              {sharedState.translate(LanguageTexts.temperature())}
            </P1>

            <P1 weight="normal" color="dark">
              {sharedState.translate(LanguageTexts.humidity())}
            </P1>

            <P1 weight="normal" color="dark">
              Pressure
            </P1>

            <P1 weight="normal" color="dark">
              Flow
            </P1>
          </PropertyNamesContainer>

          <AirStreamContainer>
            <H3 weight="normal" color="dark">
              Air 1
            </H3>

            <PropertiesSelector.PropertiesSelectorView
              dispatch={Dispatch.map(
                action => Action.dispatchPropertiesSelector("one", action),
                dispatch
              )}
              productId={"AirMixer"}
              sharedState={sharedState}
              propertiesInfo={AirStreamDefinition()}
              fieldGroup={"Settings.AirMixer"}
              isReadonly={false}
              showCodes={false}
              state={state.airStreamOne}
              hidePropertyNames={true}
            />
          </AirStreamContainer>

          <AirStreamContainer>
            <H3 weight="normal" color="dark">
              Air 2
            </H3>

            <PropertiesSelector.PropertiesSelectorView
              dispatch={Dispatch.map(
                action => Action.dispatchPropertiesSelector("two", action),
                dispatch
              )}
              productId={"AirMixer"}
              sharedState={sharedState}
              propertiesInfo={AirStreamDefinition()}
              fieldGroup={"Settings.AirMixer"}
              isReadonly={false}
              showCodes={false}
              state={state.airStreamTwo}
              hidePropertyNames={true}
            />
          </AirStreamContainer>

          <CalculateContainer>
            <StandardButton
              buttonType="PrimaryGreen"
              size="Large"
              onClick={() => dispatch(Action.calculateAirMix())}
            >
              {sharedState.translate(LanguageTexts.calculate())}
            </StandardButton>
          </CalculateContainer>

          <AirOutContainer>
            <H3 weight="normal" color="dark">
              Air Out
            </H3>
            <MixResult
              propertyValueSet={state.airMix}
              sharedState={sharedState}
              dispatch={dispatch}
            />
          </AirOutContainer>
        </InnerContainer>
      </OuterContainer>
    </Root>
  );
}

function AirStreamDefinition(): ReadonlyArray<PropertiesSelector.PropertyInfo> {
  return [
    {
      sortNo: 1,
      name: "temperature",
      group: "",
      quantity: "Temperature",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      items: [],
      defaultValues: [],
      valueSources: [],
      descriptionTexts: []
    },
    {
      sortNo: 2,
      name: "humidity",
      group: "",
      quantity: "HumidityRatio",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: ["temperature", "pressure"],
      items: [],
      defaultValues: [],
      valueSources: [],
      descriptionTexts: []
    },
    {
      sortNo: 3,
      name: "pressure",
      group: "",
      quantity: "Pressure",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    },
    {
      sortNo: 4,
      name: "flow",
      group: "",
      quantity: "MassFlow",
      validationFilter: PropertyFilter.Empty,
      visibilityFilter: PropertyFilter.Empty,
      conversionParameters: undefined,
      items: [],
      valueSources: [],
      defaultValues: [],
      descriptionTexts: []
    }
  ];
}
