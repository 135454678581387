import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../shared-state";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { Action } from "../state";
import { S1, S2 } from "@genesys/ui-elements";
import { AmountPropertySelector } from "../../../../amount-property-selector";
import { SketchItem } from "../types";
import {
  SketchTableRoot,
  SketchTableGrid,
  StyledLegend,
  GreyDiv
} from "../elements";

const fieldName = "weight";
const fieldGroup = "sketch";

export function SketchTable({
  header,
  components,
  sharedState,
  dispatch
}: {
  readonly header: LanguageTexts.TextDefinition;
  readonly components: ReadonlyArray<SketchItem>;
  readonly sharedState: SharedState.State;
  readonly dispatch: (action: Action) => void;
}): JSX.Element {
  return (
    <SketchTableRoot>
      <StyledLegend>
        <S1 color="primary">{sharedState.translate(header)} </S1>
      </StyledLegend>
      <SketchTableGrid>
        {components.map(comp => [
          <S2 key={`${comp.id}1`}>
            {comp.inCasing && " └ "}
            {comp.label}
          </S2>,
          <S2 key={`${comp.id}2`}>{comp.ItemName}</S2>,
          <S2 key={`${comp.id}3`}>
            {comp.weight ? (
              <GreyDiv>
                <AmountPropertySelector
                  type="with-simplified-props"
                  translate={sharedState.translate}
                  fieldGroup={fieldGroup}
                  fieldName={fieldName}
                  readOnly={true}
                  propertyName={fieldName}
                  propertyValueSet={PropertyValueSet.fromProperty(
                    fieldName,
                    PropertyValue.fromString(comp.weight!)!
                  )}
                  onFormatChanged={(unit, decimalCount) =>
                    dispatch(
                      Action.onFormatChanged(
                        fieldGroup,
                        fieldName,
                        unit,
                        decimalCount
                      )
                    )
                  }
                  onFormatCleared={() =>
                    dispatch(Action.onFormatCleared(fieldGroup, fieldName))
                  }
                  onValueChange={() => {
                    /** */
                  }}
                  getAmountFormat={sharedState.screenAmounts.getAmountFormat}
                  quantity={"Mass"}
                />
              </GreyDiv>
            ) : (
              <p></p>
            )}
          </S2>
        ])}
      </SketchTableGrid>
    </SketchTableRoot>
  );
}
