import * as React from "react";
import { StandardButton } from "@genesys/ui-elements";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { FavoriteSystemCard } from "../favorite-system-card/favorite-system-card";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../../index";
import { SystemActionsStateMap } from "../../types";
import {
  SystemItem,
  Header,
  Body,
  Container,
  BottomContainer,
  FavouritesLabel,
  Label
} from "./elements";

const favoritesCardIndexOffset = 100;

export function FavoriteSystemsView({
  sharedState,
  systemActionsState,
  toggleSystemActions,
  onToggleIsFavorite,
  navigate,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly systemActionsState: SystemActionsStateMap | undefined;
  readonly toggleSystemActions: (cardIndex: number) => void;
  readonly onToggleIsFavorite: (systemId: string) => void;
  readonly navigate: (url: string) => void;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  return (
    <Container>
      <Header>
        <FavouritesLabel>
          {sharedState.translate(LanguageTexts.favourites())}
        </FavouritesLabel>
      </Header>
      <Body>
        {sharedState.user.favoritesSystems.length > 0 ? (
          sharedState.user.favoritesSystems
            .concat()
            .reverse()
            .map((favoriteSystem, i) => {
              if (favoriteSystem.type === "loading") {
                return (
                  <SystemItem key={`${i}`}>
                    <FavoriteSystemCard type={"loading"} />
                  </SystemItem>
                );
              }

              if (favoriteSystem.type === "error") {
                const system = favoriteSystem;
                return (
                  <SystemItem key={`${i}${favoriteSystem.id}`}>
                    <FavoriteSystemCard
                      type="error"
                      showErrorDetails={() =>
                        dispatch(
                          Action.showSystemErrorDetails({
                            systemId: system.id,
                            message: system.message
                          })
                        )
                      }
                    />
                  </SystemItem>
                );
              }

              const genesysNo = sharedState.genesysPrefix.genesysNo(
                favoriteSystem.system.genesysNo,
                favoriteSystem.system.revisionNo
              );
              return (
                <SystemItem
                  key={`${i}${favoriteSystem.system.id}`}
                  href={`/system/${genesysNo}`}
                >
                  <FavoriteSystemCard
                    type="loaded"
                    cardIndex={i + favoritesCardIndexOffset}
                    favoriteSystem={favoriteSystem.system}
                    sharedState={sharedState}
                    systemActionsState={systemActionsState}
                    onToggleSystemActions={toggleSystemActions}
                    onToggleIFavorite={() =>
                      onToggleIsFavorite(favoriteSystem.system.id)
                    }
                    dispatch={dispatch}
                  />
                </SystemItem>
              );
            })
        ) : (
          <Label>{sharedState.translate(LanguageTexts.noFavoritesMsg())}</Label>
        )}
      </Body>
      <BottomContainer>
        <StandardButton
          size="Large"
          buttonType="PrimaryBlue"
          onClick={() => navigate("/new-system")}
        >
          {sharedState.translate(LanguageTexts.createNewSystem()).toUpperCase()}
        </StandardButton>
      </BottomContainer>
    </Container>
  );
}
