import gql from "graphql-tag";

export const productQuery = gql`
  query DrySizeProductQuery($systemTypeInput: [GetSystemTypeInputType!]!) {
    product {
      systemTypes(input: $systemTypeInput) {
        id
        name

        systemTypeCaseFilter {
          caseType
          calculationType
          claimFilter
        }

        dhu {
          id
          dehumidificationThreshold: productDataTable(
            tableName: "dehumidificationThreshold"
          ) {
            id
            tableName
            rows {
              id
              propertyFilter
              values
            }
          }
        }

        new {
          id
          properties {
            id
            sortNo
            quantity
            defaultValues {
              value
              propertyFilter
            }
            name
            groupName
            visibilityFilter
            validationFilter
            values {
              id
              sortNo
              image
              value
              validationFilter
              rangeFilter
            }
            quantityConversionParams
          }

          flowLimitationsSI: productDataTable(tableName: "flowLimitationsSI") {
            id
            tableName
            rows {
              id
              propertyFilter
              values
            }
          }

          newPropertiesMapping: productDataTable(
            tableName: "newPropertiesMapping"
          ) {
            id
            tableName
            rows {
              id
              propertyFilter
              values
            }
          }
        }

        sys {
          id
          properties {
            id
            sortNo
            quantity
            defaultValues {
              value
              propertyFilter
            }
            name
            groupName
            visibilityFilter
            validationFilter
            values {
              id
              sortNo
              image
              value
              validationFilter
              rangeFilter
            }
            quantityConversionParams
          }
        }

        opc {
          id
          properties {
            id
            sortNo
            quantity
            defaultValues {
              value
              propertyFilter
            }
            name
            groupName
            visibilityFilter
            validationFilter
            values {
              id
              sortNo
              image
              value
              validationFilter
              rangeFilter
            }
            quantityConversionParams
          }
        }

        templates {
          id
          name
          propertyFilter
          climateDataDefaults
          components {
            id
            label
            productId
            properties
            propertyFilter
            visibleProperties
            componentSections {
              id
              productSectionId
              airstream
              sortNo
            }
          }
        }
      }

      countries {
        id
        name
        regions {
          id
          name
          locations {
            id
            name
            latitude
            longitude
            elevation
            binLocationId
            extremeMaxWB
          }
        }
      }
    }
  }
`;

export const createNewSystem = gql`
  mutation DrySizeCreateNewSystems($input: [CreateSystemInputType!]!) {
    createNewSystems(systemSettings: $input) {
      id
      genesysNo
      revisionNo
    }
  }
`;

export const calculateNewSystem = gql`
  query DrySizeCalculateNewSystem(
    $systemSettings: CreateSystemInputType!
    $systemTypeId: GetSystemTypeInputType!
  ) {
    product {
      systemType(input: $systemTypeId) {
        id

        allProducts {
          id
          helpText: productDataTable(tableName: "helpText") {
            rows {
              id
              propertyFilter
              values
            }
          }

          caseFilter: productDataTable(tableName: "caseFilter") {
            id
            tableName
            rows {
              id
              propertyFilter
              values
            }
          }
        }
      }
    }

    user {
      calculateNewSystem(systemSettings: $systemSettings) {
        componentsWithErrors {
          componentId
          productId
          properties
          messages {
            id
            messageCode
            messageSeverity
            messageParameters
            operatingCaseResultId
          }
        }
        operatingCases {
          id
          caseName
          customCaseName
          caseType
          settings
          binData
          sortNo
          results {
            id
            componentId
            calculationType
            settings
            isDesignCase
            sortNo
          }
        }
      }
    }
  }
`;

export const moistureLoadQuery = gql`
  query DrySizeMoistureLoadQuery($moistureLoadNo: Int!, $revisionNo: Int!) {
    user {
      moistureLoadByMoistureLoadNo(
        moistureLoadNo: $moistureLoadNo
        revisionNo: $revisionNo
      ) {
        id
        moistureloadInput {
          id
          climateSettings
        }
        moistureLoadResult {
          maxLoadResults {
            id
            selectedFlowId
            caseType
            needResourceString
            caseName
            usedRoomControlSettings
            operatingCaseResults
            roomWarnings
            flowSuggestions {
              id
              flowSourceId
              isValid
              result
            }
          }
        }

        moistureloadFile {
          id
          name
          labels {
            id
            name
          }
        }

        moistureloadInput {
          settings
        }
      }
    }
  }
`;
