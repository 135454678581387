import * as SharedState from "../../shared-state";
import * as GQLTypes from "../../graphql-types";
import * as Authorization from "@genesys/shared/lib/authorization";
import { promiseCmd } from "../../promise-effect-manager";
import { Cmd } from "@typescript-tea/core";
import { Action } from "./actions";
import {
  recentAndFavoritesQuery,
  translationQuery,
  validationQuery
} from "../queries";
import { exhaustiveCheck } from "ts-exhaustive-check";

export const limitedLanguagePath = ["/moisture-load-manager", "/moisture-load"];
export const allowedLangauages = ["en-US", "de-DE"];

export async function queryTranslations(
  sharedState: SharedState.State,
  language: string
) {
  return await sharedState.graphQL.queryProduct<
    GQLTypes.TranslationQuery,
    GQLTypes.TranslationQueryVariables
  >(translationQuery, {
    language
  });
}

export function userSettingsDoComplyWithClaims(
  claims: Authorization.ApplicationClaims,
  currency: string
): boolean {
  if (!Authorization.hasPermission(claims, "CurrencyPrice", currency)) {
    return false;
  }

  return true;
}

export function buildloadRecentAndFavoritesCmd(
  sharedState: SharedState.State,
  force: boolean
): Cmd<Action> {
  const getIdFromSystem = (system: SharedState.BreifSystem) => {
    switch (system.type) {
      case "error":
        return system.id;
      case "loaded":
        return system.system.id;
      case "loading":
        return system.id;
      default:
        return exhaustiveCheck(system);
    }
  };

  const allSystems = [
    ...sharedState.user.lastOpenedSystems,
    ...sharedState.user.favoritesSystems
  ];
  const allIds = allSystems.map(getIdFromSystem);
  const idsToLoad = allSystems
    .filter(s => force || (s.type === "loading" && s.id))
    .map(getIdFromSystem);
  const uniqueAllIds = Array.from(new Set(allIds));
  const uniqueIdsToLoad = Array.from(new Set(idsToLoad));

  return promiseCmd(
    async () => {
      const validSystems: Array<string> = [];
      const errors = new Map<string, string>();
      for (const systemId of uniqueIdsToLoad) {
        try {
          const validation = await sharedState.graphQL.queryUser<
            GQLTypes.RecentSystemsHasPermission,
            GQLTypes.RecentSystemsHasPermissionVariables
          >(validationQuery, {
            systemId
          });

          if (validation.user.validateSystem.hasPermission) {
            validSystems.push(systemId);
          }
        } catch (error) {
          errors.set(systemId, error?.message);
        }
      }

      const res = await sharedState.graphQL.queryUser<
        GQLTypes.RecentAndFavoritesQuery,
        GQLTypes.RecentAndFavoritesQueryVariables
      >(recentAndFavoritesQuery, { ids: validSystems });
      return {
        res,
        inValidIDs: uniqueIdsToLoad.filter(id => !validSystems.includes(id)),
        errors: errors
      };
    },
    data => {
      const recentSystems: Array<SharedState.BreifSystem> = [];
      const favoriteSystems: Array<SharedState.BreifSystem> = [];
      for (const id of uniqueAllIds) {
        const favoriteSystem = sharedState.user.favoritesSystems.find(
          s => getIdFromSystem(s) === id
        );
        if (favoriteSystem) {
          if (data.inValidIDs.includes(id)) {
            favoriteSystems.push({
              type: "error",
              message: data.errors.get(id) ?? "",
              id: id
            });
          } else {
            const system = data.res.user.systems.find(s => s.id === id);
            if (system) {
              favoriteSystems.push({
                type: "loaded",
                system: {
                  id: system.id,
                  genesysNo: system.systemFile.genesysNo,
                  revisionNo: system.revisionNo,
                  labels: system.systemFile.labels,
                  name: system.systemFile.name,
                  systemFileName: system.systemFile.name,
                  owner: system.owner.userName,
                  status: system.status,
                  statusDate: system.statusDate,
                  systemFileId: system.systemFile.id,
                  systemTypeId: system.systemFile.systemTypeId,
                  systems: system.systemFile.systems.map(s => ({
                    id: s.id,
                    comment: s.comment || "",
                    revisionNo: s.revisionNo,
                    status: s.status,
                    statusDate: s.statusDate,
                    salesOrganisationId: s.salesOrganisationId
                  }))
                }
              });
            } else {
              favoriteSystems.push(favoriteSystem);
            }
          }
        }
        const recentSystem = sharedState.user.lastOpenedSystems.find(
          s => getIdFromSystem(s) === id
        );
        if (recentSystem) {
          if (data.inValidIDs.includes(id)) {
            recentSystems.push({
              type: "error",
              message: data.errors.get(id) ?? "",
              id: id
            });
          } else {
            const system = data.res.user.systems.find(s => s.id === id);
            if (system) {
              recentSystems.push({
                type: "loaded",
                system: {
                  id: system.id,
                  genesysNo: system.systemFile.genesysNo,
                  revisionNo: system.revisionNo,
                  labels: system.systemFile.labels,
                  name: system.systemFile.name,
                  systemFileName: system.systemFile.name,
                  owner: system.owner.userName,
                  status: system.status,
                  statusDate: system.statusDate,
                  systemFileId: system.systemFile.id,
                  systemTypeId: system.systemFile.systemTypeId,
                  systems: system.systemFile.systems.map(s => ({
                    id: s.id,
                    comment: s.comment || "",
                    revisionNo: s.revisionNo,
                    status: s.status,
                    statusDate: s.statusDate,
                    salesOrganisationId: s.salesOrganisationId
                  }))
                }
              });
            } else {
              recentSystems.push(recentSystem);
            }
          }
        }
      }
      return Action.recentAndFavoritesLoaded(recentSystems, favoriteSystems);
    }
  );
}
