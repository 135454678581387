import styled from "styled-components";
import { PrimaryColors, zIndex } from "@genesys/ui-elements";

export const Root = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  margin: 0px 3px;
`;

export const SelectorContainer = styled.div`
  background-color: white;
  display: flex;
  z-index: ${zIndex.AmountFormat};
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const UnitLabel = styled.label`
  white-space: nowrap;
  ${(props: { readonly userDefinedFormat: boolean }) =>
    props.userDefinedFormat && "font-weight: bold"}
`;

export const UnitLabelContainer = styled.div`
  position: relative;
`;

export const HoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: ${PrimaryColors.darkBlue};
  color: ${PrimaryColors.lightBlue};
  white-space: nowrap;
  padding: 4px;
  z-index: ${zIndex.FullOverlay};
  p {
    padding: 2px;
    line-height: 1rem;
    font-size: 0.8rem;
  }
`;
