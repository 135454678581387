import * as React from "react";
import styled from "styled-components";
import { InfoGray, S1, Pop } from "@genesys/ui-elements";

const NotOpenContainer = styled.span`
  :hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  width: 460px;
  padding-top: 12.5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 10px;
`;

const Content = styled.div`
  overflow-y: auto;
  max-height: 200px;
  /* display: grid;
  row-gap: 10px;
  font-size: 13px;
  grid-template-columns: 1fr 2fr; */

  ::-webkit-scrollbar {
    width: 3px;

    margin-right: 20px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #023442;

    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Title = styled(S1)`
  margin-bottom: 5px;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledLabel = styled.label`
  font-size: 13px;
  margin-bottom: 5px;
  display: block;
  white-space: pre-wrap;
`;

export function View({
  children,
  title,
  subtitle,
  icon = <InfoGray height="20px" />
}: {
  readonly children: React.ReactNode;
  readonly title: string;
  readonly subtitle?: string;
  readonly icon?: JSX.Element;
}) {
  const labelRef = React.useRef(null);

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(labelRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <NotOpenContainer ref={labelRef} onClick={handleClick}>
        {icon}
      </NotOpenContainer>

      {anchorEl && (
        <Pop
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          anchor={anchorEl}
          onClose={handleClose}
        >
          <Container>
            <Title>{title}</Title>
            {subtitle && <StyledLabel>{subtitle}</StyledLabel>}

            <Content>{children}</Content>
          </Container>
        </Pop>
      )}
    </>
  );
}
