import * as React from "react";
import styled from "styled-components";
import { CheckBox, StandardButton } from "@genesys/ui-elements";
import { Action } from "../../state";
import { Dispatch } from "@typescript-tea/core";
import { ButtonWithPopUpContext, ContextValue } from "./button-with-pop-up";

export const publicClaim = "PUBLIC";

const StyledUl = styled.ul`
  overflow-y: auto;
  max-height: 283px;
  width: 230px;

  ::-webkit-scrollbar {
    width: 4px;
    margin-right: 20px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const StyledLi = styled.li<{
  readonly isChecked: boolean;
}>`
  padding-left: 12px;
  padding-top: 2px;

  ${props => props.isChecked && "background-color: #F1F5FA;"};
`;

export function ClaimsManager({
  aclClaims,
  allClaimValues,
  disabled,
  canEdit,
  dispatch
}: {
  readonly allClaimValues: ReadonlyArray<string>;
  readonly disabled: boolean;
  readonly aclClaims: ReadonlyArray<string>;
  readonly canEdit: boolean;
  readonly dispatch: Dispatch<Action>;
}) {
  const [selectedClaimsValues, setClaimValues] = React.useState(aclClaims);

  const { toggle } = React.useContext<ContextValue>(
    ButtonWithPopUpContext as any
  );

  return (
    <div>
      <StyledUl>
        {[publicClaim, ...allClaimValues].map(x => {
          const isChecked = selectedClaimsValues.includes(x);
          return (
            <StyledLi key={x} isChecked={true}>
              <CheckBox
                disabled={!canEdit}
                onClick={() =>
                  isChecked
                    ? setClaimValues(
                        selectedClaimsValues.filter(claims => claims !== x)
                      )
                    : setClaimValues([...selectedClaimsValues, x])
                }
                children={<span>{x}</span>}
                isChecked={isChecked}
              />
            </StyledLi>
          );
        })}
      </StyledUl>

      <div style={{ marginTop: "10px" }}>
        <StandardButton
          onClick={() => toggle(false)}
          size="Small"
          buttonType="SecondaryBlue"
        >
          Cancel
        </StandardButton>
        <StandardButton
          disabled={disabled}
          onClick={() =>
            dispatch(
              Action.setAccesControlList(undefined, selectedClaimsValues)
            )
          }
          size="Small"
          buttonType="PrimaryBlue"
        >
          Ok
        </StandardButton>
      </div>
    </div>
  );
}
