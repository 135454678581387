import { exhaustiveCheck } from "ts-exhaustive-check";
import gql from "graphql-tag";
import * as SharedState from "../../../shared-state";
import { Cmd } from "@typescript-tea/core";
import * as GraphQlTypes from "../../../graphql-types";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Format } from "./types";

const PidDiagramUserQuery = gql`
  query PidDiagramUserQuery($systemId: ID!) {
    user {
      system(id: $systemId) {
        id
        status
        systemFile {
          id
          name
        }
        airstreams {
          id
          componentSections {
            id
            productSectionId
            componentId
            productSectionSortNo
            sortNo
          }
        }
        components {
          id
          productId
          properties
          sections {
            id
            productSectionId
            componentId
            productSectionSortNo
            sortNo
          }
          messages {
            id
            messageCode
            messageSeverity
          }
        }
      }
    }
  }
`;

const PidDiagramProductQuery = gql`
  query PidDiagramProductQuery($systemTypeInput: GetSystemTypeInputType!) {
    product {
      systemType(input: $systemTypeInput) {
        id
        symbols {
          id
          symbolId
          svg
          width
          height
        }
        allProducts {
          id
          rangeFilter
          properties {
            id
            name
            quantity
          }
          boxConnectionPoints {
            id
            productSectionId
            box
            connectionType
            positionX
            positionY
            positionZ
            mirrorX
            mirrorY
            rotation
            hideStatePoint
            diagramType
            propertyFilter
          }
          boxSymbols {
            id
            box
            symbolId
            positionX
            positionY
            positionZ
            mirrorX
            mirrorY
            rotation
            sizeX
            sizeY
            propertyFilter
            absoluteRotationAndScale
            useForSelectionBounds
            diagramType
          }
          boxTexts {
            id
            box
            source
            text
            fontSize
            positionX
            positionY
            positionZ
            rotation
            absolutePosition
            useAsLabel
            propertyFilter
            diagramType
          }
          boxLinePoints {
            id
            box
            connectionId
            lineType
            positionX
            positionY
            direction
            propertyFilter
            diagramType
          }
        }
      }
    }
  }
`;

export type State = {
  readonly userData: GraphQlTypes.PidDiagramUserQuery | undefined;
  readonly productData: GraphQlTypes.PidDiagramProductQuery | undefined;
  readonly selectedFormat: Format;
  readonly showLoader: boolean;
};

export const init = (
  sharedState: SharedState.State,
  systemId: string,
  systemTypeId: string
): [State, Cmd<Action>] => {
  return [
    {
      userData: undefined,
      productData: undefined,
      selectedFormat: { fileExtension: "svg", apiValue: "SVG" },
      showLoader: false
    },
    sharedState.graphQL.queryUserCmd<
      GraphQlTypes.PidDiagramUserQuery,
      GraphQlTypes.PidDiagramUserQueryVariables,
      Action
    >(
      PidDiagramUserQuery,
      {
        systemId: systemId
      },
      data => Action.userDataRecieved(data, systemTypeId)
    )
  ];
};

export const Action = ctorsUnion({
  userDataRecieved: (
    data: GraphQlTypes.PidDiagramUserQuery,
    systemTypeId: string
  ) => ({ data, systemTypeId }),
  productDataRecieved: (data: GraphQlTypes.PidDiagramProductQuery) => ({
    data
  }),
  setFormat: (format: string) => ({ format }),
  setLoader: (visible: boolean) => ({ visible })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "userDataRecieved": {
      return [
        { ...state, userData: action.data },
        sharedState.graphQL.queryProductCmd<
          GraphQlTypes.PidDiagramProductQuery,
          GraphQlTypes.PidDiagramProductQueryVariables,
          Action
        >(
          PidDiagramProductQuery,
          {
            systemTypeInput: {
              systemTypeId: action.systemTypeId
            }
          },
          Action.productDataRecieved
        )
      ];
    }
    case "productDataRecieved": {
      return [
        {
          ...state,
          productData: action.data
        }
      ];
    }
    case "setFormat": {
      const selectedFormat: Format =
        action.format === "SVG"
          ? { fileExtension: "svg", apiValue: "SVG" }
          : { fileExtension: "dxf", apiValue: "DXF2D" };

      return [{ ...state, selectedFormat: selectedFormat }];
    }
    case "setLoader": {
      return [{ ...state, showLoader: action.visible }];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
