import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../shared-state";
import {
  CheckBox,
  EditBlue,
  Trash,
  Input,
  StandardButton,
  OpenGreen
} from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { Action, State } from "./state";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  FilterInput,
  InputContainer,
  RowSection,
  StyledLabel,
  StyledLi,
  TrashContainer,
  Container,
  UpperSubContainer,
  StyledButton,
  LowerSubContainer,
  ButtonsContainer
} from "./elements";
import { Label, View } from "./types";

export function LabelManagerView({
  state,
  labels,
  sharedState,
  dispatch,
  onCancel,
  onAssign
}: {
  readonly state: State;
  readonly labels: ReadonlyArray<Label>;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly onCancel: () => void;
  readonly onAssign: (selectedlabels: ReadonlyArray<Label>) => void;
}) {
  const filteredLabels = labels.filter(
    label =>
      !state.labelFilter.length ||
      label.name.toUpperCase().includes(state.labelFilter.toUpperCase())
  );

  const isDisabled =
    !state.newLabelValue.replace(/\s/g, "").length ||
    !!labels.find(
      x => x.name.toLowerCase() === state.newLabelValue.toLowerCase()
    ); // checks if the label only contains whitespaces or if theres is a label with the same name already

  const [hoveredLabelId, setHoveredId] = React.useState("");

  return (
    <Container>
      <UpperSubContainer>
        {state.view === "assign" && (
          <>
            <RowSection>
              <FilterInput
                placeholder={sharedState.translate(LanguageTexts.search())}
                value={state.labelFilter}
                onChange={e =>
                  dispatch(Action.setLabelFilter(e.currentTarget.value))
                }
              />

              {filteredLabels.map(label => {
                const isChecked = state?.labelEditorChosenValues.some(
                  obj => obj.id === label.id
                )!;
                const onClick = () =>
                  dispatch(Action.toggleLabel(label.id, label.name));

                return (
                  <StyledLi
                    onMouseLeave={() => setHoveredId("")}
                    onMouseOver={() => setHoveredId(label.id)}
                    key={label.id}
                    isChecked={isChecked}
                    onClick={onClick}
                  >
                    <CheckBox
                      children={
                        <StyledLabel>
                          {label.name}

                          {hoveredLabelId === label.id && (
                            <span
                              onClick={e => {
                                e.stopPropagation();
                                dispatch(
                                  Action.changeView(
                                    "editOrDelete",
                                    label.name,
                                    label.id
                                  )
                                );
                              }}
                            >
                              <EditBlue />
                            </span>
                          )}
                        </StyledLabel>
                      }
                      isChecked={isChecked}
                      onClick={onClick}
                    />
                  </StyledLi>
                );
              })}
            </RowSection>
            <div>
              <StyledButton
                icon={OpenGreen}
                size="Small"
                buttonType="SecondaryGreen"
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();
                  dispatch(Action.changeView("create"));
                }}
              >
                {sharedState.translate(LanguageTexts.createNewLabel())}
              </StyledButton>
            </div>
          </>
        )}

        {(state.view === "create" || state.view === "editOrDelete") && (
          <InputContainer>
            <InputBox
              translate={sharedState.translate}
              view={state.view}
              onInputBoxChange={value => dispatch(Action.setLabelValue(value))}
              onDelete={() => dispatch(Action.deleteLabel())}
              inputBoxValue={state.newLabelValue}
            />
          </InputContainer>
        )}
      </UpperSubContainer>

      <LowerSubContainer>
        <ButtonsContainer>
          {ctoButtons(
            isDisabled,
            state.view,
            state,
            onCancel,
            onAssign,
            dispatch,
            sharedState.translate
          )}
        </ButtonsContainer>
      </LowerSubContainer>
    </Container>
  );
}

function InputBox({
  inputBoxValue,
  view,
  onInputBoxChange,
  onDelete,
  translate
}: {
  readonly inputBoxValue: string;
  readonly onInputBoxChange: (value: string) => void;
  readonly onDelete: () => void;
  readonly view: View;
  readonly translate: (arg: LanguageTexts.StaticText) => string;
}) {
  return (
    <>
      <label>{translate(LanguageTexts.labelName())}</label>

      <Input
        placeholder={translate(LanguageTexts.newLabel())}
        onDebounceValueChange={[
          value => {
            onInputBoxChange(value);
          },
          []
        ]}
        value={inputBoxValue}
      />
      {view === "editOrDelete" && (
        <TrashContainer>
          <label onClick={onDelete}>
            <Trash />
          </label>
        </TrashContainer>
      )}
    </>
  );
}

function ctoButtons(
  isDisabled: boolean,
  view: View,
  state: State,
  onCancel: () => void,
  onAssgin: (
    selectedlabels: ReadonlyArray<{
      readonly name: string;
      readonly id: string;
    }>
  ) => void,
  dispatch: Dispatch<Action>,
  translate: (arg: LanguageTexts.StaticText) => string
) {
  const backButton = (
    <StandardButton
      onClick={event => {
        event.stopPropagation();
        event.preventDefault();
        dispatch(Action.changeView("assign"));
      }}
      size="Large"
      buttonType="SecondaryGreen"
    >
      {translate(LanguageTexts.back()).toUpperCase()}
    </StandardButton>
  );

  switch (view) {
    case "create": {
      return (
        <>
          {backButton}
          <StandardButton
            disabled={isDisabled}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(Action.createNewLabel());
            }}
            size="Large"
            buttonType="PrimaryGreen"
          >
            {translate(LanguageTexts.create()).toUpperCase()}
          </StandardButton>
        </>
      );
    }
    case "editOrDelete": {
      return (
        <>
          {backButton}
          <StandardButton
            disabled={isDisabled}
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(Action.editLabelValue());
            }}
            size="Large"
            buttonType="PrimaryGreen"
          >
            {translate(LanguageTexts.save()).toUpperCase()}
          </StandardButton>
        </>
      );
    }

    case "assign": {
      return (
        <>
          <StandardButton
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              onCancel();
            }}
            size="Large"
            buttonType="SecondaryGreen"
          >
            {translate(LanguageTexts.cancel()).toUpperCase()}
          </StandardButton>
          <StandardButton
            onClick={event => {
              event.stopPropagation();
              event.preventDefault();
              onAssgin(state.labelEditorChosenValues);
            }}
            size="Large"
            buttonType="PrimaryGreen"
          >
            {translate(LanguageTexts.assign()).toUpperCase()}
          </StandardButton>
        </>
      );
    }
    default:
      return exhaustiveCheck(view, true);
  }
}
