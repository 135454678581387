import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";
const showOutdoorAirSource = "showoutdoorairsource";
const showReturnAirSource = "showreturnairsource";
const showCustomAirSource = "showcustomairsource";
const premMixingBoxExists = "premixingboxexists";
const postMixingBoxExists = "postmixingboxexists";
const preCoolerCount = "precoolercount";
const preHeaterExists = "preheaterpresent";
const postCoolerOrHeaterPressent = "postcoolerorheaterpressent";

export function mxnPreProcessOpc(
  newProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let pvs = PropertyValueSet.Empty;

  // Show Outdoor air source
  // for react -> always so no filter needed.
  pvs = PropertyValueSet.merge(
    {
      [showOutdoorAirSource]: PropertyValue.fromInteger(1)
    },
    pvs
  );

  // Show Return air source
  pvs = PropertyValueSet.merge(
    {
      [showReturnAirSource]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("airsource=2")!
        ) ||
          PropertyFilter.isValid(
            newProperties,
            PropertyFilter.fromStringOrEmpty("arrangement=2,4,8,9")!
          )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Show Custom air source
  pvs = PropertyValueSet.merge(
    {
      [showCustomAirSource]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("airsource=3")!
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Pre mixing box exists
  pvs = PropertyValueSet.merge(
    {
      [premMixingBoxExists]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("arrangement=2,4,8,9")!
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Post mixing box exists
  pvs = PropertyValueSet.merge(
    {
      [postMixingBoxExists]: PropertyValue.fromInteger(0)
    },
    pvs
  );

  // Pre heater exists
  pvs = PropertyValueSet.merge(
    {
      [preHeaterExists]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("newpreheater>0")!
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Pre cooler exists
  pvs = PropertyValueSet.merge(
    {
      [preCoolerCount]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("newprecooler>0")
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Post heater or post cooler exists
  pvs = PropertyValueSet.merge(
    {
      [postCoolerOrHeaterPressent]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("newpostcooler>0")
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  return pvs;
}
