import * as React from "react";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import * as System from "../../system";
import * as SolidContactView from "./components/solid-contact-image";
import * as StaticImageView from "./components/static-image";
import * as SharedState from "../../../shared-state";
import { SystemStatus } from "@genesys/shared/lib/enums/system-status";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { P1 } from "@genesys/ui-elements";
import { clientConfig } from "../../../config";
import { ImageExport } from "./components/image-export";
import {
  SpinnerContainer,
  SketchImageContainer,
  SketchTablesContainer,
  Root
} from "./elements";
import { Spinner } from "@genesys/ui-elements";
import { SketchTable } from "./components/sketch-table";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { SketchProvider, Format } from "./types";

export function MechanicalSketchView({
  system,
  state,
  sharedState,
  dispatch
}: {
  readonly system: System.System;
  readonly state: State;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element | null {
  if (system.status < SystemStatus.GenerateCasingsSuccess) {
    return (
      <Root>
        <P1 color="dark" weight="normal">
          {sharedState.translate(LanguageTexts.cannotShowMechanicalSketchMsg())}
        </P1>
      </Root>
    );
  }

  if (
    state.sketchProvider === "StaticImage" &&
    state.staticImages.length === 0
  ) {
    return (
      <Root>
        <P1 color="dark" weight="normal">
          {sharedState.translate(
            LanguageTexts.cannotShowMechanicalSketchStaticMsg()
          )}
        </P1>
      </Root>
    );
  }

  if (
    state.sketchProvider === undefined ||
    state.selectedFormat === undefined
  ) {
    return (
      <Root>
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      </Root>
    );
  }

  const routeEndpoint: string = clientConfig.genesysBackend;
  const sketchProvider: SketchProvider = state.sketchProvider;
  const selectedFormat: Format = state.selectedFormat;

  const getUrl = (): string => {
    switch (sketchProvider) {
      case "SolidContact":
        return routeEndpoint + `/internal/GetSketchFromFixedView`;
      case "StaticImage":
        return (
          routeEndpoint +
          state.staticImages.find(
            img => img.format === state.selectedFormat!.format
          )!.url
        );
      default:
        return exhaustiveCheck(sketchProvider, true);
    }
  };

  const getFileName = (): string => {
    switch (sketchProvider) {
      case "SolidContact":
        return system.file.name + "." + selectedFormat.format;
      case "StaticImage":
        return (
          system.file.name +
          "." +
          getStaticImgExportFormat(selectedFormat.format)
        );
      default:
        return exhaustiveCheck(sketchProvider, true);
    }
  };

  const getParams = (): {
    readonly [key: string]: string | number;
  } => {
    switch (sketchProvider) {
      case "SolidContact":
        return {
          format: selectedFormat.id,
          provider: "SolidContact",
          systemId: system.id,
          view: "Print",
          niid: system.statusDate
        };
      case "StaticImage":
        return {
          niid: system.statusDate
        };
      default:
        return exhaustiveCheck(sketchProvider, true);
    }
  };

  return (
    <Root>
      {state.isLoading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}

      <ImageExport
        url={getUrl()}
        fileName={getFileName()}
        params={getParams()}
        selectedFormatId={selectedFormat.id}
        formats={state.formats}
        sharedState={sharedState}
        dispatch={dispatch}
      />

      <SketchImageContainer>
        {state.sketchProvider === "SolidContact" ? (
          <SolidContactView.SolidContactImage
            system={system}
            routeEndpoint={routeEndpoint}
            sharedState={sharedState}
            onLoaded={() => dispatch(Action.setIsLoading(false))}
          />
        ) : state.sketchProvider === "StaticImage" ? (
          <StaticImageView.StaticImage
            staticImages={state.staticImages}
            system={system}
            routeEndpoint={routeEndpoint}
            sharedState={sharedState}
            onLoaded={() => dispatch(Action.setIsLoading(false))}
          />
        ) : (
          <></>
        )}
      </SketchImageContainer>

      <SketchTablesContainer>
        {state.components.length > 0 && (
          <SketchTable
            header={LanguageTexts.components()}
            components={state.components}
            sharedState={sharedState}
            dispatch={dispatch}
          />
        )}

        {state.casings.length > 0 && (
          <SketchTable
            header={LanguageTexts.casings()}
            components={state.casings}
            sharedState={sharedState}
            dispatch={dispatch}
          />
        )}
      </SketchTablesContainer>
    </Root>
  );
}

function getStaticImgExportFormat(format: string): string {
  switch (format) {
    case "DXF_2_D":
    case "DXF_3_D":
      return "DXF";
    default:
      return format;
  }
}
