import * as React from "react";
import * as SharedState from "../../../shared-state";
import * as PropertiesSelector from "../../../properties-selector";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as RoomControlSettings from "../../tools/moisture-load-rules/room-control-settings";
import * as SystemOverride from "../system-overrides";
import styled from "styled-components";
import { EditBlue, Pop } from "@genesys/ui-elements";
import { State, Action } from "./state";
import { Dispatch } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import { SystemOverrides, PropertyDefinition } from "../../tools";

interface ContextValue {
  readonly labelRef: React.MutableRefObject<null>;
  readonly anchorEl: Element | null;
  readonly setAnchorEl: React.Dispatch<React.SetStateAction<Element | null>>;
}

const Context = React.createContext<ContextValue | null>(null);

const Container = styled.div`
  min-width: 400px;
  padding-left: 10px;
  padding-right: 10px;

  > table {
    margin-top: -17px;
  }
`;

const Title = styled.p`
  color: rgb(0, 177, 247);
  margin-left: 10px;
  margin-top: 5px;
`;

export function Wrapper({ children }: { readonly children: React.ReactNode }) {
  const labelRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const providerValue = {
    anchorEl,
    labelRef,
    setAnchorEl
  };

  return (
    <>
      <Context.Provider value={providerValue}>{children}</Context.Provider>
    </>
  );
}

export function Button({ style }: { readonly style?: React.CSSProperties }) {
  const { labelRef, setAnchorEl } = React.useContext<ContextValue>(
    Context as any
  );
  return (
    <span style={style} ref={labelRef}>
      <EditBlue
        onClick={() => {
          setAnchorEl(labelRef.current);
        }}
        height="15px"
      />
    </span>
  );
}

export function PopUpContent({
  state,
  sharedState,
  isDisabled,
  propertySelectorState,
  currentSystemOverrides,
  propertieSelectorDispatch,
  dispatch
}: {
  readonly sharedState: SharedState.State;
  readonly state: State;
  readonly isDisabled: boolean;
  readonly propertySelectorState: PropertiesSelector.State;
  readonly currentSystemOverrides: SystemOverrides;
  readonly propertieSelectorDispatch: Dispatch<PropertiesSelector.Action>;
  readonly dispatch: Dispatch<Action>;
}) {
  const { anchorEl, setAnchorEl } = React.useContext<ContextValue>(
    Context as any
  );
  if (!anchorEl) {
    return null;
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectedProperties = PropertiesSelector.getSelectedProperties(
    propertySelectorState
  );

  const manualInputSelected = PropertyValueSet.getInteger(
    RoomControlSettings.manualInputProperty,
    selectedProperties
  );

  const roomControlDefinitions = RoomControlSettings.properties.map(
    propertyName =>
      state.propertyDefinitions!.find(
        definition => definition.name === propertyName
      )!
  );

  const visibleDefinitions = manualInputSelected
    ? roomControlDefinitions.filter(
        def =>
          def.name === RoomControlSettings.manualInputProperty ||
          RoomControlSettings.overridableProperties.every(op => op !== def.name)
      )
    : roomControlDefinitions;

  return (
    <Pop
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      anchor={anchorEl}
      onClose={handleClose}
    >
      <Container>
        <Title>
          {sharedState.translate(
            LanguageTexts.globalPropertyName("mlcroomcontrolsettings")
          )}
        </Title>
        <PropertiesSelector.PropertiesSelectorView
          dispatch={propertieSelectorDispatch}
          translatePropertyName={propertyName =>
            sharedState.translate(
              LanguageTexts.globalPropertyName(propertyName)
            )
          }
          translatePropertyValue={(propertyName, propertyValue) =>
            sharedState.translate(
              LanguageTexts.globalPropertyName(
                propertyName + "_" + propertyValue
              )
            )
          }
          productId={""}
          sharedState={sharedState}
          propertiesInfo={visibleDefinitions}
          fieldGroup={`MoistureLoadTool.${"OperatingCase"}`}
          isReadonly={isDisabled}
          showCodes={false}
          state={propertySelectorState}
        />

        {!!manualInputSelected && (
          <SystemOverride.View
            fieldGroups="OperatingCase"
            dispatch={Dispatch.map(
              action => Action.dispatchSystemOverride(action),
              dispatch
            )}
            sharedState={sharedState}
            systemOverrides={currentSystemOverrides}
            isDisabled={isDisabled}
            overridableProperties={RoomControlSettings.overridableProperties.reduce(
              (soFar: Array<PropertyDefinition>, propertyName) => {
                const property = state.propertyDefinitions!.find(
                  property => property.name === propertyName
                );
                if (property !== undefined) {
                  return soFar.concat(property);
                }
                return soFar;
              },
              []
            )}
          />
        )}
      </Container>
    </Pop>
  );
}
