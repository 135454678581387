import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { StandardButton, DropDownButton } from "@genesys/ui-elements/";

interface Props {
  readonly onSaveAndCalculate: () => void;
  readonly onClose: () => void;
  readonly onCalculate: () => void;
  readonly onSave: () => void;
  readonly translate: LanguageTexts.Translate;
  readonly canSave: boolean;
  readonly canCalculate: boolean;
}

export function ProcessButton(props: Props) {
  const { onClose, translate, canSave, canCalculate } = props;
  return (
    <>
      <StandardButton
        buttonType={canSave || canCalculate ? "SecondaryGreen" : "PrimaryGreen"}
        size="Large"
        onClick={onClose}
      >
        {translate(canSave ? LanguageTexts.cancel() : LanguageTexts.close())}
      </StandardButton>
      {renderComplimentButtons(props)}
    </>
  );
}

function renderComplimentButtons(props: Props) {
  if (props.canCalculate) {
    return (
      <StandardButton
        buttonType="PrimaryGreen"
        size="Large"
        onClick={props.onCalculate}
      >
        {props.translate(LanguageTexts.calculate())}
      </StandardButton>
    );
  }

  if (props.canSave) {
    const { translate, onSave, onSaveAndCalculate } = props;
    let options = [
      {
        value: translate(LanguageTexts.save()),
        onClickHandler: () => {
          onSave();
        }
      },
      {
        value: translate(LanguageTexts.saveAndCalculate()),
        onClickHandler: onSaveAndCalculate
      }
    ];
    return <DropDownButton buttonType="PrimaryGreen" options={options} />;
  }

  return null;
}
