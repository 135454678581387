import styled from "styled-components";
import { PrimaryColors } from "@genesys/ui-elements";

export const AlertMessageBox = styled.div`
  box-sizing: border-box;
  padding-bottom: 10px;
  width: 310px;
  margin: auto;
  cursor: pointer;
`;
export const AlertMessageBoxHeader = styled.label`
  color: #16181e;
  font-family: Futura;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  margin-top: 10px;
  padding-top: 10px;
  display: block;
`;

export const Message = styled.p`
  color: #959695;
  font-family: Futura;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 5px;
  word-wrap: break-word;
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: #cccccc;
`;

export const StyledDiv = styled.div`
  width: 100%;
  border-top: 1px solid black;
`;

export const HeaderContainer = styled.div<{ readonly color: string }>`
  box-sizing: border-box;
  width: 310px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  label {
    color: ${props => props.color};
    font-family: Futura;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 3px;
    line-height: 20px;
    display: block;
  }
`;

export const MessageSubTitle = styled.span`
  font-weight: 500;
  color: #16181e;
`;

export const TextButton = styled.div`
  &:hover {
    text-decoration: underline;
  }
  color: ${PrimaryColors.blue50};
`;
