import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { Quantity } from "@genesys/uom";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../shared-state";
import * as Types from "./types";
import * as GraphQlTypes from "../../../graphql-types";
import * as Queries from "./queries";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import { BaseState } from "../step-state-base";
import { promiseCmd } from "../../../promise-effect-manager";
import { isRegisteredSystemType } from "../../steps-registry";
import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";
import * as PropertiesSelector from "../../../properties-selector";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as LabelManager from "../../../label-manager";
import { steps } from "../../steps-registry";
import { getInitialData, parseOperatingCasesData } from "../operating-cases";
import { getMoistureLoadNoAndRevNo } from "../../../moisture-load-calculation";

export const init = (
  sharedState: SharedState.State,
  base: BaseState
): readonly [Types.State, Cmd<Action>?] => [
  undefined,
  promiseCmd(
    async () => {
      const res = await sharedState.graphQL.queryUser<
        GraphQlTypes.WizardSystemSettingsSystemType,
        GraphQlTypes.WizardSystemSettingsSystemTypeVariables
      >(Queries.SystemTypeQuery, {});
      const uniqueSystemTypeIds: Array<string> = [];

      for (const system of res.product.activeSystemTypes) {
        if (
          isRegisteredSystemType(system.id) &&
          !uniqueSystemTypeIds.includes(system.id)
        ) {
          uniqueSystemTypeIds.push(system.id);
        }
      }

      const imageQuery = await sharedState.graphQL.queryUser<
        GraphQlTypes.WizardImages,
        GraphQlTypes.WizardImagesVariables
      >(Queries.ImagesQuery, {
        input: uniqueSystemTypeIds.map(id => ({ systemTypeId: id }))
      });
      return [res, imageQuery] as [
        GraphQlTypes.WizardSystemSettingsSystemType,
        GraphQlTypes.WizardImages
      ];
    },
    ([res, images]) =>
      Action.dataLoaded(
        {
          systemTypes: res.product.activeSystemTypes
            .filter(st => {
              const canCreate = () => {
                const isDeveloper = Authorization.checkPermission(
                  sharedState.user.applicationClaims,
                  Authorization.genesysUserClaims.developer
                );
                if (isDeveloper) {
                  return true;
                }
                const values =
                  Authorization.getClaimValues(
                    sharedState.user.applicationClaims,
                    Authorization.systemTypeClaims.create
                  ) ?? [];
                return values
                  .map(v => v.split(";")[0].toUpperCase())
                  .some(v => v === st.id.toUpperCase());
              };
              return (
                (st.systemTypeStatus ===
                  GraphQlTypes.SystemTypeStatus.DEVELOP ||
                  st.systemTypeStatus ===
                    GraphQlTypes.SystemTypeStatus.PRODUCTION) &&
                canCreate()
              );
            })
            .filter(obj => isRegisteredSystemType(obj.id))
            .map(st => {
              const sys = images.product.systemTypes.find(
                sys => sys.id === st.id
              )!.sys;

              const defaultProperties =
                ProductProperties.createDefaultProperties(
                  st.sys.properties.map(p => ({
                    ...p,
                    defaultValues: p.defaultValues.map(d => ({
                      value: PropertyValue.fromString(d.value)!,
                      propertyFilter: PropertyFilter.fromStringOrEmpty(
                        d.propertyFilter
                      )
                    }))
                  })),
                  true
                );

              const image =
                sys.images.find(
                  img =>
                    img.imageUsage ===
                      GraphQlTypes.ImageUsage.NEW_SYSTEM_WIZARD &&
                    PropertyFilterHelpers.isValid(
                      img.propertyFilter,
                      PropertyFilter.Empty,
                      defaultProperties
                    )
                )?.url ?? "";

              return {
                id: st.id,
                name: st.name,
                image: image
              };
            })
        },
        base
      )
  )
];

export const Action = ctorsUnion({
  dataLoaded: (data: Types.Data, base: BaseState) => ({ data, base }),
  setName: (name: string) => ({ name }),
  setSystemTypeId: (systemTypeId: string) => ({ systemTypeId }),
  newProductLoaded: (
    systemTypeId: string,
    data: {
      readonly productData: GraphQlTypes.WizardSystemSettingsNewProduct;
      readonly moistureLoadData:
        | GraphQlTypes.WizardSystemSettingsMoistureLoadQuery
        | undefined;
    },
    operatingCases: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
    climateSettings: PropertyValueSet.PropertyValueSet,
    moistureLoadId: string | undefined
  ) => ({
    systemTypeId,
    data,
    operatingCases,
    climateSettings,
    moistureLoadId
  }),
  toggleLabelManager: () => ({}),
  setAssignedlables: (labels: ReadonlyArray<LabelManager.Label>) => ({
    labels
  }),
  dispatchRangePropertiesSelector: (action: PropertiesSelector.Action) => ({
    action
  }),
  dispatchLabelManager: (action: LabelManager.Action) => ({
    action
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: Types.State,
  sharedState: SharedState.State
): readonly [Types.State, Cmd<Action>?, ReadonlyArray<SharedState.Action>?] {
  const loadNewProduct = (systemTypeId: string, mno?: string) => {
    const systemHasNoOperatingCasesStep =
      steps(
        systemTypeId,
        state ? state.newProperties : PropertyValueSet.Empty
      ).steps.find(s => s === "operating-cases") === undefined;

    let defaultOperatingCases: ReadonlyArray<{
      readonly settings: PropertyValueSet.PropertyValueSet;
    }> = [];
    let defaultClimateSettings: PropertyValueSet.PropertyValueSet =
      PropertyValueSet.Empty;
    let maybeMoistureLoadId: string | undefined = undefined;

    return promiseCmd(
      async () => {
        if (systemHasNoOperatingCasesStep) {
          const { operatingCases, climateSettings, moistureLoadId } =
            parseOperatingCasesData(
              await getInitialData(systemTypeId, state, sharedState),
              sharedState,
              state?.newProperties ?? PropertyValueSet.Empty,
              state?.template.climateDataDefaults ?? PropertyValueSet.Empty,
              state?.divergentTemplateComponentMap ?? {},
              undefined
            );
          defaultOperatingCases = operatingCases;
          defaultClimateSettings = climateSettings;
          maybeMoistureLoadId = moistureLoadId;
        }

        const productQueryProm = sharedState.graphQL.queryProduct<
          GraphQlTypes.WizardSystemSettingsNewProduct,
          GraphQlTypes.WizardSystemSettingsNewProductVariables
        >(Queries.NewProductQuery, {
          input: {
            productId: `${systemTypeId}NEW`
          }
        });

        let moistureLoadQueryProm:
          | Promise<GraphQlTypes.WizardSystemSettingsMoistureLoadQuery>
          | undefined = undefined;

        if (mno) {
          const [moistureLoadNo, revisionNo] = getMoistureLoadNoAndRevNo(mno);
          moistureLoadQueryProm = sharedState.graphQL.queryProduct<
            GraphQlTypes.WizardSystemSettingsMoistureLoadQuery,
            GraphQlTypes.WizardSystemSettingsMoistureLoadQueryVariables
          >(Queries.moistureLoadQuery, {
            moistureLoadNo: moistureLoadNo,
            revisionNo
          });
        }

        const res = await Promise.all([
          productQueryProm,
          moistureLoadQueryProm
        ]);

        return {
          productData: res[0],
          moistureLoadData: res[1]
        };
      },
      data =>
        Action.newProductLoaded(
          systemTypeId,
          data,
          defaultOperatingCases.map(opc => opc.settings),
          defaultClimateSettings,
          maybeMoistureLoadId
        )
    );
  };

  const rangeIsOk = (
    pvs: PropertyValueSet.PropertyValueSet,
    properties: ReadonlyArray<PropertiesSelector.PropertyInfo>
  ) =>
    PropertiesSelector.isSelectionValid(
      {
        properties: pvs
      },
      properties
    );

  const stateOk = (state: Types.State) => {
    if (!state) {
      return state;
    }
    return {
      ...state,
      ok:
        !!state.name &&
        !!state.systemTypeId &&
        !!state.newProduct &&
        rangeIsOk(state.newProperties, state.newProduct.properties)
    };
  };

  switch (action.type) {
    case "setName":
      if (!state) {
        return [state];
      }

      return [
        stateOk({
          ...state,
          name: action.name
        })
      ];
    case "setSystemTypeId":
      if (!state) {
        return [state];
      }
      return [
        stateOk({
          ...state,

          systemTypeId: action.systemTypeId,
          newProduct: undefined
        }),
        loadNewProduct(action.systemTypeId)
      ];

    case "dataLoaded":
      return [
        stateOk({
          ...action.base,
          data: action.data,
          newProduct: undefined
        }),
        action.base.systemTypeId
          ? loadNewProduct(action.base.systemTypeId, action.base.moistureLoadNo)
          : undefined
      ];
    case "newProductLoaded":
      if (!state) {
        return [state];
      }
      const productData = action.data.productData.product;
      const systemTypeId = action.systemTypeId;

      const rangeFilters = (
        Authorization.getClaimValues(
          sharedState.user.applicationClaims,
          Authorization.systemTypeClaims.create
        ) || []
      )
        .map(cv => cv.split(";"))
        .filter(([st]) => st.toUpperCase() === systemTypeId.toUpperCase())
        .map(([, rangeFilterString]) =>
          PropertyFilter.fromStringOrEmpty(rangeFilterString || "")
        );

      const validateRangeValue = (name: string, value: string) => {
        const isDeveloper = Authorization.checkPermission(
          sharedState.user.applicationClaims,
          Authorization.genesysUserClaims.developer
        );
        if (isDeveloper) {
          return true;
        }
        return rangeFilters.some(rf =>
          PropertyFilter.isValidMatchMissing(
            PropertyValueSet.fromString(`${name}=${value}`),
            rf
          )
        );
      };

      const newProduct: Types.NewProduct = {
        properties: productData.product.properties
          .filter(p => p.name.startsWith("range"))
          .map(p => ({
            name: p.name,
            quantity: p.quantity as Quantity.Quantity,
            group: p.groupName,
            sortNo: p.sortNo ?? 9999,
            defaultValues: p.defaultValues.map(d => ({
              value: PropertyValue.fromString(d.value)!,
              propertyFilter: PropertyFilter.fromStringOrEmpty(d.propertyFilter)
            })),
            validationFilter: PropertyFilter.fromStringOrEmpty(
              p.validationFilter
            ),
            visibilityFilter: PropertyFilter.fromStringOrEmpty(
              p.visibilityFilter
            ),
            descriptionTexts: p.descriptionTexts.map(x => ({
              id: x.id,
              propertyFilter: PropertyFilter.fromStringOrEmpty(
                x.propertyFilter
              ),
              language: x.language,
              text: x.text
            })),
            items: p.values
              .map(v => ({
                id: v.value,
                sortNo: v.sortNo ?? 9999,
                image: v.image ?? undefined,
                value: PropertyValue.fromString(v.value)!,
                validationFilter: PropertyFilter.fromStringOrEmpty(
                  v.validationFilter
                ),
                text: sharedState.translate(
                  LanguageTexts.productPropertyValue(
                    `${systemTypeId}NEW`,
                    p.name,
                    parseInt(v.value, 10)
                  )
                ),
                descriptionValuesTexts: v.descriptionTexts.map(x => ({
                  id: x.id,
                  propertyFilter: PropertyFilter.fromStringOrEmpty(
                    x.propertyFilter
                  ),
                  language: x.language,
                  text: x.text
                })),
                rangeFilter: PropertyFilter.fromStringOrEmpty(v.rangeFilter)
              }))
              .filter(item =>
                validateRangeValue(p.name, (item.value?.value as string) ?? "")
              ),
            valueSources: [],
            conversionParameters: p.quantityConversionParams
          }))
      };

      const propertySelectorState = PropertiesSelector.init(
        ProductProperties.createDefaultProperties(
          newProduct.properties.map(p => {
            const rangeValues = filterByRange(
              state.systemTypeId,
              p.name,
              p.items.map(i => PropertyValue.getInteger(i.value!)!),
              sharedState.user.applicationClaims
            );
            return {
              id: "",
              name: p.name,
              quantity: p.quantity,
              defaultValues: p.defaultValues.filter(x =>
                rangeValues.includes(x.value.value as number)
              ),
              values: p.items
                .filter(v =>
                  rangeValues.includes(PropertyValue.getInteger(v.value!)!)
                )
                .map(v => ({
                  id: "",
                  value: PropertyValue.toString(v.value!)!
                }))
            };
          }),
          true
        )
      );

      const moistureLoadData =
        action.data.moistureLoadData?.user.moistureLoadByMoistureLoadNo;

      const moistureLoadLabels =
        moistureLoadData?.moistureloadFile.labels || state.assignedLabels;

      const fileName = moistureLoadData?.moistureloadFile.name || state.name;

      return [
        stateOk({
          ...state,
          name: fileName,
          assignedLabels: moistureLoadLabels.map(x => ({
            id: x.id,
            name: x.name
          })),
          newProduct: newProduct,
          newProperties: PropertyValueSet.merge(
            propertySelectorState.properties,
            state.newProperties
          ),
          operatingCases: action.operatingCases,
          climateSettings: action.climateSettings,
          moistureLoadId: action.moistureLoadId
        })
      ];
    case "dispatchRangePropertiesSelector": {
      if (!state) {
        return [state];
      }
      const [
        rangePropertiesSelectorState,
        rangePropertiesSelectorCmd,
        rangePropertiesSelectorSharedAction
      ] = PropertiesSelector.update(
        action.action,
        {
          properties: state.newProperties
        },
        sharedState,
        "SkipCalculateProperties"
      );
      return [
        stateOk({
          ...state,
          newProperties: PropertyValueSet.merge(
            rangePropertiesSelectorState.properties,
            state.newProperties
          )
        }),
        Cmd.map(
          Action.dispatchRangePropertiesSelector,
          rangePropertiesSelectorCmd
        ),
        rangePropertiesSelectorSharedAction
          ? [rangePropertiesSelectorSharedAction]
          : undefined
      ];
    }

    case "setAssignedlables": {
      if (!state) {
        return [state];
      }

      return [
        stateOk({
          ...state,
          assignedLabels: action.labels,
          labelManagerState: undefined
        }),
        undefined
      ];
    }

    case "toggleLabelManager": {
      if (!state) {
        return [state];
      }

      const [labelManagerState] = LabelManager.init(state.assignedLabels);

      return [
        stateOk({
          ...state,
          labelManagerState: state.labelManagerState
            ? undefined
            : labelManagerState
        }),
        undefined
      ];
    }

    case "dispatchLabelManager": {
      if (!state) {
        return [state];
      }

      if (state.labelManagerState) {
        const [labelManagerState, LabelManagerSharedStateACtion] =
          LabelManager.update(action.action, state.labelManagerState);

        return [
          stateOk({
            ...state,
            labelManagerState
          }),
          undefined,
          LabelManagerSharedStateACtion
        ];
      }

      const [labelManagerState] = LabelManager.init(state.assignedLabels);

      return [
        stateOk({
          ...state,
          labelManagerState
        }),
        undefined
      ];
    }
    default:
      exhaustiveCheck(action, true);
  }
}

function filterByRange(
  systemTypeId: string,
  propertyName: string,
  values: ReadonlyArray<number>,
  claims: Authorization.ApplicationClaims
): ReadonlyArray<number> {
  const isDeveloper = Authorization.checkPermission(
    claims,
    Authorization.genesysUserClaims.developer
  );
  if (isDeveloper) {
    return values;
  }

  const rangeFilters = (
    Authorization.getClaimValues(
      claims,
      Authorization.systemTypeClaims.create
    ) || []
  )
    .map(cv => cv.split(";"))
    .filter(([st]) => st.toUpperCase() === systemTypeId.toUpperCase())
    .map(([, rangeFilterString]) =>
      PropertyFilter.fromStringOrEmpty(rangeFilterString || "")
    );

  return values.filter(pv =>
    rangeFilters.some(rf =>
      PropertyFilter.isValidMatchMissing(
        PropertyValueSet.fromString(`${propertyName}=${pv}`),
        rf
      )
    )
  );
}
//tslint:disable-next-line
