import * as KnownProperties from "../known-properties";
import { PropertyValue, PropertyValueSet } from "@genesys/property";
import { Amount, Units, Quantity } from "@genesys/uom";

import { getIfIntegerChanged, getIfPropertyChanged } from "../helper-functions";
import { OperatingCaseGroupEnum } from "../../../graphql-types";

export const manualInputProperty = KnownProperties.systemLoadManualInput;

export const overridableProperties = [
  KnownProperties.systemMakeUpOpenAirTemperature,
  KnownProperties.systemMakeUpOpenAirHumidity
];

export const mainSettings = [
  KnownProperties.systemConfiguration,
  KnownProperties.systemOverPressure,
  KnownProperties.systemLoadManualInput,
  KnownProperties.systemMakeUpOpenAirSource,
  KnownProperties.systemMakeUpOpenAirTemperature,
  KnownProperties.systemMakeUpOpenUseOutdoorAirHumidity,
  KnownProperties.systemMakeUpOpenAirHumidity
];

export const flowLimits = [
  KnownProperties.systemManualFreshFlowMinimum,
  KnownProperties.systemFreshFlowPerAreaMinimum,
  KnownProperties.systemFreshFlowMinimum,
  KnownProperties.systemSetMinAirExchangesOrFlow,
  KnownProperties.systemManualTotalFlowMinimum,
  KnownProperties.systemAirExchangesPerVolumeMinimum,
  KnownProperties.systemTotalFlowMinimum,
  KnownProperties.systemSetMaxAirExchangesOrFlow,
  KnownProperties.systemManualTotalFlowMaximum,
  KnownProperties.systemAirExchangesPerVolumeMaximum,
  KnownProperties.systemTotalFlowMaximum,
  KnownProperties.systemNumberOfFlowSuggestions,
  KnownProperties.systemSetDesignCaseCoverage,
  KnownProperties.systemDesignCaseCoverage
];

export const otherLimits = [
  KnownProperties.systemSetMinTargetAirHumidity,
  KnownProperties.systemMinTargetAirHumidity,
  KnownProperties.systemSetMinCoolingAirTemperature,
  KnownProperties.systemMinCoolingAirTemperature,
  KnownProperties.systemSetMaxHeatingAirTemperature,
  KnownProperties.systemMaxHeatingAirTemperature
];

export const properties = mainSettings.concat(flowLimits).concat(otherLimits);

export const caseOverridesDefault: {
  readonly [operatingCaseGroup in OperatingCaseGroupEnum]: PropertyValueSet.PropertyValueSet;
} = {
  DESIGN_SUMMER: PropertyValueSet.Empty,
  DESIGN_WINTER: PropertyValueSet.Empty,
  JANUARY: PropertyValueSet.Empty,
  FEBRUARY: PropertyValueSet.Empty,
  MARCH: PropertyValueSet.Empty,
  APRIL: PropertyValueSet.Empty,
  MAY: PropertyValueSet.Empty,
  JUNE: PropertyValueSet.Empty,
  JULY: PropertyValueSet.Empty,
  AUGUST: PropertyValueSet.Empty,
  SEPTEMBER: PropertyValueSet.Empty,
  OCTOBER: PropertyValueSet.Empty,
  NOVEMBER: PropertyValueSet.Empty,
  DECEMBER: PropertyValueSet.Empty
};

export function getPropertyUpdates(
  prevProperties: PropertyValueSet.PropertyValueSet,
  selectedProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = getPropertyDefaults(prevProperties, selectedProperties);

  return defaults;
}

function getPropertyDefaults(
  prevSelections: PropertyValueSet.PropertyValueSet,
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = PropertyValueSet.Empty;

  const systemConfiguration = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemConfiguration
  );

  if (systemConfiguration !== undefined) {
    defaults = PropertyValueSet.merge(
      systemConfigurationDefaults(systemConfiguration),
      defaults
    );
  }

  // Room location
  const buildingLocation = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.buildingLocation
  );

  if (buildingLocation !== undefined) {
    defaults = PropertyValueSet.merge(
      roomLocationDefaults(PropertyValueSet.merge(defaults, currentSelections)),
      defaults
    );
  }

  // Air sources for make up air
  const makeUpOpenAirSource = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemMakeUpOpenAirSource
  );

  if (makeUpOpenAirSource !== undefined) {
    defaults = PropertyValueSet.merge(
      systemMakeUpOpenAirSourceDefaults(makeUpOpenAirSource),
      defaults
    );
  }

  let systemMakeUpOpenUseOutdoorAirHumidity = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemMakeUpOpenUseOutdoorAirHumidity
  );

  if (systemMakeUpOpenUseOutdoorAirHumidity !== undefined) {
    defaults = PropertyValueSet.merge(
      systemMakeUpOpenUseOutdoorAirHumidityDefaults(
        systemMakeUpOpenUseOutdoorAirHumidity,
        PropertyValueSet.merge(defaults, currentSelections)
      ),
      defaults
    );
  }

  // Design case range
  const systemSetDesignCaseCoverage = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemSetDesignCaseCoverage
  );

  if (systemSetDesignCaseCoverage !== undefined) {
    defaults = PropertyValueSet.merge(
      systemDesignCaseCoverageDefaults(),
      defaults
    );
  }

  const buildingLength = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.buildingLength
  );

  const buildingHeight = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.buildingHeight
  );

  const buildingWidth = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.buildingWidth
  );

  // For closed-make-up air
  const systemFreshFlowPerAreaMinimum = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemFreshFlowPerAreaMinimum
  );
  const systemFreshFlowMinimum = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemFreshFlowMinimum
  );

  if (
    buildingLength !== undefined ||
    buildingWidth !== undefined ||
    buildingHeight !== undefined ||
    systemFreshFlowPerAreaMinimum !== undefined ||
    systemFreshFlowMinimum !== undefined
  ) {
    defaults = PropertyValueSet.merge(
      setFreshFlowValues(PropertyValueSet.merge(defaults, currentSelections)),
      defaults
    );
  }
  // Set min air exchanges
  const systemSetMinAirExchangesOrFlow = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemSetMinAirExchangesOrFlow
  );
  if (systemSetMinAirExchangesOrFlow !== undefined) {
    defaults = PropertyValueSet.merge(
      systemSetMinAirExchangesDefaults(),
      defaults
    );
  }
  // Min air exchanges
  const minAirExchanges = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemAirExchangesPerVolumeMinimum
  );

  const minimumTotalFlow = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemTotalFlowMinimum
  );
  if (
    buildingLength !== undefined ||
    buildingWidth !== undefined ||
    buildingHeight !== undefined ||
    minAirExchanges !== undefined ||
    minimumTotalFlow !== undefined
  ) {
    defaults = PropertyValueSet.merge(
      setMinimumTotalFlowValues(
        PropertyValueSet.merge(defaults, currentSelections)
      ),
      defaults
    );
  }

  // Set max air exchanges
  const systemSetMaxAirExchangesOrFlow = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemSetMaxAirExchangesOrFlow
  );
  if (systemSetMaxAirExchangesOrFlow !== undefined) {
    defaults = PropertyValueSet.merge(
      systemSetMaxAirExchangesDefaults(),
      defaults
    );
  }
  // Max air exchanges
  const maxAirExchanges = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemAirExchangesPerVolumeMaximum
  );

  const maximumTotalFlow = getIfPropertyChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemTotalFlowMaximum
  );
  if (
    buildingLength !== undefined ||
    buildingWidth !== undefined ||
    buildingHeight !== undefined ||
    maxAirExchanges !== undefined ||
    maximumTotalFlow !== undefined
  ) {
    defaults = PropertyValueSet.merge(
      setMaximumTotalFlowValues(
        PropertyValueSet.merge(defaults, currentSelections)
      ),
      defaults
    );
  }

  // Set min target humidity
  const setMinTargetHumidity = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemSetMinTargetAirHumidity
  );
  if (setMinTargetHumidity !== undefined) {
    defaults = PropertyValueSet.merge(
      systemMinTargetAirHumidityDefaults(),
      defaults
    );
  }
  // Set max heating air temperature
  const setMaxHeatingAirTemperature = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemSetMaxHeatingAirTemperature
  );
  if (setMaxHeatingAirTemperature !== undefined) {
    defaults = PropertyValueSet.merge(
      systemMaxHeatingAirTemperatureDefaults(),
      defaults
    );
  }

  // Set min cooling air temperature
  const setMinCoolingAirTemperature = getIfIntegerChanged(
    prevSelections,
    PropertyValueSet.merge(defaults, currentSelections),
    KnownProperties.systemSetMinCoolingAirTemperature
  );
  if (setMinCoolingAirTemperature !== undefined) {
    defaults = PropertyValueSet.merge(
      systemMinCoolingAirTemperatureDefaults(),
      defaults
    );
  }

  return defaults;
}

function systemMakeUpOpenUseOutdoorAirHumidityDefaults(
  propertyValue: number,
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 1:
    default:
      return {
        [KnownProperties.systemMakeUpOpenAirHumidity]: PropertyValue.fromAmount(
          Amount.create(0, Units.GramPerKilogram)
        )
      };
    case 0:
      return {
        [KnownProperties.systemMakeUpOpenAirHumidity]: PropertyValue.fromAmount(
          PropertyValueSet.getInteger(
            KnownProperties.systemMakeUpOpenAirSource,
            currentSelections
          ) === 3
            ? Amount.create(8, Units.GramPerKilogram)
            : Amount.create(0, Units.GramPerKilogram)
        )
      };
  }
}

function systemConfigurationDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  let staticDefaults = {
    [KnownProperties.systemLoadManualInput]: PropertyValue.fromInteger(0),
    [KnownProperties.systemSetMinAirExchangesOrFlow]:
      PropertyValue.fromInteger(0),
    [KnownProperties.systemSetMaxAirExchangesOrFlow]:
      PropertyValue.fromInteger(0),
    [KnownProperties.systemNumberOfFlowSuggestions]:
      PropertyValue.fromInteger(5),
    [KnownProperties.systemSetDesignCaseCoverage]: PropertyValue.fromInteger(0),
    [KnownProperties.systemSetMinTargetAirHumidity]:
      PropertyValue.fromInteger(0),
    [KnownProperties.systemSetMinCoolingAirTemperature]:
      PropertyValue.fromInteger(0),
    [KnownProperties.systemSetMaxHeatingAirTemperature]:
      PropertyValue.fromInteger(0)
  } as PropertyValueSet.PropertyValueSet;

  switch (propertyValue) {
    case 0:
    default:
      return PropertyValueSet.merge(
        {
          [KnownProperties.systemOverPressure]: PropertyValue.fromInteger(0),
          [KnownProperties.systemMakeUpOpenAirSource]:
            PropertyValue.fromInteger(0),
          [KnownProperties.systemManualFreshFlowMinimum]:
            PropertyValue.fromInteger(0),
          [KnownProperties.systemFreshFlowPerAreaMinimum]:
            PropertyValue.fromAmount(
              Amount.create(0, Units.StandardLiterPerSecondPerSquareMeter)
            )
        },
        staticDefaults
      );
    case 2:
      return PropertyValueSet.merge(
        {
          [KnownProperties.systemOverPressure]: PropertyValue.fromInteger(1),
          [KnownProperties.systemMakeUpOpenAirSource]:
            PropertyValue.fromInteger(1),
          [KnownProperties.systemManualFreshFlowMinimum]:
            PropertyValue.fromInteger(0),
          [KnownProperties.systemFreshFlowPerAreaMinimum]:
            PropertyValue.fromAmount(
              Amount.create(0, Units.StandardLiterPerSecondPerSquareMeter)
            ),
          [KnownProperties.systemSetMinAirExchangesOrFlow]:
            PropertyValue.fromInteger(1)
        },
        staticDefaults
      );
    case 1:
      return PropertyValueSet.merge(
        {
          [KnownProperties.systemOverPressure]: PropertyValue.fromInteger(1),
          [KnownProperties.systemMakeUpOpenAirSource]:
            PropertyValue.fromInteger(1),
          [KnownProperties.systemManualFreshFlowMinimum]:
            PropertyValue.fromInteger(0),
          [KnownProperties.systemFreshFlowPerAreaMinimum]:
            PropertyValue.fromAmount(
              Amount.create(0.35, Units.StandardLiterPerSecondPerSquareMeter)
            )
        },
        staticDefaults
      );
  }
}
function roomLocationDefaults(
  currentSelections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let defaults = PropertyValueSet.Empty;
  defaults = PropertyValueSet.merge(
    {
      [KnownProperties.systemMakeUpOpenAirSource]: PropertyValue.fromInteger(
        PropertyValueSet.getInteger(
          KnownProperties.systemConfiguration,
          currentSelections
        ) === 0
          ? 0
          : 1
      )
    },
    defaults
  );

  return defaults;
}

function systemMakeUpOpenAirSourceDefaults(
  propertyValue: number
): PropertyValueSet.PropertyValueSet {
  switch (propertyValue) {
    case 0:
    case 1:
    case 2:
    default:
      return {
        [KnownProperties.systemMakeUpOpenAirTemperature]:
          PropertyValue.fromAmount(Amount.create(0, Units.Celsius)),
        [KnownProperties.systemMakeUpOpenUseOutdoorAirHumidity]:
          PropertyValue.fromInteger(0),
        [KnownProperties.systemMakeUpOpenAirHumidity]: PropertyValue.fromAmount(
          Amount.create(0, Units.GramPerKilogram)
        )
      };
    case 3:
      return {
        [KnownProperties.systemMakeUpOpenAirTemperature]:
          PropertyValue.fromAmount(Amount.create(15, Units.Celsius)),
        [KnownProperties.systemMakeUpOpenUseOutdoorAirHumidity]:
          PropertyValue.fromInteger(1),
        [KnownProperties.systemMakeUpOpenAirHumidity]: PropertyValue.fromAmount(
          Amount.create(0, Units.GramPerKilogram)
        )
      };
  }
}

function systemDesignCaseCoverageDefaults(): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.systemDesignCaseCoverage]: PropertyValue.fromAmount(
      Amount.create(95, Units.Percent)
    )
  };
}

function setFreshFlowValues(
  current: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  if (
    PropertyValueSet.getInteger(
      KnownProperties.systemManualFreshFlowMinimum,
      current
    ) === 0
  ) {
    return updateMinimumFreshFlow(current);
  } else {
    return updateMinimumFreshFlowPerArea(current);
  }
}
function systemSetMinAirExchangesDefaults(): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.systemManualTotalFlowMinimum]:
      PropertyValue.fromInteger(0),
    [KnownProperties.systemAirExchangesPerVolumeMinimum]:
      PropertyValue.fromAmount(Amount.create(0, Units.OnePerHour)),
    [KnownProperties.systemTotalFlowMinimum]: PropertyValue.fromAmount(
      Amount.create(0, Units.StandardCubicMeterPerHour)
    )
  };
}

function setMinimumTotalFlowValues(
  current: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  if (
    PropertyValueSet.getInteger(
      KnownProperties.systemManualTotalFlowMinimum,
      current
    ) === 0
  ) {
    return updateMinimumTotalFlow(current);
  } else {
    return updateMinimumAirExchanges(current);
  }
}
function systemSetMaxAirExchangesDefaults(): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.systemManualTotalFlowMaximum]:
      PropertyValue.fromInteger(0),
    [KnownProperties.systemAirExchangesPerVolumeMaximum]:
      PropertyValue.fromAmount(Amount.create(1, Units.OnePerHour)),
    [KnownProperties.systemTotalFlowMaximum]: PropertyValue.fromAmount(
      Amount.create(0, Units.StandardCubicMeterPerHour)
    )
  };
}
function setMaximumTotalFlowValues(
  current: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  if (
    PropertyValueSet.getInteger(
      KnownProperties.systemManualTotalFlowMaximum,
      current
    ) === 0
  ) {
    return updateMaximumTotalFlow(current);
  } else {
    return updateMaximumAirExchanges(current);
  }
}

function systemMinTargetAirHumidityDefaults(): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.systemMinTargetAirHumidity]: PropertyValue.fromAmount(
      Amount.create(0.6, Units.GramPerKilogram)
    )
  };
}

function systemMaxHeatingAirTemperatureDefaults(): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.systemMaxHeatingAirTemperature]: PropertyValue.fromAmount(
      Amount.create(25, Units.Celsius)
    )
  };
}

function systemMinCoolingAirTemperatureDefaults(): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.systemMinCoolingAirTemperature]: PropertyValue.fromAmount(
      Amount.create(15, Units.Celsius)
    )
  };
}

function updateMinimumFreshFlowPerArea(
  selection: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.systemFreshFlowPerAreaMinimum]: PropertyValue.fromAmount(
      getMinimumFreshFlowPerArea(selection)
    )
  };
}

function updateMinimumAirExchanges(
  selections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.systemAirExchangesPerVolumeMinimum]:
      PropertyValue.fromAmount(getMinimumAirExchanges(selections))
  };
}

function updateMaximumAirExchanges(
  selections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.systemAirExchangesPerVolumeMaximum]:
      PropertyValue.fromAmount(getMaximumAirExchanges(selections))
  };
}

function getMinimumAirExchanges(
  selections: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.DimensionlessPerDuration> {
  const minimumTotalFlow = Amount.valueAs(
    Units.StandardCubicMeterPerHour,
    PropertyValueSet.getAmount<Quantity.MassFlow>(
      KnownProperties.systemTotalFlowMinimum,
      selections
    ) || Amount.create(0, Units.StandardCubicMeterPerHour)
  );

  const volume = Amount.valueAs(
    Units.CubicMeter,
    PropertyValueSet.getAmount<Quantity.Volume>(
      KnownProperties.buildingVolume,
      selections
    ) || Amount.create(0, Units.CubicMeter)
  );

  return Amount.create(minimumTotalFlow / volume, Units.OnePerHour);
}

function getMaximumAirExchanges(
  selections: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.DimensionlessPerDuration> {
  const maximumTotalFlow = Amount.valueAs(
    Units.StandardCubicMeterPerHour,
    PropertyValueSet.getAmount<Quantity.MassFlow>(
      KnownProperties.systemTotalFlowMaximum,
      selections
    ) || Amount.create(0, Units.StandardCubicMeterPerHour)
  );

  const volume = Amount.valueAs(
    Units.CubicMeter,
    PropertyValueSet.getAmount<Quantity.Volume>(
      KnownProperties.buildingVolume,
      selections
    ) || Amount.create(0, Units.CubicMeter)
  );

  return Amount.create(maximumTotalFlow / volume, Units.OnePerHour);
}

function updateMinimumTotalFlow(
  selections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.systemTotalFlowMinimum]: PropertyValue.fromAmount(
      getMinimumTotalAirFlow(selections)
    )
  };
}

function updateMaximumTotalFlow(
  selections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.systemTotalFlowMaximum]: PropertyValue.fromAmount(
      getMaximumTotalAirFlow(selections)
    )
  };
}

function getMinimumTotalAirFlow(
  selections: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.MassFlow> {
  const exchangesPerHour = Amount.valueAs(
    Units.OnePerHour,
    PropertyValueSet.getAmount<Quantity.DimensionlessPerDuration>(
      KnownProperties.systemAirExchangesPerVolumeMinimum,
      selections
    ) || Amount.create(0, Units.OnePerHour)
  );

  const volume = Amount.valueAs(
    Units.CubicMeter,
    PropertyValueSet.getAmount<Quantity.Volume>(
      KnownProperties.buildingVolume,
      selections
    ) || Amount.create(0, Units.CubicMeter)
  );

  return Amount.create(
    volume * exchangesPerHour,
    Units.StandardCubicMeterPerHour
  );
}

function getMaximumTotalAirFlow(
  selections: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.MassFlow> {
  const exchangesPerHour = Amount.valueAs(
    Units.OnePerHour,
    PropertyValueSet.getAmount<Quantity.DimensionlessPerDuration>(
      KnownProperties.systemAirExchangesPerVolumeMaximum,
      selections
    ) || Amount.create(0, Units.OnePerHour)
  );

  const volume = Amount.valueAs(
    Units.CubicMeter,
    PropertyValueSet.getAmount<Quantity.Volume>(
      KnownProperties.buildingVolume,
      selections
    ) || Amount.create(0, Units.CubicMeter)
  );

  return Amount.create(
    volume * exchangesPerHour,
    Units.StandardCubicMeterPerHour
  );
}
function updateMinimumFreshFlow(
  selections: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  return {
    [KnownProperties.systemFreshFlowMinimum]: PropertyValue.fromAmount(
      getMinimumFreshAirFlow(selections)
    )
  };
}

function getMinimumFreshAirFlow(
  selections: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.MassFlow> {
  const freshFlowPerArea = Amount.valueAs(
    Units.KilogramPerSquareMeterHour,
    PropertyValueSet.getAmount<Quantity.MassFlowPerArea>(
      KnownProperties.systemFreshFlowPerAreaMinimum,
      selections
    ) || Amount.create(0, Units.KilogramPerSquareMeterHour)
  );

  const area = Amount.valueAs(
    Units.SquareMeter,
    PropertyValueSet.getAmount<Quantity.Area>(
      KnownProperties.buildingFloorArea,
      selections
    ) || Amount.create(0, Units.SquareMeter)
  );

  return Amount.create(area * freshFlowPerArea, Units.KilogramPerHour);
}

function getMinimumFreshFlowPerArea(
  selections: PropertyValueSet.PropertyValueSet
): Amount.Amount<Quantity.MassFlowPerArea> {
  if (
    PropertyValueSet.getInteger(
      KnownProperties.systemConfiguration,
      selections
    ) === 0
  ) {
    return Amount.create(0, Units.KilogramPerSquareMeterHour);
  }

  const minimumFlow = Amount.valueAs(
    Units.KilogramPerHour,
    PropertyValueSet.getAmount<Quantity.MassFlow>(
      KnownProperties.systemFreshFlowMinimum,
      selections
    ) || Amount.create(0, Units.KilogramPerHour)
  );

  const area = Amount.valueAs(
    Units.SquareMeter,
    PropertyValueSet.getAmount<Quantity.Area>(
      KnownProperties.buildingFloorArea,
      selections
    ) || Amount.create(0, Units.SquareMeter)
  );

  return Amount.create(minimumFlow / area, Units.KilogramPerSquareMeterHour);
}
// tslint:disable-next-line:max-file-line-count
