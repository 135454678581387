import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as KnownProperties from "../../tools/known-properties";
import * as BuildingSettings from "../../tools/moisture-load-rules/building-settings";
import * as InfiltrationModelSettings from "../../tools/moisture-load-rules/infiltration-model-settings";
import * as SystemSettings from "../../tools/moisture-load-rules/system-settings";
import { PropertyDefinitions } from "../../tools";

const labels = [
  {
    image: "closed-system",
    languagetext: LanguageTexts.mlcSystemConfigurationTypes(0)
  },
  {
    image: "closed-system-with-make-up-air",
    languagetext: LanguageTexts.mlcSystemConfigurationTypes(1)
  },
  {
    image: "open-system",
    languagetext: LanguageTexts.mlcSystemConfigurationTypes(2)
  }
];

const systemflowLimits = [
  KnownProperties.systemManualFreshFlowMinimum,
  KnownProperties.systemFreshFlowPerAreaMinimum,
  KnownProperties.systemFreshFlowMinimum,
  KnownProperties.systemSetMinAirExchangesOrFlow,
  KnownProperties.systemManualTotalFlowMinimum,
  KnownProperties.systemAirExchangesPerVolumeMinimum,
  KnownProperties.systemTotalFlowMinimum,
  KnownProperties.systemSetMaxAirExchangesOrFlow,
  KnownProperties.systemManualTotalFlowMaximum,
  KnownProperties.systemAirExchangesPerVolumeMaximum,
  KnownProperties.systemTotalFlowMaximum,
  KnownProperties.systemNumberOfFlowSuggestions,
  KnownProperties.systemSetDesignCaseCoverage,
  KnownProperties.systemDesignCaseCoverage
];

const systemotherLimits = [
  KnownProperties.systemSetMinTargetAirHumidity,
  KnownProperties.systemMinTargetAirHumidity,
  KnownProperties.systemSetMinCoolingAirTemperature,
  KnownProperties.systemMinCoolingAirTemperature,
  KnownProperties.systemSetMaxHeatingAirTemperature,
  KnownProperties.systemMaxHeatingAirTemperature
];

export function buildingDefs(
  propertyDefinitions: PropertyDefinitions
): PropertyDefinitions {
  return [
    ...propertyDefinitions.filter(x =>
      BuildingSettings.standardProperties.includes(x.name)
    )
  ];
}

export function buildingDefAdditional(
  manualInputEnabled: boolean,
  propertyDefinitions: PropertyDefinitions
): PropertyDefinitions {
  const propertiesToUse = [
    KnownProperties.buildingUseManualUValues,
    KnownProperties.buildingRoofUValue,
    KnownProperties.buildingWallUValue,
    KnownProperties.buildingUseManualLeakageCoefficient,
    KnownProperties.buildingLeakageCoefficient,
    KnownProperties.buildingLocation
  ];

  const def = propertyDefinitions.filter(x => propertiesToUse.includes(x.name));
  const manualInputProperty = propertyDefinitions.find(
    x => x.name === KnownProperties.buildingSurroundingAirManualInput
  )!;

  return def
    .concat([
      manualInputProperty,
      ...propertyDefinitions
        .filter(x => BuildingSettings.surroundingRoom.includes(x.name))
        .filter(
          x => x.name !== KnownProperties.buildingSurroundingAirManualInput
        )
    ])
    .filter(
      x =>
        !(
          manualInputEnabled &&
          BuildingSettings.overridableProperties.includes(x.name)
        )
    );
}

export function additionalSystemSelectionsDef(
  propertyDefinitions: PropertyDefinitions
): PropertyDefinitions {
  const combined = systemflowLimits.concat(systemotherLimits);
  propertyDefinitions.filter(x => !combined.includes(x.name));

  return [
    ...propertyDefinitions
      .filter(x => combined.includes(x.name))
      .map(y => ({
        ...y
      }))
  ];
}

export function useCasesDefinitions(
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  propertyDefinitions: PropertyDefinitions
): PropertyDefinitions {
  let systemConfigurationDef = propertyDefinitions.find(
    definition => definition.name === KnownProperties.systemConfiguration
  )!;

  const systemOverPressure = propertyDefinitions.find(
    x => x.name === KnownProperties.systemOverPressure
  )!;

  systemConfigurationDef = {
    ...systemConfigurationDef,
    items: systemConfigurationDef.items.map((x, i) => ({
      ...x,
      height: "100px",
      image: `/assets/images/moisture-and-heat-load-types/systems/${labels[i].image}.jpg`,
      text: translate(labels[i].languagetext)
    }))
  };

  // const remainingDefs = propertyDefinitions
  //   .filter(x => SystemSettings.mainSettings.includes(x.name))
  //   .filter(x => x.name !== KnownProperties.systemConfiguration);

  return [systemConfigurationDef, systemOverPressure];
}

export function systemMainSettingsDef(
  translate: (textDefinition: LanguageTexts.TextDefinition) => string,
  manualInputEnabled: boolean,
  propertyDefinitions: PropertyDefinitions
): PropertyDefinitions {
  let systemConfigurationDef = propertyDefinitions.find(
    definition => definition.name === KnownProperties.systemConfiguration
  )!;

  const systemOverPressure = propertyDefinitions.find(
    x => x.name === KnownProperties.systemOverPressure
  )!;

  systemConfigurationDef = {
    ...systemConfigurationDef,
    items: systemConfigurationDef.items.map((x, i) => ({
      ...x,
      height: "100px",
      image: `/assets/images/moisture-and-heat-load-types/systems/${labels[i].image}.jpg`,
      text: translate(labels[i].languagetext)
    }))
  };

  // const remainingDefs = propertyDefinitions
  //   .filter(x => SystemSettings.mainSettings.includes(x.name))
  //   .filter(x => x.name !== KnownProperties.systemConfiguration);

  let manualDefs = [systemConfigurationDef, systemOverPressure];

  const def = manualDefs.concat(
    propertyDefinitions.filter(
      x =>
        SystemSettings.mainSettings.includes(x.name) &&
        x.name !== KnownProperties.systemConfiguration &&
        x.name !== KnownProperties.systemOverPressure &&
        SystemSettings.manualInputProperty !== x.name &&
        SystemSettings.overridableProperties.every(op => op !== x.name)
    )
  );

  const manualInputProperty = propertyDefinitions.find(
    x => x.name === SystemSettings.manualInputProperty
  )!;

  return def
    .concat([
      manualInputProperty,
      ...propertyDefinitions
        .filter(x => SystemSettings.overridableProperties.includes(x.name))
        .filter(x => x.name !== SystemSettings.manualInputProperty)
    ])
    .filter(
      x =>
        !(
          manualInputEnabled &&
          SystemSettings.overridableProperties.includes(x.name)
        )
    );
}
export function infiltrationModelSelectionsDef(
  manualInputEnabled: boolean,
  propertyDefinitions: PropertyDefinitions
): PropertyDefinitions {
  const def = propertyDefinitions.filter(
    x =>
      InfiltrationModelSettings.properties.includes(x.name) &&
      InfiltrationModelSettings.manualInputProperty !== x.name &&
      InfiltrationModelSettings.overridableProperties.every(op => op !== x.name)
  );

  const manualInputProperty = propertyDefinitions.find(
    x => x.name === KnownProperties.infiltrationModelManualInput
  )!;
  // .filter(x => x.name !== KnownProperties.systemConfiguration);

  return def.concat(
    [
      manualInputProperty,
      ...propertyDefinitions.filter(x =>
        InfiltrationModelSettings.overridableProperties.includes(x.name)
      )
    ].filter(
      x =>
        !(
          manualInputEnabled &&
          InfiltrationModelSettings.overridableProperties.includes(x.name)
        )
    )
  );
}
