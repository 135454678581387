import * as React from "react";
import { Action } from "../state";
import { Dispatch } from "@typescript-tea/core";
import { P1, StandardButton } from "@genesys/ui-elements";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { CreatedSystemDialogRoot } from "../elements";

export function CreatedSystemDialog({
  url,
  dispatch
}: {
  readonly url: string;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  return (
    <ClickAwayListener
      onClickAway={() => {
        window.open(url);
        dispatch(Action.setCreatedSystemUrl(undefined));
      }}
    >
      <CreatedSystemDialogRoot>
        <P1 color="dark" weight="normal">
          System created
        </P1>
        <StandardButton
          buttonType="PrimaryGreen"
          size="Large"
          onClick={() => {
            window.open(url);
            dispatch(Action.setCreatedSystemUrl(undefined));
          }}
        >
          Open in new tab
        </StandardButton>
        <StandardButton
          buttonType="SecondaryGreen"
          size="Large"
          onClick={() => dispatch(Action.redirect(url))}
        >
          Open in current tab
        </StandardButton>
      </CreatedSystemDialogRoot>
    </ClickAwayListener>
  );
}
