import * as React from "react";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import { GenesysTableOption, GenesysTableSelect } from "@genesys/ui-elements";
import { ValueSourceImage } from "../elements";

// tslint:disable-next-line
const manualDark = require("ui-elements/assets/toggle/manual-dark.svg");
// tslint:disable-next-line
const settingsDark = require("ui-elements/assets/toggle/settings-dark.svg");

export function ValueSourceSelector({
  isLocked,
  showCodes,
  properties,
  propertyName,
  valueSources,
  onChange
}: {
  readonly isLocked: boolean;
  readonly showCodes: boolean;
  readonly propertyName: string;
  readonly properties: PropertyValueSet.PropertyValueSet;
  readonly valueSources: ReadonlyArray<{
    readonly value: number;
    readonly propertyFilter: PropertyFilter.PropertyFilter;
    readonly propertyValueSourceId: string;
  }>;
  readonly onChange: (
    selectedProperties: PropertyValueSet.PropertyValueSet,
    propertyName: string
  ) => void;
}): JSX.Element | null {
  if (valueSources.length < 2) {
    return null;
  }

  const sourceInt = PropertyValueSet.getInteger(
    `source_${propertyName}`,
    properties
  );

  const value =
    (sourceInt && sourceInt.toString()) || valueSources[0].value.toString();

  const isUserChoice =
    valueSources.find(vs => vs.value.toString() === value)
      ?.propertyValueSourceId === "User";

  if (isLocked && !isUserChoice) {
    return null;
  }

  const options = valueSources.map(v => ({
    imageUrl: v.propertyValueSourceId === "User" ? manualDark : settingsDark,
    value: v.value.toString()
  }));

  return (
    <GenesysTableSelect
      value={value}
      disabled={isLocked}
      onChange={e => {
        onChange(
          PropertyValueSet.setInteger(
            `source_${propertyName}`,
            parseInt(e.target.value, 10),
            properties
          ),
          `source_${propertyName}`
        );
      }}
    >
      {options.map(o => (
        <GenesysTableOption key={o.value} value={o.value}>
          <ValueSourceImage src={o.imageUrl} />
          {showCodes && <span> ({o.value})</span>}
        </GenesysTableOption>
      ))}
    </GenesysTableSelect>
  );
}
