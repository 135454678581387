import gql from "graphql-tag";

export const userQuery = gql`
  query RootUserQuery {
    user {
      userProfile {
        id
        name
        userName
        lastLogin

        hasAcceptedTerms

        labels {
          id
          name
        }

        applicationClaims {
          id
          key
          context
          values
        }

        currencies {
          id
          name
          signBefore
          signAfter
        }

        userSettings {
          amountFieldProfiles {
            id
            name
            measureSystem
            isEditable
            amountFieldFormats {
              id
              fieldGroup
              fieldName
              measureSystem
              unit
              decimalCount
            }
          }
          selectedAmountFieldProfile {
            id
          }
          lastOpenedSystems {
            id
          }
          favoritesSystems
          culture {
            id
            locale {
              id
            }
            language {
              id
            }
            currency {
              id
            }
          }

          energySettings {
            id
            settingName
            settingValue
          }

          reportSettings {
            systemType
            ids
          }
          psychrometricChartSettings {
            settingName
            settingValue
          }

          systemsSummaryUserSettings {
            settingName
            settingValue
          }

          climate {
            id
            country {
              id
            }
            region {
              id
            }
            location {
              id
            }
            heatingDataType
            coolingDataType
            heatingOccurence
            coolingOccurence
            climateDataSource
            manualData
          }

          wizardSettings {
            id
            lastCreatedSystemType
            lastSelectedLabels
            createSystemType {
              systemType
              counter
            }
          }

          systemManagerSettings {
            id
            lastSearchScopeType
          }

          sales {
            id
            defaultSalesOrganisation
          }
        }

        salesOrganisations {
          id
          name
        }
      }
    }
  }
`;

export const productQuery = gql`
  query RootProductQuery {
    product {
      fieldDefaults {
        id
        fieldGroup
        fieldName
        measureSystem
        unit
        decimalCount
      }

      quantityDefaults {
        id
        measureSystem
        quantity
        unit
        decimalCount
      }

      genesysNumberPrefixes {
        genesysNo
        pricingNo
        moistureLoadNo
      }

      routes {
        id
        route
        url
      }
    }
  }
`;

export const translationQuery = gql`
  query TranslationQuery($language: String!) {
    product {
      language(languageCode: $language) {
        id
        texts
      }
    }
  }
`;

export const languagesQuery = gql`
  query LanguagesQuery {
    product {
      languages {
        id
      }
    }
  }
`;

export const recentAndFavoritesQuery = gql`
  query RecentAndFavoritesQuery($ids: [ID!]!) {
    user {
      systems(ids: $ids) {
        id
        revisionNo
        status
        statusDate
        owner {
          userName
        }
        systemFile {
          id
          systemTypeId
          name
          genesysNo
          owner {
            userName
          }
          labels {
            id
            name
          }
          systems {
            id
            revisionNo
            status
            statusDate
            comment
            salesOrganisationId
          }
        }
      }
    }
  }
`;

export const validationQuery = gql`
  query RecentSystemsHasPermission($systemId: ID!) {
    user {
      validateSystem(systemId: $systemId) {
        hasPermission
      }
    }
  }
`;
