import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as Product from "../../../product";
import * as System from "../../../system";
import * as ProductData from "@genesys/shared/lib/product-data";
import * as Uuid from "uuid";
import { State } from "../state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SystemStatusEnum from "@genesys/shared/lib/enums/system-status";
import styled from "styled-components";
import { CurrencyAndSalesOrgSelector } from "./sub-views/currency-and-sales-org";
import { ExtendedInformation } from "./sub-views/extended-information";
import { ComponentPriceTable } from "./sub-views/component-price-table";
import { PriceBreakdownView } from "./sub-views/price-breakdown-view";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../state";
import { SelectedOptionsType } from "../types";
import {
  // ReadOnlyTable,
  GenericTable,
  GenericTableColumnDefinitionType,
  GenericTableRowType
} from "@genesys/ui-elements";
import { H3, AlertAccordion } from "@genesys/ui-elements";
import * as AuthorizationTools from "@genesys/shared/lib/authorization";
import * as HelperFunctions from "../helper-functions";
import { PropertyValueSet } from "@genesys/property";

const Container = styled.div`
  padding: 15px;
  overflow-y: auto;
`;

const SubContainer = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 65%;
  h3 {
    margin-bottom: 10px;
  }
  > div {
    margin-right: 12px;
  }
`;

const AlertContainer = styled.div`
  margin-bottom: 10px;
  height: 40px;
`;

const StyledDiv = styled.div`
  margin-bottom: 10px;
  > label {
    display: block;
    margin-top: 5px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
`;

const PriceBreakdownContainer = styled.div`
  width: 67%;
  max-height: 550px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 7px;
    height: 9px;
    margin-right: 20px;
    opacity: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dde4f0;
    opacity: 1;
    border-radius: 5.5px;
    margin-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

interface Price {
  readonly item: string;
  readonly price: string;
}

export function SystemHasPrice({
  products,
  system,
  state,
  sharedState,
  selectedOptions,
  systemType,
  dispatch
}: {
  readonly selectedOptions: SelectedOptionsType;
  readonly sharedState: SharedState.State;
  readonly products: ReadonlyArray<Product.Product>;
  readonly state: State;
  readonly system: System.System;
  readonly systemType: Product.SystemType;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  const sys = system.components.find(c => c.productId.endsWith("SYS"))!;
  const systemUserData = state.systemHasPriceQueryData!;
  const priceProductData = state.priceProductQueryData!;
  const validProducts = ProductData.getValidProductsForRange(
    products,
    sys.properties
  );
  const connectionPoints = validProducts.reduce(
    (soFar: ReadonlyArray<Product.BoxConnectionPoint>, product) =>
      soFar.concat(product.boxConnectionPoints),
    []
  );
  const componentOrder = HelperFunctions.getComponentOrder(
    system,
    connectionPoints
  );

  const productsToExclude = HelperFunctions.getProductsToExclude(
    systemType,
    sys.properties
  );

  const showMaster = HelperFunctions.checkIfMasterSettings(
    systemType,
    sys.properties
  );

  const currencies = HelperFunctions.getCurrencies(
    sharedState.user.currencies,
    system
  );
  const baseComponents = system.components.filter(c => !c.accessoryToId);
  const accessories = system.components.filter(c => c.accessoryToId);
  const readonly = system.status >= SystemStatusEnum.SystemStatus.LockSuccess;

  const showMasterSettings =
    showMaster &&
    AuthorizationTools.checkPermission(
      sharedState.user.applicationClaims,
      AuthorizationTools.priceClaims.canSeeMasterSettings,
      AuthorizationTools.contexts.genesys
    );

  const priceType = HelperFunctions.getPriceTypeToDisplay(
    priceProductData,
    system.salesOrganisationId
  );

  const tableComponents = HelperFunctions.getTableComponents(
    baseComponents,
    accessories,
    systemUserData,
    sharedState.translate,
    priceType,
    productsToExclude
  );

  const totalPrice = HelperFunctions.getTotalPrice(tableComponents);

  const permissions = HelperFunctions.getAuthorizations(
    sharedState.user.applicationClaims,
    systemType,
    system
  );

  const validityText = HelperFunctions.getVailidityText(
    systemUserData,
    sharedState
  );

  const expired = HelperFunctions.checkIfExpired(systemUserData, sharedState);

  const canSeeExtendedInformation =
    permissions.canSeeMarginCost ||
    permissions.canSeeMasterMarginCost ||
    permissions.canSeeMasterTransferPrice ||
    permissions.canSeeMasterListPrice ||
    permissions.canSeeTransferPrice ||
    permissions.canSeeListPrice ||
    permissions.canSeeRefCost ||
    permissions.canSeeConsolidatedMargin ||
    permissions.canSeeMargin ||
    permissions.canSeeMCZPrice;

  const priceTableColumns: GenericTableColumnDefinitionType<Price>[] = [
    {
      key: "item",
      header: " "
    },
    {
      key: "price",
      header: " ",
      align: "right"
    }
  ];

  const priceTableData: GenericTableRowType<Price>[] = [
    {
      rowId: Uuid.v4(),
      rowValues: {
        item: {
          cellId: Uuid.v4(),
          value: sharedState.translate(LanguageTexts.baseComponents())
        },
        price: {
          cellId: Uuid.v4(),
          value: `${
            currencies.currency.signBefore ? currencies.currency.signBefore : ""
          }${Math.round(totalPrice.totalBaseComponentsPrice).toLocaleString(
            sharedState.user.settings.locale
          )} ${
            currencies.currency.signAfter ? currencies.currency.signAfter : ""
          }`
        }
      }
    },
    // Add the remaning items from the existing table here
    {
      rowId: Uuid.v4(),
      rowValues: {
        item: {
          cellId: Uuid.v4(),
          value: sharedState.translate(LanguageTexts.accessories())
        },
        price: {
          cellId: Uuid.v4(),
          value: `${
            currencies.currency.signBefore ? currencies.currency.signBefore : ""
          }${Math.round(totalPrice.totalAccessoriesPrice).toLocaleString(
            sharedState.user.settings.locale
          )} ${
            currencies.currency.signAfter ? currencies.currency.signAfter : ""
          }`
        }
      }
    },

    {
      rowId: Uuid.v4(),
      rowValues: {
        item: {
          cellId: Uuid.v4(),
          value: sharedState.translate(
            LanguageTexts.dynamicText(priceType.toLowerCase())
          )
        },
        price: {
          cellId: Uuid.v4(),
          value: `${
            currencies.currency.signBefore ? currencies.currency.signBefore : ""
          }${(
            Math.round(totalPrice.totalBaseComponentsPrice) +
            Math.round(totalPrice.totalAccessoriesPrice)
          ).toLocaleString(sharedState.user.settings.locale)} ${
            currencies.currency.signAfter ? currencies.currency.signAfter : ""
          }`
        }
      },
      isBold: true
    }
  ];

  // const setPriceBreakDownState = (selectedRowId: string) => {
  //   dispatch(
  //     Action.setPriceBreakDownState({
  //       ...state.priceBreakDownState,
  //       selectedRowId
  //     })
  //   );
  // };

  return (
    <Container>
      {expired && validityText ? (
        <AlertContainer>
          <AlertAccordion
            warningType={"warning"}
            alerts={[{ title: "", body: validityText }]}
            width="550px"
            title={sharedState.translate(LanguageTexts.validityWarning())}
          />
        </AlertContainer>
      ) : (
        validityText && (
          <StyledDiv>
            <H3>{sharedState.translate(LanguageTexts.validTime())}</H3>
            <label>{validityText}</label>
          </StyledDiv>
        )
      )}
      <CurrencyAndSalesOrgSelector
        showMasterSettings={showMasterSettings}
        selectedOptions={selectedOptions}
        isDisabled={readonly}
        sharedState={sharedState}
        dispatch={dispatch}
        selectableSalesOrganisations={priceProductData.product.salesOrganisations.filter(
          s => sharedState.user.salesOrganisations.some(ss => s.id === ss.id)
        )}
      />{" "}
      {!state.valuesHasChanged && (
        <>
          <FlexContainer>
            <SubContainer>
              <div>
                <H3>{sharedState.translate(LanguageTexts.components())}</H3>
                <ComponentPriceTable
                  numberLocale={sharedState.user.settings.locale}
                  componentOrder={componentOrder}
                  tableComponents={tableComponents}
                  sharedState={sharedState}
                  currency={currencies.currency}
                  permissions={permissions}
                  selectedRowId={state.priceBreakDownState.selectedRowId}
                  selectRowForPriceBreakdown={rowId => {
                    const tc = tableComponents.find(c => c.id === rowId);

                    const component = system.components.find(
                      c => c.id === rowId
                    );

                    dispatch(
                      Action.setPriceBreakDownState({
                        selectedRowId: rowId,
                        productIdForRow: tc?.productId || "",
                        propertiesForRow:
                          component?.properties || PropertyValueSet.Empty,
                        priceInfo: [],
                        isFectchingData: false
                      })
                    );

                    if (component) {
                      dispatch(Action.fetchProductPriceBreakdownQuery());
                    }
                  }}
                />
              </div>

              <div>
                <H3>
                  {sharedState.translate(LanguageTexts.systemCalculation())}
                </H3>
                <div style={{ marginTop: "33px" }}>
                  <GenericTable
                    columns={priceTableColumns}
                    data={priceTableData}
                  />
                </div>
              </div>
            </SubContainer>
            <PriceBreakdownContainer>
              <PriceBreakdownView
                priceinfo={state.priceBreakDownState.priceInfo}
                isLoading={state.priceBreakDownState.isFectchingData}
              />
            </PriceBreakdownContainer>
          </FlexContainer>

          {!!systemUserData.user.system.pricingInformation.length && ( // Hittade ingen för att testa denna på
            <div>
              <H3>{sharedState.translate(LanguageTexts.pricing())}</H3>
              <div>{sharedState.translate(LanguageTexts.isInPricings())}:</div>

              {systemUserData.user.system.pricingInformation.map(pi => (
                <li>
                  {priceProductData.product.genesysNumberPrefixes.pricingNo +
                    pi.pricingNo +
                    "-" +
                    pi.revisionNo}
                </li>
              ))}
            </div>
          )}
          {canSeeExtendedInformation && (
            <ExtendedInformation
              dispatch={dispatch}
              isOpen={state.extendedInformationIsOpen}
              sharedState={sharedState}
              currencies={currencies}
              accessories={accessories}
              baseComponents={baseComponents}
              permissions={permissions}
              systemUserData={systemUserData}
            />
          )}
        </>
      )}
    </Container>
  );
}
