// Application
export const applicationType = "mlcapplicationtype";

// Main
// MLC - System settings main
export const systemConfiguration = "mlcsystemconfiguration";
export const systemOverPressure = "mlcsystemoverpressure";

// MLC - System settings flows
export const systemManualFreshFlowMinimum = "mlcsystemmanualfreshflowmin";
export const systemFreshFlowPerAreaMinimum = "mlcsystemfreshflowperareaminimum";
export const systemFreshFlowMinimum = "mlcsystemfreshflowminimum";

// Min air exchanges
export const systemSetMinAirExchangesOrFlow =
  "mlcsystemsetminairexchangesorflow";
export const systemAirExchangesPerVolumeMinimum =
  "mlcsystemairexchangespervolumeminimum";
export const systemManualTotalFlowMinimum = "mlcsystemmanualtotalairflowmin";
export const systemTotalFlowMinimum = "mlcsystemtotalflowminimum";

// Max air exchanges
export const systemSetMaxAirExchangesOrFlow =
  "mlcsystemsetmaxairexchangesorflow";
export const systemAirExchangesPerVolumeMaximum =
  "mlcsystemairexchangespervolumemaximum";
export const systemManualTotalFlowMaximum = "mlcsystemmanualtotalairflowmax";
export const systemTotalFlowMaximum = "mlcsystemtotalflowmaximum";

// Other
export const systemMakeUpOpenAirSource = "mlcsystemairsource";
export const systemLoadManualInput = "mlcsystemloadmanualinput";
export const systemSetDesignCaseCoverage = "mlcsystemsetdesigncasecoverage";
export const systemDesignCaseCoverage = "mlcsystemdesigncasecoverage";

export const systemNumberOfFlowSuggestions = "mlcsystemnumberofflowsuggestions";
export const systemSetMinTargetAirHumidity = "mlcsystemsetmintargetairhumidity";
export const systemMinTargetAirHumidity = "mlcsystemmintargetairhumidity";
export const systemSetMinCoolingAirTemperature =
  "mlcsystemsetmincoolingairtemperature";
export const systemMinCoolingAirTemperature =
  "mlcsystemmincoolingairtemperature";
export const systemSetMaxHeatingAirTemperature =
  "mlcsystemsetmaxheatingairtemperature";
export const systemMaxHeatingAirTemperature =
  "mlcsystemmaxheatingairtemperature";

export const systemMakeUpOpenAirTemperature =
  "mlcsystemmakeupopenairtemperature";
export const systemMakeUpOpenUseOutdoorAirHumidity =
  "mlcsystemmakeupopenuseoutdoorairhumidity";
export const systemMakeUpOpenAirHumidity = "mlcsystemmakeupopenairhumidity";

export const buildingLength = "mlcbuildinglength";
export const buildingWidth = "mlcbuildingwidth";
export const buildingHeight = "mlcbuildingheight";
export const buildingSurfaceArea = "mlcbuildingsurfacearea";
export const buildingFloorArea = "mlcbuildingfloorarea";
export const buildingVolume = "mlcbuildingvolume";
export const buildingStructure = "mlcbuildingstructure";
export const buildingUseManualUValues = "mlcbuildingusemanualuvalues";
export const buildingWallUValue = "mlcbuildingwalluvalue";
export const buildingRoofUValue = "mlcbuildingroofuvalue";
export const buildingUseManualLeakageCoefficient =
  "mlcbuildingusemanualleakagecoefficient";
export const buildingLeakageCoefficient = "mlcbuildingleakagecoefficient";
export const buildingLocation = "mlcbuildinglocation";
export const buildingSurroundingAirManualInput =
  "mlcbuildingsurroundingairmanualinput";
export const buildingSurroundingAirTemperature =
  "mlcbuildingsurroundingairtemperature";
export const buildingSurroundingUseOutdoorAirHumidity =
  "mlcbuildingsurroundinguseoutdoorairhumidity";
export const buildingSurroundingAirHumidity =
  "mlcbuildingsurroundingairhumidity";

// Infiltration model
export const infiltrationModelManualInput = "mlcinfiltrationmodelmanualinput";
export const infiltrationAirSource = "mlcinfiltrationairsource";
export const infiltrationAirTemperature = "mlcinfiltrationairtemperature";
export const infiltrationUseOutdoorAirHumidity =
  "mlcinfiltrationuseoutdoorairhumidity";
export const infiltrationAirHumidity = "mlcinfiltrationairhumidity";
export const infiltrationModel = "mlcinfiltrationmodel";
export const useManualAirChanges = "mlcusemanualairchanges";
export const airChanges = "mlcairchanges";
export const windProtectionType = "mlcwindprotectiontype";
export const useManualWindProtectionCoefficient =
  "mlcusemanualwindprotectioncoefficient";
export const windProtectionCoefficient = "mlcwindprotectioncoefficient";

// Emission from combustion
export const useEmissionFromCombustionMoistureLoad =
  "mlcuseemissionfromcombustionmoistureload";
export const useEmissionFromCombustionHeatLoad =
  "mlcuseemissionfromcombustionheatload";
export const emissionFromCombustionManualInput =
  "mlcemissionfromcombustionmanualinput";
export const fuelCombustionRate = "mlcfuelcombustionrate";
export const fuel = "mlcfuel";
export const manualWaterVaporPerFuelRatio =
  "mlcusemanualwatervaporperfuelratio";
export const waterVaporPerFuelRatio = "mlcwatervaporperfuelratio";
export const manualFuelEnergyDensity = "mlcusemanualfuelenergydensity";
export const fuelEnergyDensity = "mlcfuelenergydensity";

// Unintended ventilation
export const useUnintendedVentilationMoistureLoad =
  "mlcuseunintendedventilationmoistureload";
export const useUnintendedVentilationHeatLoad =
  "mlcuseunintendedventilationheatload";
export const unintendedVentilationManualInput =
  "mlcunintendedventilationmanualinput";

// Open door
export const useOpenDoorMoistureLoad = "mlcuseopendoormoistureload";
export const useOpenDoorHeatLoad = "mlcuseopendoorheatload";
export const openDoorManualInput = "mlcopendoormanualinput";
export const openDoorTime = "mlcopendoortime";
export const numberOfOpenDoors = "mlcnumberofopendoors";
export const useManualOpenDoorLeakageMultilplier =
  "mlcusemanualopendoorleakagemultilplier";
export const openDoorLeakageMultilplier = "mlcopendoorleakagemultilplier";

// Evaporation of water
export const useEvaporationOfWaterMoistureLoad =
  "mlcuseevaporationofwatermoistureload";
export const useEvaporationOfWaterHeatLoad = "mlcuseevaporationofwaterheatload";
export const evaporationOfWaterManualInput = "mlcevaporationofwatermanualinput";
export const waterSurfaceArea = "mlcwatersurfacearea";
export const waterSurfaceTemperature = "mlcwatersurfacetemperature";
export const airActivityNearSurface = "mlcairactivitynearsurface";
export const useManualActivityWaterAlpha = "mlcusemanualactivitywateralpha";
export const activityWaterAlpha = "mlcactivitywateralpha";

// Intentional ventilation
export const useIntentionalVentilationMoistureLoad =
  "mlcuseintentionalventilationmoistureload";
export const useIntentionalVentilationHeatLoad =
  "mlcuseintentionalventilationheatload";
export const intentionalVentilationManualInput =
  "mlcintentionalventilationmanualinput";
export const ventilationFlow = "mlcventilationflow";
export const ventilationAirSource = "mlcventilationairsource";
export const ventilationAirTemperature = "mlcventilationairtemperature";
export const ventilationAirHumidity = "mlcventilationairhumidity";

// Emission From People
export const useEmissionFromPeopleMoistureLoad =
  "mlcuseemissionfrompeoplemoistureload";
export const useEmissionFromPeopleHeatLoad = "mlcuseemissionfrompeopleheatload";
export const emissionFromPeopleManualInput = "mlcemissionfrompeoplemanualinput";
export const numberOfPersons = "mlcnumberofpersons";
export const activityLevel = "mlcactivitylevel";
export const useManualEmissionPerPerson = "mlcusemanualemissionperperson";
export const emissionPerPerson = "mlcemissionperperson";
export const useManualHeatLoadPerPerson = "mlcusemanualheatloadperperson";
export const heatLoadPerPerson = "mlcheatloadperperson";

// Evaporation from materials
export const useEvaporationFromMaterialsMoistureLoad =
  "mlcuseevaporationfrommaterialsmoistureload";
export const useEvaporationFromMaterialsHeatLoad =
  "mlcuseevaporationfrommaterialsheatload";
export const evaporationFromMaterialsManualInput =
  "mlcevaporationfrommaterialsmanualinput";
export const materialEnteringRate = "mlcmaterialenteringrate";
export const initialMoistureContentOfMaterial =
  "mlcinitialmoisturecontentofmaterial";
export const equilibriumMoistureContentOfMaterial =
  "mlcequilibriummoisturecontentofmaterial";
export const timeToReachEquilibrium = "mlctimetoreachequilibrium";

// Heat transmission
export const useHeatTransmissionHeatLoad = "mlcuseheattransmissionheatload";
export const heatTransmissionManualInput = "mlcheattransmissionmanualinput";
export const heatTransmissionAirSource = "mlcheattransmissionairsource";
export const heatTransmissionAirTemperature =
  "mlcheattransmissionairtemperature";
export const heatTransmissionAirHumidity = "mlcheattransmissionairhumidity";

// Static moisture load
export const useStaticMoistureLoad = "mlcusestaticmoistureload";
export const staticMoistureLoadManualInput = "mlcstaticmoistureloadmanualinput";
export const useMoistureLoadPerArea = "mlcusemoistureloadperarea";
export const moistureLoadPerArea = "mlcmoistureloadperarea";
export const useFloorArea = "mlcusefloorarea";
export const useWallArea = "mlcusewallarea";
export const useRoofArea = "mlcuseroofarea";
export const staticLoadsMoistureLoad = "mlcstaticloadsmoistureload";

// Static heat load
export const useStaticHeatLoad = "mlcusestaticheatload";
export const staticHeatLoadManualInput = "mlcstaticheatloadmanualinput";
export const staticLoadsHeatLoad = "mlcstaticloadsheatload";

// Room control
export const roomControlManualInput = "mlcroomcontrolmanualinput";
export const rooomTemperatureControl = "mlcroomtemperaturecontrol";
export const roomMinTemperature = "mlcroommintemperature";
export const roomMaxTemperature = "mlcroommaxtemperature";
export const roomTemperature = "mlcroomtemperature";
export const roomHumidityControl = "mlcroomhumiditycontrol";
export const roomHumidityControlType = "mlcroomhumiditycontroltype";
export const roomHumidityDewPoint = "mlcroomhumiditydpt";
export const roomHumidityAbsolute = "mlcroomhumidityabs";
export const roomHumidityRelative = "mlcroomhumidityrel";
export const roomMinHumidityDewPoint = "mlcroomminhumiditydpt";
export const roomMinHumidityAbsolute = "mlcroomminhumidityabs";
export const roomMinHumidityRelative = "mlcroomminhumidityrel";
export const roomMaxHumidityDewPoint = "mlcroommaxhumiditydpt";
export const roomMaxHumidityAbsolute = "mlcroommaxhumidityabs";
export const roomMaxHumidityRelative = "mlcroommaxhumidityrel";
export const roomMaxHumidityRelativeAdjusted = "mlcroommaxhumidityreladjusted";
export const binSize = "binsize";
export const binDataLocationId = "bindatalocationid";
export const binLocation = "binlocation";
export const climateCoolingDataType = "climatecoolingdatatype";
