import * as React from "react";
import styled from "styled-components";
import * as SharedState from "../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { ArrowLeft, ArrowRight } from "@genesys/ui-elements/lib/icons";
import { P1, PrimaryColors, Input } from "@genesys/ui-elements";

interface Props {
  readonly sharedState: SharedState.State;
  readonly currentPage: number;
  readonly itemsPerPage: number;
  readonly totalItems: number;
  readonly onPageChange: (pageNumber: number) => void;
}

const Root = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-right: 12px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-left: 16px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
    cursor: pointer;
    :hover {
      background: ${PrimaryColors.lightBlue};
    }
    :disabled {
      background: ${PrimaryColors.lightBlue};
    }
  }
`;

const PageInput = styled(Input)`
  height: 25px;
  width: 44px;
  margin: 0 8px;
  padding: 0;
  text-align: center;
  font-size: 16px;
`;

export function PagnationComponent({
  sharedState: { translate },
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange
}: Props) {
  const [pageInput, setPageInput] = React.useState(currentPage);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <Root>
      <P1 color="dark" weight="normal">
        {currentPage} {translate(LanguageTexts.of())} {totalPages}{" "}
        {translate(LanguageTexts.pages())} {"( "} {totalItems}{" "}
        {translate(LanguageTexts.total())} {" )"}
      </P1>

      <ButtonsContainer>
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <ArrowLeft disabled={currentPage === 1} height="0.7rem" />
        </button>

        <PageInput
          value={currentPage}
          onChange={e => {
            const page = parseInt(e.target.value, 10);

            if (page > 0 && page <= totalPages) {
              setPageInput(page);
            }
          }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              onPageChange(pageInput);
            }
          }}
        />

        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <ArrowRight disabled={currentPage === totalPages} height="0.7rem" />
        </button>
      </ButtonsContainer>
    </Root>
  );
}
