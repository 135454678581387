import * as React from "react";
import { SettingsIcon, ToolTipWrapper } from "@genesys/ui-elements";
import { StyledAnchor } from "../elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../shared-state";

export function UserSettingsAnchor({
  sharedState
}: {
  readonly sharedState: SharedState.State;
}) {
  return (
    <ToolTipWrapper
      padding="0px"
      title={sharedState.translate(LanguageTexts.usersettings())}
    >
      <StyledAnchor href="/user-settings">
        <SettingsIcon />
      </StyledAnchor>
    </ToolTipWrapper>
  );
}
