//tslint:disable
import {
  ctorsUnion,
  CtorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../shared-state";
import * as GraphQLTypes from "../graphql-types";
import * as Types from "./types";
import * as HumSizeSelector from "./steps/size-selectors/hum-size-selector-step";
import * as EccSizeSelector from "./steps/size-selectors/ecc-size-selector-step";
import * as DhuSizeSelector from "./steps/size-selectors/dhu-size-selector-step";
import * as DataCenterSizeSelector from "./steps/size-selectors/data-center-size-selector-step";
import * as SystemSettings from "./steps/system-settings";
import * as InitialConfiguration from "./steps/initial-configuration";
import * as OperatingCases from "./steps/operating-cases";
import * as StepsRegistry from "./steps-registry";
import * as Navigation from "../navigation-effect-manager";
import gql from "graphql-tag";
import { PropertyValueSet } from "@genesys/property";
import moment from "moment";
import { isRegisteredSystemType } from "./steps-registry";

const createNewSystem = gql`
  mutation WizardCreateNewSystems($input: [CreateSystemInputType!]!) {
    createNewSystems(systemSettings: $input) {
      id
      genesysNo
      revisionNo
    }
  }
`;

export const init = (
  sharedState: SharedState.State,
  moistureLoadNo?: string
): readonly [Types.State, Cmd<Action>?, SharedState.Action?] => {
  const [state, cmd] = SystemSettings.init(sharedState, {
    newProperties: PropertyValueSet.Empty,
    ok: false,
    systemTypeId: isRegisteredSystemType(
      sharedState.user.lastCreatedSystemType || ""
    )
      ? sharedState.user.lastCreatedSystemType || ""
      : "",
    divergentTemplateComponentMap: {},
    labelManagerState: undefined,
    labelEditorIsopen: false,
    moistureLoadId: undefined,
    moistureLoadNo: moistureLoadNo,
    assignedLabels: [],
    climateSettings: PropertyValueSet.Empty,
    operatingCases: [],
    name: getSystemName(
      sharedState.user.userName,
      sharedState.user.settings.locale
    ),
    template: {
      climateDataDefaults: PropertyValueSet.Empty,
      components: []
    }
  });
  return [
    {
      activeSteps: [{ state: state, type: "system-settings" }],
      scroll: true,
      creatingSystem: false
    },
    Cmd.map(Action.dispatchSystemSettings, cmd),
    SharedState.Action.loadLastOpenedSystemsAndFavorites()
  ];
};

export const Action = ctorsUnion({
  next: () => ({}),
  finish: (createSystemInput: GraphQLTypes.CreateNewSystemsVariables) => ({
    createSystemInput
  }),
  redirect: (url: string) => ({ url }),
  dispatchSystemSettings: (action: SystemSettings.Action) => ({ action }),
  dispatchHumSizeSelector: (action: HumSizeSelector.Action) => ({ action }),
  dispatchEccSizeSelector: (action: EccSizeSelector.Action) => ({ action }),
  dispatchDhuSizeSelector: (action: DhuSizeSelector.Action) => ({ action }),
  dispatchDataCenterSizeSelector: (action: DataCenterSizeSelector.Action) => ({
    action
  }),
  dispatchInitialConfiguration: (action: InitialConfiguration.Action) => ({
    action
  }),
  dispatchOperatingCases: (action: OperatingCases.Action) => ({ action })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: Types.State,
  sharedState: SharedState.State
): readonly [
  Types.State | undefined,
  Cmd<Action>?,
  ReadonlyArray<SharedState.Action>?
] {
  const stepState = (
    state: Types.State,
    step: Types.Step,
    stepIndex: number
  ) => ({
    ...state,
    scroll: stepIndex < state.activeSteps.length - 1 ? false : state.scroll,
    activeSteps: state.activeSteps.slice(0, stepIndex).concat(step)
  });

  const changeSystemName = (
    state: Types.State,
    step: Types.Step,
    name: string
  ) => ({
    ...state,
    activeSteps: state.activeSteps.map(s => {
      switch (s.type) {
        case "data-center-size-selector":
          return { ...s, state: { ...s.state!, name: name } };
        case "dhu-size-selector":
          return { ...s, state: { ...s.state!, name: name } };
        case "hum-size-selector":
          return { ...s, state: { ...s.state!, name: name } };
        case "ecc-size-selector":
          return { ...s, state: { ...s.state!, name: name } };
        case "initial-configuration":
          return { ...s, state: { ...s.state!, name: name } };
        case "operating-cases":
          return { ...s, state: { ...s.state!, name: name } };
        case "system-settings":
          return step;
        default:
          exhaustiveCheck(s, true);
      }
    })
  });

  switch (action.type) {
    case "dispatchDataCenterSizeSelector": {
      const stepIndex = state.activeSteps.findIndex(
        s => s.type === "data-center-size-selector"
      );
      const step = state.activeSteps[
        stepIndex
      ] as Types.DataCenterSizeSelectorStep;
      const [newStep, cmd, sharedStateAction] = DataCenterSizeSelector.update(
        action.action,
        step.state
      );
      return [
        {
          ...state,
          activeSteps: state.activeSteps
            .slice(0, stepIndex)
            .concat({ state: newStep, type: "data-center-size-selector" })
        },
        Cmd.map(Action.dispatchDataCenterSizeSelector, cmd),
        sharedStateAction
      ];
    }

    case "dispatchDhuSizeSelector": {
      const stepIndex = state.activeSteps.findIndex(
        s => s.type === "dhu-size-selector"
      );
      const step = state.activeSteps[stepIndex] as Types.DhuSizeSelectorStep;
      const [newStep, cmd, sharedStateAction] = DhuSizeSelector.update(
        action.action,
        step.state
      );
      return [
        {
          ...state,
          activeSteps: state.activeSteps
            .slice(0, stepIndex)
            .concat({ state: newStep, type: "dhu-size-selector" })
        },
        Cmd.map(Action.dispatchDhuSizeSelector, cmd),
        sharedStateAction
      ];
    }
    case "dispatchHumSizeSelector": {
      const stepIndex = state.activeSteps.findIndex(
        s => s.type === "hum-size-selector"
      );
      const step = state.activeSteps[stepIndex] as Types.HumSizeSelectorStep;
      const [newStep, cmd, sharedStateAction] = HumSizeSelector.update(
        action.action,
        step.state
      );
      return [
        stepState(
          state,
          {
            state: newStep,
            type: "hum-size-selector"
          },
          stepIndex
        ),
        Cmd.map(Action.dispatchHumSizeSelector, cmd),
        sharedStateAction
      ];
    }
    case "dispatchEccSizeSelector": {
      const stepIndex = state.activeSteps.findIndex(
        s => s.type === "ecc-size-selector"
      );
      const step = state.activeSteps[stepIndex] as Types.EccSizeSelectorStep;
      const [newStep, cmd, sharedStateAction] = EccSizeSelector.update(
        action.action,
        step.state
      );
      return [
        stepState(
          state,
          {
            state: newStep,
            type: "ecc-size-selector"
          },
          stepIndex
        ),
        Cmd.map(Action.dispatchEccSizeSelector, cmd),
        sharedStateAction
      ];
    }
    case "dispatchSystemSettings": {
      const stepIndex = state.activeSteps.findIndex(
        s => s.type === "system-settings"
      );
      const step = state.activeSteps[stepIndex] as Types.SystemSettingsStep;
      const [newStep, cmd, sharedStateAction] = SystemSettings.update(
        action.action,
        step.state,
        sharedState
      );

      if (action.action.type !== "setName") {
        return [
          stepState(
            state,
            {
              state: newStep,
              type: "system-settings"
            },
            stepIndex
          ),
          Cmd.map(Action.dispatchSystemSettings, cmd),
          sharedStateAction
        ];
      } else {
        return [
          changeSystemName(
            state,
            {
              state: newStep,
              type: "system-settings"
            },
            newStep!.name
          ),
          Cmd.map(Action.dispatchSystemSettings, cmd),
          sharedStateAction
        ];
      }
    }
    case "dispatchInitialConfiguration": {
      const stepIndex = state.activeSteps.findIndex(
        s => s.type === "initial-configuration"
      );
      const step = state.activeSteps[
        stepIndex
      ] as Types.InitialConfigurationStep;
      const [newStep, cmd, sharedStateAction] = InitialConfiguration.update(
        action.action,
        step.state,
        sharedState
      );
      return [
        stepState(
          state,
          {
            state: newStep,
            type: "initial-configuration"
          },
          stepIndex
        ),
        Cmd.map(Action.dispatchInitialConfiguration, cmd),
        sharedStateAction
      ];
    }
    case "dispatchOperatingCases": {
      const stepIndex = state.activeSteps.findIndex(
        s => s.type === "operating-cases"
      );
      const step = state.activeSteps[stepIndex] as Types.OperatingCasesStep;
      const [newStep, cmd, sharedStateAction] = OperatingCases.update(
        action.action,
        step.state,
        sharedState
      );
      return [
        stepState(
          state,
          {
            state: newStep,
            type: "operating-cases"
          },
          stepIndex
        ),
        Cmd.map(Action.dispatchOperatingCases, cmd),
        sharedStateAction
      ];
    }
    case "next": {
      const currentStep = state.activeSteps[state.activeSteps.length - 1];
      const steps = StepsRegistry.steps(
        currentStep.state!.systemTypeId,
        currentStep.state!.newProperties
      );
      const nextStep = steps.steps[state.activeSteps.length - 1];

      const addStep = (step: Types.Step): Types.State => ({
        ...state,
        scroll: true,
        activeSteps: [...state.activeSteps, step]
      });

      switch (nextStep) {
        case "system-settings": {
          const [st, cm] = SystemSettings.init(sharedState, currentStep.state!);
          return [
            addStep({
              type: "system-settings",
              state: st
            }),
            Cmd.map(Action.dispatchSystemSettings, cm)
          ];
        }
        case "data-center-size-selector": {
          const [st, cm] = DataCenterSizeSelector.init(
            sharedState,
            currentStep.state!
          );
          return [
            addStep({ state: st, type: "data-center-size-selector" }),
            Cmd.map(Action.dispatchDataCenterSizeSelector, cm)
          ];
        }
        case "dhu-size-selector": {
          const [st, cm] = DhuSizeSelector.init(
            sharedState,
            currentStep.state!
          );
          return [
            addStep({ state: st, type: "dhu-size-selector" }),
            Cmd.map(Action.dispatchDhuSizeSelector, cm)
          ];
        }
        case "hum-size-selector": {
          const [st, cm] = HumSizeSelector.init(
            sharedState,
            currentStep.state!
          );
          return [
            addStep({ state: st, type: "hum-size-selector" }),
            Cmd.map(Action.dispatchHumSizeSelector, cm)
          ];
        }
        case "ecc-size-selector": {
          const [st, cm] = EccSizeSelector.init(
            sharedState,
            currentStep.state!
          );
          return [
            addStep({ state: st, type: "ecc-size-selector" }),
            Cmd.map(Action.dispatchEccSizeSelector, cm)
          ];
        }
        case "initial-configuration": {
          const [st, cm] = InitialConfiguration.init(
            sharedState,
            currentStep.state!
          );
          return [
            addStep({ state: st, type: "initial-configuration" }),
            Cmd.map(Action.dispatchInitialConfiguration, cm)
          ];
        }
        case "operating-cases": {
          const [st, cm] = OperatingCases.init(
            sharedState,
            currentStep.state!,
            steps.extraProps
          );
          return [
            addStep({ state: st, type: "operating-cases" }),
            Cmd.map(Action.dispatchOperatingCases, cm)
          ];
        }
        default:
          exhaustiveCheck(nextStep, true);
      }
    }
    case "finish":
      return [
        {
          ...state,
          creatingSystem: true
        },
        sharedState.graphQL.queryUserCmd<
          GraphQLTypes.WizardCreateNewSystems,
          GraphQLTypes.WizardCreateNewSystemsVariables,
          Action
        >(createNewSystem, action.createSystemInput, res => {
          const system = res.createNewSystems[0];
          return Action.redirect(
            `/system/${sharedState.genesysPrefix.genesysNo(
              system.genesysNo,
              system.revisionNo
            )}`
          );
        }),
        [
          SharedState.Action.updateCreateSystemType([
            (
              action.createSystemInput
                .input as GraphQLTypes.CreateSystemInputType[]
            )[0].systemTypeId as any
          ]),
          SharedState.Action.updateLastCreatedSystemType(
            (
              action.createSystemInput
                .input as GraphQLTypes.CreateSystemInputType[]
            )[0].systemTypeId as any
          )
        ]
      ];
    case "redirect":
      return [state, Navigation.pushUrl(action.url)];

    default:
      exhaustiveCheck(action, true);
  }
}

function getSystemName(userName: string, locale: string) {
  const lang = locale.split("-")[0];
  return `${userName}_${moment()
    .locale(lang)
    .format("L LTS")
    .replace(/\s/g, "_")}`;
}
