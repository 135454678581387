import * as React from "react";
import * as VisiblityTable from "./visibility-table";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as SharedState from "../../../shared-state";
import * as GraphQLTypes from "../../../graphql-types";
import styled from "styled-components";
import {
  ArrowDown,
  ArrowUp,
  AddComponent,
  EditBlue,
  Input,
  Label
} from "@genesys/ui-elements";
import { Action, AclMessage } from "../../state";
import { MenuMode } from "./types";
import { PopOverContainer } from "./shared";
import { ClaimsManager, publicClaim } from "./claims-manager";
import { Dispatch } from "@typescript-tea/core";
import { ButtonWithPopUp } from "./button-with-pop-up";

const MenuContentContainer = styled(PopOverContainer)`
  min-width: 30px;
  min-height: 90px;

  /* right: -100px; */
`;

const EllipsisP = styled.p<{ readonly lineClamp: number | "none" }>`
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;

  width: 220px;

  -webkit-line-clamp: ${props => props.lineClamp};
  -webkit-box-orient: vertical;
`;

const SearchInput = styled(Input)`
  height: 32px;
  width: 250px;
  padding-left: 6px;

  ::-webkit-input-placeholder {
    font-size: 12px;
  }
`;

const MessageP = styled.p<{ readonly isError: boolean }>`
  color: ${props => (props.isError ? "red" : "greeen")};
  margin-top: 10px;
  font-size: 13px;

  text-align: center;
`;

const ShowButton = styled.span`
  color: #00b1f7;
  cursor: pointer;
`;

const DeleteContainer = styled.span`
  height: 8px;
  width: 7px;
  border-radius: 10px;
  background-color: red;
  position: absolute;
  top: 0;
  right: 0;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  label {
    color: #ffffff;
  }
`;

const StyledLabel = styled(Label)`
  white-space: nowrap;
`;

const UserRowWithLineClamp = withLineClamp(UsersRow);
const ClaimRowWithLineClamp = withLineClamp(ClaimsRow);

export function VisbilityEditor({
  aclUsers,
  aclClaims,
  message,
  userInputSearchValue,
  disabled,
  sharedState,
  canChangeVisiblity,
  moistureLoadItem,
  dispatch
}: // toggle,
// setMenuMode
{
  readonly sharedState: SharedState.State;
  readonly disabled: boolean;
  readonly canChangeVisiblity: boolean;
  readonly message: AclMessage | undefined;
  readonly userInputSearchValue: string;
  readonly aclUsers: ReadonlyArray<string>;
  readonly aclClaims: ReadonlyArray<string>;
  readonly moistureLoadItem: NonNullable<
    GraphQLTypes.MoistureLoadCalculationUserQuery["user"]["moistureLoadByMoistureLoadNo"]
  >;
  readonly dispatch: Dispatch<Action>;
  readonly setMenuMode: React.Dispatch<React.SetStateAction<MenuMode>>;
}) {
  const userMoistureLoadClaims =
    Authorization.getClaimValues(
      sharedState.user.applicationClaims,
      Authorization.genesysUserClaims.mlcSalesOrgSearch
    ) || [];

  return (
    <MenuContentContainer>
      <VisiblityTable.TableContainer>
        <VisiblityTable.TableHeader>
          <VisiblityTable.HeaderItem>Subject</VisiblityTable.HeaderItem>
          <VisiblityTable.HeaderItem>Value</VisiblityTable.HeaderItem>
          <VisiblityTable.HeaderItem>Add/Edit</VisiblityTable.HeaderItem>
        </VisiblityTable.TableHeader>

        <VisiblityTable.TableBody>
          <OwnerRow
            owner={moistureLoadItem.moistureloadFile.owner.userName}
            sharedState={sharedState}
          />
          <UserRowWithLineClamp
            canEdit={canChangeVisiblity}
            aclUsers={aclUsers}
            disabled={disabled}
            dispatch={dispatch}
            message={message}
            userInputSearchValue={userInputSearchValue}
          />
          <ClaimRowWithLineClamp
            canEdit={canChangeVisiblity}
            possibleClaims={userMoistureLoadClaims}
            disabled={disabled}
            dispatch={dispatch}
            aclClaims={aclClaims}
          />
        </VisiblityTable.TableBody>
      </VisiblityTable.TableContainer>
    </MenuContentContainer>
  );
}

function OwnerRow({
  sharedState,
  owner
}: {
  readonly sharedState: SharedState.State;
  readonly owner: string;
}) {
  const you = sharedState.user.userName;
  return (
    <VisiblityTable.TableRow>
      <VisiblityTable.CellItem>
        <StyledLabel weight="bold">Owner</StyledLabel>
      </VisiblityTable.CellItem>
      <VisiblityTable.CellItem>
        <p>
          {owner} {you.toLowerCase() === owner.toLowerCase() && " (YOU)"}
        </p>
      </VisiblityTable.CellItem>
      <VisiblityTable.CellItem>
        <p></p>
      </VisiblityTable.CellItem>
    </VisiblityTable.TableRow>
  );
}

function UsersRow({
  aclUsers,
  message,
  userInputSearchValue,
  disabled,
  lineClamp,
  isOverflown,
  forwardRef,
  canEdit,
  dispatch,
  setLineClamp
}: {
  // readonly sharedState: SharedState.State;
  readonly disabled: boolean;
  readonly message: AclMessage | undefined;
  readonly userInputSearchValue: string;
  readonly aclUsers: ReadonlyArray<string>;
  readonly lineClamp: 3 | "none";
  readonly isOverflown: boolean;
  readonly forwardRef: React.RefObject<HTMLElement>;
  readonly canEdit: boolean;
  readonly dispatch: Dispatch<Action>;
  readonly setLineClamp: React.Dispatch<React.SetStateAction<3 | "none">>;
}) {
  const popupContent = (
    <div>
      <SearchInput
        value={userInputSearchValue}
        placeholder={"USERNAME OF USER"}
        disabled={disabled}
        onKeyDown={e => {
          if (e.keyCode === 13 && userInputSearchValue && !disabled) {
            const trimedUsers = aclUsers.map(x => x.trim().toUpperCase());
            const trimmedInputsearch = userInputSearchValue
              .trim()
              .toUpperCase();

            if (trimedUsers.includes(trimmedInputsearch)) {
              return dispatch(
                Action.setAclMessages({
                  type: "error",
                  message: "user already exist"
                })
              );
            }
            dispatch(
              Action.setAccesControlList([...trimedUsers, trimmedInputsearch])
            );
          }
        }}
        onChange={e => {
          if (message) {
            dispatch(Action.setAclMessages(undefined));
          }
          dispatch(Action.setUserInputSearch(e.target.value));
        }}
      />
      {message && (
        <MessageP isError={message.type === "error"}>
          {message.message}
        </MessageP>
      )}
    </div>
  );

  return (
    <VisiblityTable.TableRow>
      <VisiblityTable.CellItem>
        <StyledLabel weight="bold">Allowed Users</StyledLabel>
      </VisiblityTable.CellItem>
      {/* <UsersCell /> */}
      <VisiblityTable.CellItem>
        <EllipsisP ref={forwardRef as any} lineClamp={lineClamp}>
          {aclUsers.map((user, i) => (
            <React.Fragment key={user}>
              <SubjectValueItem
                disabled={!canEdit}
                deleteOnClick={() => {
                  const newusers = aclUsers.filter(x => x !== user);
                  dispatch(Action.setAccesControlList(newusers));
                }}
              >
                {user}
              </SubjectValueItem>
              {i < aclUsers.length - 1 ? <span>|</span> : ""}
            </React.Fragment>
          ))}
        </EllipsisP>
        <ShowText
          isOverflown={isOverflown}
          lineClamp={lineClamp}
          onShowLess={() => setLineClamp(3)}
          onShowMore={() => setLineClamp("none")}
        />
      </VisiblityTable.CellItem>

      <VisiblityTable.CellItem>
        <ButtonWithPopUp
          isDisabled={!canEdit}
          icon={<AddComponent disabled={!canEdit} height={"16px"} />}
          popupContent={popupContent}
        />
      </VisiblityTable.CellItem>
    </VisiblityTable.TableRow>
  );
}

function ClaimsRow({
  aclClaims,
  lineClamp,
  forwardRef,
  isOverflown,
  disabled,
  possibleClaims,
  canEdit,
  setLineClamp,
  dispatch
}: {
  readonly aclClaims: ReadonlyArray<string>;
  readonly disabled: boolean;
  readonly lineClamp: 3 | "none";
  readonly forwardRef: React.RefObject<HTMLElement>;
  readonly isOverflown: boolean;
  readonly possibleClaims: ReadonlyArray<string>;
  readonly canEdit: boolean;
  readonly dispatch: Dispatch<Action>;
  readonly setLineClamp: React.Dispatch<React.SetStateAction<number | "none">>;
}) {
  return (
    <VisiblityTable.TableRow>
      <VisiblityTable.CellItem>
        <StyledLabel weight="bold"> Allowed Claims</StyledLabel>
      </VisiblityTable.CellItem>
      <VisiblityTable.CellItem>
        <EllipsisP ref={forwardRef as any} lineClamp={lineClamp}>
          {aclClaims.map((x, i) => (
            <React.Fragment key={x}>
              {x}
              {i < aclClaims.length - 1 ? <span>|</span> : ""}
            </React.Fragment>
          ))}
        </EllipsisP>

        <ShowText
          isOverflown={isOverflown}
          lineClamp={lineClamp}
          onShowLess={() => setLineClamp(3)}
          onShowMore={() => setLineClamp("none")}
        />
      </VisiblityTable.CellItem>
      <VisiblityTable.CellItem>
        <ButtonWithPopUp
          isDisabled={!canEdit}
          icon={<EditBlue disabled={!canEdit} height={"13px"} />}
          popupContent={
            <ClaimsManager
              canEdit={canEdit}
              disabled={disabled}
              dispatch={dispatch}
              aclClaims={aclClaims}
              allClaimValues={Array.from(
                new Set([
                  ...aclClaims
                    .filter(x => x !== publicClaim)
                    .filter(x => x !== "*"),
                  ...possibleClaims
                ])
              )}
            />
          }
        />
      </VisiblityTable.CellItem>
      {/* <ClaimsCell />
      <EditCell /> */}
    </VisiblityTable.TableRow>
  );
}

function SubjectValueItem({
  children,
  disabled,
  deleteOnClick
}: {
  readonly children: React.ReactNode;
  readonly disabled: boolean;
  readonly deleteOnClick: () => void;
}) {
  return (
    <span
      style={{
        position: "relative",
        display: "inline-block",
        paddingRight: !disabled ? "10px" : "0"
      }}
    >
      <label style={{ padding: 0 }}>{children} </label>
      {!disabled && (
        <DeleteContainer
          onClick={() => {
            if (disabled) {
              return;
            }
            deleteOnClick();
          }}
        ></DeleteContainer>
      )}
    </span>
  );
}

function withLineClamp<T>(WrappedComponent: React.ComponentType<T>) {
  return (
    withLineProps: Omit<
      T,
      "setLineClamp" | "lineClamp" | "isOverflown" | "forwardRef"
    >
  ) => {
    const [isOverflown, setIsOverflown] = React.useState(false);
    const [lineClamp, setLineClamp] = React.useState<3 | "none">(3);

    const ref = React.useRef<HTMLElement>(null);

    React.useEffect(() => {
      if (!ref.current) {
        setIsOverflown(false);
        return;
      }
      if (ref.current.scrollHeight > ref.current.clientHeight) {
        setIsOverflown(true);
      }
    }, [ref]);

    return (
      <WrappedComponent
        forwardRef={ref}
        isOverflown={isOverflown}
        lineClamp={lineClamp}
        setLineClamp={setLineClamp}
        {...(withLineProps as T)}
      />
    );
  };
}

function ShowText({
  isOverflown,
  lineClamp,
  onShowLess,
  onShowMore
}: {
  readonly isOverflown: boolean;
  readonly lineClamp: number | "none";
  readonly onShowLess: () => void;
  readonly onShowMore: () => void;
}) {
  if (!isOverflown) {
    return null;
  }

  if (lineClamp === "none") {
    return (
      <>
        <ShowButton
          onClick={() => {
            onShowLess();
          }}
        >
          show less
        </ShowButton>
        <ArrowUp />
      </>
    );
  } else {
    return (
      <>
        <ShowButton
          onClick={() => {
            onShowMore();
          }}
        >
          show more
        </ShowButton>
        <ArrowDown />
      </>
    );
  }
}
