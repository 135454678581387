import * as SharedEnergyTools from "@genesys/shared/lib/energy-tools";
import { Amount, Quantity, Units, Serialize } from "@genesys/uom";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "../state";
import { Physics } from "@munters/calculations";

export function importClimateBinCases(
  data: ReadonlyArray<ReadonlyArray<string>>,
  dispatch: Dispatch<Action>
) {
  if (data.length === 0) {
    return;
  }

  let binCases: Array<SharedEnergyTools.BinCase> = [];

  switch (data[0].length) {
    case 7: {
      binCases = parseStandardClimateBinCases(data);
      break;
    }
    case 18: {
      binCases = parseClimateBinCasesWithMonthlyHours(data);
      break;
    }
    default:
      return;
  }

  dispatch(Action.setBinCases(binCases));
}

function parseStandardClimateBinCases(
  data: ReadonlyArray<ReadonlyArray<string>>
): Array<SharedEnergyTools.BinCase> {
  const validRows = getValidRows(data);
  const unitStrFromHeader = (header: string) =>
    new RegExp(/\(([^)]+)\)/).exec(header)![1];
  const midPointUnit = Serialize.stringToUnit(
    unitStrFromHeader(validRows[0][1])
  )!;
  const tempUnit = Serialize.stringToUnit<Quantity.Temperature>(
    unitStrFromHeader(validRows[0][2])
  )!;
  const humidityUnit = Serialize.stringToUnit<
    Quantity.HumidityRatio | Quantity.RelativeHumidity
  >(unitStrFromHeader(validRows[0][3]))!;
  const binPressureUnit = Serialize.stringToUnit<Quantity.Pressure>(
    unitStrFromHeader(validRows[0][5])
  )!;
  const timeUnit = Serialize.stringToUnit<Quantity.Duration>(
    unitStrFromHeader(validRows[0][6])
  )!;

  return validRows.slice(1).map(d => {
    const binId = parseFloat(d[0]);
    const midPointTemp =
      midPointUnit.quantity === "Temperature"
        ? Amount.create(parseFloat(d[1]), midPointUnit)
        : undefined;
    const midPointWetTemp =
      midPointUnit.quantity === "WetTemperature"
        ? Amount.create(parseFloat(d[1]), midPointUnit)
        : undefined;
    const midPointDewPointTemp =
      midPointUnit.quantity === "DewPointTemperature"
        ? Amount.create(parseFloat(d[1]), midPointUnit)
        : undefined;
    const midPointSpecificEnthalpy =
      midPointUnit.quantity === "SpecificEnthalpy"
        ? Amount.create(parseFloat(d[1]), midPointUnit)
        : undefined;

    const midPointHumidityRatio =
      midPointUnit.quantity === "HumidityRatio"
        ? Amount.create(parseFloat(d[1]), midPointUnit)
        : undefined;

    const averageTemperature = Amount.create(parseFloat(d[2]), tempUnit);
    const binPressure = Amount.create(parseFloat(d[5]), binPressureUnit);
    const averageHumidity = parseAverageHumidity(
      Amount.create(parseFloat(d[3]), humidityUnit),
      binPressure,
      averageTemperature
    );

    return {
      binId: binId,
      midPointHumidityRatio,
      midPointTemp: midPointTemp,
      midPointWetTemp: midPointWetTemp,
      midPointDewPointTemp: midPointDewPointTemp,
      midPointSpecificEnthalpy: midPointSpecificEnthalpy,
      averageTemperature: averageTemperature,
      averageHumidity: averageHumidity,
      binTime: Amount.create(parseFloat(d[6]), timeUnit),
      binPressure: binPressure
    } as SharedEnergyTools.BinCase;
  });
}

function getValidRows(data: ReadonlyArray<ReadonlyArray<string>>) {
  const validRows: Array<ReadonlyArray<string>> = [];
  // Pushing headers
  validRows.push(data[0]);

  let midPointNo = 0;
  for (const row of data.slice(1)) {
    const rowWithoutMidpoint = row.slice(1);
    if (rowWithoutMidpoint.every(x => !isNaN(parseFloat(x)))) {
      validRows.push([midPointNo.toString(), ...rowWithoutMidpoint]);
      midPointNo++;
    }
  }
  return validRows;
}

function parseClimateBinCasesWithMonthlyHours(
  data: ReadonlyArray<ReadonlyArray<string>>
): Array<SharedEnergyTools.BinCase> {
  const unitStrFromHeader = (header: string) =>
    new RegExp(/\(([^)]+)\)/).exec(header)![1];

  const midPointUnit = Serialize.stringToUnit(unitStrFromHeader(data[0][1]))!;
  const tempUnit = Serialize.stringToUnit<Quantity.Temperature>(
    unitStrFromHeader(data[0][2])
  )!;
  const humidityUnit = Serialize.stringToUnit<
    Quantity.HumidityRatio | Quantity.RelativeHumidity
  >(unitStrFromHeader(data[0][3]))!;
  const binPressureUnit = Serialize.stringToUnit<Quantity.Pressure>(
    unitStrFromHeader(data[0][5])
  )!;
  const timeUnit = Serialize.stringToUnit<Quantity.Duration>(
    unitStrFromHeader(data[0][6])
  )!;

  return data.slice(1).map(d => {
    const binId = parseFloat(d[0]);
    const midPointTemp =
      midPointUnit.quantity === "Temperature"
        ? Amount.create(parseFloat(d[1]), midPointUnit)
        : undefined;
    const midPointWetTemp =
      midPointUnit.quantity === "WetTemperature"
        ? Amount.create(parseFloat(d[1]), midPointUnit)
        : undefined;
    const midPointDewPointTemp =
      midPointUnit.quantity === "DewPointTemperature"
        ? Amount.create(parseFloat(d[1]), midPointUnit)
        : undefined;
    const midPointSpecificEnthalpy =
      midPointUnit.quantity === "SpecificEnthalpy"
        ? Amount.create(parseFloat(d[1]), midPointUnit)
        : undefined;

    const binTimeJanuary = Amount.create(parseFloat(d[6]), timeUnit);
    const binTimeFebruary = Amount.create(parseFloat(d[7]), timeUnit);
    const binTimeMarch = Amount.create(parseFloat(d[8]), timeUnit);
    const binTimeApril = Amount.create(parseFloat(d[9]), timeUnit);
    const binTimeMay = Amount.create(parseFloat(d[10]), timeUnit);
    const binTimeJune = Amount.create(parseFloat(d[11]), timeUnit);
    const binTimeJuly = Amount.create(parseFloat(d[12]), timeUnit);
    const binTimeAugust = Amount.create(parseFloat(d[13]), timeUnit);
    const binTimeSeptember = Amount.create(parseFloat(d[14]), timeUnit);
    const binTimeOctober = Amount.create(parseFloat(d[15]), timeUnit);
    const binTimeNovember = Amount.create(parseFloat(d[16]), timeUnit);
    const binTimeDecember = Amount.create(parseFloat(d[17]), timeUnit);
    const binTimeTotal = Amount.create(
      Amount.valueAs(Units.Hour, binTimeJanuary) +
        Amount.valueAs(Units.Hour, binTimeFebruary) +
        Amount.valueAs(Units.Hour, binTimeMarch) +
        Amount.valueAs(Units.Hour, binTimeApril) +
        Amount.valueAs(Units.Hour, binTimeMay) +
        Amount.valueAs(Units.Hour, binTimeJune) +
        Amount.valueAs(Units.Hour, binTimeJuly) +
        Amount.valueAs(Units.Hour, binTimeAugust) +
        Amount.valueAs(Units.Hour, binTimeSeptember) +
        Amount.valueAs(Units.Hour, binTimeOctober) +
        Amount.valueAs(Units.Hour, binTimeNovember) +
        Amount.valueAs(Units.Hour, binTimeDecember),
      Units.Hour
    );

    const averageTemperature = Amount.create(parseFloat(d[2]), tempUnit);
    const binPressure = Amount.create(parseFloat(d[5]), binPressureUnit);
    const averageHumidity = parseAverageHumidity(
      Amount.create(parseFloat(d[3]), humidityUnit),
      binPressure,
      averageTemperature
    );

    return {
      binId: binId,
      midPointTemp: midPointTemp,
      midPointWetTemp: midPointWetTemp,
      midPointDewPointTemp: midPointDewPointTemp,
      midPointSpecificEnthalpy: midPointSpecificEnthalpy,
      averageTemperature: averageTemperature,
      averageHumidity: averageHumidity,
      binTime: binTimeTotal,
      binPressure: binPressure,
      binTimeJanuary: binTimeJanuary,
      binTimeFebruary: binTimeFebruary,
      binTimeMarch: binTimeMarch,
      binTimeApril: binTimeApril,
      binTimeMay: binTimeMay,
      binTimeJune: binTimeJune,
      binTimeJuly: binTimeJuly,
      binTimeAugust: binTimeAugust,
      binTimeSeptember: binTimeSeptember,
      binTimeOctober: binTimeOctober,
      binTimeNovember: binTimeNovember,
      binTimeDecember: binTimeDecember
    } as SharedEnergyTools.BinCase;
  });
}

function parseAverageHumidity(
  averageHumidity: Amount.Amount<"HumidityRatio" | "RelativeHumidity">,
  binPressure: Amount.Amount<"Pressure">,
  averageTemperature: Amount.Amount<"Temperature">
) {
  if (averageHumidity.unit.quantity === "HumidityRatio") {
    return averageHumidity;
  } else {
    return Physics.RP1485.AmountConversions.relativeHumidityToHumidityRatio(
      binPressure,
      averageTemperature,
      averageHumidity as Amount.Amount<Quantity.RelativeHumidity>
    );
  }
}
