import * as React from "react";
import styled from "styled-components";
import { StandardButton, S2, GenesysSelect } from "@genesys/ui-elements";
import * as SharedState from "../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { NewArticleState, NewPricingRow, PricingArticle } from "../../types";
import * as Guid from "@genesys/shared/lib/guid";
import { InputContainer, genesysSelectStylingProps } from "../../elements";

interface ArticleSelectorProps {
  readonly sharedState: SharedState.State;
  readonly newArticleState: NewArticleState;
  readonly pricingArticles: ReadonlyArray<PricingArticle>;
  readonly rowNo: number;
  readonly onChange: (newArticleState: NewArticleState) => void;
  readonly onAddRow: (row: NewPricingRow) => void;
}

const Root = styled.div`
  display: flex;
  align-items: flex-end;
`;

export function ArticleSelector({
  sharedState,
  newArticleState,
  rowNo,
  pricingArticles,
  onAddRow,
  onChange
}: ArticleSelectorProps) {
  const onArticleChange = (articleId: string) => {
    onChange({ ...newArticleState, selectedArticle: articleId });
  };

  return (
    <Root>
      <InputContainer>
        <S2 weight="normal" color="dark">
          {sharedState.translate(LanguageTexts.articleNo())}
        </S2>
        <GenesysSelect
          {...genesysSelectStylingProps}
          value={newArticleState.selectedArticle}
          onChange={e => onArticleChange(e.target.value)}
          options={pricingArticles.map(pa => ({
            title: pa.articleNo,
            value: pa.id
          }))}
        />
      </InputContainer>

      <StandardButton
        buttonType="PrimaryBlue"
        size="Small"
        onClick={() => {
          const article = pricingArticles.find(
            pa => pa.id === newArticleState.selectedArticle
          );
          if (!article) {
            return;
          }

          // TODO ask whu transferprice factor needs to be calculated here

          onAddRow({
            row: {
              id: Guid.guidToString(Guid.createGuid()),
              rowNo: rowNo,
              articleNo: article.articleNo,
              description: article.description,
              pid: undefined,
              quantity: article.quantity,
              unit: article.unit,
              costPerUnit: article.costPerUnit,
              listPriceFactor:
                article.listPricePerUnit / article.transferPricePerUnit,
              transferPriceFactor:
                article.transferPricePerUnit / article.costPerUnit,
              masterPriceFactor:
                article.transferPricePerUnit / article.costPerUnit,
              transferPricePerUnit: article.transferPricePerUnit,
              listPricePerUnit: article.listPricePerUnit,
              masterPricePerUnit: undefined,
              systemId: undefined,
              systemTypeId: undefined,
              type: "extra",
              visualizerCode: ""
            },
            currencyCode: article.currencyCode,
            masterCurrencyCode: article.currencyCode,
            exchangeRateTemplateId: undefined,
            salesOrganisationId: undefined,
            masterSalesOrganisationId: undefined
          });
        }}
      >
        {sharedState.translate(LanguageTexts.add())}
      </StandardButton>
    </Root>
  );
}
