import React from "react";
import styled from "../../styled-components";

// tslint:disable-next-line
const arrowUp = require("ui-elements/assets/table-drop-down/arrow-down.svg");
// tslint:disable-next-line
const arrowDown = require("ui-elements/assets/button/arrow-up.svg");

const MenuButtonWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: #8f9bb3;
  font-weight: 300;
  letter-spacing: 0;
  background: #ffffff;
  margin-bottom: 0.1em;
  color: #8f9bb3;
  line-height: 17px;
  height: 24px;

  > label {
    color: #00b1f7;
    font-family: Futura;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-right: 7px;
    cursor: pointer;

    &:hover {
      color: #4bccff;
    }
  }

  img {
    text-align: center;
    margin-right: 7px;
    height: 5px;
    width: 10px;
    cursor: pointer;
  }
`;

export function MenuButton(props: {
  readonly onClick?: () => void;
  readonly hasMenu: boolean;
  readonly show?: boolean;
  readonly isOpen?: boolean;
  readonly children: React.ReactNode;
}): JSX.Element | null {
  const { onClick, hasMenu, children, isOpen, show = true } = props;

  if (!show) {
    return null;
  }

  function onClickHandler(event: React.MouseEvent<HTMLElement>) {
    if (onClick) {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }
  }
  return (
    <MenuButtonWrapper onClick={onClickHandler}>
      <label>{children}</label>
      {hasMenu && <img src={isOpen ? arrowDown : arrowUp}></img>}
    </MenuButtonWrapper>
  );
}
