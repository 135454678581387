import * as React from "react";
import styled from "styled-components";
import { genesysSelectStylingProps } from "../elements";
import * as SharedState from "../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { ReportSettings, Pricing } from "../types";
import {
  S2,
  StandardButton,
  CheckBox,
  GenesysSelect
} from "@genesys/ui-elements";
import * as Authorization from "@genesys/shared/lib/authorization";
import { clientConfig } from "../../config";
import * as FetchDocument from "@genesys/client-core/lib/fetch-document";

interface ReportsComponentProps {
  readonly sharedState: SharedState.State;
  readonly reportSettings: ReportSettings;
  readonly unsavedContent: boolean;
  readonly languageIds: ReadonlyArray<string>;
  readonly pricing: Pricing;
  readonly pricingNoPrefix: string;
  readonly onReportSettingsChange: (reportSettings: ReportSettings) => void;
  readonly setLoader: (showLoader: boolean) => void;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.15);
`;

const InfoContainer = styled.div`
  padding: 10px;
`;

const CheckboxesOuterContainer = styled.div`
  display: flex;
  padding-top: 5px;
`;

const CheckboxesInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const BottomContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  h5 {
    margin-bottom: 6px;
  }
  padding: 0 10px 10px 10px;
`;

export function ReportsComponent({
  sharedState,
  reportSettings,
  unsavedContent,
  languageIds,
  pricing,
  pricingNoPrefix,
  setLoader,
  onReportSettingsChange
}: ReportsComponentProps) {
  const routeEndpoint = clientConfig.genesysBackend;

  const completePricingNo =
    pricingNoPrefix + pricing.pricingNo + "-" + pricing.revisionNo;

  const params: { readonly [key: string]: string } = {
    id: pricing.id,
    includeCost: reportSettings.includeCost.toString(),
    includeTransfer: reportSettings.includeTransfer.toString(),
    includeList: reportSettings.includeList.toString(),
    includePerUnit: reportSettings.includePerUnit.toString(),
    includeSubtotal: reportSettings.includeSubtotal.toString(),
    includeTotal: reportSettings.includeTotal.toString(),
    culture: reportSettings.culture
  };

  return (
    <Root>
      <S2 weight="bold" color="dark">
        {sharedState.translate(LanguageTexts.pricingReport())}
      </S2>

      {unsavedContent ? (
        <InfoContainer>
          <S2 weight="normal" color="dark">
            {sharedState.translate(LanguageTexts.savePricingToCreateReport())}
          </S2>
        </InfoContainer>
      ) : (
        <div>
          <CheckboxesOuterContainer>
            <CheckboxesInnerContainer>
              {Authorization.checkPermission(
                sharedState.user.applicationClaims,
                Authorization.genesysPricingClaims.canSeePricingCost
              ) && (
                <CheckBox
                  children={sharedState.translate(LanguageTexts.includeCost())}
                  isChecked={reportSettings.includeCost}
                  onClick={() =>
                    onReportSettingsChange({
                      ...reportSettings,
                      includeCost: !reportSettings.includeCost
                    })
                  }
                />
              )}
              {Authorization.checkPermission(
                sharedState.user.applicationClaims,
                Authorization.genesysPricingClaims.canSeePricing
              ) && (
                <CheckBox
                  children={sharedState.translate(
                    LanguageTexts.includeTransferPrice()
                  )}
                  isChecked={reportSettings.includeTransfer}
                  onClick={() =>
                    onReportSettingsChange({
                      ...reportSettings,
                      includeTransfer: !reportSettings.includeTransfer
                    })
                  }
                />
              )}

              {Authorization.checkPermission(
                sharedState.user.applicationClaims,
                Authorization.genesysPricingClaims.CanSeePricingListPrice
              ) && (
                <CheckBox
                  children={sharedState.translate(
                    LanguageTexts.includeListPrice()
                  )}
                  isChecked={reportSettings.includeList}
                  onClick={() =>
                    onReportSettingsChange({
                      ...reportSettings,
                      includeList: !reportSettings.includeList
                    })
                  }
                />
              )}
            </CheckboxesInnerContainer>
            <CheckboxesInnerContainer>
              {(Authorization.checkPermission(
                sharedState.user.applicationClaims,
                Authorization.genesysPricingClaims.CanSeePricingTransferPrice
              ) ||
                Authorization.checkPermission(
                  sharedState.user.applicationClaims,
                  Authorization.genesysPricingClaims.CanSeePricingListPrice
                )) && (
                <CheckBox
                  children={sharedState.translate(
                    LanguageTexts.includePricePerUnit()
                  )}
                  isChecked={reportSettings.includePerUnit}
                  disabled={
                    !reportSettings.includeList &&
                    !reportSettings.includeTransfer
                  }
                  onClick={() =>
                    onReportSettingsChange({
                      ...reportSettings,
                      includePerUnit: !reportSettings.includePerUnit
                    })
                  }
                />
              )}
              {(Authorization.checkPermission(
                sharedState.user.applicationClaims,
                Authorization.genesysPricingClaims.CanSeePricingTransferPrice
              ) ||
                Authorization.checkPermission(
                  sharedState.user.applicationClaims,
                  Authorization.genesysPricingClaims.CanSeePricingListPrice
                )) && (
                <CheckBox
                  children={sharedState.translate(
                    LanguageTexts.includePriceSubtotal()
                  )}
                  isChecked={reportSettings.includeSubtotal}
                  disabled={
                    !reportSettings.includeList &&
                    !reportSettings.includeTransfer
                  }
                  onClick={() =>
                    onReportSettingsChange({
                      ...reportSettings,
                      includeSubtotal: !reportSettings.includeSubtotal
                    })
                  }
                />
              )}
              {Authorization.checkPermission(
                sharedState.user.applicationClaims,
                Authorization.genesysPricingClaims.canSeePricing
              ) && (
                <CheckBox
                  children={sharedState.translate(
                    LanguageTexts.includeTotalPrice()
                  )}
                  isChecked={reportSettings.includeTotal}
                  disabled={
                    !reportSettings.includeList &&
                    !reportSettings.includeTransfer
                  }
                  onClick={() =>
                    onReportSettingsChange({
                      ...reportSettings,
                      includeTotal: !reportSettings.includeTotal
                    })
                  }
                />
              )}
            </CheckboxesInnerContainer>
          </CheckboxesOuterContainer>

          <BottomContainer>
            <div>
              <S2 weight="normal" color="dark">
                {sharedState.translate(LanguageTexts.language())}
              </S2>
              <GenesysSelect
                {...genesysSelectStylingProps}
                value={reportSettings.culture}
                options={languageIds.map(l => ({
                  value: l,
                  title: sharedState.translate(LanguageTexts.languageId(l))
                }))}
                onChange={e =>
                  onReportSettingsChange({
                    ...reportSettings,
                    culture: e.target.value
                  })
                }
              />
            </div>
            <FetchDocument.FetchDocumentComponent
              authentication={{
                type: "Bearer",
                accessToken: sharedState.accessToken
              }}
              url={`${routeEndpoint}/internal/GetPricingReportDocument`}
              params={params}
              filename={completePricingNo + ".pdf"}
              onComplete={() => {
                setLoader(false);
              }}
              type={"download"}
            >
              {onClick => (
                <StandardButton
                  size="Small"
                  buttonType="PrimaryBlue"
                  onClick={() => {
                    setLoader(true);
                    onClick();
                  }}
                >
                  {sharedState.translate(LanguageTexts.download())}
                </StandardButton>
              )}
            </FetchDocument.FetchDocumentComponent>
          </BottomContainer>
        </div>
      )}
    </Root>
  );
}
