import * as React from "react";
import { P1 } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { PropertyValueSet } from "@genesys/property";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../shared-state";
import * as System from "../../../system";
import * as Product from "../../../product";
import * as Diagram from "../../../components/diagram";
import * as PerformanceOverview from "../../../performance-overview";
import { State, Action } from "../state";
import { CenteringContainer } from "../elements";

export function PerformanceView({
  state,
  system,
  productData,
  sharedState,
  sysProperties,
  dispatch
}: {
  readonly state: State;
  readonly system: System.System;
  readonly sharedState: SharedState.State;
  readonly productData: Product.ProductData;
  readonly sysProperties: PropertyValueSet.PropertyValueSet;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  // tslint:disable-next-line
  const emptyFunc = () => {};

  return (
    <div>
      {state.hasCalculationFailed && (
        <CenteringContainer>
          <P1 weight="bold">
            {sharedState.translate(
              LanguageTexts.notPossibleToCalculateSystem()
            )}
          </P1>
        </CenteringContainer>
      )}

      {!state.hasCalculationFailed && (
        <div>
          <Diagram.Diagram
            system={system}
            productData={productData}
            sharedState={sharedState}
            mode={{ type: "DefaultMode" }}
            diagramHoverState={"no-hover"}
            selectedAirPosition={undefined}
            claimFilteredComponentsMessages={[]}
            symbols={productData.systemType.symbols}
            openNotes={emptyFunc}
            addToDiagram={emptyFunc}
            editComponent={emptyFunc}
            moveComponent={emptyFunc}
            selectComponent={emptyFunc}
            openAccessories={emptyFunc}
            deleteComponent={emptyFunc}
            exchangeComponent={emptyFunc}
            selectComponentMenu={emptyFunc}
            setDiagramHoverState={emptyFunc}
            moveComponentSection={emptyFunc}
            setGenericDialogState={emptyFunc}
            setSelectedAirPosition={emptyFunc}
          />
          <PerformanceOverview.PerformanceOverviewView
            systemType={productData}
            sharedState={sharedState}
            sysProperties={sysProperties}
            selectedAirPosition={undefined}
            state={state.performanceOverviewState}
            systemItem={{
              ...system,
              operatingCases: state.operatingCaseResults
            }}
            onAirPositionClick={emptyFunc}
            dispatch={Dispatch.map(Action.dispatchPeformanceOverview, dispatch)}
          />
        </div>
      )}
    </div>
  );
}
