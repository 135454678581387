import * as LanguageTexts from "../language-texts";
import * as DhuSystemTypeInformation from "./dhu-system-type-information";
import * as HcxSystemTypeInformation from "./hcx-system-type-information";
import * as EpxSystemTypeInformation from "./epx-system-type-information";
import * as HumSystemTypeInformation from "./hum-system-type-information";
import * as McdSystemTypeInformation from "./mcd-system-type-information";

export function getSystemTypeStatusText(
  translate: LanguageTexts.Translate,
  components: ReadonlyArray<{
    readonly productId: string;
    readonly properties?: string | null;
  }>,
  systemType: {
    readonly id: string;
    readonly plenumSizeProperty: string;
  }
): string {
  switch (systemType.id) {
    case "LDP":
    case "BXA":
    case "DSP":
    case "DSS":
    case "ERD":
    case "HCD":
    case "ICA":
    case "MCA":
    case "MCX":
    case "MLP":
    case "MXN":
    case "MXO":
    case "NA2":
    case "DES":
    case "PST": {
      return DhuSystemTypeInformation.getText(
        translate,
        getSysComponentProperties(systemType.id, components),
        getDhuComponentProperties(components),
        systemType.id,
        systemType.plenumSizeProperty
      );
    }
    case "HCX": {
      return HcxSystemTypeInformation.getText(
        translate,
        getDhuComponentProperties(components),
        systemType.id
      );
    }
    case "DCA": {
      return EpxSystemTypeInformation.getText(
        translate,
        components.find(c => c.productId === `${systemType.id}EPX`)
          ?.properties || undefined,
        systemType.id
      );
    }
    case "FAA":
    case "FAU":
    case "HUM": {
      return HumSystemTypeInformation.getText(
        translate,
        getSysComponentProperties(systemType.id, components),
        systemType.id
      );
    }
    case "MAA":
    case "MAB":
    case "MCD": {
      return McdSystemTypeInformation.getText(
        translate,
        getSysComponentProperties(systemType.id, components),
        getDhuComponentProperties(components),
        systemType.id
      );
    }

    default: {
      return "";
    }
  }
}

function getSysComponentProperties(
  systemTypeId: string,
  components: ReadonlyArray<{
    readonly productId: string;
    readonly properties?: string | null;
  }>
): string | undefined {
  const sysComponent = components.find(
    c => c.productId === `${systemTypeId}SYS`
  );
  return sysComponent?.properties || "";
}

const dhuRegex = new RegExp(/\w+?(DHU|HCU|DHS)/, "i");
function getDhuComponentProperties(
  components: ReadonlyArray<{
    readonly productId: string;
    readonly properties?: string | null;
  }>
): string | undefined {
  const dhuComponent = components.find(c => dhuRegex.test(c.productId));
  return dhuComponent?.properties || "";
}
