import * as React from "react";
import * as SharedState from "../../../../shared-state";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as GraphQLTypes from "../../../../graphql-types";
import styled from "styled-components";
import { PropertyValueSet, PropertyValue } from "@genesys/property";
import * as ScreenAmounts from "@genesys/shared/lib/screen-amounts";
import { AmountFormatSelector } from "../../../../amount-format-selector";
import { MoistureLoadReadOnlyTable } from "../shared/read-only-table";
import { Unit, Quantity } from "@genesys/uom";
import { AmountFormat, getValue } from "@genesys/shared/lib/product-properties";
import {
  AlertAccordion,
  TableCard,
  StandardButton,
  OverlayLoader
} from "@genesys/ui-elements";
import { State, Action, defaultFlowIndex } from "../state";
import { Dispatch } from "@typescript-tea/core";
import { PopUp } from "../../../components";
import { hasPermissionForMlc } from "../../../tools";
import { MoistureLoadStatus } from "@genesys/shared/lib/enums/moisture-load-status";
import * as Authorization from "@genesys/shared/lib/authorization";

const Container = styled.div``;

const FlexContainer = styled.div`
  display: flex;
`;

const StyledDiv = styled.div`
  > * {
    margin-top: 1em;
    margin-bottom: 1em;
  }
`;

const Label = styled.span`
  color: #023442;
  margin-bottom: 10px;
  font-size: 17px;
  display: inline-block;
`;

const StyledLabel = styled.span`
  color: #00b1f7;
  font-size: 15px;
  display: block;
  margin-bottom: 7px;
`;

const SubContainer = styled.div`
  margin-bottom: 10px;
`;

const TableContainer = styled.div`
  margin-right: 15px;
`;

const ButtonContainer = styled.div`
  margin-bottom: 10px;

  > button {
    margin-right: 10px;
  }
`;

const fieldGroupName = "MoistureLoadResult.FlowSuggestions";

interface Result {
  readonly id: number;
  readonly valid: boolean;
  readonly propertyValueSet: PropertyValueSet.PropertyValueSet;
  readonly flowSourceId: GraphQLTypes.FlowSourceEnum;
}

export function View({
  maxResults,
  sharedState,
  state,
  moistureLoadItem,
  disableOpcButtons,
  dispatch,
  onFormatChanged,
  onFormatCleared
}: {
  readonly maxResults: ReadonlyArray<
    GraphQLTypes.MoistureLoadResultFragment["maxLoadResults"][0]
  >;
  readonly moistureLoadItem: NonNullable<
    GraphQLTypes.MoistureLoadCalculationUserQuery["user"]["moistureLoadByMoistureLoadNo"]
  >;
  readonly state: State;
  readonly disableOpcButtons: boolean;
  readonly sharedState: SharedState.State;
  readonly dispatch: Dispatch<Action>;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
}) {
  if (
    !maxResults.length ||
    maxResults.every(mr => {
      return !mr.flowSuggestions.length;
    })
  ) {
    return (
      <StyledDiv>
        <p className="noflowsuggestions">
          {sharedState.translate(
            LanguageTexts.globalPropertyName(
              "mlcnosystemflowsuggestionsavailable"
            )
          )}
        </p>
      </StyledDiv>
    );
  }

  const opcaseResults: Array<{
    readonly caseName: string;
    readonly settings: string;
  }> = [];

  const moistureLoadNo = moistureLoadItem.moistureloadFile.moistureLoadNo;
  const revNo = moistureLoadItem.revisionNo;

  const canSeeOpcButtons = hasPermissionForMlc(
    sharedState.user.applicationClaims
  );
  const canSeeDrySizeButton =
    Authorization.checkPermission(
      sharedState.user.applicationClaims,
      Authorization.genesysUserClaims.developer
    ) ||
    Authorization.checkPermission(
      sharedState.user.applicationClaims,
      Authorization.genesysUserClaims.canUseDrySize
    );
  const canSeeSystemSelectionGuideButton =
    Authorization.checkPermission(
      sharedState.user.applicationClaims,
      Authorization.genesysUserClaims.developer
    ) ||
    Authorization.checkPermission(
      sharedState.user.applicationClaims,
      Authorization.genesysUserClaims.canUseSystemSelectionGuide
    );

  const isLocked = moistureLoadItem.status === MoistureLoadStatus.LockSuccess;

  const isDeleted = moistureLoadItem.moistureloadFile.isDeleted;
  return (
    <Container>
      {state.useOverlay && <OverlayLoader />}
      {canSeeOpcButtons && !isDeleted && (
        <ButtonContainer>
          <PopUp
            canShowPopUp={disableOpcButtons}
            content={
              <div>
                {sharedState.translate(LanguageTexts.mlcHasToBeLocked())}
              </div>
            }
            item={
              <StandardButton
                disabled={disableOpcButtons}
                onClick={() => {
                  window.open(
                    `/new-system/with-mno/${sharedState.genesysPrefix.moistureLoadNo(
                      moistureLoadNo,
                      revNo
                    )}`
                  );
                }}
                buttonType="SecondaryBlue"
                size="Small"
              >
                {sharedState.translate(LanguageTexts.mlcCreateSystemFromopc())}
              </StandardButton>
            }
          />
          {canSeeSystemSelectionGuideButton && (
            <PopUp
              canShowPopUp={disableOpcButtons}
              content={
                <div>
                  {sharedState.translate(LanguageTexts.mlcHasToBeLocked())}
                </div>
              }
              item={
                <StandardButton
                  disabled={disableOpcButtons}
                  onClick={() => {
                    window.open(
                      `/system-selection-guide/with-mno/${sharedState.genesysPrefix.moistureLoadNo(
                        moistureLoadNo,
                        revNo
                      )}`
                    );
                  }}
                  buttonType="PrimaryBlue"
                  size="Small"
                >
                  {sharedState.translate(LanguageTexts.mlcCreateWithSysSel())}
                </StandardButton>
              }
            />
          )}
          {canSeeDrySizeButton && (
            <PopUp
              canShowPopUp={disableOpcButtons}
              content={
                <div>
                  {sharedState.translate(LanguageTexts.mlcHasToBeLocked())}
                </div>
              }
              item={
                <StandardButton
                  disabled={disableOpcButtons}
                  onClick={() => {
                    window.open(
                      `/dry-size/${sharedState.genesysPrefix.moistureLoadNo(
                        moistureLoadNo,
                        revNo
                      )}`
                    );
                  }}
                  buttonType="PrimaryBlue"
                  size="Small"
                >
                  {sharedState.translate(LanguageTexts.drysize())}
                </StandardButton>
              }
            />
          )}
        </ButtonContainer>
      )}

      {maxResults.map(maxResult => {
        const results = maxResult.flowSuggestions.map(x => ({
          id: x.id,
          valid: x.isValid,
          propertyValueSet: PropertyValueSet.fromString(x.result),
          flowSourceId: x.flowSourceId
        }));

        const distinctPropertyNames = results.reduce(
          (propertyNames: string[], res) => {
            propertyNames = propertyNames.concat(
              PropertyValueSet.getPropertyNames(res.propertyValueSet)
            );
            return Array.from(new Set(propertyNames));
          },
          []
        );

        const warnings = maxResult.roomWarnings
          .map(warning => {
            return sharedState.translate(
              LanguageTexts.globalPropertyName(getWarningTranslationId(warning))
            );
          })
          .map(w => ({
            title: "",
            body: w
          }));

        const loadCase = PropertyValueSet.fromString(
          maxResult.operatingCaseResults
        );

        let flowIndex = results.findIndex(
          x => x.id === maxResult.selectedFlowId
        );

        if (flowIndex === -1) {
          flowIndex = defaultFlowIndex;
        }

        const selectedFlowIndex =
          state.selectedFlowIndex[maxResult.needResourceString] ?? flowIndex;

        const flowSuggestionRows = createFlowSuggestionRows(
          results,
          sharedState,
          distinctPropertyNames,
          onFormatChanged,
          onFormatCleared
        );

        opcaseResults.push({
          caseName: sharedState.translate(
            LanguageTexts.globalPropertyName(maxResult.caseName)
          ),
          settings: PropertyValueSet.toString(
            PropertyValueSet.merge(
              loadCase,
              results[selectedFlowIndex].propertyValueSet
            )
          )
        });

        return (
          <SubContainer key={maxResult.caseType}>
            <Label>
              {sharedState.translate(
                LanguageTexts.globalPropertyName(maxResult.needResourceString)
              )}
            </Label>
            {!!warnings.length && (
              <SubContainer>
                <AlertAccordion
                  width="570px"
                  warningType="warning"
                  title=""
                  alerts={warnings}
                />
              </SubContainer>
            )}

            <FlexContainer>
              <TableContainer>
                <TableCard
                  title={sharedState.translate(
                    LanguageTexts.globalPropertyName(maxResult.caseName)
                  )}
                  height="265px"
                  rows={createLoadCaseRows(
                    loadCase,
                    sharedState,
                    onFormatChanged,
                    onFormatCleared
                  )}
                />
              </TableContainer>

              <div>
                <StyledLabel>
                  {sharedState.translate(
                    LanguageTexts.globalPropertyName("mlcflowsuggestions")
                  )}
                </StyledLabel>
                <MoistureLoadReadOnlyTable
                  flowOnChangeDisabled={isLocked || isDeleted}
                  flowOnchange={newindex => {
                    dispatch(
                      Action.setSelectedLoadCaseFlow(
                        moistureLoadItem.id,
                        maxResult.id,
                        results[newindex].id
                      )
                    );

                    dispatch(
                      Action.setSelectedFlowIndexes({
                        ...state.selectedFlowIndex,
                        [maxResult.needResourceString]: newindex
                      })
                    );
                  }}
                  selectedFlowIndex={selectedFlowIndex + 1}
                  rows={flowSuggestionRows}
                />
              </div>
            </FlexContainer>
          </SubContainer>
        );
      })}
    </Container>
  );
}

function createLoadCaseRows(
  loadCase: PropertyValueSet.PropertyValueSet,
  sharedState: SharedState.State,
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void,
  onFormatCleared: (fieldGroup: string, fieldName: string) => void
) {
  return PropertyValueSet.getPropertyNames(loadCase).map(propertyName => {
    const propertyValue = PropertyValueSet.getValue(propertyName, loadCase);

    let amountFormat: ScreenAmounts.AmountFormat | undefined = undefined;
    let value = "";

    if (propertyValue.type === "amount") {
      amountFormat = sharedState.screenAmounts.getAmountFormat(
        fieldGroupName,
        propertyName,
        PropertyValue.getAmount(propertyValue)!
      );
      value = getValue(propertyValue, amountFormat);
    } else {
      value = propertyValue.value.toString();
    }

    return {
      title: sharedState.translate(
        LanguageTexts.globalPropertyName("pp_" + propertyName)
      ),
      amountFormatSelector: () => (
        <>
          {amountFormat && (
            <>
              <span> [ </span>
              <AmountFormatSelector
                type="AmountFormatSelectorProps"
                fieldName={propertyName}
                fieldGroup={fieldGroupName}
                amountFormat={amountFormat}
                conversionParameters={undefined}
                translate={sharedState.translate}
                onFormatCleared={() =>
                  onFormatCleared(fieldGroupName, propertyName)
                }
                onFormatChanged={(unit, decimalCount) =>
                  onFormatChanged(
                    fieldGroupName,
                    propertyName,
                    unit,
                    decimalCount
                  )
                }
              />
              <span> ] </span>
            </>
          )}
        </>
      ),
      value
    };
  });
}

function createFlowSuggestionRows(
  rows: ReadonlyArray<Result>,
  sharedState: SharedState.State,
  distinctPropertyNames: ReadonlyArray<string>,
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void,
  onFormatCleared: (fieldGroup: string, fieldName: string) => void
) {
  rows = Array.from(rows).sort((a, b) => a.id - b.id);
  const notes = {
    name: (
      <span style={{ fontWeight: "bold" }}>
        {sharedState.translate(
          LanguageTexts.globalPropertyName("mlcsystemsuggestionnote")
        )}
      </span>
    ),
    results: rows.map(res =>
      res.flowSourceId !== GraphQLTypes.FlowSourceEnum.OK_GENERATED
        ? sharedState.translate(
            LanguageTexts.globalPropertyName(
              GetFlowNoteResourceString(res.flowSourceId)
            )
          )
        : ""
    )
  };
  const newArr = distinctPropertyNames.map(propertyName => {
    let amountFormat: AmountFormat | undefined = undefined;
    const results = rows.map(res => {
      const propertyValue = PropertyValueSet.getValue(
        propertyName,
        res.propertyValueSet
      );

      if (propertyValue !== undefined) {
        if (propertyValue.type === "amount") {
          amountFormat = sharedState.screenAmounts.getAmountFormat(
            fieldGroupName,
            propertyName,
            PropertyValue.getAmount(propertyValue)!
          );
          return getValue(propertyValue, amountFormat);
        }
        return propertyValue.value.toString();
      }
      return "";
    });

    return {
      name: (
        <FlexContainer>
          <span style={{ fontWeight: "bold" }}>
            {sharedState.translate(
              LanguageTexts.globalPropertyName("pp_" + propertyName)
            )}
          </span>
          {amountFormat && (
            <>
              <span> [ </span>
              <AmountFormatSelector
                type="AmountFormatSelectorProps"
                fieldName={propertyName}
                fieldGroup={fieldGroupName}
                amountFormat={amountFormat}
                conversionParameters={undefined}
                translate={sharedState.translate}
                onFormatCleared={() => {
                  onFormatCleared(fieldGroupName, propertyName);
                }}
                onFormatChanged={(unit, decimalCount) => {
                  onFormatChanged(
                    fieldGroupName,
                    propertyName,
                    unit,
                    decimalCount
                  );
                }}
              />
              <span> ] </span>
            </>
          )}
        </FlexContainer>
      ),

      results: results
    };
  });

  newArr.unshift(notes);
  // newArr.unshift({ name: <></>, results: [] }); // To make the First row white
  return newArr;
}

function GetFlowNoteResourceString(flowSourceId: GraphQLTypes.FlowSourceEnum) {
  switch (flowSourceId) {
    case GraphQLTypes.FlowSourceEnum.MIN_COOLING_FLOW:
      return "mlcmincoolingflownote";
    case GraphQLTypes.FlowSourceEnum.MAX_COOLING_FLOW:
      return "mlcmaxcoolingflownote";
    case GraphQLTypes.FlowSourceEnum.MIN_HEATING_FLOW:
      return "mlcminheatingflownote";
    case GraphQLTypes.FlowSourceEnum.MAX_HEATING_FLOW:
      return "mlcmaxheatingflownote";
    case GraphQLTypes.FlowSourceEnum.MIN_DEHUMIDIFICATION_FLOW:
      return "mlcmindehumidificationflownote";
    case GraphQLTypes.FlowSourceEnum.MAX_DEHUMIDIFICATION_FLOW:
      return "mlcmaxdehumidificationflownote";
    case GraphQLTypes.FlowSourceEnum.MIN_HUMIDIFICATION_FLOW:
      return "mlcminhumidificationflownote";
    case GraphQLTypes.FlowSourceEnum.MAX_HUMIDIFICATION_FLOW:
      return "mlcmaxhumidificationflownote";
    case GraphQLTypes.FlowSourceEnum.MIN_EXCHANGES:
      return "mlcminexchangesnote";
    case GraphQLTypes.FlowSourceEnum.MAX_EXCHANGES:
      return "mlcmaxexchangesnote";
    case GraphQLTypes.FlowSourceEnum.MAKE_UP_FLOW:
      return "mlcmakeupflownote";
    case GraphQLTypes.FlowSourceEnum.OK_GENERATED:
    default:
      return "mlcgeneratedflownote";
  }
}

function getWarningTranslationId(warning: GraphQLTypes.RoomWarningEnum) {
  switch (warning) {
    case GraphQLTypes.RoomWarningEnum.HIGH_HUMIDITY:
      return "mlchighhumiditywarning";
    case GraphQLTypes.RoomWarningEnum.LOW_HUMIDITY:
      return "mlclowhumiditywarning";
    case GraphQLTypes.RoomWarningEnum.HIGH_TEMP:
      return "mlchightempwarning";
    case GraphQLTypes.RoomWarningEnum.LOW_TEMP:
      return "mlclowtempwarning";
    case GraphQLTypes.RoomWarningEnum.GENERAL_WARNING:
      return "mlcgeneralwarning";
    default:
      return "";
  }
}
//tslint:disable-next-line
