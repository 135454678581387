import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import { PropertyValueSet } from "@genesys/property";
import { SystemOverrides } from "../../../tools";
import { MoistureHeatLoadTypeEnum } from "../../../../graphql-types";
import { Action } from "./action";
import { LoadsState, State, UpdatedMoistureLoadSettings } from "./types";
import { loadMapCreator } from "../loads-creator";
import * as LocalTools from "./tools";
import * as SharedState from "../../../../shared-state";
import * as OperationTimeGen2 from "../../../../operation-time-manager";
import * as SystemOverride from "../../../components/system-overrides";

type ResultsInvalid = boolean;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State,
  currentMoistureLoadSettings: PropertyValueSet.PropertyValueSet,
  currentSystemOverrides: SystemOverrides
): readonly [
  State,
  UpdatedMoistureLoadSettings,
  SystemOverrides,
  Cmd<Action>?,
  ResultsInvalid?,
  ReadonlyArray<SharedState.Action | undefined>?
] {
  switch (action.type) {
    case "addLoad": {
      const newLoad = { ...action.load };
      const updatedSettings = LocalTools.updateSettingsToAddTypesToLoad(
        currentMoistureLoadSettings,
        action.typeOfLoad,
        newLoad
      );

      const selectedLoadsState = LocalTools.getSelectedLoadState(
        state,
        updatedSettings
      );

      return [
        {
          ...state,
          currentPageTab: "moisture-heat-load-types",
          loadsState: {
            ...selectedLoadsState,
            selectedLoad: action.load.sourceName
          }
        },
        updatedSettings,
        currentSystemOverrides,
        undefined,
        true
      ];
    }

    case "addTypeToLoad": {
      // let loadIndex = state.currentSelectedLoads.findIndex(
      //   x => x === action.loadName
      // )!;

      // let load = state.currentSelectedLoads[loadIndex];

      let updatedSettings = { ...currentMoistureLoadSettings };
      const loadDef = loadMapCreator[action.loadName](
        state.moistureLoadClaimValue!
      );

      updatedSettings = LocalTools.updateSettingsToAddTypesToLoad(
        currentMoistureLoadSettings,
        action.typeOfLoad,
        loadDef!
      );

      const selectedLoadsState = LocalTools.getSelectedLoadState(
        state,
        updatedSettings
      );

      return [
        {
          ...state,
          currentPageTab: "moisture-heat-load-types",
          loadsState: {
            ...selectedLoadsState
          }
        },
        updatedSettings,
        currentSystemOverrides,
        undefined,
        true
      ];
    }

    case "changeTopMenuTab": {
      return [
        {
          ...state,
          currentTopMenuTab: action.tab
        },
        currentMoistureLoadSettings,
        currentSystemOverrides
      ];
    }

    case "changePageTab": {
      return [
        {
          ...state,
          currentPageTab: action.tab
        },
        currentMoistureLoadSettings,
        currentSystemOverrides
      ];
    }

    case "dispatchSystemOverride": {
      const loadDef = loadMapCreator[action.loadName](
        state.moistureLoadClaimValue!
      );
      const [systemOverridesState, updatedSystemOverrides] =
        SystemOverride.update(
          action.action,
          state.systemOverridesState,
          sharedState,
          currentSystemOverrides,
          loadDef.additionalInfo.updateFunction
          // state.currentSelectedLoads.find(
          //   x => x.sourceName === action.loadName
          // )!.additionalInfo.updateFunction
        );

      return [
        {
          ...state,
          systemOverridesState
        },
        currentMoistureLoadSettings,
        updatedSystemOverrides,
        undefined,
        true
      ];
    }
    case "dispatchOperationTime": {
      const loadsState = state.loadsState;
      const loadIndex = loadsState.activeLoads.findIndex(
        x => x.sourceName === action.loadName
      );
      const activeLoadState = loadsState.activeLoads[loadIndex]!;

      const loadDef = loadMapCreator[action.loadName](
        state.moistureLoadClaimValue!
      );

      // const load = state.allLoadsDefMap.get(action.loadName)!;

      // const loadState = load.additionalInfo.getActiveLoadState(
      //   currentMoistureLoadSettings
      // );

      const currentOperationTimeState = activeLoadState.operationTimeState;

      const [operationTimeState] = OperationTimeGen2.update(
        action.action,
        currentOperationTimeState
      );

      const loadState = {
        ...activeLoadState,
        operationTimeState
      };

      let currentSelectedLoads = [...loadsState.activeLoads];
      currentSelectedLoads[loadIndex] = loadState;

      let loadsOperationTimes =
        LocalTools.getActiveLoadsOperationalTimes(currentSelectedLoads);

      if (loadDef.operationalTime.onOperationTimeUpdate) {
        loadsOperationTimes = loadDef.operationalTime.onOperationTimeUpdate(
          currentMoistureLoadSettings,
          loadsOperationTimes
        );
      }

      currentSelectedLoads = currentSelectedLoads.map(x => ({
        ...x,
        operationTimeState: loadsOperationTimes[x.sourceName]
      }));

      return [
        {
          ...state,
          loadsState: {
            ...state.loadsState,
            activeLoads: currentSelectedLoads
          }
          // resultState: undefined,
        },
        currentMoistureLoadSettings,
        currentSystemOverrides,
        undefined,
        true
      ];
    }
    case "setSelectedLoad": {
      return [
        {
          ...state,
          loadsState: {
            ...state.loadsState,
            selectedLoad: action.selectedLoad
          }
        },
        currentMoistureLoadSettings,
        currentSystemOverrides
      ];
    }

    case "removeTypesFromLoad": {
      // let load = state.currentSelectedLoads.find(
      //   x => x.sourceName === action.loadName
      // )!;
      let updatedSettings = { ...currentMoistureLoadSettings };
      const loadDef = loadMapCreator[action.loadName](
        state.moistureLoadClaimValue!
      );

      updatedSettings = LocalTools.updateSettingsToRemoveTypesFromLoad(
        currentMoistureLoadSettings,
        action.typeOfLoad,
        loadDef!
      );

      const selectedLoadsState = LocalTools.getSelectedLoadState(
        state,
        updatedSettings
      );
      return [
        {
          ...state,
          currentPageTab: "moisture-heat-load-types",
          loadsState: {
            ...selectedLoadsState
          }
        },
        updatedSettings,
        currentSystemOverrides,
        undefined,
        true
      ];
    }

    default:
      return exhaustiveCheck(action, true);
  }
}

export function getUpdatedSelectedLoadState(
  state: State,
  updatedSettings: PropertyValueSet.PropertyValueSet
): LoadsState {
  return LocalTools.getSelectedLoadState(state, updatedSettings);

  // return {
  //   ...state,
  //   loadsState: {
  //     ...selectedLoadsState
  //   }
  // };
}

export function getUpdatedResultState(
  state: State,
  removeResult: boolean
): State {
  return {
    ...state,
    ...(removeResult && {
      currentPageTab: "moisture-heat-load-types"
    })
  };
}

export function getCurrentSelectedLoads(state: State) {
  return state.loadsState.activeLoads;
}

// export function getAllVisibleLoads(state: State) {
//   return state.visibleLoads;
// }

// export function getAvailableLoads(state: State) {
//   return state.loadsState.selectableLoads;
// }

export function setSelectedLoad(
  state: State,
  loadName: MoistureHeatLoadTypeEnum | undefined
): State {
  return {
    ...state,
    loadsState: {
      ...state.loadsState,
      selectedLoad: loadName
    }
  };
}
