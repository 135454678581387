import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../../../../shared-state";
import * as DateHelper from "@genesys/shared/lib/date-helper";
import * as StatusIcon from "../../../../status-icon-manager";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import styled from "styled-components";
import { exhaustiveCheck } from "ts-exhaustive-check";
import {
  MenuContainer,
  OrderyByHeader,
  RevisionsContainer,
  StatusContainer
} from "../../../../shared-manager-components";
import { Dispatch } from "@typescript-tea/core";
import {
  S2,
  P2,
  ToolTipWrapper,
  MenuDots,
  ArrowUp,
  ArrowDown,
  PrimaryColors
} from "@genesys/ui-elements";
import { Action, State } from "../../../state";
import {
  ColumnName,
  RowInfo,
  MoistureLoadRow,
  OrderByColumn
} from "../../../types";
import { MenuContent } from "./revison-menu-content";

const StyledA = styled.a`
  text-decoration: none;
`;

const MoistureLoadNameContainer = styled.div`
  padding: 5px;
  border-radius: 10px;
  width: 250px;
  :hover {
    background: ${PrimaryColors.lightBlue};
  }
`;

const NormalHeaderContainer = styled.div`
  padding-bottom: 3px;
`;

const LabelsP2 = styled(P2)`
  width: 150px;
`;

const StyledS2 = styled(S2)``;

function NormalHeader({ text }: { readonly text: string }) {
  return <NormalHeaderContainer>{headerElement(text)}</NormalHeaderContainer>;
}

function headerElement(text: string) {
  return (
    <StyledS2 color="dark" weight="bold">
      {text}
    </StyledS2>
  );
}

export function getHeaderCellContent(
  state: State,
  cellName: ColumnName,
  sharedState: SharedState.State,
  dispatch: Dispatch<Action>
) {
  const currentColumn = state.searchParams.orderByColumn;

  const setOrderByColumn = (column: OrderByColumn) => {
    let newSearchParams = state.searchParams;
    if (currentColumn.name === column) {
      newSearchParams = {
        ...state.searchParams,
        orderByColumn: {
          ...currentColumn,
          isDescending: !currentColumn.isDescending
        }
      };
    } else {
      newSearchParams = {
        ...state.searchParams,
        orderByColumn: {
          name: column,
          isDescending: true
        }
      };
    }

    dispatch(Action.setSearchParams(newSearchParams));
    dispatch(Action.search());
  };

  switch (cellName) {
    case "m-no": {
      return (
        <OrderyByHeader
          headerElement={headerElement("M-No")}
          sortedColumn={currentColumn.name === cellName}
          isDescending={state.searchParams.orderByColumn.isDescending}
          setOrderByColumn={() => setOrderByColumn(cellName)}
        />
      );
    }

    case "actions-menu": {
      return <></>;
    }

    case "moisture-load-name": {
      return (
        <OrderyByHeader
          headerElement={headerElement(
            sharedState.translate(LanguageTexts.moistureLoadName())
          )}
          sortedColumn={currentColumn.name === cellName}
          isDescending={state.searchParams.orderByColumn.isDescending}
          setOrderByColumn={() => setOrderByColumn(cellName)}
        />
      );
    }

    case "owner": {
      return (
        <OrderyByHeader
          headerElement={headerElement(
            sharedState.translate(LanguageTexts.owner())
          )}
          sortedColumn={currentColumn.name === cellName}
          isDescending={state.searchParams.orderByColumn.isDescending}
          setOrderByColumn={() => setOrderByColumn(cellName)}
        />
      );
    }

    case "date-changed": {
      return (
        <OrderyByHeader
          headerElement={headerElement(
            sharedState.translate(LanguageTexts.changed())
          )}
          sortedColumn={currentColumn.name === cellName}
          isDescending={state.searchParams.orderByColumn.isDescending}
          setOrderByColumn={() => setOrderByColumn(cellName)}
        />
      );
    }

    case "status": {
      return (
        <NormalHeader text={sharedState.translate(LanguageTexts.status())} />
      );
    }

    case "labels": {
      return (
        <NormalHeader text={sharedState.translate(LanguageTexts.labels())} />
      );
    }

    case "revisions": {
      return (
        <NormalHeader text={sharedState.translate(LanguageTexts.revisions())} />
      );
    }

    default:
      exhaustiveCheck(cellName);
  }
}

export function getCellContent(
  state: State,
  cellName: ColumnName,
  sharedState: SharedState.State,
  dispatch: Dispatch<Action>,
  rowInfo: RowInfo
): JSX.Element | null {
  const mlRow = rowInfo.moistureLoadRow;

  switch (cellName) {
    case "m-no": {
      const prefix = sharedState.genesysPrefixNotation.moistureLoadNo;

      if (mlRow.type === "moisture-load-file") {
        return (
          <P2 weight="normal" color="secondary">
            {prefix + mlRow.moistureLoadNo}
          </P2>
        );
      }

      return (
        <P2 weight="normal" color="secondary">
          {mlRow.revisionNo}
        </P2>
      );
    }

    case "actions-menu": {
      const moistureLoadActionsParentRef = React.createRef<HTMLAnchorElement>();

      return (
        <>
          <a
            ref={moistureLoadActionsParentRef}
            onClick={() =>
              dispatch(Action.toggleManagerActionsMenu(getId(mlRow)))
            }
          >
            <ToolTipWrapper
              title={sharedState.translate(LanguageTexts.actions())}
              placement="top"
            >
              <MenuDots height="1.8rem" />
            </ToolTipWrapper>
          </a>
          {rowInfo.isActionMenuOpen && (
            <ClickAwayListener
              onClickAway={() => {
                dispatch(Action.toggleManagerActionsMenu(""));
              }}
            >
              <div>
                <MenuContainer
                  menuIsOpen={rowInfo.isActionMenuOpen}
                  menuParentRef={moistureLoadActionsParentRef as any}
                >
                  <MenuContent
                    dispatch={dispatch}
                    mlRow={mlRow}
                    state={state}
                    sharedState={sharedState}
                  />
                </MenuContainer>
              </div>
            </ClickAwayListener>
          )}
        </>
      );
    }

    case "moisture-load-name": {
      return (
        <StyledA
          href={`/moisture-load/${sharedState.genesysPrefix.moistureLoadNo(
            mlRow.moistureLoadNo,
            mlRow.type === "moisture-load-file"
              ? mlRow.latestRevision
              : mlRow.revisionNo
          )}`}
        >
          <MoistureLoadNameContainer>
            <P2 weight="normal" color="secondary">
              {mlRow.name}
            </P2>
          </MoistureLoadNameContainer>
        </StyledA>
      );
    }

    case "owner": {
      return (
        <P2 weight="normal" color="secondary">
          {mlRow.type === "moisture-load-file" ? mlRow.owner : ""}
        </P2>
      );
    }

    case "date-changed": {
      return (
        <P2 weight="normal" color="secondary">
          {dateFormatter(sharedState, mlRow.statusDate!)}
        </P2>
      );
    }

    case "status": {
      return (
        <StatusContainer>
          <StatusIcon.StatusIconManager
            sharedState={sharedState}
            statusNumber={mlRow.status}
            type="moistureload"
          />
        </StatusContainer>
      );
    }

    case "labels": {
      return (
        <LabelsP2 weight="normal" color="secondary">
          {mlRow.type === "moisture-load-file"
            ? mlRow.labels.map((label, i) => {
                if (i !== mlRow.labels.length - 1) {
                  return label.name + " | ";
                } else {
                  return label.name;
                }
              })
            : ""}
        </LabelsP2>
      );
    }

    case "revisions": {
      if (mlRow.type === "moisture-load-file") {
        return (
          <RevisionsContainer>
            <P2 weight="normal" color="secondary">
              {mlRow.latestRevision}
            </P2>
            <span onClick={() => dispatch(Action.toggleRevision(getId(mlRow)))}>
              {rowInfo.isRevisionExpanded ? (
                <ArrowUp height="0.4rem" />
              ) : (
                <ArrowDown height="0.4rem" />
              )}
            </span>
          </RevisionsContainer>
        );
      }

      return null;
    }

    default:
      exhaustiveCheck(cellName);
  }
}

function getId(mlRow: MoistureLoadRow) {
  if (mlRow.type === "moisture-load-file") {
    return mlRow.id;
  } else if (mlRow.type === "moisture-load-revision") {
    return mlRow.id;
  }

  return "";
}

function dateFormatter(sharedState: SharedState.State, date: number) {
  return DateHelper.getDateString(
    DateHelper.fromUTC(date),
    sharedState.user.settings.locale
  );
}
