import {
  H3,
  AlertAccordion,
  InfoGray,
  GenesysSelect
} from "@genesys/ui-elements";
import { AmountPropertySelector } from "../../amount-property-selector";
import * as React from "react";
import * as Authorization from "@genesys/shared/lib/authorization";
import {
  PropertyValueSet,
  PropertyValue,
  PropertyFilter
} from "@genesys/property";
import * as GraphQLTypes from "../../graphql-types";
import { Amount, Quantity, Unit, Units } from "@genesys/uom";
import { LocationData } from "../types";
import * as SharedState from "../../shared-state";
import {
  ComponentContainer,
  InputContainer,
  PressureContainer,
  StyledTextInput,
  StyledLabel,
  StyledDiv,
  LocationInnerContainer,
  LocationHelpContainer,
  FlexContainer
} from "../elements";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { getClimateSettingsForLocationChange } from "../functions";
import * as Calculations from "@munters/calculations";
import { CustomPressureSelector } from "./custom-pressure-selector";

const genesysSelectStylingProps = {
  width: 14,
  height: 25,
  fontSize: 13
};

export function LocationComponent({
  sharedState,
  climateSettings,
  countries,
  isManualDataSource,
  messages,
  isDisabled,
  currentLocation,
  onSettingChange,
  onFormatChanged,
  onFormatCleared,
  translate
}: {
  readonly sharedState: SharedState.State;
  readonly isDisabled: boolean;
  readonly messages: ReadonlyArray<string> | undefined;
  readonly climateSettings: PropertyValueSet.PropertyValueSet;
  readonly currentLocation: LocationData;
  readonly countries: ReadonlyArray<
    GraphQLTypes.ClimateSelectorCountriesProductQuery["product"]["countries"][0]
  >;
  readonly isManualDataSource: boolean;
  readonly onSettingChange: (
    newClimateSettings: PropertyValueSet.PropertyValueSet
  ) => void;
  readonly onFormatChanged: (
    fieldgroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;
  readonly onFormatCleared: (fieldgroup: string, fieldName: string) => void;
  readonly translate: (arg: LanguageTexts.StaticText) => string;
}) {
  const latitude = formatLatitude(
    Amount.valueAs(
      Units.Degrees,
      PropertyValue.getAmount<Quantity.Angle>(
        currentLocation.location.latitude
      )!
    )
  );

  const longitude = formatLongitude(
    Amount.valueAs(
      Units.Degrees,
      PropertyValue.getAmount<Quantity.Angle>(
        currentLocation.location.longitude
      )!
    )
  );

  const isCustomPressure =
    PropertyValueSet.getInteger("custompressure", climateSettings) === 1;

  const showCodes = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysUserClaims.showProductCodes
  );

  return (
    <div>
      {messages && (
        <StyledDiv>
          <AlertAccordion
            title="Warning"
            alerts={messages.map(m => ({
              title: "",
              body: m
            }))}
            warningType="warning"
          />
        </StyledDiv>
      )}

      <ComponentContainer>
        <H3 weight="normal" color="dark">
          {translate(LanguageTexts.location())}
        </H3>

        <InputContainer>
          <StyledLabel weight="normal" color="secondary">
            Climate Data source
          </StyledLabel>
          <GenesysSelect
            {...genesysSelectStylingProps}
            value={isManualDataSource ? "Manual" : "ASHRAE"}
            disabled={isDisabled}
            options={[
              { value: "Manual", title: "Manual" },
              { value: "ASHRAE", title: "Ashrae" }
            ]}
            onChange={e =>
              onSettingChange(
                e.target.value === "Manual"
                  ? getClimateSettingsfromChangeToManualDataSource(
                      climateSettings
                    )
                  : getClimateSettingsForLocationChange(
                      climateSettings,
                      countries,
                      currentLocation!.location.id
                    )
              )
            }
          />
        </InputContainer>
        {!isManualDataSource && (
          <>
            <InputContainer>
              <StyledLabel weight="normal" color="secondary">
                {translate(LanguageTexts.country())}
              </StyledLabel>
              <GenesysSelect
                {...genesysSelectStylingProps}
                disabled={isDisabled}
                value={currentLocation.country.id}
                options={countries.map(c => {
                  return { value: c.id, title: c.name };
                })}
                onChange={e =>
                  onSettingChange(
                    getClimateSettingsForLocationChange(
                      climateSettings,
                      countries,
                      countries.find(c => c.id === e.target.value)?.regions[0]
                        .locations[0].id!
                    )
                  )
                }
              />
            </InputContainer>

            <InputContainer>
              <StyledLabel weight="normal" color="secondary">
                {translate(LanguageTexts.state())}
              </StyledLabel>
              <GenesysSelect
                {...genesysSelectStylingProps}
                disabled={isDisabled}
                value={currentLocation.region.id}
                options={currentLocation.allRegions.map(r => {
                  return { value: r.id, title: r.name };
                })}
                onChange={e =>
                  onSettingChange(
                    getClimateSettingsForLocationChange(
                      climateSettings,
                      countries,
                      currentLocation!.allRegions.find(
                        r => r.id === e.target.value
                      )?.locations[0].id!
                    )
                  )
                }
              />
            </InputContainer>
            <LocationHelpContainer>
              <LocationInnerContainer>
                <InfoGray
                  onClick={() =>
                    window.open("https://klimaat.github.io/StationFinder/")
                  }
                />
              </LocationInnerContainer>

              <InputContainer>
                <StyledLabel weight="normal" color="secondary">
                  {translate(LanguageTexts.location())}
                </StyledLabel>
                <GenesysSelect
                  {...genesysSelectStylingProps}
                  disabled={isDisabled}
                  value={currentLocation.location.id}
                  options={currentLocation.region.locations.map(l => {
                    return { value: l.id, title: l.name };
                  })}
                  onChange={e =>
                    onSettingChange(
                      getClimateSettingsForLocationChange(
                        climateSettings,
                        countries,
                        e.target.value
                      )
                    )
                  }
                />
              </InputContainer>
            </LocationHelpContainer>

            <InputContainer>
              <StyledLabel weight="normal" color="secondary">
                Latitude
              </StyledLabel>
              <StyledTextInput disabled placeholder={latitude} />
            </InputContainer>

            <InputContainer>
              <StyledLabel weight="normal" color="secondary">
                Longitude
              </StyledLabel>
              <StyledTextInput disabled placeholder={longitude} />
            </InputContainer>
          </>
        )}

        <InputContainer>
          <StyledLabel weight="normal" color="secondary">
            Elevation
          </StyledLabel>
          <AmountPropertySelector
            type="with-simplified-props"
            translate={translate}
            fieldGroup="ClimateDataLocationDialog"
            fieldName="altitude"
            readOnly={!isManualDataSource || isDisabled}
            propertyName="altitude"
            propertyValueSet={climateSettings}
            onFormatChanged={(unit, decimalCount) =>
              onFormatChanged(
                "ClimateDataLocationDialog",
                "altitude",
                unit,
                decimalCount
              )
            }
            onFormatCleared={() =>
              onFormatCleared("ClimateDataLocationDialog", "altitude")
            }
            onValueChange={value =>
              value
                ? onSettingChange(
                    getClimateSettingsForElevationChange(
                      climateSettings,
                      PropertyValue.fromAmount(value)
                    )
                  )
                : climateSettings
            }
            getAmountFormat={sharedState.screenAmounts.getAmountFormat}
            quantity="Length"
          />
        </InputContainer>

        <PressureContainer>
          <FlexContainer>
            <StyledLabel weight="normal" color="secondary">
              Athmosperic Pressure
            </StyledLabel>

            <AmountPropertySelector
              type="with-simplified-props"
              translate={translate}
              fieldGroup="ClimateDataLocationDialog"
              fieldName="atmosphericpressure"
              readOnly={!isCustomPressure || isDisabled}
              propertyName="atmosphericpressure"
              propertyValueSet={climateSettings}
              onFormatChanged={(unit, decimalCount) =>
                onFormatChanged(
                  "ClimateDataLocationDialog",
                  "atmosphericpressure",
                  unit,
                  decimalCount
                )
              }
              onFormatCleared={() =>
                onFormatCleared(
                  "ClimateDataLocationDialog",
                  "atmosphericpressure"
                )
              }
              onValueChange={value =>
                value
                  ? onSettingChange(
                      PropertyValueSet.set(
                        "atmosphericpressure",
                        PropertyValue.fromAmount(value),
                        climateSettings
                      )
                    )
                  : climateSettings
              }
              getAmountFormat={sharedState.screenAmounts.getAmountFormat}
              quantity="Pressure"
              validationFilter={PropertyFilter.fromString(
                "atmosphericpressure>0:pascal"
              )}
            />
          </FlexContainer>

          <CustomPressureSelector
            isDisabled={isDisabled}
            showCodes={showCodes}
            isManual={isCustomPressure}
            onChange={() => {
              isCustomPressure
                ? onSettingChange(
                    getClimateSettingsWithDefaultPressure(climateSettings)
                  )
                : onSettingChange(
                    PropertyValueSet.setInteger(
                      "custompressure",
                      1,
                      climateSettings
                    )
                  );
            }}
          />
        </PressureContainer>
      </ComponentContainer>
    </div>
  );
}

function formatLatitude(latitude: number) {
  return Math.abs(latitude).toFixed(2) + " " + (latitude < 0 ? "S" : "N");
}

function formatLongitude(longitude: number) {
  return Math.abs(longitude).toFixed(2) + " " + (longitude < 0 ? "E" : "W");
}

function getClimateSettingsWithDefaultPressure(
  climateSettings: PropertyValueSet.PropertyValueSet
) {
  let newClimateSettings = PropertyValueSet.setInteger(
    "custompressure",
    0,
    climateSettings
  );

  const altitude = PropertyValueSet.getValue("altitude", climateSettings);

  newClimateSettings = PropertyValueSet.set(
    "atmosphericpressure",
    PropertyValue.fromAmount(
      Calculations.Physics.RP1485.AshraeHb2009.calculateAtmosphericPressure(
        PropertyValue.getAmount<Quantity.Length>(altitude)!
      )
    ),
    newClimateSettings
  );

  return newClimateSettings;
}

function getClimateSettingsForElevationChange(
  climateSettings: PropertyValueSet.PropertyValueSet,
  newElevationValue: PropertyValue.PropertyValue
) {
  let newClimateSettings = PropertyValueSet.set(
    "altitude",
    newElevationValue,
    climateSettings
  );

  newClimateSettings = PropertyValueSet.set(
    "atmosphericpressure",
    PropertyValue.fromAmount(
      Calculations.Physics.RP1485.AshraeHb2009.calculateAtmosphericPressure(
        PropertyValue.getAmount<Quantity.Length>(newElevationValue)!
      )
    ),
    newClimateSettings
  );

  return newClimateSettings;
}

function getClimateSettingsfromChangeToManualDataSource(
  climateSettings: PropertyValueSet.PropertyValueSet
) {
  let newClimateSettings = PropertyValueSet.removeProperties(
    ["wmo", "climatebinLocationid"],
    climateSettings
  );

  newClimateSettings = PropertyValueSet.setText(
    "locationname",
    "Manual",
    newClimateSettings
  );

  return newClimateSettings;
}
// tslint:disable-next-line: max-file-line-count
