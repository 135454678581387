import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as DataUpload from "@genesys/client-core/lib/data-upload";
import { StandardButton } from "@genesys/ui-elements";
import { Dispatch } from "@typescript-tea/core";
import { importClimateBinCases } from "../functions/custom-bins-import";
import { Action } from "../state";
import { CustomBinsImportRoot } from "../elements";

export function CustomBinsImportComponent({
  isSystemLocked,
  translate,
  dispatch
}: {
  readonly isSystemLocked: boolean;
  readonly translate: LanguageTexts.Translate;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  return (
    <CustomBinsImportRoot>
      <DataUpload.SimpleDataUploadContainer
        onParsed={data => importClimateBinCases(data, dispatch)}
      >
        {onClick => (
          <StandardButton
            buttonType="PrimaryBlue"
            size="Small"
            disabled={isSystemLocked}
            onClick={onClick}
          >
            {translate(LanguageTexts.importText())}
          </StandardButton>
        )}
      </DataUpload.SimpleDataUploadContainer>

      <StandardButton
        buttonType="SecondaryBlue"
        size="Small"
        disabled={isSystemLocked}
        onClick={() => {
          const a = document.createElement("a");
          a.href = "/assets/energy-template.xlsx";
          a.download = "example-template";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }}
      >
        {translate(LanguageTexts.exampleTemplate())}
      </StandardButton>
    </CustomBinsImportRoot>
  );
}
