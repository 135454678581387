import * as React from "react";
import styled from "styled-components";
// import { Dispatch } from "@typescript-tea/core";
import * as Elements from "@genesys/ui-elements";
// import * as DebugSettings from "@genesys/client-core/lib/debug-settings";
// import * as SharedState from "../shared-state";
// import * as Authorization from "@genesys/shared/lib/authorization";

const StyledFieldset = styled.fieldset`
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px;

  legend {
    padding: 10px;
  }
`;

export function ThrowServerSideException({
  disableButton,
  exceptionThrown,
  onClick
}: {
  readonly exceptionThrown: boolean;
  readonly disableButton: boolean;
  readonly onClick: () => void;
}) {
  return (
    <StyledFieldset>
      <legend>Server side error</legend>
      <Elements.StandardButton
        size="Small"
        buttonType="SecondaryBlue"
        disabled={disableButton}
        onClick={e => {
          e.stopPropagation();
          onClick();
        }}
      >
        Throw server side error
      </Elements.StandardButton>
      {exceptionThrown && <p>Exception thrown on the server</p>}
    </StyledFieldset>
  );
}
