import gql from "graphql-tag";

export const calculateOilon = gql`
  query CalculateOilon($oilonInput: OilonInputType!) {
    user {
      calculateOilon(oilonInput: $oilonInput) {
        hasResult
        oilonId
        errors
        calculationCases {
          name
          cop
          wetAirCapacityRequired
          highTemperatureStageCop
          highTemperatureStageHeatingCapacity
          highTemperatureStageElectricPowerConsumed
          highTemperatureStageLiquidOutletTemperature
          highTemperatureStageRelativeCapacity
          highTemperatureStageRelativeCapacityAtMinimumSpeed
          highTemperatureStageCondenserFlowRate
          highTemperatureStageCirculationPumpPower
          lowTemperatureStageCop
          lowTemperatureStageHeatingCapacity
          lowTemperatureStageElectricPowerConsumed
          lowTemperatureStageCondenserLiquidOutletTemperature
          lowTemperatureStageRelativeCapacity
          lowTemperatureStageRelativeCapacityAtMinimumSpeed
          lowTemperatureStageCondenserFlowRate
          lowTemperatureStageCirculationPumpPower
          lowTemperatureStageCoolingCapacity
          lowTemperatureStageEvaporatorLiquidOutletTemperature
          lowTemperatureStageEvaporatorFlowRate
        }
      }
    }
  }
`;
