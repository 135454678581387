import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ListMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 175px;
`;

const ListMenuItemBase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 13px;
  margin-top: 4px;
  margin-bottom: 4px;

  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  user-select: none;

  &:hover {
    background-color: #f2f4f7;
  }
`;

export const ListMenuItem = styled(ListMenuItemBase)`
  cursor: pointer;
`;

export const ListMenuItemSelected = styled(ListMenuItemBase)`
  background-color: #f2f4f7;
`;

export const ListMenuItemIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const ListMenuItemTitle = styled.div`
  margin-left: 4px;
  font-family: Futura;
  font-size: 0.8667rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.4rem;
`;
