import React from "react";
import styled from "../../styled-components";

import { zIndex } from "../../zIndex";
import { PrimaryColors } from "../../colors";

const List = styled.ul`
  list-style-type: none;
  position: absolute;
  background-color: #ffffff;
  margin: 0;
  margin-left: 0px;
  border: 1px solid #dddddd;
  box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-sizing: content-box;
  min-width: 183px;
  right: 0;
  z-index: ${zIndex.Dropdown};

  li {
    padding: 8px 10px 8px 16px;
    cursor: pointer;

    color: #959695;
    font-size: 13px;
    font-family: futura;

    h4 {
      margin: 0;
    }

    &:hover {
      background-color: #f1f5fa;
    }
  }
`;

const HoverAnchor = styled.span`
  cursor: pointer;
  position: relative;
  padding: 9px 6px 3px 6px;
  :hover {
    border-radius: 20px;
    background: ${PrimaryColors.lightBlue};
  }
`;

interface Value {
  readonly value: string;
  readonly onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  // readonly href?: string;
  readonly key?: string;
  readonly disabled?: boolean;
}

export function WithMenu(props: {
  readonly icon: React.ReactElement;
  readonly onMouseOver?: () => void;
  readonly onMousLeave?: () => void;
  readonly values: ReadonlyArray<Value>;
}) {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const divRef = React.useRef<HTMLUListElement>(null);
  function onClose() {
    setAnchorEl(null);
  }
  const { icon, values, onMouseOver, onMousLeave } = props;
  return (
    <HoverAnchor
      onMouseLeave={() => {
        onClose();
        if (onMousLeave) {
          onMousLeave();
        }
      }}
      onMouseOver={e => {
        setAnchorEl(e.currentTarget);
        if (onMouseOver) {
          onMouseOver();
        }
      }}
    >
      {icon}
      {anchorEl && (
        <List ref={divRef as string & React.RefObject<HTMLUListElement>}>
          {values.map(
            item =>
              !item.disabled && (
                <li
                  key={item.key ? item.key : item.value}
                  onClick={event => {
                    if (item.onClick) {
                      event.preventDefault();
                      event.stopPropagation();
                      onClose();
                      item.onClick(event);
                    }
                  }}
                >
                  <a>{item.value}</a>
                </li>
              )
          )}
        </List>
      )}
    </HoverAnchor>
  );
}
