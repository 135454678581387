import {
  PropertyValue,
  PropertyValueSet,
  PropertyFilter
} from "@genesys/property";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as ProductProperties from "@genesys/shared/lib/product-properties";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Types from "./types";
import { PropertyItem } from "../properties-selector";
import * as PropertyFilterHelpers from "@genesys/shared/lib/property-filter-helpers";
import { PropertyInfo } from "../properties-selector";
import * as opcPreProcess from "../wizard/steps/operating-cases/opc-pre-process";
import { createInitialOperatingCases } from "../operating-case-selector";
import { Amount } from "uom";
import { Units } from "uom-units";
import { v4 } from "uuid";
import { Quantity } from "@genesys/uom";
import { newPropertiesOverrides } from "./data";

const blankSystemVariant: Types.SystemVariant = {
  identifier: "",
  systemType: "",
  variant: "",
  warnings: [],
  newProperties: undefined,
  operatingCases: [PropertyValueSet.Empty],
  divergentSysComponent: undefined,
  invalidThreshold: undefined,
  invalidProperties: [],
  invalidSystemRequirements: undefined,
  shouldCalculate: false,
  calculationResults: undefined,
  quantity: 0,
  diffBetweenMoistureLoadCapacityAndLoadPerUnit: undefined,
  nominalDehumCapacity: Amount.create(0, Units.KilogramPerHour),
  totalMoistureLoad: Amount.create(0, Units.KilogramPerHour)
};

export function generateSystemVariantGroups(
  newPropertiesSet: ReadonlyArray<PropertyValueSet.PropertyValueSet>,
  systemTypes: ReadonlyArray<Types.SystemType>,
  genericOperatingCase: PropertyValueSet.PropertyValueSet,
  climateSettings: PropertyValueSet.PropertyValueSet,
  systemConfiguration: Types.SystemConfiguration,
  translate: LanguageTexts.Translate
): ReadonlyArray<Types.SystemVariantsGroup> {
  const {
    totalMoistureLoad,
    returnAirHumidity,
    freshAirFlow,
    freshAirHumidity
  } = getOperatingCaseValues(genericOperatingCase);

  const newExternalMixingValue = getNewExternalMixingValue(systemConfiguration);
  const processFanModeValue = getProcessFanModeValue(systemConfiguration);

  const propertiesSet = newPropertiesSet.map(pvs =>
    PropertyValueSet.setInteger(
      "newexternalmixing",
      newExternalMixingValue,
      PropertyValueSet.setInteger("processfanmode", processFanModeValue, pvs)
    )
  );

  const variantGroups: ReadonlyArray<Types.SystemVariantsGroup> =
    propertiesSet.map(genericNewProperties => {
      const reactHeaterType: Types.ReactHeaterType =
        getReactHeaterType(genericNewProperties);

      const allSystemTypesVariants = systemTypes.reduce(
        (soFarSystems, currentSystemType) => {
          const genericOperatingCaseWithSystemTypePropertyNames =
            getGenericOperatingCaseWithSystemTypePropertyNames(
              genericOperatingCase,
              currentSystemType.opcProperties
            );

          const { isSystemRequirementsMet, systemRequirementsFilter } =
            checkSystemRequirements(
              currentSystemType.newMappings,
              genericNewProperties
            );

          const dhmodelItems = getDhmodels(
            currentSystemType.newMappings,
            genericNewProperties,
            currentSystemType.newProperties
          );

          const systemTypeVariants: ReadonlyArray<
            Types.SystemVariant | undefined
          > = dhmodelItems.map(dhmodel => {
            const systemVariantForDhModel: Types.SystemVariant = {
              ...blankSystemVariant,
              identifier: v4(),
              systemType: currentSystemType.id,
              variant: dhmodel.text
            };

            if (!isSystemRequirementsMet) {
              return {
                ...systemVariantForDhModel,
                invalidSystemRequirements: {
                  propertyFilter: systemRequirementsFilter,
                  selectionNewProperties: genericNewProperties
                }
              };
            }

            const { nominalFlowPerUnit, nominalDehumCapacityPerUnit } =
              getNominalValues(dhmodel, currentSystemType.flowLimits);

            if (
              nominalFlowPerUnit === undefined ||
              nominalDehumCapacityPerUnit === undefined
            ) {
              return systemVariantForDhModel;
            }

            const modifiedNewPropertiesDefinitions =
              modifyNewPropertiesDefinitions(currentSystemType);

            const systemTypeSpecificProperties =
              getSystemTypeSpecificProperties(
                currentSystemType.newMappings,
                modifiedNewPropertiesDefinitions,
                genericNewProperties,
                dhmodel
              );

            if (systemTypeSpecificProperties.invalidProperties.length > 0) {
              return {
                ...systemVariantForDhModel,
                invalidProperties:
                  systemTypeSpecificProperties.invalidProperties
              };
            }

            const newProperties = createDefaultProperties(
              systemTypeSpecificProperties.validProperties
            );

            const { quantity, invalidThreshold } = tryToGetQuantity(
              returnAirHumidity,
              nominalFlowPerUnit,
              freshAirFlow,
              freshAirHumidity,
              totalMoistureLoad,
              nominalDehumCapacityPerUnit,
              systemConfiguration
            );

            if (quantity === undefined) {
              return {
                ...systemVariantForDhModel,
                invalidThreshold: invalidThreshold
              };
            }

            const moistureLoadPerUnit = totalMoistureLoad / quantity;

            const freshFlowPerUnit = freshAirFlow / quantity;

            const targetHumidity =
              returnAirHumidity - moistureLoadPerUnit / nominalFlowPerUnit;

            const diffBetweenMoistureLoadCapacityAndLoadPerUnit =
              getDiffBetweenMoistureLoadCapacityAndLoadPerUnit(
                systemConfiguration,
                nominalDehumCapacityPerUnit,
                nominalFlowPerUnit,
                moistureLoadPerUnit,
                freshFlowPerUnit,
                freshAirHumidity,
                returnAirHumidity
              );

            const modifiedOperatingCase = getModifiedOperatingCase(
              genericOperatingCaseWithSystemTypePropertyNames,
              currentSystemType.opcProperties,
              nominalFlowPerUnit,
              freshFlowPerUnit,
              targetHumidity
            );

            const defaultOperatingCaseForSystem =
              getDefaultOperatingCaseForSystem(
                currentSystemType,
                newProperties,
                climateSettings,
                translate
              );

            const operatingCase = PropertyValueSet.merge(
              modifiedOperatingCase,
              defaultOperatingCaseForSystem
            );

            const divergantSysComponent =
              getSysTemplateComponentWithNumberOfUnits(
                currentSystemType,
                newProperties,
                quantity
              );

            return {
              ...systemVariantForDhModel,
              newProperties: newProperties,
              operatingCases: [operatingCase],
              divergentSysComponent: divergantSysComponent,
              quantity: quantity,
              diffBetweenMoistureLoadCapacityAndLoadPerUnit:
                diffBetweenMoistureLoadCapacityAndLoadPerUnit,
              nominalDehumCapacity: Amount.create(
                nominalDehumCapacityPerUnit,
                Units.KilogramPerHour
              ),
              totalMoistureLoad: Amount.create(
                totalMoistureLoad,
                Units.KilogramPerHour
              )
            };
          });

          return soFarSystems.concat(
            systemTypeVariants
              .filter(s => s !== undefined)
              .map(s => s as Types.SystemVariant)
          );
        },
        [] as ReadonlyArray<Types.SystemVariant>
      );

      return {
        systemVariants: allSystemTypesVariants,
        reactHeaterType: reactHeaterType
      };
    });

  const variantGroupsMergedOnReactHeaterType =
    mergeVariantGroupsWithTheSameReactHeaterType(variantGroups);

  const variantGroupsWithOnePreSelectedSystem =
    getSystemVariantsWithBestSystemSelectedToBeCalculated(
      variantGroupsMergedOnReactHeaterType
    );

  return variantGroupsWithOnePreSelectedSystem;
}

function getSysTemplateComponentWithNumberOfUnits(
  system: Types.SystemType,
  newProperties: PropertyValueSet.PropertyValueSet,
  quantity: number
) {
  const sysTemplate = system.sysTemplates
    .find(t =>
      PropertyFilterHelpers.isValid(
        t.propertyFilter,
        PropertyFilter.Empty,
        newProperties
      )
    )!
    .components.map(t => ({
      id: t.id,
      properties: PropertyValueSet.fromString(t.properties ?? ""),
      visibleProperties: t.visibleProperties ?? [],
      propertyFilter: t.propertyFilter
    }))
    .find(c =>
      PropertyFilterHelpers.isValid(
        c.propertyFilter,
        PropertyFilter.Empty,
        newProperties
      )
    );

  const divergantSysComponent = !sysTemplate
    ? undefined
    : {
        id: sysTemplate.id,
        properties: PropertyValueSet.keepProperties(
          sysTemplate.visibleProperties.concat(["numberofunits"]),
          PropertyValueSet.merge(
            PropertyValueSet.merge(
              PropertyValueSet.fromString(`numberofunits=${quantity}`),
              sysTemplate.properties
            ),
            PropertyValueSet.merge(
              newProperties,
              createDefaultProperties(system.sysProperties)
            )
          )
        )
      };

  return divergantSysComponent;
}

function getGenericOperatingCaseWithSystemTypePropertyNames(
  genericOperatingCase: PropertyValueSet.PropertyValueSet,
  systemTypeProperties: ReadonlyArray<PropertyInfo>
): PropertyValueSet.PropertyValueSet {
  const getPropertyNameForSystemType = (
    possiblePropertyNames: ReadonlyArray<string>
  ) => {
    return possiblePropertyNames.find(ppn =>
      systemTypeProperties.some(p => p.name === ppn)
    );
  };

  const outdoorAirTemperatureName = getPropertyNameForSystemType([
    "outdoorairtemperature",
    "outdoortemperature"
  ]);
  const outdoorAirHumidityName = getPropertyNameForSystemType([
    "outdoorairhumidity",
    "outdoorhumidity"
  ]);
  const returnAirTemperatureName = getPropertyNameForSystemType([
    "returnairtemperature",
    "returntemp"
  ]);
  const returnAirHumidityName = getPropertyNameForSystemType([
    "returnairhumidity",
    "returnhum"
  ]);
  const customAirTemperatureName = getPropertyNameForSystemType([
    "customairtemperature"
  ]);
  const customAirHumidityName = getPropertyNameForSystemType([
    "customairhumidity"
  ]);
  const processInletExternalStaticName = getPropertyNameForSystemType([
    "processinletexternalstatic"
  ]);
  const processOutletExternalStaticName = getPropertyNameForSystemType([
    "processoutletexternalstatic"
  ]);
  const reactInletExternalStaticName = getPropertyNameForSystemType([
    "reactinletexternalstatic"
  ]);
  const reactOutletExternalStaticName = getPropertyNameForSystemType([
    "reactoutletexternalstatic"
  ]);
  const supplyOutletAirFlowName = getPropertyNameForSystemType([
    "supplyoutletairflow",
    "supplyoutletflow"
  ]);
  const supplyTargetHumidityName = getPropertyNameForSystemType([
    "supplytargethumidity",
    "targethum"
  ]);
  const preMixingAirFlowName = getPropertyNameForSystemType([
    "premixingboxairflow"
  ]);

  const propertiesMapping: ReadonlyArray<{
    readonly requirementPropertyName: string;
    readonly systemPropertyName: string | undefined;
  }> = [
    {
      requirementPropertyName: "outdoorairtemperature",
      systemPropertyName: outdoorAirTemperatureName
    },
    {
      requirementPropertyName: "source_outdoorairtemperature",
      systemPropertyName: outdoorAirTemperatureName
        ? "source_" + outdoorAirTemperatureName
        : undefined
    },
    {
      requirementPropertyName: "outdoorairhumidity",
      systemPropertyName: outdoorAirHumidityName
    },
    {
      requirementPropertyName: "source_outdoorairhumidity",
      systemPropertyName: outdoorAirHumidityName
        ? "source_" + outdoorAirHumidityName
        : undefined
    },
    {
      requirementPropertyName: "returnairtemperature",
      systemPropertyName: returnAirTemperatureName
    },
    {
      requirementPropertyName: "source_returnairtemperature",
      systemPropertyName: returnAirTemperatureName
        ? "source_" + returnAirTemperatureName
        : undefined
    },
    {
      requirementPropertyName: "returnairhumidity",
      systemPropertyName: returnAirHumidityName
    },
    {
      requirementPropertyName: "source_returnairhumidity",
      systemPropertyName: returnAirHumidityName
        ? "source_" + returnAirHumidityName
        : undefined
    },
    {
      requirementPropertyName: "customairtemperature",
      systemPropertyName: customAirTemperatureName
    },
    {
      requirementPropertyName: "source_customairtemperature",
      systemPropertyName: customAirTemperatureName
        ? "source_" + customAirTemperatureName
        : undefined
    },
    {
      requirementPropertyName: "customairhumidity",
      systemPropertyName: customAirHumidityName
    },
    {
      requirementPropertyName: "source_customairhumidity",
      systemPropertyName: customAirHumidityName
        ? "source_" + customAirHumidityName
        : undefined
    },
    {
      requirementPropertyName: "processinletexternalstatic",
      systemPropertyName: processInletExternalStaticName
    },
    {
      requirementPropertyName: "source_processinletexternalstatic",
      systemPropertyName: processInletExternalStaticName
        ? "source_" + processInletExternalStaticName
        : undefined
    },
    {
      requirementPropertyName: "processoutletexternalstatic",
      systemPropertyName: processOutletExternalStaticName
    },
    {
      requirementPropertyName: "source_processoutletexternalstatic",
      systemPropertyName: processOutletExternalStaticName
        ? "source_" + processOutletExternalStaticName
        : undefined
    },
    {
      requirementPropertyName: "reactinletexternalstatic",
      systemPropertyName: reactInletExternalStaticName
    },
    {
      requirementPropertyName: "source_reactinletexternalstatic",
      systemPropertyName: reactInletExternalStaticName
        ? "source_" + reactInletExternalStaticName
        : undefined
    },
    {
      requirementPropertyName: "reactoutletexternalstatic",
      systemPropertyName: reactOutletExternalStaticName
    },
    {
      requirementPropertyName: "source_reactoutletexternalstatic",
      systemPropertyName: reactOutletExternalStaticName
        ? "source_" + reactOutletExternalStaticName
        : undefined
    },
    {
      requirementPropertyName: "supplyoutletairflow",
      systemPropertyName: supplyOutletAirFlowName
    },
    {
      requirementPropertyName: "source_supplyoutletairflow",
      systemPropertyName: supplyOutletAirFlowName
        ? "source_" + supplyOutletAirFlowName
        : undefined
    },
    {
      requirementPropertyName: "supplytargethumidity",
      systemPropertyName: supplyTargetHumidityName
    },
    {
      requirementPropertyName: "source_supplytargethumidity",
      systemPropertyName: supplyTargetHumidityName
        ? "source_" + supplyTargetHumidityName
        : undefined
    },
    {
      requirementPropertyName: "premixingboxairflow",
      systemPropertyName: preMixingAirFlowName
    },
    {
      requirementPropertyName: "source_premixingboxairflow",
      systemPropertyName: preMixingAirFlowName
        ? "source_" + preMixingAirFlowName
        : undefined
    }
  ];

  return propertiesMapping.reduce((soFar, current) => {
    const property = PropertyValueSet.get(
      current.requirementPropertyName,
      soFar
    );
    if (property && current.systemPropertyName) {
      return PropertyValueSet.set(
        current.systemPropertyName,
        property,
        PropertyValueSet.removeProperty(current.requirementPropertyName, soFar)
      );
    } else {
      return soFar;
    }
  }, genericOperatingCase);
}

function getDhmodels(
  newMappings: ReadonlyArray<Types.NewMapping>,
  selectionNewProperties: PropertyValueSet.PropertyValueSet,
  systemNewProperties: ReadonlyArray<PropertyInfo>
) {
  const dhmodelProperty = systemNewProperties.find(p => p.name === "dhmodel");

  if (!dhmodelProperty) {
    return [];
  }

  const dhmodelItems = newMappings.reduce((soFar, current) => {
    if (
      current.name === "dhmodel" &&
      PropertyFilter.isValid(selectionNewProperties, current.filter)
    ) {
      const dhmodel = dhmodelProperty.items.find(
        i => PropertyValue.getInteger(i.value!) === parseInt(current.value, 10)
      );

      if (dhmodel) {
        return soFar.concat([dhmodel]);
      }
    }

    return soFar;
  }, [] as ReadonlyArray<PropertyItem>);

  return dhmodelItems;
}

function getSystemTypeSpecificProperties(
  newMappings: ReadonlyArray<Types.NewMapping>,
  systemProperties: ReadonlyArray<PropertyInfo>,
  genericNewProperties: PropertyValueSet.PropertyValueSet,
  dhmodelPropertyItem: PropertyItem
) {
  // Dhmodel is special-case since its what we are looping on outside of this function and if we run it with the others it might find more than one valid value
  const validateDhModel = (
    soFar: {
      readonly validProperties: ReadonlyArray<PropertyInfo>;
      readonly invalidProperties: ReadonlyArray<Types.InvalidProperty>;
    },
    current: PropertyInfo,
    currentConfiguration: PropertyValueSet.PropertyValueSet
  ) => {
    const isDhmodelValid =
      dhmodelPropertyItem.value &&
      PropertyFilter.isValid(currentConfiguration, current.validationFilter);

    if (isDhmodelValid) {
      return {
        ...soFar,
        validProperties: soFar.validProperties.concat([
          {
            ...current,
            defaultValues: [
              {
                value: dhmodelPropertyItem.value,
                propertyFilter: PropertyFilter.Empty
              }
            ]
          }
        ])
      };
    } else {
      return {
        ...soFar,
        invalidProperties: soFar.invalidProperties.concat([
          {
            type: "not-valid-with-configuration",
            propertyName: current.name,
            configuration: currentConfiguration,
            propertyValue: dhmodelPropertyItem.value!,
            propertyFilter: current.validationFilter
          }
        ])
      };
    }
  };

  const validateProperty = (
    soFar: {
      readonly validProperties: ReadonlyArray<PropertyInfo>;
      readonly invalidProperties: ReadonlyArray<Types.InvalidProperty>;
    },
    current: PropertyInfo,
    currentConfiguration: PropertyValueSet.PropertyValueSet
  ) => {
    // Find all mapping rows for property
    const mappingRows = newMappings.filter(r => r.name === current.name);

    // If no mapping rows exists we assume its not relevant and we leave it in the set unchanged
    if (mappingRows.length === 0) {
      return {
        ...soFar,
        validProperties: soFar.validProperties.concat([current])
      };
    } else {
      const mappingRow = mappingRows.find(r =>
        PropertyFilter.isValid(genericNewProperties, r.filter)
      );

      // If there is no valid mapping
      if (!mappingRow) {
        return {
          ...soFar,
          invalidProperties: soFar.invalidProperties.concat([
            {
              type: "no-valid-mapping",
              propertyName: current.name,
              selectionNewProperties: genericNewProperties,
              mappingRowsFilter: mappingRows.map(r => r.filter)
            }
          ])
        };
      }

      // Either the value is hardcoded in the mapping table or it is the same as in the selection set.
      const value = mappingRow.value.startsWith("{")
        ? PropertyValueSet.getValue(
            mappingRow.value.substring(1, mappingRow.value.length - 1),
            genericNewProperties
          )
        : PropertyValue.create("integer", mappingRow.value);

      const maybeMappedValueInItems = current.items.find(i =>
        i.value ? PropertyValue.equals(i.value, value) : false
      );

      if (!maybeMappedValueInItems) {
        return {
          ...soFar,
          invalidProperties: soFar.invalidProperties.concat([
            {
              type: "undefined-value",
              propertyName: current.name,
              propertyValue: value
            }
          ])
        };
      }

      const isValueValid = PropertyFilter.isValid(
        currentConfiguration,
        maybeMappedValueInItems.validationFilter
      );

      if (isValueValid) {
        return {
          ...soFar,
          validProperties: soFar.validProperties.concat([
            {
              ...current,
              defaultValues: [
                { value: value, propertyFilter: PropertyFilter.Empty }
              ]
            }
          ])
        };
      } else {
        return {
          ...soFar,
          invalidProperties: soFar.invalidProperties.concat([
            {
              type: "not-valid-with-configuration",
              propertyName: current.name,
              configuration: currentConfiguration,
              propertyValue: value,
              propertyFilter: maybeMappedValueInItems.validationFilter
            }
          ])
        };
      }
    }
  };

  let validatatedProperties: {
    readonly validProperties: ReadonlyArray<PropertyInfo>;
    readonly invalidProperties: ReadonlyArray<Types.InvalidProperty>;
  } = { invalidProperties: [], validProperties: [] };
  let propertiesSoFar = PropertyValueSet.Empty;

  for (let i = 0; i < 4; i++) {
    validatatedProperties = systemProperties.reduce(
      (soFar, current) => {
        const currentConfiguration = PropertyValueSet.merge(
          propertiesSoFar,
          createDefaultProperties(soFar.validProperties)
        );

        if (current.name === "dhmodel") {
          return validateDhModel(soFar, current, currentConfiguration);
        }
        return validateProperty(soFar, current, currentConfiguration);
      },
      {
        validProperties: [] as ReadonlyArray<PropertyInfo>,
        invalidProperties: [] as ReadonlyArray<Types.InvalidProperty>
      }
    );

    if (validatatedProperties.invalidProperties.length === 0) {
      return validatatedProperties;
    }

    propertiesSoFar = createDefaultProperties(
      validatatedProperties.validProperties
    );
  }

  return validatatedProperties;
}

function getDefaultOperatingCaseForSystem(
  systemType: Types.SystemType,
  newProperties: PropertyValueSet.PropertyValueSet,
  climateSettings: PropertyValueSet.PropertyValueSet,
  translate: LanguageTexts.Translate
): PropertyValueSet.PropertyValueSet {
  const opcTemplates = systemType.opcTemplates
    .find(t =>
      PropertyFilterHelpers.isValid(
        t.propertyFilter,
        PropertyFilter.Empty,
        newProperties
      )
    )!
    .components.filter(c =>
      PropertyFilterHelpers.isValid(
        c.propertyFilter,
        PropertyFilter.Empty,
        newProperties
      )
    );

  const opcTemplateComponents: ReadonlyArray<{
    readonly id: string;
    readonly properties: PropertyValueSet.PropertyValueSet;
  }> = opcTemplates.map(opc => ({
    id: opc.id,
    properties: PropertyValueSet.fromString(opc.properties || "")
  }));

  const opcProductProperties = systemType.opcProperties.map(p => ({
    name: p.name,
    quantity: p.quantity,
    defaultValues: p.defaultValues,
    valueSources: p.valueSources.map(v => ({
      id: v.id,
      value: v.value,
      propertyValueSourceId: v.propertyValueSourceId,
      propertyFilter: PropertyFilter.toString(v.propertyFilter),
      claimFilter: "",
      parameters: v.parameters
    })),
    items: p.items
      .filter(v =>
        PropertyFilterHelpers.isValid(
          v.validationFilter,
          PropertyFilter.Empty,
          newProperties
        )
      )
      .map(i => ({
        id: i.id!,
        value: PropertyValue.toString(i.value!)
      })),
    translate
  }));

  const opcPreProcess = getOpcPreProcess(systemType.id, newProperties);

  const operatingCases = createInitialOperatingCases(
    climateSettings,
    opcTemplateComponents,
    opcProductProperties,
    translate
  ).map(p => ({
    ...p,
    settings: PropertyValueSet.merge(opcPreProcess(), p.settings)
  }));

  return operatingCases.map(opc => opc.settings)[0];
}

function getOpcPreProcess(
  systemTypeId: string,
  newProperties: PropertyValueSet.PropertyValueSet
) {
  if (systemTypeId.toUpperCase() === "DSP") {
    return () => opcPreProcess.dspPreProcessOpc(newProperties);
  } else if (systemTypeId.toUpperCase() === "MCD") {
    return () => opcPreProcess.mcdPreProcessOpc(newProperties);
  } else if (systemTypeId.toUpperCase() === "MLP") {
    return () => opcPreProcess.mlpPreProcessOpc(newProperties);
  } else if (systemTypeId.toUpperCase() === "MXN") {
    return () => opcPreProcess.mxnPreProcessOpc(newProperties);
  } else if (systemTypeId.toUpperCase() === "MXO") {
    return () => opcPreProcess.mxoPreProcessOpc(newProperties);
  } else {
    return () => PropertyValueSet.Empty;
  }
}

function createDefaultProperties(
  properties: ReadonlyArray<PropertyInfo>
): PropertyValueSet.PropertyValueSet {
  return ProductProperties.autoSelectSingleValidValue(
    properties.map(p => ({
      name: p.name,
      quantity: p.quantity,
      validationFilter: PropertyFilter.toString(p.validationFilter),
      values: p.items.map(v => ({
        value: PropertyValue.toString(v.value!)!,
        validationFilter: PropertyFilter.toString(v.validationFilter)
      }))
    })),
    ProductProperties.createDefaultProperties(
      properties.map(p => ({
        id: "",
        name: p.name,
        quantity: p.quantity,
        defaultValues: p.defaultValues,
        values: p.items.map(v => ({
          id: "",
          value: PropertyValue.toString(v.value!)!
        }))
      })),
      true
    ),
    ""
  );
}

function getReactHeaterType(
  pvs: PropertyValueSet.PropertyValueSet
): Types.ReactHeaterType {
  const reactHeaterTypeInteger = PropertyValueSet.getInteger(
    "reactheatertype",
    pvs
  )!;

  const reactHeaterType =
    reactHeaterTypeInteger === 1
      ? "electric"
      : reactHeaterTypeInteger === 2
      ? "gas"
      : "steam";

  return reactHeaterType;
}

function mergeVariantGroupsWithTheSameReactHeaterType(
  systemVariantGroups: ReadonlyArray<Types.SystemVariantsGroup>
): ReadonlyArray<Types.SystemVariantsGroup> {
  const reactHeaterGroupMerged = systemVariantGroups.reduce(
    (soFar, current) => {
      const reactHeaterGroup = soFar.find(
        s => s.reactHeaterType === current.reactHeaterType
      );

      if (reactHeaterGroup === undefined) {
        return soFar.concat([current]);
      }

      return soFar
        .filter(sf => sf.reactHeaterType !== current.reactHeaterType)
        .concat([
          {
            ...reactHeaterGroup,
            systemVariants: reactHeaterGroup.systemVariants.concat(
              current.systemVariants
            )
          }
        ]);
    },
    [] as ReadonlyArray<Types.SystemVariantsGroup>
  );

  return reactHeaterGroupMerged;
}

function getSystemVariantsWithBestSystemSelectedToBeCalculated(
  systemVariantGroups: ReadonlyArray<Types.SystemVariantsGroup>
): ReadonlyArray<Types.SystemVariantsGroup> {
  const variantGroupsWithOnePreSelectedSystem = systemVariantGroups.reduce(
    (soFar, current) => {
      const sortedVariants = current.systemVariants
        .filter(
          v =>
            v.diffBetweenMoistureLoadCapacityAndLoadPerUnit !== undefined &&
            v.quantity === 1
        )
        .sort(
          (a, b) =>
            a.diffBetweenMoistureLoadCapacityAndLoadPerUnit! -
            b.diffBetweenMoistureLoadCapacityAndLoadPerUnit!
        );

      if (sortedVariants.length === 0) {
        return soFar.concat([current]);
      }

      const selectedVariantId = sortedVariants[0].identifier;

      return soFar.concat([
        {
          ...current,
          systemVariants: current.systemVariants.map(sv => {
            if (sv.identifier === selectedVariantId) {
              return { ...sv, shouldCalculate: true };
            } else {
              return sv;
            }
          })
        }
      ]);
    },
    [] as ReadonlyArray<Types.SystemVariantsGroup>
  );

  return variantGroupsWithOnePreSelectedSystem;
}

function checkSystemRequirements(
  newMappings: ReadonlyArray<Types.NewMapping>,
  pvs: PropertyValueSet.PropertyValueSet
): {
  readonly isSystemRequirementsMet: boolean;
  readonly systemRequirementsFilter: PropertyFilter.PropertyFilter | undefined;
} {
  const systemRequirementsFilter = newMappings.find(
    row => row.name === "systemrequirements"
  )?.filter;

  const isSystemRequirementsMet = !systemRequirementsFilter
    ? false
    : PropertyFilter.isValid(pvs, systemRequirementsFilter);

  return { isSystemRequirementsMet, systemRequirementsFilter };
}

function getOperatingCaseValues(
  requirementsOpc: PropertyValueSet.PropertyValueSet
): {
  readonly totalMoistureLoad: number;
  readonly returnAirHumidity: number;
  readonly freshAirFlow: number;
  readonly freshAirHumidity: number;
} {
  const totalMoistureLoad = Amount.valueAs(
    Units.KilogramPerHour,
    PropertyValueSet.getAmount<Quantity.MassFlow>(
      "mlcbuildingmoistureload",
      requirementsOpc
    )!
  );

  const returnAirHumidity = Amount.valueAs(
    Units.KilogramPerKilogram,
    PropertyValueSet.getAmount<Quantity.HumidityRatio>(
      "returnairhumidity",
      requirementsOpc
    )!
  );

  const freshAirFlow = Amount.valueAs(
    Units.KilogramPerHour,
    PropertyValueSet.getAmount<Quantity.MassFlow>(
      "mlcfreshflow",
      requirementsOpc
    ) ?? Amount.create(0, Units.KilogramPerHour)
  );

  const freshAirHumidity = Amount.valueAs(
    Units.KilogramPerKilogram,
    PropertyValueSet.getAmount<Quantity.HumidityRatio>(
      "mlcfreshabshumidity",
      requirementsOpc
    ) ?? Amount.create(0, Units.GramPerKilogram)
  );

  return {
    totalMoistureLoad,
    returnAirHumidity,
    freshAirFlow,
    freshAirHumidity
  };
}

function getNominalValues(
  dhmodel: PropertyItem,
  flowLimits: ReadonlyArray<Types.FlowLimits>
): {
  readonly nominalFlowPerUnit: number | undefined;
  readonly nominalDehumCapacityPerUnit: number | undefined;
} {
  const limits = flowLimits.find(n =>
    PropertyFilter.isValid(
      PropertyValueSet.fromString(
        `dhmodel=${
          dhmodel.value ? PropertyValue.getInteger(dhmodel.value) : ""
        }`
      ),
      n.filter
    )
  );

  if (limits === undefined) {
    return {
      nominalFlowPerUnit: undefined,
      nominalDehumCapacityPerUnit: undefined
    };
  }

  const nominalFlowPerUnit = Amount.valueAs(
    Units.KilogramPerHour,
    limits.nominalFlow
  );

  const nominalDehumCapacityPerUnit = Amount.valueAs(
    Units.KilogramPerHour,
    limits.nominalDehumCapacity
  );

  return {
    nominalFlowPerUnit,
    nominalDehumCapacityPerUnit
  };
}

function tryToGetQuantity(
  returnAirHumidity: number,
  nominalFlowPerUnit: number,
  freshAirFlow: number,
  freshAirHumidity: number,
  totalMoistureLoad: number,
  nominalDehumCapacityPerUnit: number,
  systemConfiguration: Types.SystemConfiguration
): {
  readonly quantity: number | undefined;
  readonly invalidThreshold: Types.InvalidThreshold | undefined;
} {
  const getQuantity = () => {
    switch (systemConfiguration) {
      case "closed-system":
        return {
          quantity: Math.ceil(totalMoistureLoad / nominalDehumCapacityPerUnit),
          invalidThreshold: undefined
        };

      case "closed-system-with-make-up-air":
        const quantityUnitForMakeUp = Math.ceil(
          (freshAirFlow * (freshAirHumidity - returnAirHumidity) +
            totalMoistureLoad) /
            nominalDehumCapacityPerUnit
        );
        const quantityFreshFlow = Math.ceil(freshAirFlow / nominalFlowPerUnit);
        return {
          quantity: Math.max(...[quantityUnitForMakeUp, quantityFreshFlow]),
          invalidThreshold: undefined
        };

      case "open-system":
        if (
          nominalDehumCapacityPerUnit <=
          nominalFlowPerUnit * (freshAirHumidity - returnAirHumidity)
        ) {
          return {
            quantity: undefined,
            invalidThreshold: { type: "invalid-treshold" }
          };
        }
        return {
          quantity: Math.ceil(
            totalMoistureLoad /
              (nominalDehumCapacityPerUnit -
                nominalFlowPerUnit * (freshAirHumidity - returnAirHumidity))
          ),
          invalidThreshold: undefined
        };
      default:
        return exhaustiveCheck(systemConfiguration, true);
    }
  };

  return getQuantity() as {
    readonly quantity: number | undefined;
    readonly invalidThreshold: Types.InvalidThreshold | undefined;
  };
}

function getDiffBetweenMoistureLoadCapacityAndLoadPerUnit(
  systemConfiguration: Types.SystemConfiguration,
  nominalDehumCapacityPerUnit: number,
  nominalFlowPerUnit: number,
  moistureLoadPerUnit: number,
  freshFlowPerUnit: number,
  freshAirHumidity: number,
  returnAirHumidity: number
) {
  switch (systemConfiguration) {
    case "closed-system":
      return (
        (nominalDehumCapacityPerUnit - moistureLoadPerUnit) /
        nominalDehumCapacityPerUnit
      );
    case "closed-system-with-make-up-air":
      return (
        (nominalDehumCapacityPerUnit -
          freshFlowPerUnit * (freshAirHumidity - returnAirHumidity) -
          moistureLoadPerUnit) /
        nominalDehumCapacityPerUnit
      );
    case "open-system":
      return (
        (nominalDehumCapacityPerUnit -
          nominalFlowPerUnit * (freshAirHumidity - returnAirHumidity) -
          moistureLoadPerUnit) /
        nominalDehumCapacityPerUnit
      );
    default:
      exhaustiveCheck(systemConfiguration, true);
  }
}

function getModifiedOperatingCase(
  operatingCase: PropertyValueSet.PropertyValueSet,
  systemTypeProperties: ReadonlyArray<PropertyInfo>,
  nominalFlowPerUnit: number,
  freshFlowPerUnit: number,
  targetHumidity: number
): PropertyValueSet.PropertyValueSet {
  const {
    supplyOutletAirFlowName,
    preMixingAirFlowName,
    supplyTargetHumidityName
  } = getSystemTypePropertyNames(systemTypeProperties);

  const modifiedoperatingCase = PropertyValueSet.setAmount(
    supplyOutletAirFlowName,
    Amount.create(nominalFlowPerUnit, Units.KilogramPerHour),
    PropertyValueSet.setAmount(
      preMixingAirFlowName,
      Amount.create(freshFlowPerUnit, Units.KilogramPerHour),
      PropertyValueSet.setAmount(
        supplyTargetHumidityName,
        Amount.create(targetHumidity, Units.KilogramPerKilogram),
        operatingCase
      )
    )
  );

  return modifiedoperatingCase;
}

function getSystemTypePropertyNames(
  systemTypeProperties: ReadonlyArray<PropertyInfo>
) {
  const getPropertyNameForSystemType = (
    possiblePropertyNames: ReadonlyArray<string>
  ) => {
    return possiblePropertyNames.find(ppn =>
      systemTypeProperties.some(p => p.name === ppn)
    )!;
  };

  const supplyOutletAirFlowName = getPropertyNameForSystemType([
    "supplyoutletairflow",
    "supplyoutletflow"
  ]);
  const supplyTargetHumidityName = getPropertyNameForSystemType([
    "supplytargethumidity",
    "targethum"
  ]);
  const preMixingAirFlowName = getPropertyNameForSystemType([
    "premixingboxairflow"
  ]);

  return {
    supplyOutletAirFlowName,
    supplyTargetHumidityName,
    preMixingAirFlowName
  };
}

function modifyNewPropertiesDefinitions(
  systemType: Types.SystemType
): ReadonlyArray<PropertyInfo> {
  const modifiedNewPropertiesDefinitions = systemType.newProperties.map(np => {
    const propertyOverride = newPropertiesOverrides.find(
      npo => npo.systemType === systemType.id && npo.propertyName === np.name
    );

    if (propertyOverride === undefined) {
      return np;
    }

    return {
      ...np,
      items: np.items.map(i => {
        if (i.value?.value === propertyOverride.propertyValue.value) {
          return {
            ...i,
            validationFilter: propertyOverride.replacementFilter
          };
        }
        return i;
      })
    };
  });

  return modifiedNewPropertiesDefinitions;
}

function getNewExternalMixingValue(
  systemConfiguration: Types.SystemConfiguration | undefined
): number {
  if (systemConfiguration === undefined) {
    return 0;
  }

  switch (systemConfiguration) {
    case "open-system":
    case "closed-system":
      return 0;
    case "closed-system-with-make-up-air":
      return 1;
    default:
      return exhaustiveCheck(systemConfiguration, true);
  }
}

function getProcessFanModeValue(
  systemConfiguration: Types.SystemConfiguration | undefined
): number {
  if (systemConfiguration === undefined) {
    return 1;
  }
  switch (systemConfiguration) {
    case "closed-system":
      return 1;
    case "open-system":
    case "closed-system-with-make-up-air":
      return 2;
    default:
      return exhaustiveCheck(systemConfiguration, true);
  }
}

//tslint:disable-next-line
