import * as React from "react";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as SharedState from "../shared-state";
import * as Types from "./types";
import * as Tools from "./tools";
import * as XlsxExporter from "@genesys/client-core/lib/exporter";
import styled from "styled-components";
import {
  GenesysSelect,
  ReadOnlyTable,
  S1,
  StandardButton
} from "@genesys/ui-elements";
import { PropertyValueSet } from "@genesys/property";
import { Unit, Quantity, Format } from "@genesys/uom";
import { AmountFormatSelector } from "../amount-format-selector";

const SubContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
  > span {
    margin-right: 5px;
  }
`;

const ResultsContainer = styled.div``;

const BoxContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 18px 0 rgb(0 0 0 / 10%);
  padding-bottom: 10px;
  padding-left: 7px;

  :nth-child(2) {
    margin-top: 10px;
  }

  > span {
    > h4 {
      word-break: break-word;
      width: 105px;
    }
  }
`;

const energyListResultFieldGroup = "EnergyListResult";

export function ListResultView({
  sharedState,
  selectedListPreset,
  listPresetOnChange,
  onFormatChanged,
  onFormatCleared,
  energyResults
}: {
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;

  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
  readonly sharedState: SharedState.State;
  readonly selectedListPreset: Types.ListPresets;
  readonly energyResults: ReadonlyArray<Types.EnergyResult>;
  readonly listPresetOnChange: (visualizer: Types.ListPresets) => void;
}) {
  const translate = sharedState.translate;
  const showMonthly = energyResults.every(er => canShowMonthly(er));

  let listPresets: ReadonlyArray<{
    readonly value: Types.ListPresets;
    readonly title: string;
  }> = [
    { value: "bins", title: translate(LanguageTexts.bins()) },
    { value: "months", title: translate(LanguageTexts.months()) }
  ];

  if (!showMonthly) {
    listPresets = listPresets.filter(l => l.value !== "months");
  }

  return (
    <>
      <SubContainer>
        <span>{translate(LanguageTexts.showResultFor())}</span>
        <GenesysSelect
          width={80}
          height={35}
          options={listPresets.map(b => ({
            value: b.value,
            title: b.title
          }))}
          value={selectedListPreset}
          onChange={e => {
            listPresetOnChange(e.target.value as Types.ListPresets);
          }}
        />
      </SubContainer>
      <DisplayResults
        energyResults={energyResults}
        selectedListPreset={selectedListPreset}
        sharedState={sharedState}
        onFormatChanged={onFormatChanged}
        onFormatCleared={onFormatCleared}
      />
    </>
  );
}

function DisplayResults({
  energyResults,
  selectedListPreset,
  sharedState,
  onFormatChanged,
  onFormatCleared
}: {
  readonly sharedState: SharedState.State;
  readonly energyResults: ReadonlyArray<Types.EnergyResult>;
  readonly selectedListPreset: Types.ListPresets;
  readonly onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => void;

  readonly onFormatCleared: (fieldGroup: string, fieldName: string) => void;
}) {
  const translate = sharedState.translate;
  const createAmountFormat = sharedState.screenAmounts.getAmountFormat;

  return (
    <ResultsContainer>
      {energyResults.map((eR, i) => {
        const list =
          selectedListPreset === "bins"
            ? Tools.createBinList(eR, translate, (fieldname, amount) => {
                return createAmountFormat(
                  energyListResultFieldGroup,
                  fieldname,
                  amount
                );
              })
            : Tools.createMonthlyList(eR, translate, (fieldname, amount) => {
                return createAmountFormat(
                  energyListResultFieldGroup,
                  fieldname,
                  amount
                );
              });

        const exportData = () =>
          [
            list.formattedHeader.map(h => h.name),
            list.formattedHeader.map(h =>
              h.format ? Format.getUnitFormat(h.format.unit)!.label : ""
            )
          ].concat(list.formattedRows);

        return (
          <BoxContainer key={i}>
            {eR.name && (
              <span>
                <S1 color="primary">{translate(eR.name)}</S1>
              </span>
            )}
            <div>
              <ReadOnlyTable
                headers={list.formattedHeader.map(header => ({
                  header: header.name,
                  visible: true,
                  amountFormatSelector: () => {
                    const format = header.format;
                    if (format === undefined) {
                      return null;
                    }
                    return (
                      <>
                        [
                        <AmountFormatSelector
                          type="AmountFormatSelectorProps"
                          fieldName={header.internalName}
                          fieldGroup={energyListResultFieldGroup}
                          conversionParameters={undefined}
                          amountFormat={createAmountFormat(
                            energyListResultFieldGroup,
                            header.internalName,
                            format.unit.quantity
                          )}
                          translate={sharedState.translate}
                          onFormatChanged={(unit, decimalCount) => {
                            onFormatChanged(
                              energyListResultFieldGroup,
                              header.internalName,
                              unit,
                              decimalCount
                            );
                          }}
                          onFormatCleared={() => {
                            onFormatCleared(
                              energyListResultFieldGroup,
                              header.internalName
                            );
                          }}
                        />
                        ]
                      </>
                    );
                  }
                }))}
                rows={list.formattedRows
                  .map(row => ({
                    name: row[0],
                    results: [...row].splice(1),
                    isBold: false
                  }))
                  .concat({
                    name: list.totalRow[0],
                    results: list.totalRow.splice(1),
                    isBold: true
                  })
                  .concat({
                    name: list.averageRow[0],
                    results: list.averageRow.splice(1),
                    isBold: true
                  })}
              />

              <XlsxExporter.XlsxSingleSheetExport
                data={exportData()}
                filename={"Energy"}
              >
                {download => (
                  <StandardButton
                    buttonType="PrimaryBlue"
                    size="Small"
                    onClick={() => download()}
                  >
                    {translate(LanguageTexts.exportText())}
                  </StandardButton>
                )}
              </XlsxExporter.XlsxSingleSheetExport>
            </div>
          </BoxContainer>
        );
      })}
    </ResultsContainer>
  );
}

function canShowMonthly(energyResult: Types.EnergyResult): boolean {
  return energyResult.binResults.every(br =>
    Types.propertiesRequiredForMonthlyView.every(pn =>
      PropertyValueSet.hasProperty(pn, br.binData)
    )
  );
}
