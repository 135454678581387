import { Cmd } from "@typescript-tea/core";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../shared-state";
import * as TopMenu from "../../top-menu";
import * as PerformanceOverview from "../../performance-overview";
import * as Printouts from "../../modals/printouts";
import * as ComponentEditor from "../../modals/component-editor";
import * as OperatingCases from "../../../operating-case-selector";
import * as MechanicalSketch from "../../modals/mechanical-sketch";
import * as BuildInSc from "../../modals/build-in-solid-contacts";
import * as BuildInRevit from "../../modals/build-in-revit";
import * as Accessories from "../../modals/accessories";
import * as Price from "../../modals/price";
import * as PsychrometricChart from "../../modals/psychrometric-chart-presenter";
import * as Energy from "../../modals/energy";
import * as MCompare from "../../modals/m-compare";
import * as Pid from "../../modals/pid";
import * as PropertyGroups from "../../modals/property-groups";
import * as SystemAccessories from "../../modals/system-accessories";
import * as PlenumSize from "../../modals/plenum-size";
import * as NotesAndDocuments from "../../modals/notes-and-documents";
import * as MultipleSystemsEditor from "../../modals/multiple-systems-editor";
import * as DataCenterEditor from "../../modals/data-center-editor";
import * as CondensationAnalysis from "../../modals/condensation-analysis";
import * as SystemActions from "../../../system-actions";
import * as LockedSystemCalculation from "../../modals/locked-system-calculation";
import { ConfiguratorAction } from "../../shell-system-configurator/configurator-actions";
import { CombinedActions, DispatchActions } from "../action";
import { State, ShouldReloadSystem } from "../types";

export function updateDispatches(
  action: DispatchActions,
  state: State,
  sharedState: SharedState.State
): readonly [
  State,
  ReadonlyArray<ConfiguratorAction | undefined>?,
  ReadonlyArray<SharedState.Action | undefined>?,
  Cmd<CombinedActions>?,
  ShouldReloadSystem?
] {
  switch (action.type) {
    case "dispatchSystemActions": {
      if (!state.systemActionsState) {
        return [state];
      }

      const [
        systemActionsState,
        SystemActionsCmd,
        sharedStateActions,
        isDone,
        urL
      ] = SystemActions.update(
        action.action,
        state.systemActionsState,
        sharedState
      );

      if (isDone) {
        return [
          {
            ...state,
            actionCompleteUrl: urL,
            systemActionsState: undefined
          },
          undefined,
          [
            ...(sharedStateActions ?? []),
            SharedState.Action.loadLastOpenedSystemsAndFavorites(true)
          ],
          Cmd.map(DispatchActions.dispatchSystemActions, SystemActionsCmd),
          true
        ];
      } else {
        return [
          {
            ...state,
            actionCompleteUrl: urL,
            systemActionsState: systemActionsState
          },
          undefined,
          sharedStateActions,
          Cmd.map(DispatchActions.dispatchSystemActions, SystemActionsCmd)
        ];
      }
    }

    case "dispatchTopMenu": {
      return [
        {
          ...state,
          topMenu: TopMenu.update(action.action, state.topMenu, sharedState)
        }
      ];
    }
    case "dispatchPeformanceOverview": {
      const [performanceOverviewState, performanceOverviewSharedStateAction] =
        PerformanceOverview.update(action.action, state.performanceOverview);
      return [
        {
          ...state,
          performanceOverview: performanceOverviewState
        },
        undefined,
        [performanceOverviewSharedStateAction]
      ];
    }
    case "dispatchComponentEditor": {
      if (state.modal.type !== "ComponentEditor") {
        return [state];
      }

      const [
        componentEditorState,
        componentEditorCmd,
        componentEditorStateSharedStateAction
      ] = ComponentEditor.update(
        action.action,
        state.modal.state,
        sharedState,
        state.system.id,
        state.modal.selectedComponentId
      );
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: componentEditorState
          }
        },
        undefined,
        [componentEditorStateSharedStateAction],
        Cmd.map(DispatchActions.dispatchComponentEditor, componentEditorCmd)
      ];
    }
    case "dispatchOperatingCases": {
      if (state.modal.type !== "OperatingCases") {
        return [state];
      }

      const [
        operatingCasesState,
        operatingCasesCmd,
        operatingCasesSharedStateAction
      ] = OperatingCases.update(action.action, state.modal.state, sharedState);

      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: operatingCasesState
          }
        },
        undefined,
        [operatingCasesSharedStateAction],
        Cmd.map(DispatchActions.dispatchOperatingCases, operatingCasesCmd)
      ];
    }
    case "dispatchPrintouts": {
      if (state.modal.type !== "Print") {
        return [state];
      }

      const [printoutsState, printOutSharedStateAction] = Printouts.update(
        action.action,
        state.modal.state,
        state.system.file.systemTypeId,
        state.system.salesOrganisationId
      );
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: printoutsState
          }
        },
        undefined,
        [printOutSharedStateAction]
      ];
    }
    case "dispatchPrice": {
      if (state.modal.type !== "Price") {
        return [state];
      }

      const [priceState, priceCmd] = Price.update(
        action.action,
        state.modal.state,
        sharedState,
        state.system
      );
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: priceState
          }
        },
        undefined,
        undefined,
        Cmd.map(DispatchActions.dispatchPrice, priceCmd)
      ];
    }
    case "dispatchEnergy": {
      if (state.modal.type !== "Energy") {
        return [state];
      }

      const [energyState, energyCmd, energySharedStateAction] = Energy.update(
        action.action,
        state.modal.state,
        sharedState,
        state.system
      );
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: energyState
          }
        },
        undefined,
        [energySharedStateAction],
        Cmd.map(DispatchActions.dispatchEnergy, energyCmd)
      ];
    }
    case "dispatchMCompare": {
      if (state.modal.type !== "MCompare") {
        return [state];
      }

      const [mCompareState, mCompareCmd, mCompareSharedStateAction] =
        MCompare.update(
          action.action,
          state.modal.state,
          sharedState,
          state.system
        );
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: mCompareState
          }
        },
        undefined,
        [mCompareSharedStateAction],
        Cmd.map(DispatchActions.dispatchMCompare, mCompareCmd)
      ];
    }
    case "dispatchAccessories": {
      if (state.modal.type !== "Accessories") {
        return [state];
      }

      const [accessoriesState, accessoriesCmd, sharedStateAction] =
        Accessories.update(
          action.action,
          state.modal.state,
          sharedState,
          state.system.id
        );
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: accessoriesState
          }
        },
        undefined,
        [sharedStateAction],
        Cmd.map(DispatchActions.dispatchAccessories, accessoriesCmd)
      ];
    }
    case "dispatchMechanicalSketch": {
      if (state.modal.type !== "MechanicalSketch") {
        return [state];
      }

      const sysProperties = state.system.components.find(x =>
        x.productId.endsWith("SYS")
      )?.properties;

      if (sysProperties === undefined) {
        return [state];
      }

      const [mechanicalState, mechanicalStateCmd, sharedStateCmd] =
        MechanicalSketch.update(
          action.action,
          state.modal.state,
          sharedState,
          state.system.id,
          sysProperties
        );
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: mechanicalState
          }
        },
        undefined,
        [sharedStateCmd],
        Cmd.map(DispatchActions.dispatchMechanicalSketch, mechanicalStateCmd)
      ];
    }
    case "dispatchBuildInSc": {
      if (state.modal.type !== "BuildInSc") {
        return [state];
      }

      const [buildInScState, buildInScCmd, sharedStateCmd] = BuildInSc.update(
        action.action,
        state.modal.state,
        sharedState
      );
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: buildInScState
          }
        },
        undefined,
        [sharedStateCmd],
        Cmd.map(DispatchActions.dispatchBuildInSc, buildInScCmd)
      ];
    }
    case "dispatchBuildInRevit": {
      if (state.modal.type !== "BuildInRevit") {
        return [state];
      }

      const [buildInRevitState, buildInRevitCmd, sharedStateCmd] =
        BuildInRevit.update(action.action, state.modal.state, sharedState);
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: buildInRevitState
          }
        },
        undefined,
        [sharedStateCmd],
        Cmd.map(DispatchActions.dispatchBuildInRevit, buildInRevitCmd)
      ];
    }
    case "dispatchPsychrometricChart": {
      if (state.modal.type !== "PsychrometricChart") {
        return [state];
      }
      const [PsychrometricChartState, PsychrometricChartCmd, sharedStateCmd] =
        PsychrometricChart.update(
          action.action,
          state.modal.state,
          sharedState
        );
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: PsychrometricChartState
          }
        },
        undefined,
        [sharedStateCmd],
        Cmd.map(
          DispatchActions.dispatchPsychrometricChart,
          PsychrometricChartCmd
        )
      ];
    }
    case "dispatchPid": {
      if (state.modal.type !== "Pid") {
        return [state];
      }
      const [pidState, pidCmd, sharedStateCmd] = Pid.update(
        action.action,
        state.modal.state,
        sharedState
      );
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: pidState
          }
        },
        undefined,
        [sharedStateCmd],
        Cmd.map(DispatchActions.dispatchPid, pidCmd)
      ];
    }
    case "dispatchPropertyGroups": {
      if (state.modal.type !== "PropertyGroup") {
        return [state];
      }
      const [propertyGroupsState, propertyGroupsCmd, sharedStateCmd] =
        PropertyGroups.update(action.action, state.modal.state, sharedState);
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: propertyGroupsState
          }
        },
        undefined,
        [sharedStateCmd],
        Cmd.map(DispatchActions.dispatchPropertyGroups, propertyGroupsCmd)
      ];
    }
    case "dispatchSystemAccessories": {
      if (state.modal.type !== "SystemAccessories") {
        return [state];
      }
      const [systemAccessoriesState, systemAccessoriesCmd, sharedStateCmd] =
        SystemAccessories.update(action.action, state.modal.state, sharedState);
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: systemAccessoriesState
          }
        },
        undefined,
        [sharedStateCmd],
        Cmd.map(DispatchActions.dispatchSystemAccessories, systemAccessoriesCmd)
      ];
    }
    case "dispatchPlenumSize": {
      if (state.modal.type !== "PlenumSize") {
        return [state];
      }
      const [PlenumSizeState, PlenumSizeCmd, sharedStateCmd] =
        PlenumSize.update(action.action, state.modal.state, sharedState);
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: PlenumSizeState
          }
        },
        undefined,
        [sharedStateCmd],
        Cmd.map(DispatchActions.dispatchPlenumSize, PlenumSizeCmd)
      ];
    }
    case "dispatchNotesAndDocuments": {
      if (state.modal.type !== "NotesAndDocuments") {
        return [state];
      }
      const [notesAndDocumentsState, notesAndDocumentsCmd, sharedStateCmd] =
        NotesAndDocuments.update(action.action, state.modal.state, sharedState);
      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: notesAndDocumentsState
          }
        },
        undefined,
        [sharedStateCmd],
        Cmd.map(DispatchActions.dispatchNotesAndDocuments, notesAndDocumentsCmd)
      ];
    }
    case "dispatchMultipleSystemsEditor": {
      if (state.modal.type !== "MultipleSystemsEditor") {
        return [state];
      }

      const [
        multipleSystemsEditorState,
        multipleSystemsEditorCmd,
        sharedStateCmd
      ] = MultipleSystemsEditor.update(
        action.action,
        state.modal.state,
        sharedState
      );

      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: multipleSystemsEditorState
          }
        },
        undefined,
        [sharedStateCmd],
        Cmd.map(
          DispatchActions.dispatchMultipleSystemsEditor,
          multipleSystemsEditorCmd
        )
      ];
    }
    case "dispatchDataCenterEditor": {
      if (state.modal.type !== "DataCenterEditor") {
        return [state];
      }

      const [dataCenterEditorState, dataCenterEditorCmd, sharedStateCmd] =
        DataCenterEditor.update(action.action, state.modal.state, sharedState);

      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: dataCenterEditorState
          }
        },
        undefined,
        [sharedStateCmd],
        Cmd.map(DispatchActions.dispatchDataCenterEditor, dataCenterEditorCmd)
      ];
    }
    case "dispatchCondensationAnalysis": {
      if (state.modal.type !== "CondensationAnalysis") {
        return [state];
      }

      const [condensationState, condensationActionCmd, sharedStateCmd] =
        CondensationAnalysis.update(
          action.action,
          state.modal.state,
          sharedState
        );

      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: condensationState
          }
        },
        undefined,
        [sharedStateCmd],
        Cmd.map(
          DispatchActions.dispatchCondensationAnalysis,
          condensationActionCmd
        )
      ];
    }
    case "dispatchLockedSystemCalculation": {
      if (state.modal.type !== "LockedSystemCalculation") {
        return [state];
      }

      const [
        lockedSystemCalculationState,
        lockedSystemCalculationActionCmd,
        sharedStateCmd
      ] = LockedSystemCalculation.update(
        action.action,
        state.modal.state,
        sharedState
      );

      return [
        {
          ...state,
          modal: {
            ...state.modal,
            state: lockedSystemCalculationState
          }
        },
        undefined,
        [sharedStateCmd],
        Cmd.map(
          DispatchActions.dispatchLockedSystemCalculation,
          lockedSystemCalculationActionCmd
        )
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}

// tslint:disable-next-line: max-file-line-count
