import * as React from "react";
import styled from "styled-components";
import { Dispatch } from "@typescript-tea/core";
import { Action } from "./state";
import * as Elements from "@genesys/ui-elements";

const StyledFieldset = styled.fieldset`
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px;

  legend {
    padding: 10px;
  }
`;

const SearchInput = styled(Elements.Input)`
  height: 32px;
  width: 100px;
  padding-left: 6px;
  margin-right: 8px;
`;

export function ImpersonationView({
  input,
  showError,
  dispatch
}: {
  readonly input: string;
  readonly showError: boolean;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  return (
    <StyledFieldset>
      <legend>Impersonation</legend>
      <div>
        <SearchInput
          value={input}
          onChange={e => {
            dispatch(Action.setImpersonationInput(e.target.value));
          }}
        />
        <Elements.StandardButton
          onClick={() => dispatch(Action.impersonateUser(input))}
          buttonType="PrimaryGreen"
          size="Small"
        >
          impersonate
        </Elements.StandardButton>
      </div>
      {showError && (
        <Elements.P1>There was an error trying to impersonate</Elements.P1>
      )}
    </StyledFieldset>
  );
}
