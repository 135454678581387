import * as SharedState from "../../shared-state";
import * as Product from "../product";
import * as System from "../system";
import * as TopMenu from "../top-menu";
import * as PerformanceOverview from "../performance-overview";
import { State } from "./types";

export const init = (
  sharedState: SharedState.State,
  system: System.System,
  productData: Product.ProductData
): readonly [State, SharedState.Action] => {
  return [
    {
      system: system,
      alertSliderExpanded: system.components.length > 0,
      showConfirmLockRevision: false,
      showEnergyBinSelectionsValidationError: false,
      productData: productData,
      modal: { type: "NoModal" },
      topMenu: TopMenu.init(sharedState),
      diagramMode: {
        type: "DefaultMode"
      },
      performanceOverview: PerformanceOverview.init(),
      actionCompleteUrl: undefined,
      overlay: { type: "OverlayNone" },
      exchangeComponentDialog: undefined,
      runningMutations: 0,
      systemActionsState: undefined,
      diagramHoverState: "no-hover",
      genericDialogState: undefined,
      selectedAirPosition: undefined,
      expandedGroups: {
        exceptions: new Set(),
        errors: new Set(),
        criticalWarnings: new Set(),
        warnings: new Set()
      }
    },
    SharedState.Action.updateLastOpenedSystems(system.id)
  ];
};
