import {
  PropertyValueSet,
  PropertyFilter,
  PropertyValue
} from "@genesys/property";
const showOutdoorAirSource = "showoutdoorairsource";
const showReturnAirSource = "showreturnairsource";
const showCustomAirSource = "showcustomairsource";
const premMixingBoxExists = "premixingboxexists";
//const preCoolerExists = "precoolerexists"; precooler only exists as makeup air
const preHeaterExists = "preheaterexists";
//const preHeaterMakeupExists = "preheatermakeupexists"  preheater makeup air doesn't exist
const preCoolerMakeupExists = "precoolmakeupexists";
const preHeaterReactExists = "preheatreactexists";
const preCoolerReactExists = "precoolreactexists";
const postCoolerOrHeater = "postcoolerorheater";

export function ldpPreProcessOpc(
  newProperties: PropertyValueSet.PropertyValueSet
): PropertyValueSet.PropertyValueSet {
  let pvs = PropertyValueSet.Empty;

  // Show Outdoor air source
  pvs = PropertyValueSet.merge(
    {
      [showOutdoorAirSource]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("mauscope=1,2,3")!
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Show Return air source
  // Always 1 since mixing air stream always there
  pvs = PropertyValueSet.merge(
    {
      [showReturnAirSource]: PropertyValue.fromInteger(1)
    },
    pvs
  );

  // Show Custom air source
  pvs = PropertyValueSet.merge(
    {
      [showCustomAirSource]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("mauscope=0,2")
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Pre cooler in mau, blend cooling always present
  pvs = PropertyValueSet.merge(
    {
      [preCoolerMakeupExists]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("mauscope=1,3")
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Pre heater exists
  // for mau freeze protection
  pvs = PropertyValueSet.merge(
    {
      [preHeaterExists]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("mauscope=1,3&maufreezeprotection>0")
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // pre mixing box exists
  // always 1 currently
  pvs = PropertyValueSet.merge(
    {
      [premMixingBoxExists]: PropertyValue.fromInteger(1)
    },
    pvs
  );

  // Pre heater REACT exists
  pvs = PropertyValueSet.merge(
    {
      [preHeaterReactExists]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("mauscope=2,3")
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Pre cooler REACT exists
  pvs = PropertyValueSet.merge(
    {
      [preCoolerReactExists]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("mauscope=2,3")
        )
          ? 1
          : 0
      )
    },
    pvs
  );

  // Post treatment exists
  pvs = PropertyValueSet.merge(
    {
      [postCoolerOrHeater]: PropertyValue.fromInteger(
        PropertyFilter.isValid(
          newProperties,
          PropertyFilter.fromStringOrEmpty("postcooltype=0&postheattype=0")
        )
          ? 0
          : 1
      )
    },
    pvs
  );

  return pvs;
}
