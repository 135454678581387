import * as React from "react";
import { exhaustiveCheck } from "ts-exhaustive-check";
import styled from "styled-components";
import { Dispatch } from "@typescript-tea/core";
import * as Elements from "@genesys/ui-elements";
import { Modal, ModalContent } from "@genesys/ui-elements/lib/organisms/modal";
import * as SharedState from "../shared-state";
import {
  Action,
  State,
  LoadedState,
  categoryKey,
  All,
  LogRow,
  Empty
} from "./state";
import { FilterListComponent } from "./filter-list-component";
import {
  getGroupedParallellNodesElement,
  getStandardTimerLogElement
} from "./timer-elements";

export function View({
  dispatch,
  state,
  sharedState
}: {
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.State;
  readonly state: State;
}): JSX.Element {
  switch (state.type) {
    case "NoSearchState": {
      return (
        <div>
          <RefreshAndHelp isHelpOpen={state.isHelpOpen} dispatch={dispatch} />
          <div>NoSearchState</div>
        </div>
      );
    }
    case "FetchingState": {
      return (
        <div>
          <RefreshAndHelp isHelpOpen={state.isHelpOpen} dispatch={dispatch} />
          <div>FetchingState</div>
        </div>
      );
    }
    case "LoadedState": {
      return (
        <div>
          <RefreshAndHelp isHelpOpen={state.isHelpOpen} dispatch={dispatch} />
          <LoadedStateNavigationContainer>
            <Elements.StandardButton
              buttonType={
                state.view === "logs" ? "PrimaryBlue" : "SecondaryBlue"
              }
              size="Small"
              onClick={() => dispatch(Action.setView("logs"))}
            >
              logs
            </Elements.StandardButton>
            <Elements.StandardButton
              buttonType={
                state.view === "timers" ? "PrimaryBlue" : "SecondaryBlue"
              }
              size="Small"
              onClick={() => dispatch(Action.setView("timers"))}
            >
              timers
            </Elements.StandardButton>
          </LoadedStateNavigationContainer>

          {state.view === "logs" ? (
            <LogsLoadedView
              dispatch={dispatch}
              sharedState={sharedState}
              state={state}
            />
          ) : (
            <TimersView
              state={state}
              sharedState={sharedState}
              dispatch={dispatch}
            />
          )}
        </div>
      );
    }
    default: {
      return exhaustiveCheck(state, true);
    }
  }
}

const LoadedStateNavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0;
  button {
    margin-left: 5px;
  }
`;

const RefreshAndHelpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: solid #ddd 1px;
  padding: 10px;
  input {
    width: 360px;
  }
`;

const HelpIconContainer = styled.div`
  cursor: pointer;
  img {
    position: relative;
    top: 2px;
    left: 12px;
  }
`;

function RefreshAndHelp({
  isHelpOpen,
  dispatch
}: {
  readonly isHelpOpen: boolean;
  readonly dispatch: Dispatch<Action>;
}): JSX.Element {
  return (
    <RefreshAndHelpContainer>
      {isHelpOpen && (
        <ModalComponent onClose={() => dispatch(Action.setIsHelpOpen(false))} />
      )}
      <Elements.StandardButton
        buttonType="PrimaryGreen"
        size="Large"
        onClick={() => {
          dispatch(Action.RetrieveTelemetry());
        }}
      >
        Refresh
      </Elements.StandardButton>
      <HelpIconContainer onClick={() => dispatch(Action.setIsHelpOpen(true))}>
        <Elements.HelpIcon />
      </HelpIconContainer>
    </RefreshAndHelpContainer>
  );
}

const ResultsContainer = styled.div`
  padding: 5px 10px;
`;
const ExportContainer = styled.div`
  padding: 5px 10px;
`;
const FilterContainer = styled.div`
  padding: 5px 10px;
  border-bottom: 1px #ddd solid;

  ul {
    list-style-type: none;
  }
`;
const CategoriesContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  column-gap: 15px;

  h2 {
    padding: 10px 0;
  }
`;

const RegexContainer = styled.div`
  button {
    position: relative;
    bottom: 15px;
    left: 10px;
  }
`;

const RowsContainer = styled.div`
  padding: 10px 0;
  max-width: 100%;

  p {
    white-space: pre;
  }

  p:nth-child(2n + 2) {
    background-color: rgb(211, 211, 211);
  }
`;

const TextArea = styled(Elements.TextArea)`
  width: 700px;
`;

function LogsLoadedView({
  dispatch,
  state
}: {
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.State;
  readonly state: LoadedState;
}): JSX.Element {
  const regex = new RegExp(state.lineFilterRegex, "i");
  const emptyCaseType = state.enabledCaseTypes.has(Empty);
  const emptyCaseName = state.enabledCaseNames.has(Empty);
  const emptyComponent = state.enabledComponents.has(Empty);
  const filteredRows = state.logRows.filter(
    r =>
      state.enabledServicesAndCategories.has(
        categoryKey(r.serviceName, r.category)
      ) &&
      regex.test(r.body) &&
      ((emptyCaseType && r.caseType === "") ||
        state.enabledCaseTypes.has(r.caseType)) &&
      ((emptyCaseName && r.caseName === "") ||
        state.enabledCaseNames.has(r.caseName)) &&
      ((emptyComponent && r.component === "") ||
        state.enabledComponents.has(r.component))
  );

  return (
    <ResultsContainer>
      <ExportContainer>
        <Elements.StandardButton
          buttonType="PrimaryBlue"
          size="Small"
          onClick={() =>
            dispatch(
              Action.createExportFile(
                filteredRows.map(r => r.formatted).join("\n")
              )
            )
          }
        >
          Export
        </Elements.StandardButton>
        {state.exportFileUrl && (
          <a
            style={{ display: "none" }}
            ref={e => {
              if (e) {
                e.click();
                dispatch(Action.clearExportFile());
              }
            }}
            href={state.exportFileUrl}
            download={`Server Log - ${state.traceIdField}.txt`}
          />
        )}
      </ExportContainer>
      <FilterContainer>
        <Elements.StandardButton
          buttonType="SecondaryBlue"
          size="Small"
          onClick={() => dispatch(Action.toggleFilterContainer())}
        >
          {state.showFilter ? "Hide filter" : "Show filter"}
        </Elements.StandardButton>
        ({filteredRows.length} / {state.logRows.length})
        {state.showFilter && (
          <div>
            <div>
              <Elements.StandardButton
                buttonType="SecondaryBlue"
                size="Small"
                onClick={() => dispatch(Action.toggleCategoriesContainer())}
              >
                {state.showCategoryFilter
                  ? "Hide categories"
                  : "Show categories"}
              </Elements.StandardButton>
              {state.showCategoryFilter && (
                <>
                  <CategoriesContainer>
                    {Array.from(state.servicesWithCategories.entries()).map(
                      ([serviceName, categories]) => (
                        <div key={serviceName}>
                          <h2>{serviceName}</h2>
                          <ul>
                            <li>
                              <Elements.CheckBox
                                isChecked={state.enabledServicesAndCategories.has(
                                  categoryKey(serviceName, All)
                                )}
                                onClick={() => {
                                  dispatch(
                                    Action.toggleCategory(serviceName, All)
                                  );
                                }}
                                children="All"
                              />
                            </li>
                            {Array.from(categories.values())
                              .sort((a, b) => a.localeCompare(b))
                              .filter(c => c !== All)
                              .map(c => (
                                <li key={c}>
                                  <Elements.CheckBox
                                    isChecked={state.enabledServicesAndCategories.has(
                                      categoryKey(serviceName, c)
                                    )}
                                    onClick={() => {
                                      dispatch(
                                        Action.toggleCategory(serviceName, c)
                                      );
                                    }}
                                    children={c}
                                  />
                                </li>
                              ))}
                          </ul>
                        </div>
                      )
                    )}
                  </CategoriesContainer>
                </>
              )}
            </div>
            <div>
              <Elements.StandardButton
                buttonType="SecondaryBlue"
                size="Small"
                onClick={() => dispatch(Action.toggleAdvancedContainer())}
              >
                {state.showAdvancedFilter ? "Hide advanced" : "Show advanced"}
              </Elements.StandardButton>
              {state.showAdvancedFilter && (
                <>
                  <CategoriesContainer>
                    {FilterListComponent(
                      "CaseTypes",
                      state.caseTypes,
                      state.enabledCaseTypes,
                      (id: string) => {
                        dispatch(Action.toggleCaseType(id));
                      }
                    )}
                    {FilterListComponent(
                      "Case names",
                      state.caseNames,
                      state.enabledCaseNames,
                      (id: string) => {
                        dispatch(Action.toggleCaseName(id));
                      }
                    )}

                    {FilterListComponent(
                      "Components",
                      state.components,
                      state.enabledComponents,
                      (id: string) => {
                        dispatch(Action.toggleComponent(id));
                      }
                    )}
                  </CategoriesContainer>
                </>
              )}
            </div>
            <div>
              <RegexContainer>
                <TextArea
                  placeholder="Line filter regex"
                  value={state.lineFilterRegexField}
                  onChange={e =>
                    dispatch(Action.setLineFilterRegexField(e.target.value))
                  }
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      dispatch(Action.setLineFilterRegex());
                    }
                  }}
                />
              </RegexContainer>
              <Elements.StandardButton
                buttonType="PrimaryGreen"
                size="Large"
                onClick={() => {
                  dispatch(Action.setLineFilterRegex());
                }}
                disabled={state.lineFilterRegexField === state.lineFilterRegex}
              >
                Apply Filter
              </Elements.StandardButton>
            </div>
          </div>
        )}
      </FilterContainer>
      <LogRows rows={filteredRows} />
    </ResultsContainer>
  );
}

function LogRows({
  rows
}: {
  readonly rows: ReadonlyArray<LogRow>;
}): JSX.Element {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    if (ref.current) {
      // Get how many pixels is left of the screen and magic number(trial and error)
      // to avoid page scrollling
      const rootTop = ref.current.getBoundingClientRect().top + 20;
      const rootHeight = window.innerHeight - rootTop;
      ref.current.style.height = `${rootHeight}px`;
    }
  });
  return (
    <div ref={ref} style={{ overflow: "auto" }}>
      <RowsContainer>
        {rows.length === 0 && <div>Could not load logs</div>}
        {rows.map(r => (
          <p key={r.id}>{r.formatted}</p>
        ))}
      </RowsContainer>
    </div>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 10px;
`;

function ModalComponent({ onClose }: { readonly onClose: () => void }) {
  return (
    <Modal maxWidth={600} onClose={onClose}>
      <ModalContent>TODO</ModalContent>
      <ButtonContainer>
        <Elements.StandardButton
          buttonType="SecondaryGreen"
          size="Small"
          onClick={() => onClose()}
        >
          Close
        </Elements.StandardButton>
      </ButtonContainer>
    </Modal>
  );
}

const TimerViewRoot = styled.div`
  padding: 12px 24px;
`;

function TimersView({
  state
}: {
  readonly dispatch: Dispatch<Action>;
  readonly sharedState: SharedState.State;
  readonly state: LoadedState;
}): JSX.Element {
  const ref = React.useRef<HTMLTextAreaElement>(null);
  React.useLayoutEffect(() => {
    if (ref.current) {
      const rootTop = ref.current.getBoundingClientRect().top + 20;
      const rootHeight = window.innerHeight - rootTop;
      ref.current.style.height = `${rootHeight}px`;
    }
  });

  const singleNodeTimerLogs = state.topLevelNodes.singleNodes.map(sn =>
    getStandardTimerLogElement(sn, true)
  );
  const multipleParallellNodeTimerLog =
    state.topLevelNodes.multipleParallellNode &&
    getGroupedParallellNodesElement(state.topLevelNodes.multipleParallellNode);
  return (
    <TimerViewRoot>
      {singleNodeTimerLogs.length === 0 &&
        multipleParallellNodeTimerLog !== undefined && (
          <div>Could not load timers</div>
        )}
      {singleNodeTimerLogs.map((timerLog, ix) => (
        <div key={"key" + ix}>{timerLog}</div>
      ))}
      {multipleParallellNodeTimerLog && (
        <div key="hejhej">{multipleParallellNodeTimerLog}</div>
      )}
    </TimerViewRoot>
  );
}
