import * as React from "react";
import { isDesktop } from "react-device-detect";
import { Dispatch } from "@typescript-tea/core";
import {
  StandardButton,
  MenuButton,
  MainLogoYellow,
  MainLogo,
  MainLogoGreen,
  MainLogoPurple,
  HelpIcon,
  ProfileIcon,
  WithMenu,
  SettingsIcon,
  ToolTipWrapper
} from "@genesys/ui-elements";
import {
  MenuBarContainer,
  StyledA,
  DropDownContainer,
  TopOuter,
  DropDownMenu,
  FixedContainer
} from "./elements";
import { State, Action } from "./state";
import { clientConfig, Environment } from "../config";
import { UserManager } from "oidc-client";
import { hasPermissionForMlc } from "../moisture-load-calculation";
import { exhaustiveCheck } from "ts-exhaustive-check";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import * as Authorization from "@genesys/shared/lib/authorization";
import * as SharedState from "../shared-state";

export const MainMenuView = ({
  dispatch,
  logoClicked,
  state,
  sharedState
}: {
  readonly dispatch: Dispatch<Action>;
  readonly logoClicked: () => void;
  readonly state: State;
  readonly sharedState: SharedState.State;
}) => {
  const canUsePricing = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysPricingClaims.canUsePricing
  );

  const canSeePricing = Authorization.checkPermission(
    sharedState.user.applicationClaims,
    Authorization.genesysPricingClaims.canSeePricing
  );

  const canSeeMoistureload = hasPermissionForMlc(
    sharedState.user.applicationClaims
  );

  const canSeeSystemSelectionGuide =
    (Authorization.checkPermission(
      sharedState.user.applicationClaims,
      Authorization.genesysUserClaims.developer
    ) ||
      Authorization.checkPermission(
        sharedState.user.applicationClaims,
        Authorization.genesysUserClaims.canUseSystemSelectionGuide
      )) &&
    isDesktop;

  const canSeeMRC =
    Authorization.checkPermission(
      sharedState.user.applicationClaims,
      Authorization.genesysUserClaims.canSeeMRC
    ) && isDesktop;

  const enviroment = clientConfig.environment;

  const [borderColor, genesysLogo] = getEnviromentVariables(enviroment);

  return (
    <TopOuter>
      <FixedContainer>
        <MenuBarContainer>
          <ul>
            <li>
              <div
                onClick={e => {
                  e.stopPropagation();
                  logoClicked();
                }}
              >
                {genesysLogo}
              </div>
            </li>
            <li>
              <a href="/">
                <MenuButton
                  children={sharedState
                    .translate(LanguageTexts.dashboard())
                    .toUpperCase()}
                  hasMenu={false}
                />
              </a>
            </li>
            <li>
              <a href="/system-manager">
                <MenuButton
                  children={sharedState
                    .translate(LanguageTexts.systems())
                    .toUpperCase()}
                  hasMenu={false}
                />
              </a>
            </li>
            {(canUsePricing || canSeePricing) && (
              <li>
                <DropDownContainer
                  onMouseEnter={() => {
                    dispatch(Action.togglePricingDrowdown());
                    dispatch(Action.closeSystemDropDown());
                  }}
                  onMouseLeave={() => dispatch(Action.togglePricingDrowdown())}
                >
                  <MenuButton
                    children={sharedState
                      .translate(LanguageTexts.pricing())
                      .toUpperCase()}
                    hasMenu={true}
                    isOpen={state.isPricingDropdownOpen}
                  />
                  {state.isPricingDropdownOpen && (
                    <DropDownMenu
                      onClick={() => {
                        dispatch(Action.togglePricingDrowdown());
                      }}
                    >
                      <a href="/pricing-manager">
                        <MenuButton
                          children={sharedState.translate(
                            LanguageTexts.pricingmanager()
                          )}
                          hasMenu={false}
                        />
                      </a>
                      {canUsePricing && (
                        <a href="/new-pricing">
                          <MenuButton
                            children={sharedState.translate(
                              LanguageTexts.newPricing()
                            )}
                            hasMenu={false}
                          />
                        </a>
                      )}
                    </DropDownMenu>
                  )}
                </DropDownContainer>
              </li>
            )}
            <li>
              <DropDownContainer
                onMouseEnter={() => {
                  dispatch(Action.toggleToolsDrowdown());
                  dispatch(Action.closeSystemDropDown());
                }}
                onMouseLeave={() => dispatch(Action.toggleToolsDrowdown())}
              >
                <MenuButton
                  children={sharedState
                    .translate(LanguageTexts.tools())
                    .toUpperCase()}
                  isOpen={state.isToolsDropdownOpen}
                  hasMenu={true}
                />
                {state.isToolsDropdownOpen && (
                  <DropDownMenu
                    onClick={() => {
                      dispatch(Action.toggleToolsDrowdown());
                    }}
                  >
                    <a href="/tools/air-mixing">
                      <MenuButton
                        children={sharedState.translate(
                          LanguageTexts.airMixer()
                        )}
                        hasMenu={false}
                      />
                    </a>
                    <a href="/tools/air-power">
                      <MenuButton
                        children={sharedState.translate(
                          LanguageTexts.airPowerTool()
                        )}
                        hasMenu={false}
                      />
                    </a>
                    <a href="/tools/humidity-conversion">
                      <MenuButton
                        children={sharedState.translate(
                          LanguageTexts.humidityConversion()
                        )}
                        hasMenu={false}
                      />
                    </a>
                    <a href="/tools/psychrometric-chart">
                      <MenuButton
                        children={sharedState.translate(
                          LanguageTexts.psychrometricChart()
                        )}
                        hasMenu={false}
                      />
                    </a>
                    <a href="/tools/weather-data-binning">
                      <MenuButton
                        children={sharedState.translate(
                          LanguageTexts.binPage()
                        )}
                        hasMenu={false}
                      />
                    </a>
                  </DropDownMenu>
                )}
              </DropDownContainer>
            </li>
            {canSeeMoistureload && (
              <li>
                <DropDownContainer
                  onMouseEnter={() => {
                    dispatch(Action.toggleMoistureLoadDropdown());
                    dispatch(Action.closeSystemDropDown());
                  }}
                  onMouseLeave={() =>
                    dispatch(Action.toggleMoistureLoadDropdown())
                  }
                >
                  <MenuButton
                    children={
                      isDesktop
                        ? sharedState
                            .translate(LanguageTexts.moistureLoad())
                            .toUpperCase()
                        : "MLC"
                    }
                    hasMenu={true}
                    show={canSeeMoistureload}
                    isOpen={state.isMoistureLoadDropdownOpen}
                  />
                  {state.isMoistureLoadDropdownOpen && (
                    <DropDownMenu
                      onClick={() => {
                        dispatch(Action.toggleMoistureLoadDropdown());
                      }}
                    >
                      <a href="/moisture-load-manager">
                        <MenuButton
                          children={sharedState.translate(
                            LanguageTexts.moistureLoadManager()
                          )}
                          hasMenu={false}
                        />
                      </a>
                      <a href="/new-moisture-load">
                        <MenuButton
                          children={sharedState.translate(
                            LanguageTexts.newMoistureLoadCalc()
                          )}
                          hasMenu={false}
                        />
                      </a>
                    </DropDownMenu>
                  )}
                </DropDownContainer>
              </li>
            )}
            <li>
              <a href="/new-system">
                <StandardButton
                  size="Small"
                  buttonType="PrimaryBlue"
                  style={{ border: 0 }}
                >
                  {sharedState
                    .translate(LanguageTexts.createNewSystem())
                    .toUpperCase()}
                </StandardButton>
              </a>
            </li>
            {canSeeSystemSelectionGuide && (
              <li>
                <a href="/system-selection-guide">
                  <StandardButton
                    size="Small"
                    buttonType="PrimaryBlue"
                    style={{ border: 0 }}
                  >
                    Selection Guide
                  </StandardButton>
                </a>
              </li>
            )}
            {canSeeMRC && (
              <li>
                <StandardButton
                  size="Small"
                  buttonType="PrimaryBlue"
                  style={{ border: 0 }}
                  onClick={() =>
                    window.open("https://mrc.genesys.munters.com/")
                  }
                >
                  MRC
                </StandardButton>
              </li>
            )}
          </ul>
          <ul>
            <li>
              <WithMenu
                icon={<HelpIcon width="20px" />}
                values={[
                  {
                    value: sharedState.translate(LanguageTexts.wiki()),
                    onClick: () =>
                      window.open("https://wiki.genesys.munters.com/")
                  },
                  {
                    value: sharedState.translate(LanguageTexts.changeLog()),
                    onClick: () => {
                      window.open(
                        `${clientConfig.wikiJsBaseUrl.replace(
                          "/graphql",
                          "/Changelogs"
                        )}`
                      );
                    }
                  },
                  {
                    value: sharedState.translate(LanguageTexts.about()),
                    onClick: e => {
                      if (e.ctrlKey) {
                        return window.open("/about");
                      }
                      return dispatch(Action.navigate("/about"));
                    }
                    // href: "/about"
                  }
                ]}
              />
            </li>
            <li>
              <ToolTipWrapper
                padding="0px"
                title={sharedState.translate(LanguageTexts.usersettings())}
              >
                <StyledA href="/user-settings">
                  <SettingsIcon />
                </StyledA>
              </ToolTipWrapper>
            </li>
            <li>
              <WithMenu
                icon={<ProfileIcon width="20px" />}
                values={[
                  {
                    value:
                      sharedState.translate(LanguageTexts.loggedinuser()) +
                      ": " +
                      sharedState.user.userName,
                    onClick: () => {
                      return undefined;
                    }
                  },
                  {
                    value: sharedState.translate(
                      LanguageTexts.requestAccessLevel()
                    ),
                    onClick: () => window.open("https://stsusers.munters.com/")
                  },
                  {
                    value: sharedState.translate(LanguageTexts.logout()),
                    onClick: async () => {
                      const manager = new UserManager({
                        authority: clientConfig.oidcAuthority,
                        client_id: clientConfig.oidcClientId,
                        redirect_uri: clientConfig.oidcRedirectUri,
                        post_logout_redirect_uri: window.location.origin,
                        response_type: clientConfig.oidcResponseType,
                        scope: clientConfig.oidcScope,
                        monitorSession: false,
                        loadUserInfo: false
                      });
                      await manager.signoutRedirect();
                    }
                  }
                ]}
              />
            </li>
          </ul>
        </MenuBarContainer>
        {enviroment !== "Production" && (
          <div
            style={{
              backgroundColor: borderColor,
              height: "8px"
            }}
          ></div>
        )}
      </FixedContainer>
    </TopOuter>
  );
};

function getEnviromentVariables(env: Environment): [string, JSX.Element] {
  switch (env) {
    case "Localhost":
      return ["#A020F0", <MainLogoPurple width="106px" />];
    case "Develop": {
      return ["#46D580", <MainLogoGreen width="106px" />];
    }
    case "Staging": {
      return ["#F0C530", <MainLogoYellow width="106px" />];
    }

    case "Production": {
      return ["", <MainLogo width="106px" />];
    }

    default: {
      return exhaustiveCheck(env);
    }
  }
}
