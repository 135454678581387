import { exhaustiveCheck } from "ts-exhaustive-check";
import * as SharedState from "../../../shared-state";
import { Cmd } from "@typescript-tea/core";
import * as GraphQlTypes from "../../../graphql-types";
import {
  CtorsUnion,
  ctorsUnion
} from "@genesys/client-core/lib/constructors-union";
import { PropertyFilter, PropertyValueSet } from "@genesys/property";
import { Quantity, Unit } from "@genesys/uom";
import * as LanguageTexts from "@genesys/shared/lib/language-texts";
import { SketchProvider, SketchItem, StaticImage, Format } from "./types";
import { sketchProviderQuery, sketchMetaDataQuery } from "./queries";
import * as Product from "../../product";

export type State = {
  readonly selectedFormat: Format | undefined;
  readonly sketchProvider: SketchProvider | undefined;
  readonly isLoading: boolean;
  readonly formats: ReadonlyArray<Format>;
  readonly components: ReadonlyArray<SketchItem>;
  readonly casings: ReadonlyArray<SketchItem>;
  readonly staticImages: ReadonlyArray<StaticImage>;
};

export const init = (
  systemTypeImages: ReadonlyArray<Product.Image>,
  systemTypeId: string,
  sharedState: SharedState.State
): [State, Cmd<Action>] => {
  return [
    {
      selectedFormat: undefined,
      sketchProvider: undefined,
      isLoading: true,
      formats: [],
      components: [],
      casings: [],
      staticImages: []
    },
    sharedState.graphQL.queryProductCmd<
      GraphQlTypes.MechanicalSketchProduct,
      GraphQlTypes.MechanicalSketchProductVariables,
      Action
    >(
      sketchProviderQuery,
      {
        systemType: {
          systemTypeId
        }
      },
      data => Action.parseProductData(data, systemTypeImages)
    )
  ];
};

export const Action = ctorsUnion({
  parseProductData: (
    data: GraphQlTypes.MechanicalSketchProduct,
    systemTypeImages: ReadonlyArray<Product.Image>
  ) => ({
    data,
    systemTypeImages
  }),
  parseSketchMetaData: (
    data: GraphQlTypes.MechanicalSketchSolidContactUser
  ) => ({ data }),
  setFormat: (format: Format) => ({ format }),
  setIsLoading: (isLoading: boolean) => ({ isLoading }),
  onFormatChanged: (
    fieldGroup: string,
    fieldName: string,
    unit: Unit.Unit<Quantity.Quantity>,
    decimalCount: number
  ) => ({ fieldGroup, fieldName, unit, decimalCount }),
  onFormatCleared: (fieldGroup: string, fieldName: string) => ({
    fieldGroup,
    fieldName
  })
});
export type Action = CtorsUnion<typeof Action>;

export function update(
  action: Action,
  state: State,
  sharedState: SharedState.State,
  systemTypeId: string,
  sysProperties: PropertyValueSet.PropertyValueSet
): [State, Cmd<Action>?, SharedState.Action?] {
  switch (action.type) {
    case "parseProductData": {
      const validSketchProviderRow =
        action.data.product.systemType.mechanicalSketchProviders.find(m =>
          PropertyFilter.isValid(
            sysProperties,
            PropertyFilter.fromString(m.propertyFilter || "") ||
              PropertyFilter.Empty
          )
        );

      const sketchProvider: SketchProvider | undefined =
        validSketchProviderRow?.mechanicalSketchProvider === "SOLID_CONTACT"
          ? "SolidContact"
          : validSketchProviderRow?.mechanicalSketchProvider === "STATIC_IMAGE"
          ? "StaticImage"
          : undefined;

      if (sketchProvider === "SolidContact") {
        const formats: Array<Format> = [
          {
            id: "DXF2D",
            format: "DXF"
          },
          {
            id: "SVG",
            format: "SVG"
          }
        ];

        const selectedFormat: Format = formats[0];

        return [
          {
            ...state,
            sketchProvider: sketchProvider,
            selectedFormat: selectedFormat,
            formats: formats
          },
          sharedState.graphQL.queryUserCmd<
            GraphQlTypes.MechanicalSketchSolidContactUser,
            GraphQlTypes.MechanicalSketchSolidContactUserVariables,
            Action
          >(
            sketchMetaDataQuery,
            {
              mechanicalSketch: {
                systemId: systemTypeId,
                provider: "SolidContact"
              }
            },
            Action.parseSketchMetaData
          )
        ];
      } else if (sketchProvider === "StaticImage") {
        const staticImages: ReadonlyArray<StaticImage> = action.systemTypeImages
          .filter(
            img =>
              img.imageUsage === "MECHANICAL_SKETCH" &&
              PropertyFilter.isValid(sysProperties, img.propertyFilter)
          )
          .map(img => ({
            format: img.imageFormat,
            url: img.url
          }));

        const formats: ReadonlyArray<Format> = staticImages.reduce(
          (soFar, current) => {
            const format = current.format.toUpperCase();
            return soFar.find(sf => sf.id === format) === undefined
              ? soFar.concat([
                  {
                    id: format,
                    format: format
                  }
                ])
              : soFar;
          },
          [] as ReadonlyArray<Format>
        );

        const selectedFormat: Format | undefined =
          formats.length > 0 ? formats[0] : undefined;

        return [
          {
            ...state,
            sketchProvider: sketchProvider,
            selectedFormat: selectedFormat,
            formats: formats,
            staticImages: staticImages
          }
        ];
      } else {
        return [state];
      }
    }
    case "parseSketchMetaData": {
      const components: ReadonlyArray<SketchItem> =
        action.data.user.mechanicalSketchMetaData.components.map(c => ({
          id: c.id,
          label: c.label ?? "",
          ItemName: sharedState.translate(
            c.componentLabel
              ? LanguageTexts.curlyTranslate(
                  c.componentLabel,
                  c.productId.substring(0, 3)
                )
              : LanguageTexts.product(c.productId)
          ),
          inCasing: c.inCasing,
          weight: c.weight ?? undefined
        }));

      const casings: ReadonlyArray<SketchItem> =
        action.data.user.mechanicalSketchMetaData.casings.map(c => ({
          id: c.id,
          label: c.label ?? "",
          ItemName: sharedState.translate(
            c.componentLabel
              ? LanguageTexts.curlyTranslate(
                  c.componentLabel,
                  c.productId.substring(0, 3)
                )
              : LanguageTexts.product(c.productId)
          ),
          inCasing: c.inCasing,
          weight: c.weight ?? undefined
        }));

      return [
        {
          ...state,
          components: components,
          casings: casings
        }
      ];
    }
    case "setFormat": {
      return [
        {
          ...state,
          selectedFormat: action.format
        }
      ];
    }
    case "setIsLoading": {
      return [
        {
          ...state,
          isLoading: action.isLoading
        }
      ];
    }
    case "onFormatChanged": {
      return [
        state,
        ,
        SharedState.Action.saveAmountFormat(
          action.fieldGroup,
          action.fieldName,
          action.unit,
          action.decimalCount
        )
      ];
    }
    case "onFormatCleared": {
      return [
        state,
        undefined,
        SharedState.Action.clearAmountFormat(
          action.fieldGroup,
          action.fieldName
        )
      ];
    }
    default:
      return exhaustiveCheck(action, true);
  }
}
